import React, {useRef} from 'react';
import './footer.scss';
import './footerMobile.scss'
import { Link } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import ApplicationsLinks from "./ApplicationsLinks";

function Footer() {
  const {t} = useTranslation();
  const ref = useRef();

  return (
    <footer className='footer'>
      <div className='footer__container'>
        <ul className='footer__list'>
          <li className='footer__item'>
            <Link to={'/about'} className='footer__link'>
              {t('footer.aboutUs')}
            </Link>
          </li>
          <li className='footer__item'>
            <Link to={'/support'} className='footer__link'>
              {t('footer.support')}
            </Link>
          </li>
          <li className='footer__item'>
            <Link to={'/politics'} className='footer__link'>
              {t('footer.politics')}
            </Link>
          </li>
          <li className='footer__item'>
            <ApplicationsLinks/>
          </li>
        </ul>
        <div className='footer__sub-info'>
          <img
            src={require('../../assets/img/logo_single.png')}
            ref={ref}
            alt={'ABChess'}
            width={24}
            className={'footer__bonus'}
          />
          <span className='footer__sub-text'>ABCHESS {(new Date()).getFullYear()}</span>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
