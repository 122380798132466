import { SET_LANG, SHOW_JIVO } from './types';

const jivoLangs = {
  rus: `//code-ya.jivosite.com/widget/gnUfosl3dI`,
  eng: `//code-ya.jivosite.com/widget/mrTZfPX3sA`,
  kz: `//code-ya.jivosite.com/widget/E4qWZgMlxX`,
  es: `//code-ya.jivosite.com/widget/T6wt0eLRfG`,
  tr: `//code-ya.jivosite.com/widget/RTEAO9XDdZ`
}

let head = document.querySelector('head')

const excludeJivoURLS = ['webinar', 'partner']
const location = window.location

export function setLang(lang) {
  return (dispatch) => {
    (async function () {

      const jchat = document.querySelector('#jchat')

      if (jchat) {
        await jchat.remove()
      }

      if(!new RegExp(excludeJivoURLS.join('|')).test(location.pathname)){
        let newJchat = await document.createElement('script')

        newJchat.async = true
        newJchat.src = jivoLangs[lang]
        newJchat.id = 'jchat'
  
        head.appendChild(newJchat)
      }

    })()

    dispatch({
      type: SET_LANG,
      payload: lang
    });

  }
}