import React from 'react';
import {useTranslation} from "react-i18next";
import './contacts.scss';

export default function AboutContacts() {
  const {t} = useTranslation();

  return (
    <div className='about__contacts contacts'>
      <img src={require('../../../../assets/img/icon-building.png')} className='contacts__image'/>
      <div className='contacts__container'>
        <p className='contacts__title'>{t('contacts.contacts')}</p>
        <ul className='contacts__list'>
          <li className='contacts__item'>
            <span className='contacts__text'>{t('contacts.date')}:</span>
            <span className='contacts__contact'>{t('contacts.year')}</span>
          </li>
          <li className='contacts__item'>
            <span className='contacts__text'>{t('contacts.address')}:</span>
            <span className='contacts__contact'>{t('contacts.contactAddress')}</span>
          </li>
          <li className='contacts__item'>
            <span className='contacts__text'>{t('contacts.founderText')}:</span>
            <span className='contacts__contact'>{t('contacts.founder')}</span>
          </li>
          <li className='contacts__item'>
            <span className='contacts__text'>{t('contacts.parentOrganization')}:</span>
            <span className='contacts__contact'>{t('contacts.organization')}</span>
          </li>
        </ul>
      </div>
      <img src={require('../../../../assets/img/icon-chess.png')} className='contacts__image'/>
    </div>
  )
}