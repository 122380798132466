import React from 'react';
import Modal from './modal';
import style from './modal.module.scss';
import { useTranslation } from 'react-i18next';
import { deleteLesson } from 'actions/webinar';
import { useDispatch } from 'react-redux';

export default function ModalConfirm({
  opened,
  close,
  action,
  args,
  onConfirm
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const actions = {
    webinar: {
      delete: {
        title: t('modalsContent.webinar.delete.title'),
        note: t('modalsContent.webinar.delete.note'),
        actions: {
          confirm: {
            label: t('modalsContent.webinar.delete.actions.confirm'),
            fn: () => {
              if (typeof args === 'object' && args.class_id) {
                dispatch(deleteLesson(args));
                if (typeof onConfirm === 'function') {
                  onConfirm();
                }
              }
            }
          },
          decline: {
            label: t('modalsContent.webinar.delete.actions.decline'),
            fn: () => {
              if (typeof close === 'function') {
                close();
              }
            }
          }
        }
      }
    }
  }

  const action_types = {
    webinar_delete: actions.webinar.delete
  }

  return (
    <Modal
    opened = { opened }
    close = { close }
    >
      <div className = { style.modal_content }>
        <div className = { style.modal_content_title_container }>
          <h3 className = { style.modal_content_title }>
            {
              action_types[action].title
            }
          </h3>
          <p className = { style.modal_content_text }>
            {
              action_types[action].note
            }
          </p>
        </div>
        <div className = { style.modal_actions }>
          <div className = { style.modal_actions_item }>
            <button
            onClick = { action_types[action].actions.confirm.fn }
            >
              {
                action_types[action].actions.confirm.label
              }
            </button>
          </div>
          <div className = { style.modal_actions_item }>
            <button
            onClick = { action_types[action].actions.decline.fn }
            >
              {
                action_types[action].actions.decline.label
              }
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}