import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { TOGGLE_LOGIN_WINDOW } from 'actions/types';
import { openOverlayAction } from '../../../../actions/overlay';
import Login from '../../../Login';

export default function CompletePopup({ closeModal, onNextClick, scores }) {
  const { t } = useTranslation();
  const auth = useSelector(state => state.auth.authenticated);
  const dispatch = useDispatch();

  const handleChange = () => {
    closeModal();
    onNextClick();
  };



  const getCircleColor = (str) => {
    if (str === 'speed' || str === 'strategy') {
      return 'chess-display__circle--green';
    }
    if (str === 'tactics' || str === 'perception') {
      return 'chess-display__circle--red';
    }
    if (str === 'memory' || str === 'endshpil') {
      return 'chess-display__circle--yellow';
    }
    if (str === 'debut' || str === 'logic') {
      return 'chess-display__circle--blue';
    }
    return 'chess-display__circle--blue';
  };

  if (!auth) {
    return (
      <div className="chess-display__popup">
        <div className="chess-display__popup-container">
          <span className="chess-display__popup-text" style={{ margin: '5px 0' }}>{t('exercises.taskIsComplete')}</span>
          <span className="chess-display__popup-text"
                style={{ margin: '5px 0 10px 0' }}>{t('exercises.pleaseLogin')}</span>
          <button className="chess-display__popup-button" type="button"
                  onClick={() => dispatch(openOverlayAction(Login))}>
            {t('exercises.toLogin')}
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="chess-display__popup">
      <div className="chess-display__popup-container">
        {!!scores.delta_rating &&
          <div className="chess-display__popup-rating">
            <div className={'chess-display__popup-rating__container'}>
              <span className={'chess-display__popup-rating__title'}>Ваш рейтинг изменился на</span>
              <span className={'chess-display__popup-rating__delta-rating'}>{scores.delta_rating <= 0 ? scores.delta_rating : '+' + scores.delta_rating}</span>
            </div>
            <div className={'chess-display__popup-rating__current-rating'}>Текущий рейтинг: <span style={{fontSize: 18, fontWeight: 'bold'}}>{scores.new_rating}</span></div>
          </div>
        }

        <div className="chess-display__popup-image" />
        {
          !!scores.achievements.length
          &&
          <div className={'chess-display__achievement'}>
            <span className={'chess-display__achievement__title'}>У вас новое достижение!</span>
            <span className={'chess-display__achievement__info'}>{scores.achievements[0].name}</span>
          </div>
        }
        <ul className="chess-display__popup-list">
          {Object.entries(scores.return_scores).map((el, index) => {
            if (el[1]) {
              return (
                <li className="chess-display__popup-item" key={'score' + index}>
                  <span className={`chess-display__circle ${getCircleColor(el[0])}`} />
                  <span className="chess-display__popup-text">{t(`exercises.${[el[0]]}`)}: <span className={'chess-display__popup-text__data'}>{el[1]}</span></span>
                </li>
              );
            }
          })}
        </ul>
        <button className="chess-display__popup-button" type="button" onClick={handleChange}>
          {t('exercises.next')}
        </button>
      </div>
    </div>
  );
}