import React, {useState} from "react";
import style from "./style.module.scss";
import {useTranslation} from "react-i18next";
import {getProfileInfo} from "../../../../../actions/getProfile";
import {useDispatch, useSelector} from "react-redux";
import {
    SET_TEMPORARY_AUTH
} from '../../../../../actions/types';

const EditNamePopup = ({submitFunc, closeModal}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {name, surname} = useSelector(state => state.profile.profileData);
    const { authenticated, temporary_auth } = useSelector(state => state.auth);
    
    const [form, setForm] = useState({
        name: name || '',
        surname: surname || ''
    });

    const handleInputChange = (e) => {
        const target = e.target;
        const value = target.value;
        const name = target.name;
        setForm({
            ...form,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        submitFunc(form.name, form.surname);
        closeModal();
        //Обновляем данные в дропдауне шапки
        if (authenticated) {
            dispatch(getProfileInfo())
        }
        else {
            const name = form.surname ? form.name + ' ' + form.surname : form.name;
            dispatch({
                type: SET_TEMPORARY_AUTH,
                payload: {
                    ...temporary_auth,
                    name
                }
            })
            localStorage.setItem('webinar-name', name);
        }
    }

    return (
        <div className={style.container}>
            <div className='login__header'>
                <p className='login__title'>{t('webinar.enterYourData')}</p>
            </div>
            <div className='login__body'>
                <form className='login__form' onSubmit={handleSubmit}>
                    <ul className='login__form-list'>
                        <li className='login__form-item'>
                            <label className='login__label'>
                                <input className='login__input' onChange={handleInputChange} value={form.name} name='name' placeholder={t('profile.name')} required/>
                            </label>
                        </li>
                        <li className='login__form-item'>
                            <label className='login__label'>
                                <input className='login__input login__input--password' value={form.surname} onChange={handleInputChange} name='surname' placeholder={t('profile.surname')}/>
                            </label>
                        </li>
                    </ul>
                    <button className={style.submitButton} disabled={!form.name && !form.surname}>{t('webinar.change')}</button>
                </form>
            </div>
        </div>
    )
}

export default EditNamePopup;