import React from 'react';
import TaskItem from './TaskItem';
import './tasks.scss';
import './tasksListMobile.scss';

export default function TasksList({coursesData, data}) {
  return (
    <div className='interface__tasks-container tasks'>
      <div className='tasks__list'>
        {coursesData && coursesData.map(el =>
          <TaskItem {...el} key={el.id} data={data}/>
        )}
      </div>
    </div>
  )
}