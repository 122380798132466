import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DISABLE_FULLSCREEN, SET_FULLSCREEN, TOGGLE_EXERCISES_LIST } from 'actions/types';
import './display.scss';
import './displayMobile.scss';
import './TaskText/taskTextMobile.scss';
import Fullsize from 'components/common/WindowButtons/Fullsize/Fullsize';
import HideSidebar from 'components/common/WindowButtons/HideSidebar/HideSidebar';

const TASKS_LIST = 'task list';

export default function MainDisplay({ children, toggleComponent, data, type }) {
  const open = useSelector(state => state.settings.exercisesList);
  const fullscreen = useSelector(state => state.settings.fullscreen);
  const dispatch = useDispatch();
  const [fullsize, setFullSize] = React.useState(open)

  const toggleMenu = useCallback(() => dispatch({ type: TOGGLE_EXERCISES_LIST }), [dispatch]);
  const setFullscreen = useCallback(() => { dispatch({ type: SET_FULLSCREEN }); setFullSize(!fullsize) }, [dispatch, fullsize]);
  const disableFullscreen = useCallback(() => { dispatch({ type: DISABLE_FULLSCREEN }); setFullSize(!fullsize) }, [dispatch, fullsize]);

  useEffect(() => {
    if ((data.content && !data.content.videoDescription) || !data.course_description) {
      toggleComponent(TASKS_LIST)
    }
  }, [data]);

  return (
    <div className='interface__task'>
      {/*type === 'lesson' && <span className={`interface__hide-button ${open ? '' : 'interface__hide-button--hidden'}`} onClick={toggleMenu} />*/}
      {type === 'lesson' && <span className={`interface__fullsize`}>
        <Fullsize open={fullsize} onClick={fullscreen ? disableFullscreen : setFullscreen} />
      </span>}
      {type === 'lesson' && <span className={`interface__hidesidebar`}><HideSidebar open={open} onClick={toggleMenu} /></span>}
      {children}
    </div>
  )
}