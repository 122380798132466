import React, {useCallback, useEffect, useState} from 'react';
import {useHttp} from 'hooks/http.hook';
import MyCoursesLoader from './MyCoursesLoader';

export default function MyCoursesList({apiRoute, noItemsMessage, ItemComponent, sortFunction}) {
  const [data, setData] = useState({courses: []});
  const {request, loading} = useHttp();

  const loadCourses = useCallback(async() => {
    try {
      const result = await request(apiRoute, 'get');
      setData(result.data);
    } catch (e) {
      console.log(e);
    }
  }, [apiRoute, request]);

  useEffect(() => {
    loadCourses();
  }, [loadCourses]);

  const prepareData = () => {
    let newData = Object.entries(data)[0][1];
    if(sortFunction && newData.length) {
      return sortFunction(newData)
    }
    return newData;
  };

  if(loading) {
    return <MyCoursesLoader/>
  }

  return (
    <ul className='courses__list'>
      {prepareData().length > 0 ? prepareData().map((el, index) => <ItemComponent {...el} key={'c' + index}/>) : <p>{noItemsMessage}</p>}
    </ul>
  )
}