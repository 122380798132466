import axios from 'axios';
import {FETCH_REGISTRATION, REGISTRATION, REGISTRATION_ERROR} from './types';
import {POST_REGISTRATION} from 'services/api';

export function signUpAction(data, callback) {
  return async (dispatch) => {
    dispatch({type: FETCH_REGISTRATION});
    try {
      const res = await axios.post(POST_REGISTRATION, data);
      if(res.status === 201 || res.status === 200) {
        dispatch({ type: REGISTRATION });
        callback();
      }
    } catch(error) {
      dispatch({
        type: REGISTRATION_ERROR,
        payload: error
      });
    }
  };
}

