import {
  SET_WEBINAR_RAITING,
  SET_WEBINAR_CURRENT_EXERCISE,
  SHOW_WEBINAR_SCORES,
  UPDATE_WEBINAR_RATING,
  SET_WEBINAR_TIMESTAMP,
  TRAINING_LESSON,
  DELETE_LESSON
} from './types';
import axios from 'axios';

export const deleteLesson = (payload) => async dispatch => {
  const { class_id } = payload;
  return axios.delete(`/api/v2/teacher/class/cancel`, {
    data: {
      class_id
    }
  });
}

export const trainingLesson = (payload) => ({
  type: TRAINING_LESSON,
  payload
})

export const setWebinarRaiting = (payload) => ({
  type: SET_WEBINAR_RAITING,
  payload
})

export const setWebinarCurrentExercise = (payload) => ({
  type: SET_WEBINAR_CURRENT_EXERCISE,
  payload
})

export const showWebinarScores = (payload) => ({
  type: SHOW_WEBINAR_SCORES,
  payload
})

export const updateWebinarRating = () => ({
  type: UPDATE_WEBINAR_RATING,
})

export const setWebinarTimestamp = (payload) => ({
  type: SET_WEBINAR_TIMESTAMP,
  payload,
})
