import React, {useEffect, useState} from "react";
import style from "./style.module.scss";
import {TimePickerCaret} from "../../../../../../assets/img/partners/timePickerCaret";

const TimePicker = ({time, changeTime, timeProperty, id}) => {
    const modified_picker = id === 'total_exercise_time';
    const [minutes, setMinutes] = useState(time.split(':')[1]);
    const [seconds, setSeconds] = useState(time.split(':')[2]);
    const [hours, setHours] = useState('00');

    useEffect(() => {
        if (`${minutes}:${seconds}` !== time) {
            changeTime(`00:${minutes}:${seconds}`, timeProperty, id)
        }
    }, [minutes, seconds])

    useEffect(() => {
        if (!modified_picker) {
            return;
        }
        const splitted = time.split(':');
        setMinutes(splitted[1]);
        setSeconds(splitted[2]);
        if (modified_picker) {
            setHours(splitted[0]);
        }
    }, [time]);

    const onlyNumbers = (target) => {
        let regexp = /^\d*$/;
        if (!regexp.test(target.value)) target.value = target.value.slice(0,-1);
    }

    const handleBlur = (e, setValueFunc) => {
        const value = e.target.value;
        if (value === '' || +value < 0) setValueFunc('00')
        else if (+value > 59 || value.length > 2) setValueFunc('59');
        else if (+value < 10) setValueFunc(`0${+value}`)
    };

    const buttonChangeValue = (value, setValueFunc, type) => {
        if ((+value === 59 && type === '+') || (+value === 0 && type === '-')) return false;
        if (type === '+') setValueFunc(+value+1 < 10 ? `0${+value+1}` : +value+1);
        else setValueFunc(+value-1 < 10 ? `0${+value-1}` : +value-1);
    }
    return (
        <div className={style.timePicker}>
            {
                modified_picker &&
                <>
                    <div className={style['timePicker-timeWrapper']}>
                    {
                        !modified_picker &&
                        <button onClick={() => buttonChangeValue(minutes, setMinutes, '+')}><TimePickerCaret rotate="180deg"/></button>
                    }
                    <input
                        className={style['timePicker-timeWrapper__input']}
                        value={hours}
                        onChange={e => setHours(e.target.value)}
                        onBlur={e => handleBlur(e, setHours)}
                        onInput={(e) => onlyNumbers(e.target)}
                    />
                    {
                        !modified_picker &&
                        <button onClick={() => buttonChangeValue(minutes, setMinutes, '-')}><TimePickerCaret /></button>
                    }
                </div>
                <p className={style.timePicker__colon}>:</p>
                </>
            }
            <div className={style['timePicker-timeWrapper']}>
                {
                    !modified_picker &&
                    <button onClick={() => buttonChangeValue(minutes, setMinutes, '+')}><TimePickerCaret rotate="180deg"/></button>
                }
                <input
                    className={style['timePicker-timeWrapper__input']}
                    value={minutes}
                    onChange={e => setMinutes(e.target.value)}
                    onBlur={e => handleBlur(e, setMinutes)}
                    onInput={(e) => onlyNumbers(e.target)}
                />
                {
                    !modified_picker &&
                    <button onClick={() => buttonChangeValue(minutes, setMinutes, '-')}><TimePickerCaret /></button>
                }
            </div>
            <p className={style.timePicker__colon}>:</p>
            <div className={style['timePicker-timeWrapper']}>
                {
                    !modified_picker &&
                    <button onClick={() => buttonChangeValue(seconds, setSeconds, '+')}><TimePickerCaret rotate="180deg"/></button>
                }
                <input
                    className={style['timePicker-timeWrapper__input']}
                    value={seconds}
                    onChange={e => setSeconds(e.target.value)}
                    onBlur={e => handleBlur(e, setSeconds)}
                    onInput={(e) => onlyNumbers(e.target)}
                />
                {
                    !modified_picker &&
                    <button onClick={() => buttonChangeValue(seconds, setSeconds, '-')}><TimePickerCaret /></button>
                }
            </div>
        </div>
    )
}

export default React.memo(TimePicker);