import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from "react-router-dom";

export default function SectionNavigationItem({ superId, supercategory_name, supercategory_id, categories, slug }) {
  const [open, setOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    let superCategory = location.pathname.split('/')[2];
    if (Number(superCategory) === Number(supercategory_id) || Number(superId) === Number(supercategory_id)) {
      setOpen(true)
    }
  }, [location]);

  if (categories.length === 0) {
    return <></>
  }

  return (
    <li className='section-navigation__item' key={supercategory_name}>
      <div className='section-navigation__super-category-group'>
        <div onClick={() => { setOpen(!open) }} className={`section-navigation__super-category-arrow ${open ? 'section-navigation__super-category-arrow--active' : ''}`} />
        <NavLink className='section-navigation__link' to={slug ? `/super-categories/${slug}/` : `/courses/${supercategory_id}`}>
          {supercategory_name}
        </NavLink>
      </div>
      {open &&
        <ul className='section-navigation__sub-list'>
          {categories.map(el =>
            <li className='section-navigation__sub-item' key={el.category_name || el.name}>
              <NavLink className={`section-navigation__sub-link`} exact to={slug ? `/super-categories/${slug}/${el.id}` : `/courses/${supercategory_id || el.super_course_category_id}/${el.category_id || el.id}`}>
                {el.category_name || el.name}
              </NavLink>
            </li>
          )}
        </ul>
      }
    </li>
  )
}
