import axios from 'axios';
import { AUTHENTICATION_ERROR, SET_TEMPORARY_AUTH } from '../actions/types';

export const PREFIX_V1 = '/api/v1';
export const PREFIX_V2 = '/api/v2';
export const PREFIX_V3 = '/api/v3';
export const USER = '/user';
export const INFO = '/info';

export const POST_REGISTRATION = `${PREFIX_V1}/register`;
export const POST_LOGIN = `${PREFIX_V1}/login`;
export const TEACHER_LOGIN = `${PREFIX_V2}/admin/auth`;
export const GET_COURSES_CATEGORIES = `${PREFIX_V1}/courses_categories`;
export const POST_COMPLETED_EXERCISE = `${PREFIX_V1}/exercise/completed`;
export const GET_BUYING_COURSES = `${PREFIX_V2}/user_buying_courses`;
export const GET_LATEST_PASSING_COURSES = `${PREFIX_V2}/user_latest_passing_courses`;
export const GET_COMPLETED_COURSES = `${PREFIX_V2}/user_completed_courses`;
export const GET_ALL_PASSING_COURSES = `${PREFIX_V2}/user_passing_courses`;
export const POST_GAME_BOT = `${PREFIX_V1}/user/game/bot`;
export const POST_USER_INFO = `${PREFIX_V1}/user/info`;
export const PUT_USER_PASSWORD = `${PREFIX_V1}/user/info/password`;
export const PASSWORD_RESET = `${PREFIX_V1}/user/reset/password`;
export const POST_USER_IMAGE = `${PREFIX_V1}/user/image`;
export const GET_USER_INFO = `${PREFIX_V1}/user/info`;
export const CANCEL_SUBSCRIPTION = `${PREFIX_V2}/payment/paddle/cancel_subscription`;
export const GET_ADVERTISING = `${PREFIX_V2}/advertising`;
export const GET_MISTAKES = `${PREFIX_V2}/user/mistakes`;
export const POST_SEND_QUESTION = `${PREFIX_V2}/send_question`;
export const GET_SUPER_CATEGORIES = `${PREFIX_V1}/courses_supercategories`;
export const GET_COURSES_LIST = `${PREFIX_V2}/courses_list?`;
export const GET_ALL_COURSES_LIST = `${PREFIX_V2}/courses_list?with_hidden=true`;

/**
 * Generate username
 * @returns string
 */
export const generateUsername = () =>
  axios
    .get(`${PREFIX_V3}/classes/generate_username`)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      console.error(err.msg || err.message);
    });

/**
 * Request access to webinar without registration
 * @param {string} student_name
 * @param {string} session_hash
 * @param {string} password
 */
export const temporaryAuth = (student_name, session_hash, password, webinar_id, onFinish) => async (dispatch) => {
  if (!student_name) {
    dispatch({
      type: AUTHENTICATION_ERROR,
      payload: 'Поле не может быть пустым',
    });
    return;
  }
  return axios
    .post(`${PREFIX_V2}/class/temporary_auth`, {
      student_name,
      session_hash,
      password,
    })
    .then((response) => {
      const data = [
        {
          key: 'webinar-class-status',
          value: response.data.class_status,
        },
        {
          key: 'webinar-session-token',
          value: response.data.session_token,
        },
        {
          key: 'webinar-name',
          value: student_name,
        },
        {
          key: 'webinar-session-hash',
          value: session_hash,
        },
        {
          key: 'webinar-id',
          value: webinar_id,
        },
      ];
      data.forEach((token) => {
        localStorage.setItem(token.key, token.value);
      });

      dispatch({
        type: SET_TEMPORARY_AUTH,
        payload: {
          class_status: response.data.class_status,
          session_token: response.data.session_token,
          name: student_name,
          webinar_id,
        },
      });
    })
    .catch((err) => {
      console.log(err);

      dispatch({
        type: AUTHENTICATION_ERROR,
        payload: err.response.data.message || err.response.data.description,
      });
    })
    .finally(() => {
      onFinish && onFinish();
    });
};

export const getClassInfo = (token, session_id) => {
  return axios.get(`${PREFIX_V2}/classes/session?id=${session_id}`, {
    headers: {
      'Class-Session': token,
    },
  });
};

export const getUserInfo = (token) => {
  return axios.get(POST_USER_INFO, { headers: { 'Access-Token': token } });
};

export const getPrivateSuperCategories = (slug, lang = 'rus') => {
  return axios.get(`${PREFIX_V3}/super-categories/${slug}/?lang=${lang}`);
};

export const getPartnerLessons = (type) => {
  return axios.get(`${PREFIX_V2}/classes/${type}`);
};

export const createLesson = (data) => {
  return axios.put(`${PREFIX_V2}/teacher/class/create`, data);
};

export const getLesson = (id) => {
  return axios.get(`${PREFIX_V2}/teacher/class?class_id=${id}`);
};

export const lessonAuth = (session_hash, password) => {
  return axios.post(`${PREFIX_V2}/class/auth`, { session_hash, password });
};

export const getLastUserAnswer = (token) => {
  return axios.get(`${PREFIX_V2}/student/class/exercise/current`, {
    headers: { 'Class-Session': token },
  });
};

export const updateWebinarCurrentExercise = (token, answer) => {
  return axios.put(
    `${PREFIX_V2}/student/class/exercise/current/update`,
    { last_answer: answer },
    { headers: { 'Class-Session': token } },
  );
//   Нагрузочное тестирование
};

export const getRating = (id, token) => {
  return axios.get(`${PREFIX_V2}/classes/session/rating?id=${id}`, {
    headers: { 'Class-Session': token },
  });
};

export const getSpecificCoursesList = (id) => {
  return axios.get(`${PREFIX_V2}/classes/courses/list?category_id=${id}`);
};

export const cancelLesson = (id) => {
  return axios.delete(`${PREFIX_V2}/teacher/class/cancel?class_id=${id}`);
};

export const getChatHistory = (token) => {
  return axios.get(`${PREFIX_V2}/classes/chats/history`, {
    headers: { 'Class-Session': token },
  });
};

export const webinarSocketUrl = (hash) => {
  return `${axios.defaults.baseURL}${PREFIX_V2}/session?id=${hash}`;
};

export const trainingWebinarSocketUrl = (hash) => {
  return `${axios.defaults.baseURL}${PREFIX_V2}/training-session?id=${hash}`;
};

export const getWebinarExercise = (id, token) => {
  return axios.get(`${PREFIX_V2}/classes/session/exercise/full?exercise_id=${id}`, {
    headers: { 'Class-Session': token },
  });
};

export const uploadLessonStatistic = (id) => {
  return axios.get(`${PREFIX_V2}/class/statistics/download?class_id=${id}`);
};

export const uploadLessonStudentAnswers = (id) => {
  return axios.get(`${PREFIX_V2}/class/answer/statistics/download?class_id=${id}`)
}

export const getWebinarStudentsList = (webinarId) => {
  return axios.get(`${PREFIX_V2}/class/students?class_id=${webinarId}`);
};

export const banStudent = (webinarId, studentId, isTemporary) => {
  return axios.put(`${PREFIX_V2}/teacher/class/student/access`, {
    class_id: webinarId,
    student_id: studentId,
    is_temporary: isTemporary,
  });
};

export const getAllUsersRating = () => {
  return axios.get(`${PREFIX_V2}/classes/rating`);
};

export const getWebinarResults = (token) => {
  return axios.get(`${PREFIX_V2}/class/students/rating/final`, {
    headers: { 'Class-Session': token },
  });
};

export const getCurrentWebinarRating = (id, token) => {
  return axios.get(`${PREFIX_V2}/classes/session/rating?id=${id}`, {
    headers: { 'Class-Session': token },
  });
};

export const getCalendar = (weekStart, weekEnd) => {
  return axios.get(`${PREFIX_V3}/classes/?start_date=${weekStart}`);
};

export const getLevels = (query = "rus") => {
  return axios.get(`${PREFIX_V2}/levels/level_groups?language=${query}`);
};

export const getSchedule = (query) => {
  return axios.get(`${PREFIX_V3}/classes/?${query}`);
};

export const getRatings = (days) => {
  return axios.get(days ? `${PREFIX_V1}/ratings?num_of_days=${days}` : `${PREFIX_V1}/ratings/all-time`);
};

export const getAllRatings = (days) => {
  return axios.get(days ? `${PREFIX_V1}/ratings/all-time=${days}` : `${PREFIX_V1}/ratings`);
};

export const getUserStatisticsExercises = (token) => {
  return axios.get(`${PREFIX_V1}/user/statistics/exercises`, {
    headers: { 'Access-Token': token },
  });
};

export const getUserStatisticsSuperCategory = (token, superCategory) => {
  return axios.get(`${PREFIX_V2}/user/statistics_by_super_category?super_category=${superCategory}`, {
    headers: { 'Access-Token': token },
  });
};

export const getUserStatisticsCategory = (token, category) => {
  return axios.get(`${PREFIX_V2}/user/statistics_by_category?category=${category}`, {
    headers: { 'Access-Token': token },
  });
};

export const getUserStatisticsCourse = (token, courseId, numOfDays) => {
  return axios.get(`${PREFIX_V1}/user/statistics/course?course_id=${courseId}&num_of_days=${numOfDays}`, {
    headers: { 'Access-Token': token },
  });
};

export const getUserStatisticsSkills = (token) => {
  return axios.get(`${PREFIX_V1}/user/statistics/skills`, {
    headers: { 'Access-Token': token },
  });
};

export const getUserAchievements = (token) => {
  return axios.get(`${PREFIX_V1}/user/achievements`, {
    headers: { 'Access-Token': token },
  });
};

export const getUserRatings = (token) => {
  return axios.get(`${PREFIX_V1}/user-ratings/every-day`, {
    headers: { 'Access-Token': token },
  });
};

export const getUserRatingDelta = (token) => {
  return axios.get(`${PREFIX_V1}/user-ratings/delta-today`, {
    headers: { 'Access-Token': token },
  });
};

export const savePlatform = (lang) => {
  return axios.post(`${PREFIX_V3}/iam/`, { platform: 'web', language: lang });
};

export const getCourseCategories = (lang) => {
  let url = `${PREFIX_V1}/courses_categories?`;
  let searchParams = new URLSearchParams();
  lang && searchParams.append('lang', lang);
  return url + searchParams.toString();
};

export const getSuperCategoriesURL = (slug, lang = 'rus') => {
  return `${PREFIX_V3}/super-categories/${slug}/?lang=${lang}`;
};

export const getSearchUrl = (key) => {
  let url = `${PREFIX_V2}/search?`;
  let searchParams = new URLSearchParams();
  key && searchParams.append('key', key);
  return url + searchParams.toString();
};

export const getFullCourseUrl = (course_id) => {
  let url = `${PREFIX_V1}/course_full?`;
  let searchParams = new URLSearchParams();
  course_id && searchParams.append('course_id', course_id);
  return url + searchParams.toString();
};

export const getLessonUrl = (lesson_id) => {
  let url = `${PREFIX_V2}/lesson_exercises?`;
  let searchParams = new URLSearchParams();
  lesson_id && searchParams.append('lesson_id', lesson_id);
  searchParams.append('type', '1');
  return url + searchParams.toString();
};

export const getExerciseFullUrl = (exercise_id) => {
  let url = `${PREFIX_V1}/exercise_full?`;
  let searchParams = new URLSearchParams();
  exercise_id && searchParams.append('exercise_id', exercise_id);
  return url + searchParams.toString();
};

export const getAdvertisingCoursesUrl = (lang) => {
  let url = `${PREFIX_V2}/advertised_courses?`;
  let searchParams = new URLSearchParams();
  lang && searchParams.append('lang', lang);
  return url + searchParams.toString();
};

export const getCoursesListUrl = (lang, category) => {
  let url = `${PREFIX_V2}/courses/list?`;
  let searchParams = new URLSearchParams();
  lang && searchParams.append('lang', lang);
  category && searchParams.append('type_category', category);
  return url + searchParams.toString();
};

export const getCoursesUrl = (lang, category, page) => {
  let url = `${PREFIX_V2}/courses_list?`;
  let searchParams = new URLSearchParams();
  lang && searchParams.append('lang', lang);
  category && searchParams.append('supercategory', category);
  page && searchParams.append('page', page);
  return url + searchParams.toString();
};

export const getPrivateCourseURL = (lang) => {
  let url = `${PREFIX_V3}/super`;
};

export const getAdvertisingByOrder = (order) => {
  let url = `${PREFIX_V3}/advertising/?`;
  let searchParams = new URLSearchParams();
  order && searchParams.append('order', order);
  searchParams.append('language', localStorage.getItem('i18nextLng'));
  return url + searchParams.toString();
};

export const getSupport = (lang) => {
  let url = `${PREFIX_V2}/get_faq_by_language?`;
  let searchParams = new URLSearchParams();
  lang && searchParams.append('language', lang);
  return url + searchParams.toString();
};

export const feedback = (data) => {
  return axios.post(`${PREFIX_V3}/feedback/`, data);
};
