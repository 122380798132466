import React, {useState} from 'react';
import { signUpAction } from '../../actions/signUp';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import {parseErrorMessage} from "helpers/errorsHelper";
import HidePass from "constants/SVG/HidePass";
import ShowPass from "constants/SVG/ShowPass";
import { getSavedLang } from 'utils/getBrowserLang';

const reEmail = /\S+@\S+\.\S+/;
export default function Registration({closeModal, ...props}) {
  const [form, setForm] = useState({
    email: '', password: '', password_repeat: ''
  });
  const [clientError, setClientError] = useState(false);
  const [pass, showPass] = useState(false);
  const [pass2, showPass2] = useState(false);
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const error = useSelector(state => state.registration.error);
  const loading = useSelector(state => state.registration.loading);

  const handleInputChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    setForm({
      ...form,
      [name]: value
    })
  };

  const onSuccessSubmit = () => {
    props.succesRegistration();
    props.setPopupMode('default')
  };

  const handleSubmit = (e) => {
    const { email, password, password_repeat } = form;
    e.preventDefault();
    if(password.length >= 6 && reEmail.test(email) && password_repeat === password) {
      let data = new FormData();
      data.append('email', email);
      data.append('password', password);
      data.append('lang', getSavedLang());
      dispatch(signUpAction(data, onSuccessSubmit));
      setClientError(false);
    } else {
      let errors = [];
      password.length < 6 && errors.push(t('login_errors.password_length'));
      !reEmail.test(email) && errors.push(t('login_errors.invalid_email'));
      password_repeat !== password && errors.push(t('login_errors.notMatch'));
      setClientError(errors);
    }
  };

  const getErrorMessage = () => {
    if(error) {
      if(error.response.status === 400 || error.response.status === 401 || error.response.status === 409) {
        let message = parseErrorMessage(error.response.data.message);
        if(message) {
          return t(`login_errors.${message}`)
        }
      } else {
        return error.message
      }
    }
  };

  return (
    <>
      <form className='login__form' onSubmit={handleSubmit}>
        <ul className='login__form-list'>
          <li className='login__form-item'>
            <label className='login__label'>
              <input className='login__input' onChange={handleInputChange} type='text' name='email' placeholder={t('auth.emailAddress')}/>
            </label>
          </li>
          <li className='login__form-item'>
            <label className='login__label'>
              <span className='login__password-button' onClick={() => showPass(!pass)}>
                {pass ? <HidePass/> : <ShowPass/>}
              </span>
              <input className='login__input' onChange={handleInputChange} type={pass ? 'text' : 'password'} name='password' placeholder={t('auth.password')}/>
            </label>
          </li>
          <li className='login__form-item'>
            <label className='login__label'>
              <span className='login__password-button' onClick={() => showPass2(!pass2)}>
                {pass2 ? <HidePass/> : <ShowPass/>}
              </span>
              <input className='login__input' onChange={handleInputChange} type={pass2 ? 'text' : 'password'} name='password_repeat' placeholder={t('auth.repeatPassword')}/>
            </label>
          </li>
        </ul>
        <button className='login__button' disabled={loading}>{t('auth.registration')}</button>
        {clientError && clientError.map(el => <span className='login__error'>{el}</span>)}
        {error && <span className='login__error'>{getErrorMessage()}</span>}
        <p className='login__registration-link'>{t('auth.alreadyHave')} <button className='login__navigation-link login__navigation-link--small' type='button' onClick={() => props.setPopupMode('default')}>{t('auth.login')}</button></p>
      </form>
    </>

  )
};

