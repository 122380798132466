import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import './application.scss'

export default function ApplicationsLinks() {
  const [open, setOpen] = useState(false);
  const ref = useRef();
  const {t} = useTranslation();

  const toggleOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  });

  const handleClickOutside = e => {
    if (!ref.current.contains(e.target) && e.target.id !== 'profile_button') {
      setOpen(false);
    }
  };

  return (
    <div className='footer__applications applications' onClick={toggleOpen} ref={ref}>
      <span className='footer__link'>
        {t('footer.applications')}
      </span>
      <span className={`applications__arrow-container ${open ? 'applications__arrow-container--active' : ''}`}>
        <span className={`applications__arrow`}/>
      </span>
      {open &&
        <ul className='applications__list'>
          <li className='applications__item'>
            <a href='https://apps.apple.com/ru/app/abchess-%D0%BB%D0%B8%D1%87%D0%BD%D1%8B%D0%B9-%D1%82%D1%80%D0%B5%D0%BD%D0%B5%D1%80/id1506197698' className='applications__link'>iOS</a>
          </li>
          <li className='applications__item'>
            <a href='https://play.google.com/store/apps/details?id=com.smougg.abchess' className='applications__link'>Android</a>
          </li>
        </ul>
      }
    </div>
  )
}