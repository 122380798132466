import React from 'react'
import s from './HideSidebar.module.scss'
import { ReactComponent as ArrowIcon } from './arrow.svg'

export default function ({ open, onClick }) {
    return (
        <section onClick={onClick} className={`${s['hidesidebar-wrapper']} ${open && s['active']}`}>
            <div className={`${s['hidesidebar-body']}`}>
                <div className={`${s['hidesidebar-icon']} ${open && s['active']}`}>
                    <ArrowIcon />
                </div>
            </div>
        </section>
    )
}