import React from 'react';
import style from './style.module.scss';
import Cart from '../../../Cart';
import Item from './Item';
import Loader3 from '../../../../../../common/Loader/loader3';
import { useTranslation } from 'react-i18next';

export default function TopList({ ratings, loading }) {
  const { t } = useTranslation();

  return (
    <Cart classname={style.top__list}>
      <div className={style.list__header}>
        <span className={style.list__header_text}>№</span>
        <span className={style.list__header_text}>{t('rating.top.best')}</span>
        <span className={style.list__header_text}>{t('rating.top.exercises')}</span>
      </div>
      <div className={style.list__body}>
        {loading ? (
          <Loader3 />
        ) : (
          ratings?.map((rating, index) => <Item key={rating.id} rating={rating} index={index} />)
        )}
      </div>
    </Cart>
  );
}
