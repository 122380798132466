import React, {useEffect, useState} from "react";
import moment from "moment";
import {CalendarIcon} from "../../../assets/img/partners";
import classnames from "classnames";
import style from "./style.module.scss";


const CustomToggle = ({formatValue, onClick, error, profileInput, icon = <CalendarIcon />}) => {
    return (
        <div className={classnames(style.input__wrapper, {
            'text-input--warn': error,
        }, profileInput && style['profile-input'] )}>
            <div className={style.input__icon}>
                {icon}
            </div>
            <input
                className={style.input}
                onFocus={onClick}
                value={formatValue}
            />
        </div>
    )
}

export default CustomToggle;