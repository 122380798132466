import React, {useCallback, useState} from 'react';
import { signInAction } from 'actions/signIn';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import ShowPass from "constants/SVG/ShowPass";
import HidePass from "constants/SVG/HidePass";
import {parseErrorMessage} from "helpers/errorsHelper";

export default function LoginForm({setPopupMode, mode, closeModal}) {
  const [form, setForm] = useState({
    email: '', password: ''
  });
  const [pass, showPass] = useState(false);
  // const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const {t} = useTranslation();
  const error = useSelector(state => state.auth.error);
  const loading = useSelector(state => state.auth.loading);

  const handleInputChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    setForm({
      ...form,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(signInAction({ email: form.email, password: form.password }, {login_type: mode}, () => closeModal(), mode));
  };

  const getErrorMessage = useCallback((error) => {
    if(error) {
      if(error.response) {
        if(error.response.status === 400 || error.response.status === 401 || error.response.status === 403) {
          let message = parseErrorMessage(error.response.data.message);
          if (message) {
            return t(`login_errors.${message}`)
          }
        }
      } else {
        return error.message
      }
    }
  }, [error]);

  const togglePass = () => {
    showPass(!pass);
  };

  return (
    <form className='login__form' onSubmit={handleSubmit}>
      <ul className='login__form-list'>
        <li className='login__form-item'>
          <label className='login__label'>
            <input className='login__input' onChange={handleInputChange} type='email' name='email' placeholder={t('auth.emailAddress')} required/>
          </label>
        </li>
        <li className='login__form-item'>
          <label className='login__label'>
            <span className='login__password-button' onClick={togglePass}>
              {pass ? <HidePass/> : <ShowPass/>}
            </span>
            <input className='login__input login__input--password' onChange={handleInputChange} type={pass ? 'text' : 'password'} name='password' placeholder={t('auth.password')} required/>
          </label>
        </li>
      </ul>
      {
        mode === 'default' && <p className='login__forgot-link' onClick={() => setPopupMode('password_reset')}>{t('auth.forgot')}</p>
      }
      <button className='login__button' disabled={loading}>{t('auth.login')}</button>
      {error && <span className='login__error'>{getErrorMessage(error)}</span>}
      {
        mode === 'default' && <p className='login__registration-link'>{t('auth.notRegistered')} <button className='login__navigation-link login__navigation-link--small' type='button' onClick={() => setPopupMode('registration')}>{t('auth.reg')}</button></p>
      }
    </form>
  )
}