import React, { useCallback, useEffect, useMemo, useState } from "react";
import Header from "../Header";
import { useTranslation } from "react-i18next";
import style from "./style.module.scss";
import { Link } from "react-router-dom";
import Lesson from "./Lesson";
import { getPartnerLessons } from "../../../../services/api";
import Loader3 from "../../../common/Loader/loader3";
import useResolution from "../../../../hooks/useResolution";
import MobileMenu from "./MobileMenu";
import WebinarListMenu from "../../../common/WebinarListMenu";
import SingleNumber from "components/common/Pagination/SingleNumber/SingleNumber";
import Pagination from "components/common/Pagination/Pagination";
import { deleteLesson as DELETE_LESSON } from 'actions/webinar';
import { useDispatch } from "react-redux";

const Lessons = () => {
    const { t } = useTranslation();
    const [lessonType, setLessonType] = useState('future');
    const [lessons, setLessons] = useState([]);
    const [loading, setLoading] = useState(true);
    const isMobile = useResolution(650);
    const dispatch = useDispatch();

    const menuLinks = useMemo(() => [
        { text: t('partners.futureLessons'), query: 'future' },
        { text: t('partners.presentLessons'), query: 'current' },
        { text: t('partners.pastLessons'), query: 'passing' },
    ], []);

    const deleteLesson = useCallback(id => {
        setLessons([...lessons.filter(lesson => lesson.id !== id)])
    }, [lessons.length])

    useEffect(() => {
        if (!loading) setLoading(true)
        getPartnerLessons(lessonType)
            .then(res => {
                setLessons(res.data)
                setLoading(false)
            })
    }, [lessonType])

    return (
        <div>
            <Header text={t('partners.lessons')} />
            <div className={style.container}>
                {
                    loading
                        ? <Loader3 />
                        : (
                            <div>
                                {
                                    <Pagination items={lessons.map(lesson =>
                                        <Lesson
                                            key={lesson.id}
                                            lesson={lesson}
                                            lessonType={lessonType}
                                            deleteLesson={deleteLesson}
                                        />
                                    )} />
                                }
                            </div>
                        )
                }
                <div>
                    {
                        isMobile
                            ? <MobileMenu links={menuLinks} setType={setLessonType} lessonType={lessonType} />
                            : <WebinarListMenu links={menuLinks} setType={setLessonType} lessonType={lessonType} />

                    }
                    <Link to="/partner/lesson/create" className={style.createLesson}>{t('partners.createLesson')}</Link>
                </div>
            </div>
        </div>
    )
}

export default Lessons;