export const hideScroll = (state = false) => {
  const body = document.querySelector('body');
  const html = document.querySelector('html');

  if (state && !body.classList.contains('scroll-hidden')) {
    body.classList.add('scroll-hidden');
    html.classList.add('scroll-hidden');
  } else {
    body.classList.remove('scroll-hidden');
    html.classList.remove('scroll-hidden');
  }
};
