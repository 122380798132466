import React from 'react';
import './description.scss';
import './descriptionMobile.scss';
import {useTranslation} from "react-i18next";

export default function TaskDescription({ data }) {
  const {t} = useTranslation();

  return (
    <>
      {(data && (data.course_description || (data.content && data.content.videoDescription))) &&
        <div className='interface__description description'>
          <p className='description__title'>{t('courses.description')}</p>
          {data.course_description && data.course_description.split('\n').map((el, index) => <p className='description__text' key={`desc${index}`}>{el}</p>)}
          {(data.content && data.content.videoDescription) && data.content.videoDescription.split('\n').map((el, index) => <p className='description__text' key={`desc${index}`}>{el}</p>)}
        </div>
      }
    </>
  )
}