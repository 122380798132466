import React, {useCallback, useEffect, useState} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import axios from 'axios';
import CourseInterface from '../../common/CourseInterface';
import TestsList from '../../common/CourseInterface/TestsList';
import ChessDisplay from '../../common/CourseInterface/MainDisplay/ChessDisplay';
import FindBest from '../../common/CourseInterface/MainDisplay/TaskText/FindBest';
import VideoDisplay from '../../common/CourseInterface/MainDisplay/VideoDisplay';
import PictureDisplay from '../../common/CourseInterface/MainDisplay/PictureDisplay';
import TestRadio from '../../common/CourseInterface/MainDisplay/TaskText/TestRadio';
import Loader3 from '../../common/Loader/loader3';
import RepeatTask from '../../common/CourseInterface/MainDisplay/TaskText/RepeatTask';
import PlayPosition from '../../common/CourseInterface/MainDisplay/TaskText/PlayPosition';
import TestCheckbox from '../../common/CourseInterface/MainDisplay/TaskText/TestCheckbox';
import NoAccessComponent from "./NoAccessComponent";
import {getExerciseFullUrl, getLessonUrl} from "services/api";
import PictureRadio from "../../common/CourseInterface/MainDisplay/TaskText/PictureRadio";
import {showErrorMessage} from "../../../helpers/notifications";

const noAccessError = "The server could not verify that you are authorized to access the URL requested. You either supplied the wrong credentials (e.g. a bad password), or your browser doesn't understand how to supply the credentials required.";
const noAccessError2 = "You don't have the permission to access the requested resource. It is either read-protected or not readable by the server.";

export default function Lesson() {
  const [exerciseData, setExerciseData] = useState('');
  const [coursesData, setCoursesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [exerciseLoading, setExerciseLoading] = useState(false);
  const [noAccess, setNoAccess] = useState(false);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    let lessonId = location.pathname.split('/')[4];
    axios.get(getLessonUrl(lessonId))
      .then(res => {
        setCoursesData(res.data);
        if(!location.search) {
          history.push(`${location.pathname}?exercise=${res.data.exercises[0].id}`)
        }
      })
      .catch(e => {
        if(e.response.status === 401 && e.response.data.message  === "Access denied") {
          let courseId = location.pathname.split('/')[2];
          history.push(location.pathname.includes('super-categories') ? `/super-categories/${location.pathname.split('/')[2]}` : `/course/${courseId}`);
        }
        if(e.response?.data?.message === noAccessError || e.response?.data?.message === noAccessError2) {
          setNoAccess(true);
        }
        if(e.response?.status === 404) {
          showErrorMessage('Такого урока не найдено')
        }
      })
      .finally(() => {
        setLoading(false);
        setExerciseLoading(false);
      });
  }, []);

  useEffect(() => {
    let exerciseId = location.search.split('=')[1];
    loadExercise(exerciseId);
  }, [location.search]);

  const loadExercise = (id) => {
    setExerciseLoading(true);
    id && axios.get(getExerciseFullUrl(id))
      .then(res => {
        setExerciseData(res.data);
      })
      .catch(e => {
        console.log(e.response);
        if(e.response?.data.message === noAccessError || e.response?.data.message === noAccessError2) {
          setNoAccess(true);
        }
      })
      .finally(() => {
        setExerciseLoading(false)
      });
  };

  const getExerciseIndex = () => coursesData.exercises.findIndex(el => el.id === exerciseData.id);

  const onNextClick = () => {
    if(coursesData.exercises[getExerciseIndex() + 1]) {
      history.push(`${location.pathname}?exercise=${coursesData.exercises[getExerciseIndex() + 1].id}`);
    } else {
      let urlArray = location.pathname.split('/');
      history.push(`/${urlArray[1]}/${urlArray[2]}`);
    }
  };

  const onBackClick = () => {
    if(coursesData.exercises[getExerciseIndex() - 1]) {
      history.push(`${location.pathname}?exercise=${coursesData.exercises[getExerciseIndex() - 1].id}`);
    } else {
      let urlArray = location.pathname.split('/');
      history.push(`/${urlArray[1]}/${urlArray[2]}`);
    }
  };

  const componentToDisplay = useCallback((props) => {
    if(exerciseData.type === 2) {
      return <VideoDisplay {...exerciseData} onBackClick={onBackClick} onNextClick={onNextClick} loading={loading} toggleComponent={props.toggleComponent} componentToDisplay={props.componentToDisplay}/>
    }
    if(exerciseData.type === 3) {
      return <ChessDisplay chessType={'best'} {...exerciseData} onBackClick={onBackClick} onNextClick={onNextClick} TaskText={FindBest} loading={exerciseLoading}/>
    }

    if(exerciseData.type === 4) {
      return <ChessDisplay chessType={'repeat'} {...exerciseData} onBackClick={onBackClick} onNextClick={onNextClick} TaskText={RepeatTask} loading={exerciseLoading}/>
    }

    if(exerciseData.type === 5) {
      return <ChessDisplay chessType={'position'} {...exerciseData} onBackClick={onBackClick} onNextClick={onNextClick} TaskText={PlayPosition} loading={exerciseLoading}/>
    }

    if(exerciseData.type === 6) {
      if(exerciseData.content.answers.filter(el => el.is_correct === true).length === 1) {
        return <PictureDisplay {...exerciseData} TaskText={TestRadio} type={'chess'} onBackClick={onBackClick}
                               onNextClick={onNextClick} loading={exerciseLoading}/>
      } else {
        return <PictureDisplay {...exerciseData} TaskText={TestCheckbox} type={'chess'} onBackClick={onBackClick}
                               onNextClick={onNextClick} loading={exerciseLoading}/>
      }
    }

    if(exerciseData.type === 7) {
      if(exerciseData.content.answers.filter(el => el.is_correct === true).length === 1) {
        return <PictureDisplay {...exerciseData} TaskText={TestRadio} type={'picture'} onBackClick={onBackClick}
                               onNextClick={onNextClick} loading={exerciseLoading}/>
      } else {
        return <PictureDisplay {...exerciseData} TaskText={TestCheckbox} type={'picture'} onBackClick={onBackClick}
                               onNextClick={onNextClick} loading={exerciseLoading}/>
      }
    }

    if(exerciseData.type === 8) {
        return <PictureDisplay {...exerciseData} TaskText={PictureRadio} type={'picture'} onBackClick={onBackClick}
                               onNextClick={onNextClick} loading={exerciseLoading}/>
    }

    if(noAccess) {
      return <NoAccessComponent/>
    }

    if(!loading && !exerciseLoading) {
      return <></>
    }

    return <Loader3/>;
  }, [exerciseData, exerciseLoading, loading]);

  return (
    <CourseInterface
      type={'lesson'}
      ListToDisplay={TestsList}
      DisplayComponent={componentToDisplay}
      title={coursesData.title}
      data={exerciseData}
      coursesData={coursesData}
      loading={loading}
    />
  )
}