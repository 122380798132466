import React, {useCallback, useState} from 'react'
import {useDropzone} from 'react-dropzone';
import {useHttp} from "hooks/http.hook";
import {useTranslation} from "react-i18next";
import {POST_USER_IMAGE} from "services/api";
import {showErrorMessage} from "../../helpers/notifications";

export default function AvatarDropzone({userImage}) {
  const [imageUrl, setImageUrl] = useState('');
  const [fileToSend, setFileToSend] = useState(null);
  const {request, loading} = useHttp();
  const {t} = useTranslation();

  const onDrop = useCallback((acceptedFiles) => {
    let file = acceptedFiles[0];
    if(file.size > 1048576) {
      showErrorMessage(t('profile.fileSizeError'));
      return false
    }
    const reader = new FileReader();
    reader.onabort = () => alert('file reading was aborted');
    reader.onerror = () => alert('file reading has failed');
    reader.onload = () => {
      getUrlImage(file);
      setFileToSend(file);
    };
    reader.readAsArrayBuffer(file)

  }, []);

  const getUrlImage = (file) => {
    let imageFile = file;
    if (imageFile) {
      const localImageUrl = URL.createObjectURL(imageFile);
      const imageObject = new window.Image();
      imageObject.onload = () => {
        imageFile.width = imageObject.naturalWidth;
        imageFile.height = imageObject.naturalHeight;
        URL.revokeObjectURL(imageFile);
      };
      imageObject.src = localImageUrl;
      setImageUrl(imageObject.src);
    }
  };

  const onCancelClick = () => {
    setImageUrl(userImage);
    setFileToSend(null);
  };

  const handleSubmit = () => {
    let formData = new FormData();
    formData.append('image', fileToSend, 'image.' + fileToSend.path.split('.').slice(-1));
    request(POST_USER_IMAGE, 'post', formData)
      .then(() => {
        setFileToSend(null);
      })
  };

  const getCurrentImage = () => {
    if(userImage && !imageUrl) {
      return <img src={userImage} className='profile__avatar profile__avatar--image'/>
    } else if(imageUrl) {
      return <img src={imageUrl} className='profile__avatar profile__avatar--image'/>
    }
    return <span className='profile__avatar'/>
  };

  const {getRootProps, getInputProps} = useDropzone({onDrop, accept: 'image/jpeg, image/png'});

  return (
    <>
      <div {...getRootProps()} className='profile__avatar-dropzone'>
        {getCurrentImage()}
        <span className='profile__avatar-text'>{t('profile.change')}</span>
        <input {...getInputProps()} />
      </div>
      {fileToSend &&
        <div className='profile__dropzone-buttons'>
          <button className={'profile__cancel'} disabled={loading} type={'button'} title={t('profile.cancel')} onClick={onCancelClick}/>
          <button className={'profile__done'} disabled={loading} type={'button'} title={t('profile.save')} onClick={handleSubmit}/>
        </div>
      }
    </>
  )
}