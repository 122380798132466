import axios from 'axios';
import {
  AUTHENTICATED,
  AUTHENTICATED_LOADING,
  AUTHENTICATION_ERROR,
  DISABLE_LOGIN_WINDOW
} from './types';
import {POST_LOGIN, savePlatform, TEACHER_LOGIN} from "services/api";
import setAuthorizationToken from '../utils/setAuthorizationToken';
import {getProfileInfo} from "./getProfile";

export function signInAction(data, params, successCallback, mode) {
  return async (dispatch) => {
    dispatch({ type: AUTHENTICATED_LOADING });
    try {
      const res = await axios.post(POST_LOGIN, mode === 'default' ? data : {...data, role: 'teacher'}, { params: {...params}});

      localStorage.setItem('token', JSON.stringify(res.data.token));
      localStorage.setItem('role', JSON.stringify(res.data.role || 'default'));

      setAuthorizationToken();
      dispatch({ type: AUTHENTICATED });
      dispatch({ type: DISABLE_LOGIN_WINDOW });
      dispatch(getProfileInfo());
      savePlatform(localStorage.getItem('i18nextLng')).then()
      if (successCallback) successCallback();
    } catch(error) {
      dispatch({
        type: AUTHENTICATION_ERROR,
        payload: error
      });
    }
  };
}
