import React from 'react';
import style from './style.module.scss';
import {
  certificate_1,
  certificate_2,
  certificate_3,
  certificate_4,
  certificate_5,
  certificate_6,
  certificate_1_en,
  certificate_2_en,
  certificate_3_en,
  certificate_4_en,
  certificate_5_en,
  certificate_6_en,
} from '../../../../assets/img/abclass/certificates/allCertificates';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import Certificate from './Certificate';

const certificatesData = [
  { id: 1, level: 1, image: certificate_1 },
  { id: 2, level: 2, image: certificate_2 },
  { id: 3, level: 3, image: certificate_3 },
  { id: 4, level: 4, image: certificate_4 },
  { id: 5, level: 5, image: certificate_5 },
  { id: 6, level: 6, image: certificate_6 },
];

const certificatesDataEn = [
  { id: 1, level: 1, image: certificate_1_en },
  { id: 2, level: 2, image: certificate_2_en },
  { id: 3, level: 3, image: certificate_3_en },
  { id: 4, level: 4, image: certificate_4_en },
  { id: 5, level: 5, image: certificate_5_en },
  { id: 6, level: 6, image: certificate_6_en },
];

const Certificates = () => {
  const { t } = useTranslation();
  return (
    <section className={style['certificates-wrapper']}>
      <div className={style['certificates-wrapper__title-area']}>
        <h1 className={style['certificates-wrapper__title-area__title']}>
          {t('abclass.certificatesForCourses')}
        </h1>
      </div>
      <div className={style['certificates-wrapper__certificates-area']}>
        {(i18next.language === 'rus' ? certificatesData : certificatesDataEn).map((certificate, idx) => (
          <Certificate withLabel={false} idx={idx} certificate={certificate} key={`certificate-${idx}`} />
        ))}
      </div>
    </section>
  );
};

export default Certificates;
