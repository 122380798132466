import React, {useEffect, useState} from 'react';
import './audioHint.scss';

export default function AudioHint({ audioSrc }) {
  const [audio, setAudio] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    setAudio(new Audio(parseVideoId(audioSrc)));
  }, []);

  const parseVideoId = (url) => {
    let stringUrl = new URL(url);
    let pathNameArray = stringUrl.pathname.split('/');
    let result = '';
    pathNameArray.forEach((el, index) => {
      if(el === 'd') {
        result = `https://docs.google.com/uc?export=open&id=${pathNameArray[index + 1]}`
      }
    });
    return result;
  };

  const playHintSound = () => {
    audio.addEventListener('playing', () => {
      setIsPlaying(true);
      audio.addEventListener('ended', () => {
        setIsPlaying(false);
      });
    });
    audio.play();
  };

  return <span className={`chess-display__hint hint__logo chess-display__hint--audio ${isPlaying ? 'chess-display__hint--active' : ''}`} onClick={isPlaying ? () => {} : playHintSound}/>
}