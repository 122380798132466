import {
  SET_WEBINAR_RAITING,
  SET_WEBINAR_CURRENT_EXERCISE,
  SHOW_WEBINAR_SCORES,
  UPDATE_WEBINAR_RATING,
  SET_WEBINAR_TIMESTAMP,
  TRAINING_LESSON
} from '../actions/types';

const init_state = {
  rating: [],
  current_exercise_id: null,
  timestamp: 0,
  training_lesson: {
    webinar_id: null,
    value: false
  }
};

export default function WebinarReducer(state = init_state, action) {
  switch (action.type) {
    case TRAINING_LESSON:
      return {
        ...state,
        training_lesson: {
          ...action.payload
        }
      }
    case SHOW_WEBINAR_SCORES:
      return {
        ...state,
        rating: state.rating.map((student) => ({
          ...student,
          show_scores: action.payload,
        })),
      };
    case SET_WEBINAR_CURRENT_EXERCISE:
      return {
        ...state,
        current_exercise_id: action.payload,
      };
    case UPDATE_WEBINAR_RATING:
      return {
        ...state,
        rating: state.rating.map((student) => {
          return student.last_exercise === state.current_exercise_id
            ? {
                ...student,
                exercise_correct: student.exercise_correct,
                show_scores: student.exercise_correct !== null,
                last_scores: student.exercise_correct ? student.last_scores : 0,
              }
            : {
                ...student,
                exercise_correct: null,
                show_scores: false,
                last_scores: 0,
              };
        }),
      };
    case SET_WEBINAR_RAITING:
      return {
        ...state,
        rating:
          action.payload.length === 0
            ? state.rating.map((student) => ({
                ...student,
                exercise_correct: null,
                show_scores: false,
              }))
            : action.payload.map((student) => {
                return student.last_exercise === state.current_exercise_id ||
                  (state.current_exercise_id === null && student.is_active) 
                  ? {
                      ...student,
                      exercise_correct: student.exercise_correct,
                      show_scores: student.exercise_correct !== null,
                      last_scores: student.exercise_correct ? student.last_scores : 0,
                    }
                  : {
                      ...student,
                      exercise_correct: null,
                      show_scores: false,
                      last_scores: 0,
                    };
              }),
      };
      case SET_WEBINAR_TIMESTAMP:
        return {
          ...state,
          timestamp: action.payload,
        }
    default:
      return state;
  }
}
