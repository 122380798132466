export const LANG = {
  "ru": "rus",
  "en": "eng",
  "rus": "rus",
  "eng": "eng",
  "kz": "kz",
  "kaz": "kz",
  "es": "es",
  "spanish": "es",
  "tr": "tr"
};