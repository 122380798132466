import React, { useEffect, useState } from "react";
import style from "./style.module.scss";
import { lessonSignUpBanner } from "../../../../assets/img/abclass";
import { useTranslation } from "react-i18next";
import Button from "../Main/Button";
import { feedback } from "../../../../services/api";
import { showSuccessMessage } from "../../../../helpers/notifications";
import lessonSignUpMobileBanner from '../../../../assets/img/abclass/lessonSignUpMobileBanner.png';
import useResolution from "hooks/useResolution";
const LessonSignUp = () => {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const isMobile = useResolution(560);
    const { t } = useTranslation();

    const submit = () => {
        if (name.trim() && phone.trim()) {
            feedback({ name, phone })
                .then(() => {
                    showSuccessMessage('Спасибо за заявку!')
                })
        }
    }

    return (
        <div className={style.wrapper}>
            <div className={style.container} id="lesson_signUp">
                {!isMobile ?
                    <>
                        <img src={lessonSignUpBanner} className={style.container__banner} alt="banner" />
                        <svg className={style.container__svg} width="486" height="414" viewBox="0 0 486 414" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M77.0034 346.5C184.587 337.532 304.5 379.5 332 395C381.743 423.037 479.5 423.5 474.371 359.416C470.64 312.809 388.64 210.128 436.082 171.638C542.077 91.2954 453.358 20.762 407.131 1.61089C370.15 -13.71 340.359 -33.7336 330.086 -41.8302C299.579 -65.8085 206.253 -90.6898 77.0035 1.61089C-84.5582 116.987 -57.4757 357.711 77.0034 346.5Z" fill="#8FD3D6" />
                        </svg>
                    </>
                    :
                    <>
                        <img src={lessonSignUpMobileBanner} className={style.container__banner} alt="banner" />
                        <svg className={style.container__svg} width="251" height="286" viewBox="0 0 251 286" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M-31.277 283.108C42.9195 276.922 125.62 305.867 144.585 316.556C178.892 335.893 246.311 336.212 242.774 292.015C240.201 259.872 183.648 189.057 216.367 162.512C289.469 107.102 228.282 58.4574 196.401 45.2496C170.896 34.6833 150.35 20.8737 143.265 15.2897C122.226 -1.24724 57.8619 -18.407 -31.277 45.2496C-142.701 124.82 -124.023 290.839 -31.277 283.108Z" fill="#8FD3D6" />
                        </svg>

                    </>

                }

                <div className={style.content}>
                    <div>
                        {isMobile && <p className={style.form__title}>{t('abclass.firstTwoIsFree')}</p>}
                        <ul className={style.steps}>
                            <li className={style.steps__step}>
                                <div className={style.steps__step__number}>
                                    <p>1</p>
                                    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M30.1031 6.21963C12.9031 -8.98037 3.60315 7.21963 1.10315 17.2196C-2.89685 31.2205 4.60315 32.7188 13.6031 36.7188C22.6031 40.7188 51.6031 25.2196 30.1031 6.21963Z" fill="#8FD3D6" />
                                    </svg>
                                </div>
                                {t('abclass.getToKnow')}
                            </li>
                            <li className={style.steps__step}>
                                <div className={style.steps__step__number}>
                                    <p>2</p>
                                    <svg width="33" height="38" viewBox="0 0 33 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.678458 15.4101C-2.62873 26.3873 6.95598 34.0104 12.1617 36.4497C18.2043 40.5647 27.779 36.4497 30.535 24.5593C33.291 12.6689 36.0469 1.68864 25.023 0.316495C13.9991 -1.05565 4.81244 1.68864 0.678458 15.4101Z" fill="#8FD3D6" />
                                    </svg>
                                </div>
                                {t('abclass.tellAboutLessons')}
                            </li>
                            <li className={style.steps__step}>
                                <div className={style.steps__step__number}>
                                    <p>3</p>
                                    <svg width="36" height="35" viewBox="0 0 36 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M20.4409 1.67236C9.94782 -3.68216 4.00776 5.0933 2.34936 10.1503C0.540206 16.3963 -4.43498 22.1989 9.58599 29.3383C36.7234 43.617 37.7787 27.5534 34.9142 17.7368C34.4619 14.6131 30.934 7.02687 20.4409 1.67236Z" fill="#8FD3D6" />
                                    </svg>
                                </div>
                                {t('abclass.selectClasses')}
                            </li>
                        </ul>
                    </div>
                    <div className={style.form}>
                        {!isMobile && <><p className={style.form__title}>{t('abclass.signUpToFreeLesson')}</p>
                            <p className={style.form__stock}><sup>*</sup>{t('abclass.firstTwoIsFree')}</p></>}
                        <input
                            value={name}
                            onChange={e => setName(e.target.value)}
                            className={style.form__input}
                            placeholder={t('abclass.name')}
                        />
                        <input
                            value={phone}
                            onChange={e => setPhone(e.target.value)}
                            className={style.form__input}
                            placeholder={t('abclass.phone')}
                        />
                        <Button onClick={submit} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LessonSignUp;