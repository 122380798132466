import React, {useEffect, useState} from 'react';
import {ButtonBack, ButtonNext, CarouselProvider, Slide, Slider, Dot} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import PublicSlide from './PublicSlide';
import { Link } from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import './publicSlider.scss';
import './publicSliderMobile.scss';
import {useWindowSize} from "hooks/resize.hook";
import GoToButton from "components/common/GoToButton";

export default function PublicSlider({data, type, loading, pageId, supercategoryId}) {
  const [slidesCountOnWindow, setSlidesCountOnWindow] = useState(4);
  const {t} = useTranslation();
  const windowSize = useWindowSize();

  let firstClientX;
  let firstClientY;
  let clientX;
  let clientY;


  const touchStart = (e) => {
    firstClientX = e.touches[0].clientX;
    firstClientY = e.touches[0].clientY;
  };

  const preventTouch = (e) => {
    const minValue = 5; // threshold

    clientX = e.touches[0].clientX - firstClientX;
    clientY = e.touches[0].clientY - firstClientY;

    // Vertical scrolling does not work when you start swiping horizontally.
    if(Math.abs(clientX) > minValue){
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
  };

  useEffect(() => {
    window.addEventListener('touchstart', touchStart);
    window.addEventListener('touchmove', preventTouch, {passive: false});

    return () => {
      window.removeEventListener('touchstart', touchStart);
      window.removeEventListener('touchmove', preventTouch, {passive: false});
    }
  }, []);

  useEffect(() => {
    if(windowSize.width > 1100) {
      setSlidesCountOnWindow(4)
    } else if(windowSize.width > 900) {
      setSlidesCountOnWindow(3)
    } else if(windowSize.width >= 600) {
      setSlidesCountOnWindow(2)
    } else if(windowSize.width < 600) {
      setSlidesCountOnWindow(1)
    }
  }, [windowSize]);

  const dotsToRender = () => {
    let dotNumbers = Math.ceil(data.length / slidesCountOnWindow);
    let dotsArray = [];
    for(let i = 0; i<dotNumbers; i++) {
      dotsArray.push(<Dot slide={i * slidesCountOnWindow} key={`dotpublic${i}`}><span className='slider-position'/></Dot>)
    }

    return dotsArray;
  };

  return (
    <div className='public-slider'>
      <div className='public-slider__container'>
        <div className='public-slider__header'>
          <h2 className='public-slider__header-title'>{type}</h2>
          {!loading && windowSize.width > 650 && <GoToButton route={`/courses/${supercategoryId}/${pageId}`}>{t('main.allCourses')}</GoToButton>}
        </div>
        <div className={'slider__body'}>
          <CarouselProvider
            className={'public-slider__list'}
            naturalSlideWidth={100}
            naturalSlideHeight={30}
            totalSlides={data.length}
            visibleSlides={slidesCountOnWindow}
            step={slidesCountOnWindow}
            dragEnabled={1}
            lockOnWindowScroll={true}
            isIntrinsicHeight={true}
          >
            {data.length > slidesCountOnWindow &&
            <ButtonBack>
              <span className='slider-button slider-button--left public-slider__button public-slider__button--left'/>
            </ButtonBack>
            }
            <Slider className={'public-slider__slider'}>
              {data.map((el, index) =>
                <Slide className='public-slider__slide' index={0} key={el.id || 'loadingPublicSlide' + index}>
                  <PublicSlide {...el}/>
                </Slide>
              )}
            </Slider>
            {data.length > slidesCountOnWindow &&
            <ButtonNext>
              <span className='slider-button slider-button--right public-slider__button public-slider__button--right'/>
            </ButtonNext>
            }
            {data.length > slidesCountOnWindow &&
            <div className='public-slider__options'>
              <div className='slider-position-container'>
                {dotsToRender().map(el => el)}
              </div>
            </div>
            }
          </CarouselProvider>
        </div>
      </div>
    </div>
  );
}