import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import './lazyImg.scss';

const LazyImg = ({ image, classes, placeholder }) => (
    <LazyLoadImage
      className={classes}
      height={image.height}
      src={image.src}
      width={image.width}
      placeholder={placeholder}
      wrapperClassName={'lazy-wrapper'}
    />
);

export default LazyImg;