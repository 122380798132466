import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import style from './style.module.scss';
import classnames from 'classnames';
import Level from './Levels/Level';
import WebinarCard from './WebinarCard';
import { convertDateFromLocale } from '../../../../../utils/convertDateFromLocale';
import { getCalendar, getLevels } from '../../../../../services/api';
import Levels from './Levels';
import Loader3 from '../../../../common/Loader/loader3';
import useResolution from 'hooks/useResolution';

const Calendar = () => {
  const [webinars, setWebinars] = useState([]);
  const [levels, setLevels] = useState([]);
  const [loading, setLoading] = useState(true);
  const isMobile = useResolution(540);
  const { t } = useTranslation();

  const currentWeek = useMemo(() => {
    const start = moment().startOf('isoWeek');
    const end = moment().endOf('isoWeek');

    const days = [];
    let day = start;
    while (day <= end) {
      days.push({
        name: t(`calendar.${moment(day.toDate()).locale('en').format('dd')}`).toLowerCase(),
        date: isMobile ? moment(day.toDate()).format('DD MMMM') : moment(day.toDate()).format('DD/MM'),
      });
      day = day.clone().add(1, 'day');
    }
    return days;
  }, []);

  const webinarsGrouping = useCallback((array) => {
    if (array.length === 0) return [];
    const groups = {};

    //Объединяем уроки в группы по времени
    array.forEach((item) => {
      const time = moment(item.begin_date).format('HH:mm');
      if (!groups[time]) groups[time] = [item];
      else groups[time] = [...groups[time], item];
    });

    //Получаем минимальное и максимальное время, чтобы создать группы в которых нет занятий
    let minTime;
    let maxTime;
    const getHours = (index) => +Object.keys(groups)[index].slice(0, 2);
    // По ТЗ для разных длин массива, должно быть разное количество времён до/после, без switch case никак
    switch (Object.keys(groups).length) {
      case 1:
        minTime = groups['00:00'] || groups['01:00'] ? 0 : getHours(0) - 2;
        maxTime = groups['23:00'] || groups['22:00'] ? 23 : getHours(0) + 2;
        break;
      case 2:
        minTime = groups['00:00'] || groups['01:00'] ? 0 : getHours(0) - 2;
        maxTime = groups['23:00'] ? 23 : getHours(1) + 1;
        break;
      case 3:
        minTime = groups['00:00'] ? 0 : getHours(0) - 1;
        maxTime = groups['23:00'] ? 23 : getHours(2) + 1;
        break;
      case 4:
        minTime = groups['00:00'] ? 0 : getHours(0) - 1;
        maxTime = getHours(3);
        break;
      default:
        minTime = getHours(0);
        maxTime = getHours(Object.keys(groups).length - 1);
    }

    for (let i = minTime; i <= +maxTime; i++) {
      if (!groups[`${i < 10 ? `0${i}` : i}:00`]) groups[`${i < 10 ? `0${i}` : i}:00`] = [];
    }

    Object.entries(groups).forEach(([key, value]) => {
      //Заполняем массивы равными количеству дней в неделе, нужно для рендера
      //Нельзя забывать, что в проекте много локалей, поэтому количество дней в неделе может быть разное
      if (value.length === 0) groups[key] = Array(currentWeek.length).fill(null);
      else {
        const groupArray = Array(currentWeek.length).fill(null);
        value.forEach((item) => {
          const weekIndex = currentWeek.findIndex(
            (day) =>
              day.name === t(`calendar.${moment(item.begin_date).locale('en').format('dd')}`).toLowerCase()
          );
          //Проверяем наличие дубликатов (в один день и в одинаковое время может проходить несколько занятий)
          if (groupArray[weekIndex]) {
            groupArray[weekIndex] = Array.isArray(groupArray[weekIndex])
              ? [...groupArray[weekIndex], item]
              : [groupArray[weekIndex], item];
          } else groupArray[weekIndex] = item;
        });
        groups[key] = groupArray;
      }
    });
    //Сортируем снова, т.к. "пустые" группы добавляются в конец объекта
    return Object.entries(groups).sort((a, b) => +a[0].slice(0, 2) - +b[0].slice(0, 2));
  }, []);

  useEffect(() => {
    Promise.all([
      getCalendar(
        moment().startOf('isoWeek').format('YYYY-MM-DD'),
        moment().endOf('isoWeek').format('YYYY-MM-DD')
      ).then((res) => {
        const interval = 60 * 60 * 1000; // 1 час
        //Конвертируем по локали и округляем вниз время уроков
        //Предварительно сортируем массив (!!! по времени, а не по дате), чтобы первая найденная группа 100% была минимальной, а последняя максимальной по времени
        const localizedWebinars = res.data
          .map((webinar) => {
            return {
              ...webinar,
              begin_date: moment(
                Math.floor(convertDateFromLocale(webinar.begin_date) / interval) * interval
              ).format('YYYY-MM-DD HH:mm:ss'),
              normal_time: moment(Math.floor(convertDateFromLocale(webinar.begin_date))).format(
                'YYYY-MM-DD HH:mm:ss'
              ),
            };
          })
          .sort(
            (a, b) =>
              +moment(a.begin_date).format('HH:mm').slice(0, 2) -
              +moment(b.begin_date).format('HH:mm').slice(0, 2)
          );

        setWebinars(webinarsGrouping(localizedWebinars));
      }),
      getLevels().then((res) => {
        const array = res.data.reduce((acc, category) => [...acc, ...category.levels], []);
        setLevels(array);
      }),
    ])
    .then(() => setLoading(false))
    .catch(() => setLoading(false));
  }, []);

  if (loading) return <Loader3 />;

  return (
    <div className={style.container}>
      <table className={style.table}>
        <thead>
          <tr className={style['table-week']}>
            <th className={style['table-week__day']} width={isMobile ? '10%' : '15%'}></th>
            {currentWeek.map((item, index) => (
              <th key={index} className={style['table-week__day']}>
                <p className={style['table-week__day_name']}>
                  {item.name.charAt(0).toUpperCase() + item.name.slice(1)}
                </p>
                <p className={style['table-week__day_date']}>{item.date}</p>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {webinars.length === 0 && (
            <tr>
              <td colSpan={currentWeek.length + 1} width='100%'>
                <p className={style.emptyCalendar}>{t('abclass.empty')}</p>
              </td>
            </tr>
          )}
          {webinars.map(([key, value]) => (
            <tr key={key}>
              <td width='15%' className={style.table__time}>
                {key}
              </td>
              {value.map((item, index) => (
                <td key={index}>
                  {item && Array.isArray(item) ? (
                    <div className={style.table__array}>
                      {item.map((webinar) => (
                        <WebinarCard key={webinar.id} webinar={webinar} />
                      ))}
                    </div>
                  ) : (
                    item && <WebinarCard webinar={item} />
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <Levels levels={levels} />
    </div>
  );
};

export default Calendar;
