import React, { useCallback, useEffect, useState } from 'react';
import style from './style.module.scss';

import Cart from '../../../Cart';
import SupercategoryAccordion from './SupercategoryAccordion';
import { useSelector } from 'react-redux';

export default function Courses({ setMainCourseId, setSuperCategory, setCategory }) {
  const data = useSelector((state) => state.courses.coursesData);
  console.log(data);
  const [supercategoryActive, setSupercategoryActive] = useState([]);
  useEffect(() => {
    setSupercategoryActive(Array.from({ length: data.supercategories.length }, () => false));
  }, [data.supercategories.length]);

  const setSupercategoryActiveByIndex = useCallback((index) => {
    setSupercategoryActive((prevState) => {
      const newState = [...prevState]; // Создаем копию предыдущего состояния
      newState[index] = !newState[index]; // Вносим изменение в копию состояния
      return newState;
    });
  }, []);

  return (
    <Cart classname={style.container}>
      <div className={style.accordions}>
        {data?.supercategories.map((supercategory, index) => (
          <SupercategoryAccordion
            key={supercategory.supercategory_id}
            index={index}
            supercategory={supercategory}
            setSuperCategory={setSuperCategory}
            setCategory={setCategory}
            setSupercategoryActiveByIndex={setSupercategoryActiveByIndex}
            supercategoryActive={supercategoryActive}
            setMainCourseId={setMainCourseId}
          />
        ))}
      </div>
    </Cart>
  );
}
