import React, { useState } from 'react';
import Chessboard from 'chessboardjsx';
import { BOARD_STYLE, CHESS_SQUARE_COLORS, DEFAULT_POSITION_OBJECT } from '../ChessConst';
import { checkIsValidToStart, objToFen } from "helpers/chessHelpers";
import './testChess.scss'
import PlayWithBotControls from "../../Pages/PlayWithBotPage/PlayWithBotControls";
import PlayWithBot from "./PlayWithBot";
import { setBoardWidth } from "../chessHelperFuntions";
import { useTranslation } from "react-i18next";
import PlayWithHuman from "./PlayWithHuman";
import { showErrorMessage } from "helpers/notifications";
import { PIECES } from "constants/chessPieces";
import { useSelector } from "react-redux";
import DrawOverlay from './DrawOverlay';

function EmptyChessboardLogic(props) {
  const [positionObject, setPositionObject] = useState({});
  const [firstStep, setFirstStep] = useState('w');
  const [start, setStart] = useState(false);
  const { t } = useTranslation();
  const chessBoardColor = useSelector(state => state.settings.chessBoardColor);
  const containerRef = React.useRef(null);
  const chessRef = React.useRef(null);

  const setStartPosition = () => {
    setPositionObject({ ...DEFAULT_POSITION_OBJECT });
  };

  const clearBoard = () => {
    setPositionObject({});
  };

  const getPosition = (obj) => {
    setPositionObject(obj);
  };

  const onStartClick = () => {
    let fenToStart = objToFen(positionObject);
    if (checkIsValidToStart(fenToStart)) {
      setStart(true);
    } else {
      showErrorMessage(t('game.invalidPosition'));
    }
  };

  if (start) {
    if (props.type === 'human') {
      return (
        <PlayWithHuman
          settings={props.settings}
          startFen={`${objToFen(positionObject)} ${firstStep} - - 0 1`}
          TaskText={PlayWithBotControls}
          calcWidth={setBoardWidth}
        />
      )
    }
    return (
      <PlayWithBot
        settings={props.settings}
        startFen={`${objToFen(positionObject)} ${firstStep} - - 0 1`}
        firstStep={firstStep}
        TaskText={PlayWithBotControls}
        calcWidth={setBoardWidth}
      />
    )
  }

  return (
    <div className='free-position'>
      <div className='free-position__buttons-container'>
        <button className='free-position__button' type={'button'} onClick={setStartPosition}>{t('game.startPosition')}</button>
        <button className='free-position__button' type={'button'} onClick={clearBoard}>{t('game.clearBoard')}</button>
        <div className='free-position__step-container'>
          <p className='free-position__step-title'>{t('game.firstMove')}</p>
          <div className='free-position__block'>
            <span className={`free-position__step free-position__step--white ${firstStep === 'w' ? 'free-position__step--active' : ''}`} onClick={() => setFirstStep('w')} />
            <span className={`free-position__step free-position__step--black ${firstStep === 'b' ? 'free-position__step--active' : ''}`} onClick={() => setFirstStep('b')} />
          </div>
        </div>
        <button className='free-position__button free-position__button--start' type={'button'} onClick={onStartClick}>{t('game.startGame')}</button>
      </div>
      <div ref={containerRef} className={'chess-display__chessboard'}>
        {window.innerWidth > 300 &&
          <DrawOverlay ref={chessRef} squareWidth={setBoardWidth() / 8} containerRef={containerRef} withFigures={true}/>
        }
        <div ref={chessRef}>
          <Chessboard
            sparePieces={true}
            id='PlayWithBot1'
            orientation={props.orientation}
            calcWidth={setBoardWidth}
            position={positionObject}
            dropOffBoard="trash"
            lightSquareStyle={chessBoardColor?.LIGHT || ''}
            darkSquareStyle={chessBoardColor?.DARK || ''}
            boardStyle={BOARD_STYLE}
            getPosition={getPosition}
            pieces={PIECES}
          />
        </div>
      </div>
    </div>
  )
}

export default function EmptyChessboard({ settings, type }) {
  const orientationParser = () => {
    return settings.side === 'w' ? 'white' : 'black'
  };

  return (
    <EmptyChessboardLogic
      orientation={orientationParser()}
      settings={settings}
      type={type}
    />
  );
}
