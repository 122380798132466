import React, {useEffect} from 'react';
import Loader1 from "../common/Loader/loader1";
import {useDispatch} from "react-redux";
import {useLocation, useHistory} from 'react-router-dom';
import {signInAction} from "actions/signIn";

export default function AppleLogin() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if(location.search) {
      let code = location.search.split('&')[0].split('=')[1];
      if(code) {
        dispatch(signInAction({ login_type: 'apple_web', code}, history));
      }
    }

  }, []);

  return (
    <Loader1/>
  )
}