import React from 'react';
import style from './style.module.scss';
import Button from '../../../../../common/Button';
import { closeOverlayAction } from '../../../../../../actions/overlay';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

export default function RatingModal({ name }) {
  console.log(name);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <div className={style.modal}>
      <div className={style.modal__header}>
        <span className={style.modal__title}>Достижения</span>
      </div>
      <div className={style.modal__body}>
        {/*<span className={style.body__header}>Текст заголовока</span>*/}
        <span className={style.body__text}>
          Здесь хранятся твои достижения. Проходи больше уровней, прокачивай навыки и зарабатывай еще больше ачивок!
        </span>
        <div className={style.modal__button_container}>
          <Button onClick={() => dispatch(closeOverlayAction())} classes={style.modal__button}>
            {t('statistics_skills.modal.button_text')}
          </Button>
        </div>
      </div>
    </div>
  );
}
