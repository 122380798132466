import { UNAUTHENTICATED } from './types';
import deleteAuthorizationToken from '../utils/deleteAuthorizationToken';

export function signOutAction() {
  deleteAuthorizationToken();
  localStorage.removeItem('token');
  localStorage.removeItem('role');
  return {
    type: UNAUTHENTICATED
  };
}