import React from "react";
import classnames from "classnames";
import style from "./style.module.scss";
import {useTranslation} from "react-i18next";
import {banStudent} from "../../../../../../services/api";

const BanButton = ({isBan, studentId, lessonId, isTemporary, openBanConfirmation, updateList}) => {
    const {t} = useTranslation();

    const onClickButton = () => {
        if (!isBan) openBanConfirmation();
        else {
            banStudent(lessonId, studentId, isTemporary)
                .then(() => updateList())
        }
    }

    return (
        <button
            className={classnames({
                [style.ban]: !isBan,
                [style.unban]: isBan,
            })}
            onClick={() => onClickButton()}
        >
            {!isBan ? t('partners.ban') : t('partners.unban')}
        </button>
    )
}

export default BanButton
