import React from 'react'
import s from './SingleNumber.module.scss'

/**
 * 
 * @param {number} number number which will be displayed on the component
 * @param {boolean} active state of the active number 
 * @param {function} onClick function which will be called when the component has been clicked 
 * @returns pagin component
 */

export default function SingleNumber({ number, active, onClick }) {
    return (
        <div className={`${s['single-item']} ${active && s['active']}`} onClick={() => onClick()}>
            {typeof number === 'number' ? <span>{number}</span> : number}
        </div>
    )
}