import React from 'react';
import Cart from '../../../../Cart';
import style from './style.module.scss';
import ProgressbarContainer from '../../../../ProgressbarContainer';
import { useTranslation } from 'react-i18next';

export default function LeftCart({ statisticsSkills }) {
  const { t } = useTranslation();
  return (
    <Cart classname={style.container}>
      <ProgressbarContainer
        progressColor={'#ADE398'}
        width={statisticsSkills?.logic}
        label={t('statistics_skills.logic')}
      />
      <ProgressbarContainer
        progressColor={'#0CB8FF'}
        width={statisticsSkills?.memory}
        label={t('statistics_skills.memory')}
      />
      <ProgressbarContainer
        progressColor={'#FEDB49'}
        width={statisticsSkills?.perception}
        label={t('statistics_skills.focus')}
      />
      <ProgressbarContainer
        progressColor={'#E65E87'}
        width={statisticsSkills?.flexibility}
        label={t('statistics_skills.flexibility')}
      />
      <ProgressbarContainer
        progressColor={'#615EE6'}
        width={statisticsSkills?.speed}
        label={t('statistics_skills.planning')}
      />
    </Cart>
  );
}
