import React from 'react';
import {Link} from "react-router-dom";
import './goToButton.scss';

export default function GoToButton({route, children, classes}) {

  return (
    <Link className={`go-to-button ${classes ? classes : ''}`} to={route ? route : '/'}>
      {children}
      <span className='arrow arrow-right'/>
    </Link>
  )
}