import React, {useRef} from "react";
import style from "./style.module.scss";
import Picker from "emoji-picker-react";
import {useOnClickOutside} from "../../../../../../hooks/outsideClick.hook";

const EmojiPicker = ({selectEmoji, closePicker}) => {
    const ref = useRef(null);

    useOnClickOutside(ref, closePicker);

    return (
        <div className={style.emojiPicker} ref={ref}>
            <Picker
                onEmojiClick={selectEmoji}
                disableSearchBar={true}
                groupVisibility={{
                    animals_nature: false,
                    food_drink: false,
                    travel_places: false,
                    activities: false,
                    objects: false,
                    symbols: false,
                    flags: false,
                    recently_used: false
                }}
                groupNames={{
                    smileys_people: '',
                }}
                pickerStyle={{height: '250px'}}
            />
        </div>
    )
}

export default EmojiPicker;