import React, { useEffect, useState, useRef } from 'react';
import {useLocation, NavLink} from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function TestItem({title, id, type, is_completed}) {
  const [currentLocationId, setId] = useState('');

  const ref = useRef();
  const location = useLocation();
  const completedExercises = useSelector(state => state.completedExercises.exercises);

  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

  useEffect(() => {
    if(Number(currentLocationId) === Number(id)) {
      scrollToRef(ref);
    }
  },[]);

  const getLocation = () => {
    return `${location.pathname}?exercise=${id}`
  };

  useEffect(() => {
    setId(location.search.split('=')[1]);
  }, [location]);

  const getLogo = (number, is_completed) => {
    if(number === 2) {
      return (is_completed || completedExercises.includes(id)) ? <span className='test__video-complete'/> : <></>;
    }
    if(number === 3) {
      return (is_completed || completedExercises.includes(id)) ? <span className='move__logo move__logo--solved'/> : <span className='move__logo'/>;
    }
    if(number === 4) {
      return (is_completed || completedExercises.includes(id)) ? <span className='repeat-task__logo repeat-task__logo--solved'/>: <span className='repeat-task__logo'/>;
    }
    if(number === 5) {
      return (is_completed || completedExercises.includes(id)) ? <span className='position__logo position__logo--solved'/> : <span className='position__logo'/>;
    }
    if(number === 6) {
      return (is_completed || completedExercises.includes(id)) ? <span className='test-chess__logo test-chess__logo--solved'/> : <span className='test-chess__logo'/>;
    }
    return (is_completed || completedExercises.includes(id)) ? <span className='test__logo test__logo--solved'/> : <span className='test__logo'/>;
  };

  if(!title && !id) {
    return (
      <div className={'test__item'}>
        <span className='move__logo site__logo'/>
        <div className='test__info'>
          <p className='test__title'/>
        </div>
      </div>
    )
  }

  if(Number(type) === 2) {
    return (
      <NavLink className={`test__video-lesson ${Number(currentLocationId) === Number(id) ? 'test__video-lesson--active' : ''}`} to={getLocation()} ref={ref} style={{color: 'white'}}>
        <span className='video__logo'/>
        <p className='test__video-title'>
          {title}
          {getLogo(Number(type), is_completed)}
        </p>
      </NavLink>
    )
  }

  return (
    <NavLink className={Number(currentLocationId) === Number(id) ? 'test__item test__item--active' : 'test__item'} to={getLocation()} ref={ref}>
      {Number(currentLocationId) === Number(id) && <span className='test__active-decoration'/>}
      {getLogo(Number(type), is_completed)}
      <div className='test__info'>
        <p className='test__title'>{title}</p>
      </div>
    </NavLink>
  )
}