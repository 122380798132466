import React from 'react';
import style from './style.module.scss';
import Button from '../../../../../common/Button';
import {closeOverlayAction} from '../../../../../../actions/overlay';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import classnames from 'classnames';

export default function ChessKnowledgeModal() {
    const dispatch = useDispatch();
    const lang = useSelector((state) => state.settings.lang);
    const {t} = useTranslation();
    return (
        <div className={style.modal}>
            <div className={style.modal__header}>
                <span className={style.modal__title}>{t('statistics_skills.chess_knowledge')}</span>
            </div>

            <div className={style.modal__body}>
                {lang === 'rus' || lang === 'eng' || lang === 'es' ? (
                    <>
                        <span
                            className={style.body__header}>{t('statistics_skills.modal.chess_knowledge.header')}</span>
                        <span className={style.body__text}>{t('statistics_skills.modal.chess_knowledge.text1')}</span>
                        <span className={style.body__text}>{t('statistics_skills.modal.chess_knowledge.text2')}</span>
                        <span className={style.body__text}>
              {t('statistics_skills.modal.chess_knowledge.explanations_level_rise')}
            </span>
                        <span className={style.body__text}>{t('statistics_skills.modal.start_level')}</span>
                        <span className={style.body__text}>{t('statistics_skills.modal.luck')}</span>
                    </>
                ) : (
                    ''
                )}

                <div className={style.modal__button_container}>
                    <Button onClick={() => dispatch(closeOverlayAction())} classes={style.modal__button}>
                        {t('statistics_skills.modal.button_text')}
                    </Button>
                </div>
            </div>
        </div>
    );
}
