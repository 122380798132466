import React, { useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import {PASSWORD_RESET} from "services/api";
import { getSavedLang } from 'utils/getBrowserLang';

export default function ResetPassword(props) {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const {t} = useTranslation();

  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };

 const handleSubmit = (e) => {
    e.preventDefault();
    if(email) {
      axios.get(PASSWORD_RESET, {
        params: {
          email,
          lang: getSavedLang(),
        }
      })
        .then((res) => {
          setSuccess(true);
        })
        .catch(e => {
          console.log(e.response)
        });
    } else {
      setError({ error: ['Введите email']})
    }
  };

  return (
    <form className='login__form' onSubmit={handleSubmit}>
      <p className='login__reset-title'>{t('auth.recovery')}</p>
      <div className='login__form-item' style={{width: '100%'}}>
        <input className='login__input' onChange={handleInputChange} type='email' name='email' placeholder={`${t('auth.emailAddress')}`}/>
      </div>
      <button className='login__button login__button--red' disabled={success}>{t('auth.reset')}</button>
      <p className='login__registration-link'>
          {t('auth.or')}
          <button className='login__navigation-link' type='button' onClick={() => props.setPopupMode('default')}>{t('auth.login')}</button>
      </p>
      {error && error.map(el => <span className='login__error'>{el}</span>)}
      {success && <span>{t('auth.successReset')}</span>}
    </form>
  )
}
