import React from "react";
import {ClockIcon} from "../../../../assets/img/partners";
import style from "../style.module.scss";
import InputError from "../../../common/InputError";
import {useField} from "formik";
import classnames from "classnames";
import InputMask from "react-input-mask";

const TimeField = ({
                       name,
                       label,
                       ...props
                   }) => {
    const [field, meta, helpers] = useField(name);
    const fieldError = () => meta.error && meta.touched ? meta.error : false;

    const handleChange = (event) => {
        const value = event.target.value;
        if (/^\d{2}:\d{2}$/.test(value) && /^\d{2}:\d{2}$/.test(field.value)) return false;
        else if (value && (value !== '__:__' || field.value !== '__:__')) {
            helpers.setValue(`${event.target.value}:00`)
        }
    }

    return (
        <div className={style.pickerWrapper}>
            {label && <label className={style.label} htmlFor={`${field.name}_${label}`}>{label}</label>}
            <div className={classnames(style.input__wrapper, {
                'text-input--warn': !!fieldError()
            })}>
                <div className={style.input__icon}>
                    <ClockIcon />
                </div>
                <InputMask mask="99:99" {...field} onChange={handleChange} className={style.input} />
            </div>
            {fieldError() && <InputError>{fieldError()}</InputError>}
        </div>
    )
}

export default React.memo(TimeField);