import React, {useEffect, useState} from 'react';
import {getPriceFromPaddleGross} from "helpers/currencyHelper";
import {useTranslation} from "react-i18next";

export default function SubscriptionItem({month, isSpecial, onItemClick, id}) {
  const {t} = useTranslation();
  const [data, setData] = useState({
    currency: '',
    gross: ''
  });

  const getPrice = (obj) => {
    setData({ currency: obj.country, gross: obj.price.gross})
  };

  useEffect(() => {
    window.Paddle && window.Paddle.Product.Prices(id, 1, getPrice)
  }, []);

  return (
    <li className={`subscription__list-item ${isSpecial ? 'subscription__list-item--special': ''}`} onClick={() => onItemClick(id)}>
      <div className='subscription__list-container'>
        <span className='subscription__item-text subscription__item-text--month'>{t('subscription.months')}: {month}</span>
        <span className='subscription__item-text subscription__item-text--price'>{getPriceFromPaddleGross(data.gross)}</span>
      </div>
    </li>
  )
}