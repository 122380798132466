import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import HidePass from 'constants/SVG/HidePass';
import ShowPass from 'constants/SVG/ShowPass';
import {useHttp} from 'hooks/http.hook'
import './passwordChange.scss';
import {PUT_USER_PASSWORD} from "services/api";
import {showErrorMessage, showSuccessMessage} from "helpers/notifications";

export default function PasswordChangeForm(props) {
  const [form, setForm] = useState({
    password: '',
    new_password: '',
    repeat_new_password: ''
  });
  const [clientError, setClientError] = useState('');
  const [pass1, showPass1] = useState(false);
  const [pass2, showPass2] = useState(false);
  const [pass3, showPass3] = useState(false);
  const {request, loading, error} = useHttp();
  const {t} = useTranslation();

  const handleInputChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    setForm({...form, [name]: value})
  };

  const handleSubmit = async(e) => {
    setClientError('');
    e && e.preventDefault();
    if(form.password && form.new_password && form.repeat_new_password) {
      if(form.new_password === form.repeat_new_password) {
        let data = new FormData();
        data.append('password', form.password);
        data.append('new_password', form.new_password);
          let res = await request(PUT_USER_PASSWORD, 'PUT', data);
          if(res) {
            showSuccessMessage(t('profile.successChangedPass'));
            props.handleClose();
          } else {
            showErrorMessage(t('errors.incorrectPass'))
          }
      } else {
        setClientError(t('profile.notMatch'))
      }
    } else {
      setClientError(t('profile.enterRequired'))
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <ul className='password-change__list'>
        <span className='password-change__close-button' onClick={props.handleClose}>
          <svg className='password-change__close-svg' width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path className='password-change__close-svg-path' d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="#C5C6C6"/>
          </svg>
        </span>
        <li className='password-change__item'>
          {t('profile.currentPass')}
          <label className='password-change__label'>
            <span className='login__password-button' onClick={() => showPass1(!pass1)}>
              {pass1 ? <HidePass/> : <ShowPass/>}
            </span>
            <input
              className='profile__input password-change__input'
              onChange={handleInputChange}
              type={pass1 ? 'text' : 'password'}
              name='password'/>
          </label>
        </li>
        <li className='password-change__item'>
          {t('profile.newPass')}
          <label className='password-change__label'>
            <span className='login__password-button' onClick={() => showPass2(!pass2)}>
              {pass2 ? <HidePass/> : <ShowPass/>}
            </span>
            <input
              className='profile__input password-change__input'
              onChange={handleInputChange} type={pass2 ? 'text' : 'password'}
              name='new_password'
            />
          </label>
        </li>
        <li className='password-change__item'>
          {t('profile.repeatPass')}
          <label className='password-change__label'>
            <span className='login__password-button' onClick={() => showPass3(!pass3)}>
              {pass3 ? <HidePass/> : <ShowPass/>}
            </span>
            <input
              className='profile__input password-change__input'
              onChange={handleInputChange}
              type={pass3 ? 'text' : 'password'}
              name='repeat_new_password'
            />
          </label>
        </li>
        <li className='password-change__item'>
          <button
            className='profile__button profile__button--save password-change__button'
            disabled={loading}
            type='submit'
            onClick={handleSubmit}
          >
            {t('profile.save')}
          </button>
        </li>
        {clientError &&
          <li className='password-change__item'>
            {clientError}
          </li>
        }
        {error &&
          <li className='password-change__item'>
            {t('profile.error')}
          </li>
        }
      </ul>
    </form>
  )
}