import React from 'react';
import './buyCourseHeader.scss';
import './buyCourseHeaderMobile.scss';
import {useWindowSize} from "hooks/resize.hook";
import ModalWindow from "components/common/ModalWindow";
import PreviewModal from "../BuyCoursePreview/PreviewWindow";
import PreviewButton from "../BuyCoursePreview/PreviewButton";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {TOGGLE_LOGIN_WINDOW} from "actions/types";
import {useTranslation} from "react-i18next";
import {openOverlayAction} from "../../../../actions/overlay";
import Login from "../../../Login";

export default function BuyCourseHeader({id, courseImage = '', exercises = '', video = '', update = '', title = '', description = '', previewVideo, subscription, paddleId }) {
  const windowSize = useWindowSize();
  const auth = useSelector(state => state.auth.authenticated);
  const dispatch = useDispatch();
  const {t} = useTranslation();

    const openLogin = () => {
        dispatch(openOverlayAction(Login))
    }

  return (
    <div className='buy-course__header'>
      <div className='buy-course__image-container'>
        <img className='buy-course__image' src={courseImage}/>
      </div>
      <div className='buy-course__header-container'>
        <div className='buy-course__header-info-container'>
          <h2 className='buy-course__header-title'>{title}</h2>
          <ul className='buy-course__header-info-list'>
            {exercises && <li className='buy-course__header-info-item'>{exercises}</li>}
            {video && <li className='buy-course__header-info-item'>{video}</li>}
            {update && <li className='buy-course__header-info-item'>{update}</li>}
          </ul>
          {description && <p  className='buy-course__header-description' dangerouslySetInnerHTML={{__html: description}}></p>}
          {auth ?
            <div className='buy-course__header-buttons-container'>
              {subscription && <Link className='buy-course__button buy-course__button--red' to={`/subscription/${subscription}`}>{t('courseBuy.subscription')}</Link>}
              {paddleId && <Link className='buy-course__button' to={`/course/${id}?mode=card`}>{t('courseBuy.buy')}</Link>}
            </div>
            :
            <div className='buy-course__header-buttons-container'>
              {subscription && <button className='buy-course__button buy-course__button--red' onClick={() => dispatch(openLogin)}>{t('courseBuy.subscription')}</button>}
              {paddleId && <button className='buy-course__button' type={'button'} onClick={openLogin}>{t('courseBuy.buy')}</button>}
            </div>
          }
        </div>
        {windowSize.width > 950 &&
          <div className='buy-course__header-info-container buy-course__header-info-container--center'>
            {previewVideo && <ModalWindow WindowComponent={PreviewModal} ButtonComponent={PreviewButton} previewVideo={previewVideo}/>}
          </div>
        }
      </div>
    </div>
  )
}