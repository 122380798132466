import React from "react";
import style from "./style.module.scss"
import {ReactComponent as Trash}from "../../../../../../assets/img/trash.svg";
import TimePicker from "./TimePicker";

const Exercise = ({
    number,
    exercise,
    deleteExercise,
    changeTime,
    is_training
}) => {
    return (
        <>
            <td width="5%">
                <div>{number}.</div>
            </td>
            <td>{exercise.course_name}</td>
            <td>{exercise.lesson_name}</td>
            <td>{exercise.name}</td>
            {
                !is_training &&
                <>
                    <td>
                        <div>
                            <TimePicker changeTime={changeTime} timeProperty="duration" id={exercise.id} time={exercise.duration} />
                        </div>
                    </td>
                    <td>
                        <div>
                            <TimePicker changeTime={changeTime} timeProperty="pause_time" id={exercise.id} time={exercise.pause_time} />
                        </div>
                    </td>
                </>
            }
            <td width="10%">
                <div>
                    <button onClick={() => deleteExercise(exercise.id)} className={style.exercise__deleteBtn}><Trash /></button>
                </div>
            </td>
        </>
    )
}

export default React.memo(Exercise);
