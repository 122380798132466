import React from 'react';
import { Route, Switch } from 'react-router-dom';
import SubscriptionMain from "./SubscriptionMain";
import SubscriptionBasic from "./SubscriptionBasic";
import SubscriptionPro from "./SubscriptionPro";

export default function SubscriptionRouter() {
  return (
    <Switch>
      <Route path='/subscription' exact component={SubscriptionMain} />
      <Route path='/subscription/basic' component={SubscriptionBasic} />
      <Route path='/subscription/pro' component={SubscriptionPro} />
    </Switch>
  )
}