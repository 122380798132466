import React from 'react';
import './display.scss';

export default function InteractiveContainer({ children }) {
  return (
    <div className='interface__display display display--buttons'>
      <div className='display__main-window'>
        {children}
      </div>
    </div>
  )
}