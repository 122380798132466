import React, {useMemo} from "react";
import style from "./style.module.scss";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";

const Navbar = () => {
    const {t} = useTranslation();

    const links = useMemo(() => [
        {id: 1, path: '/abclass', text: t('abclass.main'), exact: true},
        {id: 2, path: '/abclass/training', text: t('abclass.training')},
        {id: 3, path: '/abclass/schedule', text: t('abclass.schedule')}
    ],[]);

    return (
        <div className={style.container}>
            <div className={style.links}>
                {
                    links.map(link => (
                        <NavLink
                            className={style.links__link}
                            activeClassName={style.links__link_active}
                            key={link.id}
                            to={link.path}
                            exact={!!link.exact}
                        >
                            {link.text}
                        </NavLink>
                    ))
                }
            </div>
        </div>
    )
}

export default Navbar