import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";

export default function Turn({ turn }) {
  const {t} = useTranslation();

  if(turn === 'w') {
    return (
      <div className="chess-display__turn-container">
        <span className='chess-display__chess-img white-pawn__logo'/>
        <span className='chess-display__step'>{t('exercises.whiteStep')}</span>
      </div>
    )
  } else {
    return (
      <div className="chess-display__turn-container">
        <span className='chess-display__chess-img black-pawn__logo2'/>
        <span className='chess-display__step'>{t('exercises.blackStep')}</span>
      </div>
    )
  }
}

Turn.propTypes = {
  turn: PropTypes.string.isRequired
};

