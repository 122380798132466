import React, { useCallback, useRef, useState } from 'react';
import HeaderProfileCard from './HeaderProfileCard';
import {useSelector} from "react-redux";

function ProfileButton() {
  const [ open, handleOpen ] = useState(false);
  const profile = useSelector(state => state.profile.profileData);
  const btnRef = useRef()

  const togglePopup = useCallback(() => {
    handleOpen(!open);
  }, [open]);

  return (
    <div className='header-profile__item header-profile__item--container'>
      <button ref={btnRef} type='button' className='header-profile__person person__logo' style={profile.image && {backgroundImage: `url(${profile.image})`, objectFit: 'cover'}} id={'profile_button'} onClick={togglePopup}/>
      {open && <HeaderProfileCard togglePopup={togglePopup} btnRef={btnRef}/>}
    </div>
  );
}

export default ProfileButton;