import React from 'react';
import {Link} from "react-router-dom";
import './backToButton.scss';

export default function BackToButton({route, children}) {

  return (
    <Link className='back-to-button' to={route ? route : '/'}>
      <span className='arrow arrow-left'/>
      {children}
    </Link>
  )
}