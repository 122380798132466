import React, { FC, useCallback } from 'react'
import s from './Calendar.module.css'


function getCountOfDays(date) {
    return 32 - new Date(new Date(date).getFullYear(), new Date(date).getMonth(), 32).getDate()
}

function generateYears(year) {
    let years = []

    for (let i = year - 5; i <= year + 4; i++) {
        years.push(i)
    }

    return years
}

function generateCalendar(date) {

    let calendarData = []
    let today = new Date(date)
    let countOfDays = getCountOfDays(date)
    let firstDayOfMonth = new Date(today.getFullYear(), today.getMonth()).getDay()

    const prevMonth = today.getMonth() - 1
    const prevYear = today.getFullYear()
    const nextMonth = today.getMonth() + 1
    const nextYear = today.getFullYear()

    //fill space of previous month days
    for (let i = 1; i < firstDayOfMonth; i++) {

        let dataObj = {
            year: prevYear,
            month: prevMonth,
            day: getCountOfDays(new Date(prevYear, prevMonth, 2)?.toISOString().substr(0, 10)) - firstDayOfMonth + i + 1
        }

        calendarData.push(dataObj)
    }


    //fill current month
    for (let i = 1; i <= countOfDays; i++) {

        let dataObj = {
            year: today.getFullYear(),
            month: today.getMonth(),
            day: i
        }

        calendarData.push(dataObj)
    }

    //fill next month days
    for (let i = 1; i <= 42 - countOfDays - (firstDayOfMonth === 0 ? firstDayOfMonth : firstDayOfMonth - 1); i++) {

        let dataObj = {
            year: nextYear,
            month: nextMonth,
            day: i
        }

        calendarData.push(dataObj)
    }

    return calendarData
}

const MonthPicker = ({ month, months, setMonth }) => {
    const [initMonth, setInitMonth] = React.useState(month)
    const [listMonths] = React.useState(months)

    const onClickHandler = (month) => {
        setMonth({ month })
        setInitMonth(month)
    }

    return (
        <div className={s.yearPickerWrapper}>
            <div className={s.months}>
                {listMonths.map((item, key) =>
                    <div key={key} onClick={() => { onClickHandler(key) }} className={s.year}>
                        <span className={`${s.yearText} ${initMonth === item && s.initYear}`}>{item}</span>
                    </div>
                )}
            </div>
        </div>
    )
}

const YearPicker = ({ year, setYear }) => {

    const [initYear, setInitYear] = React.useState(year)
    const [years, setYears] = React.useState(generateYears(year))

    const prevYear = React.useCallback((e) => {
        setYears(generateYears(initYear - 10))
        setInitYear(initYear - 10)
        e.stopPropagation()
    }, [initYear, year])

    const nextYear = React.useCallback((e) => {
        setYears(generateYears(initYear + 10))
        setInitYear(initYear + 10)
        e.stopPropagation()
    }, [initYear, year])

    const onClickHandler = (year) => {
        setYear({ year })
        setInitYear(year)
        setYears(generateYears(year))
    }

    React.useEffect(() => {
        setInitYear(year)
        setYears(generateYears(year))
    }, [year])

    return (
        <div className={s.yearPickerWrapper}>
            <button className={s.button} onClick={(e) => prevYear(e)} type='button'>
                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-left" role="img" viewBox="0 0 256 512"><path fill="currentColor" d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z" /></svg>
            </button>
            <div className={s.years}>
                {years.map((item, key) =>
                    <div key={key} onClick={() => { onClickHandler(item) }} className={s.year}>
                        <span className={`${s.yearText} ${initYear === item && s.initYear}`}>{item}</span>
                    </div>
                )}
            </div>
            <button className={s.button} onClick={(e) => nextYear(e)} type='button'>
                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" role="img" viewBox="0 0 256 512"><path fill="currentColor" d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z" /></svg>
            </button>
        </div>
    )
}

const ProfileCalendar = ({ namesOfDays = ['Mon', 'Tue', 'Wen', 'Thu', 'Fri', 'Sat', 'Sun'], namesOfMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'], datePosition = 'topLeft', data, theme = 'abchess', value, className = '', setSelected, onClick }) => {

    const [date, setDate] = React.useState(value ? new Date(value) : new Date())
    const [activeDay, setActiveDay] = React.useState(date)
    const [showModal, setShowModal] = React.useState(false)
    const today = new Date(Date.now())

    const nextMonth = useCallback(() => {
        let currentDate = new Date(date?.toISOString().substr(0, 10))
        let nextMonth = currentDate.getUTCMonth() + 2
        let nextYear = currentDate.getUTCFullYear()

        setDate(new Date(nextYear, nextMonth))
    }, [date])

    const nextYear = useCallback(() => {
        let currentDate = new Date(date?.toISOString().substr(0, 10))
        let nextYear = currentDate.getUTCFullYear() + 1

        setDate(new Date(nextYear, currentDate.getUTCMonth() + 1))
    }, [date])

    const prevMonth = useCallback(() => {
        let currentDate = new Date(date?.toISOString().substr(0, 10))
        let prevMonth = currentDate.getUTCMonth()
        let prevYear = currentDate.getUTCFullYear()

        setDate(new Date(prevYear, prevMonth))
    }, [date])

    const prevYear = useCallback(() => {
        let currentDate = new Date(date?.toISOString().substr(0, 10))
        let prevYear = currentDate.getUTCFullYear() - 1

        setDate(new Date(prevYear, currentDate.getUTCMonth() + 1))
    }, [date])

    const customDate = useCallback(({ year, month, day }) => {
        let currentDate = new Date(date?.toISOString().substr(0, 10))

        setDate(new Date(year ? year : currentDate.getUTCFullYear(), month < 12 ? month : currentDate.getUTCMonth(), day ? day : currentDate.getUTCDate()))
        setShowModal(false)
    }, [date])

    const onClickHandler = useCallback((e, value) => {
        e.preventDefault()
        const [day, month, year] = splitDateString(value)

        if (date.getUTCMonth() < month) {
            nextMonth()
        } else if (date.getUTCMonth() > month) {
            prevMonth()
        }

        setActiveDay(new Date(year, month, day))

        let newActiveDay = new Date(year, month, day + 1)
        setShowModal(false)

        let options = {year: '2-digit', month: '2-digit', day: '2-digit'}

        if (setSelected) setSelected(new Date(`${(newActiveDay.getUTCMonth() + 1 < 10 && '0') + (newActiveDay.getUTCMonth() + 1)}/${(newActiveDay.getUTCDate() < 10 && '0') + newActiveDay.getUTCDate()}/${newActiveDay.getUTCFullYear()}`).toLocaleDateString(options))
        if (onClick) onClick(new Date(`${(newActiveDay.getUTCMonth() + 1 < 10 && '0') + (newActiveDay.getUTCMonth() + 1)}/${(newActiveDay.getUTCDate() < 10 && '0') + newActiveDay.getUTCDate()}/${newActiveDay.getUTCFullYear()}`).toLocaleDateString(options))
    }, [date])

    const splitDateString = (date) => {
        return date.replace(/\./, '/').split('/').map(item => Number(item))
    }

    const isCurrentDay = (day1, month1, year1, day2, month2, year2) => {
        return new Date(year1, month1, day1).getTime() === new Date(year2, month2, day2).getTime()
    }

    React.useEffect(() => {
        if (value) {
            if (value.match(/^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/)) {
                setDate(new Date(value))
                setActiveDay(new Date(value))
            }

        }
    }, [value])

    return (
        <div className={`${s.calendar} ${className} ${s[theme]}`} onClick={() => setShowModal(false)}>
            <div className={s.navigation}>
                <button className={s.button} onClick={() => prevYear()} type='button'>
                    <svg xmlns="http://www.w3.org/2000/svg" ariaHidden="true" focusable="false" dataPrefix="fas" data-icon="angle-double-left" role="img" viewBox="0 0 448 512"><path fill="currentColor" d="M223.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L319.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L393.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34zm-192 34l136 136c9.4 9.4 24.6 9.4 33.9 0l22.6-22.6c9.4-9.4 9.4-24.6 0-33.9L127.9 256l96.4-96.4c9.4-9.4 9.4-24.6 0-33.9L201.7 103c-9.4-9.4-24.6-9.4-33.9 0l-136 136c-9.5 9.4-9.5 24.6-.1 34z" /></svg>
                </button>
                <button className={s.button} onClick={() => prevMonth()} type='button'>
                    <svg xmlns="http://www.w3.org/2000/svg" ariaHidden="true" focusable="false" dataPrefix="fas" dataIcon="angle-left" role="img" viewBox="0 0 256 512"><path fill="currentColor" d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z" /></svg>
                </button>
                <div className={s.monthYear}>
                    <button className={s.text} type='button' onClick={(e) => { setShowModal('month'); e.stopPropagation() }}>
                        {namesOfMonths[new Date(date).getUTCMonth()]}
                    </button>
                    <button className={s.text} type='button' onClick={(e) => { setShowModal('year'); e.stopPropagation() }}>
                        {new Date(date).getUTCFullYear()}
                    </button>
                </div>
                <button className={s.button} onClick={() => nextMonth()} type='button'>
                    <svg xmlns="http://www.w3.org/2000/svg" ariaHidden="true" focusable="false" dataPrefix="fas" dataIcon="angle-right" role="img" viewBox="0 0 256 512"><path fill="currentColor" d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z" /></svg>
                </button>
                <button className={s.button} onClick={() => nextYear()} type='button'>
                    <svg xmlns="http://www.w3.org/2000/svg" ariaHidden="true" focusable="false" dataPrefix="fas" dataIcon="angle-double-right" role="img" viewBox="0 0 448 512"><path fill="currentColor" d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34zm192-34l-136-136c-9.4-9.4-24.6-9.4-33.9 0l-22.6 22.6c-9.4 9.4-9.4 24.6 0 33.9l96.4 96.4-96.4 96.4c-9.4 9.4-9.4 24.6 0 33.9l22.6 22.6c9.4 9.4 24.6 9.4 33.9 0l136-136c9.4-9.2 9.4-24.4 0-33.8z" /></svg>
                </button>
                <div className={`${s.modalWrapper}`}>

                    <div className={`${s.modalItem} ${showModal === 'year' && s.showModal}`}>
                        <YearPicker year={new Date(date?.toISOString().substr(0, 10)).getFullYear()} setYear={customDate} />
                    </div>
                    <div className={`${s.modalItem} ${showModal === 'month' && s.showModal}`}>
                        <MonthPicker months={namesOfMonths} month={namesOfMonths[new Date(date).getUTCMonth()]} setMonth={customDate} />
                    </div>
                </div>
            </div>
            <div className={s.calendarWrapper}>
                {namesOfDays.map((item, key) =>
                    <div
                        key={`dayName${key}`}
                        className={s.daysItem}>
                        <div className={`${s.number} ${s[datePosition]}`}>{item}</div>
                    </div>)}

                {generateCalendar(date?.toISOString().substr(0, 10)).map((item, key) =>
                    <div
                        onClick={(e) => onClickHandler(e, `${item.day}/${item.month}/${item.year}`)}
                        key={`day${key}`}
                        className={`${s.calendarItem} ${item.month === new Date(date).getUTCMonth() ? s.currentMonth : s.otherMonth} ${isCurrentDay(today.getUTCDate(), today.getUTCMonth(), today.getUTCFullYear(), item.day, item.month, item.year) && s.currentDay} ${isCurrentDay(item.day, item.month, item.year, activeDay.getDate(), activeDay.getMonth(), activeDay.getFullYear()) && s.selectedDay}`}
                    >
                        <div className={`${s.number} ${s[datePosition]}`}>{item.day}</div>
                        <div className={s.content}>{data &&
                            data.filter((content) => {
                                let [day, month, year] = content.date.split('.')
                                if (isCurrentDay(Number(day), Number(month) - 1, Number(year), item.day, item.month, item.year)) return content.payload
                            }).length || ''}</div>
                    </div>)}
            </div>
        </div>
    )
}

export default React.memo(ProfileCalendar);