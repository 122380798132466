import React from 'react';
import { NavLink } from "react-router-dom";
import {useTranslation} from "react-i18next";
import CoursesRouter from "./CoursesRouter";
import './myCourses.scss';
import './myCoursesMobile.scss';

export default function MyCourses() {
  const {t} = useTranslation();

  return (
    <section className='courses'>
      <div className='courses__wrapper'>
        <h1 className='courses__title'>{t('courses.myCourses')}</h1>
        <nav className='courses__navigation'>
          <ul className='courses__navigation-list'>
            <li className='courses__navigation-item'>
              <NavLink to='/my-courses/progress' className='courses__navigation-link'>
                {t('courses.inProgress')}
              </NavLink>
            </li>
            <li className='courses__navigation-item'>
              <NavLink to='/my-courses/completed' className='courses__navigation-link'>
                {t('courses.completed')}
              </NavLink>
            </li>
            <li className='courses__navigation-item'>
              <NavLink to='/my-courses/purchased' className='courses__navigation-link'>
                {t('courses.purchased')}
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
        <div className='courses__container'>
          <CoursesRouter/>
        </div>
    </section>
  )
}
