import { useWindowSize } from "hooks/resize.hook";
import useResolution from "hooks/useResolution";
import React, { useEffect, useRef, useState, useLayoutEffect } from "react";
import { setBoardWidth } from "../chessHelperFuntions";

const DrawOverlay = React.forwardRef(({ squareWidth, containerRef, fen, withFigures }, chessRef) => {
  const [isDraw, setIsDraw] = useState(false);
  const [isCoinDraw, setIsCoinDraw] = useState(false);
  const [arrowsToRender, setArrowsToRender] = useState([]);
  const [showCurrentArrow, setShowCurrentArrow] = useState(false);
  const [currentArrow, setCurrentArrow] = useState('');
  const [coins, setCoins] = useState([]);
  const [coef, setCoef] = useState(100 / (squareWidth * 8));
  const ref = useRef();
  const [overlayPosition, setOverlayPosition] = useState({ left: 0, top: 0, width: 0, height: 0 })
  const nineHundredPx = useResolution(900);
  const windowSize = useWindowSize();

  useLayoutEffect(() => {
    const listener = event => {
      if (!ref.current || ref.current.contains(event.target) || containerRef.current.contains(event.target)) {
        return;
      }
      clearCanvas();
    };
    document.addEventListener('mousedown', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [ref]);

  const resizeEvent = React.useCallback((chessRef) => {
    if (chessRef.current?.children) {

      let figures1, board

      if (withFigures && chessRef.current.children[1]?.length !== 0) {
        [figures1, board] = chessRef.current.children[0]?.children;
      } else {
        board = chessRef.current.children[0];
      }

      const { width, height, left, top } = getBoardSize();
      setOverlayPosition({left, top, width, height})

    }
  }, [chessRef.current, nineHundredPx, windowSize])

  React.useEffect(() => {
    resizeEvent(chessRef)
    const board = chessRef.current;
    if (board) {
      let {
        width,
        height
      } = board.getBoundingClientRect();
      if (width === 0 || height === 0) {
        const b = board.firstChild;
        const list = b.children;
        setTimeout(() => {
          const array = Array.from(list);
          if (!array || !Array.isArray(array)) {
            return;
          }
          const top_margin = array[0]?.getBoundingClientRect().height;
          array.map(child => {
            if (child.getAttribute('data-boardid') === 'PlayWithBot1') {
              const boardSize = getBoardSize();
              const { width, height, left, top } = boardSize;
              setOverlayPosition({
                  ...overlayPosition,
                  top: top_margin + 6 + 'px',
                  left,
                  width,
                  height,
                });
            }
          });
        }, 100);
      }
      if (width === 0 || height === 0) {
        return;
      }
      const boardSize = getBoardSize();
      const { left, top } = boardSize;
      setOverlayPosition({
            ...overlayPosition,
            width: boardSize.width,
            height: boardSize.height,
            left,
            top
        });
      }
  }, [chessRef.current])

  React.useEffect(() => {
    window.addEventListener('resize', function () {
      resizeEvent(chessRef)
    })
    return () => {
      window.removeEventListener('resize', function () {
        resizeEvent(chessRef)
      })
    }
  }, [chessRef.current, nineHundredPx])

  useEffect(() => {
    fen && clearCanvas();
  }, [fen]);

  useEffect(() => {
    setCoef(100 / (squareWidth * 8))
  }, [squareWidth]);

  useEffect(() => {
    ref.current.addEventListener('click', function (e) {
      if (e.which === 3) {
        ref.current.addEventListener('contextmenu', function (e) {
          e.preventDefault();
        }, false);
      }
    });
  }, [ref]);

  const onShiftPress = (e) => {
    if (e.keyCode === 16) {
      setIsDraw(true);
    }
    if (e.shiftKey && e.altKey) {
      setIsDraw(true);
      setIsCoinDraw(true);
    }

    const onShiftUp = (e) => {
      if (e.keyCode === 16) {
        setIsDraw(false)
      }
      if (e.shiftKey || e.altKey) {
        setIsDraw(false);
        setIsCoinDraw(false);
      }
      document.removeEventListener('keyup', onShiftUp);
    };
    document.addEventListener('keyup', onShiftUp);
  };

  useEffect(() => {
    document.addEventListener('keydown', onShiftPress);
    return () => {
      document.removeEventListener('keydown', onShiftPress);
    }
  }, [ref]);


  const onMouseOut = () => {
    setCurrentArrow('');
    setShowCurrentArrow(false);
  };

  const mouseDown = (e) => {
    e.preventDefault();

    let rect = ref.current.getBoundingClientRect();
    let x = e.clientX - rect.left;
    let y = e.clientY - rect.top;

    let startCords = {
      x: (Math.floor(x / squareWidth) * squareWidth + squareWidth / 2) * coef,
      y: (Math.floor(y / squareWidth) * squareWidth + squareWidth / 2) * coef
    };

    let linesColor;
    if (e.buttons === 2) {
      linesColor = 'red'
    }
    if (e.buttons === 1) {
      linesColor = 'green'
    }

    if (isCoinDraw) {
      let filteredCoins = coins.filter(el => el.key === `coin${startCords.x}${startCords.y}`);
      if (filteredCoins.length) {
        let newArr = [...coins];
        newArr.splice(newArr.findIndex(el => el.key === filteredCoins[0].key), 1);
        setCoins(newArr)
      } else {
        setCoins([...coins, <image xlinkHref={require('../../../assets/img/coin.png')} key={`coin${startCords.x}${startCords.y}`} pointerEvents={'none'} x={startCords.x - (squareWidth * 0.7 * coef / 2)} y={startCords.y - (squareWidth * 0.7 * coef / 2)} height={squareWidth * 0.7 * coef} width={squareWidth * 0.7 * coef} />])
      }
      return;
    }

    const onMouseMove = (e2) => {
      let layerX = e2.clientX - rect.left;
      let layerY = e2.clientY - rect.top;
      let linesColor;
      if (e2.buttons === 2) {
        linesColor = 'red'
      }
      if (e2.buttons === 1) {
        linesColor = 'green'
      }
      getCurrentArrow(startCords, { x: (Math.floor(layerX / squareWidth) * squareWidth + squareWidth / 2) * coef, y: (Math.floor(layerY / squareWidth) * squareWidth + squareWidth / 2) * coef }, linesColor);
    };

    ref.current.addEventListener('mousemove', onMouseMove);

    getCurrentArrow(startCords, startCords, linesColor);
    setShowCurrentArrow(true);

    const onMouseUp = function (e3) {
      e3.preventDefault();
      ref.current.removeEventListener('mousemove', onMouseMove);
      setShowCurrentArrow(false);
      setCurrentArrow('');
      let x2 = e3.clientX - rect.left;
      let y2 = e3.clientY - rect.top;
      let linesColor;
      if (e3.which === 3) {
        linesColor = 'red'
      }
      if (e3.which === 1) {
        linesColor = 'green'
      }

      let endCords = {
        x: (Math.floor(x2 / squareWidth) * squareWidth + squareWidth / 2) * coef,
        y: (Math.floor(y2 / squareWidth) * squareWidth + squareWidth / 2) * coef
      };


      let filteredArrows = arrowsToRender.filter(el => {
        if (startCords.x === endCords.x && startCords.y === endCords.y) {
          return el.key === `c${startCords.x}${startCords.y}`
        }
        return el.key === `a${startCords.x}${startCords.y}${endCords.x}${endCords.y}`
      });
      if (filteredArrows.length) {
        let newArr = [...arrowsToRender];
        newArr.splice(newArr.findIndex(el => el.key === filteredArrows[0].key), 1);
        setArrowsToRender(newArr)
      } else {
        if (startCords.x === endCords.x && startCords.y === endCords.y) {
          drawCircle(startCords.x, startCords.y, linesColor);
        } else {
          drawLine(startCords, endCords, linesColor);
        }
      }
      ref.current.removeEventListener('mouseup', onMouseUp);
    };

    let removeMouseUp = () => {
      ref.current && ref.current.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('mouseup', removeMouseUp);
    };

    document.addEventListener('mouseup', removeMouseUp);
    ref.current.addEventListener('mouseup', onMouseUp);
  };

  const checkIsKnight = (startCords, endCords) => {
    let roundSquare = Math.round(squareWidth) * coef;
    let roundStartX = Math.round(startCords.x);
    let roundStartY = Math.round(startCords.y);
    let roundEndX = Math.round(endCords.x);
    let roundEndY = Math.round(endCords.y);
    let fallibility = 5;
    return (
      ((roundStartY + roundSquare * 2 < roundEndY + fallibility && roundStartY + roundSquare * 2 > roundEndY - fallibility) || (roundStartY - roundSquare * 2 < roundEndY + fallibility && roundStartY - roundSquare * 2 > roundEndY - fallibility))
      &&
      ((roundStartX + roundSquare < roundEndX + fallibility && roundStartX + roundSquare > roundEndX - fallibility) || (roundStartX - roundSquare < roundEndX + fallibility && roundStartX - roundSquare > roundEndX - fallibility))
      ||
      ((roundStartX + roundSquare * 2 < roundEndX + fallibility && roundStartX + roundSquare * 2 > roundEndX - fallibility) || (roundStartX - roundSquare * 2 < roundEndX + fallibility && roundStartX - roundSquare * 2 > roundEndX - fallibility))
      &&
      ((roundStartY + roundSquare < roundEndY + fallibility && roundStartY + roundSquare > roundEndY - fallibility) || (roundStartY - roundSquare < roundEndY + fallibility && roundStartY - roundSquare > roundEndY - fallibility)));
  };

  const getCurrentArrow = (startCords, endCords, linesColor) => {
    let color = linesColor === 'green' ? 'rgba(10,100,10,1)' : 'rgba(100,10,10,1)';
    if (startCords.x === endCords.x && startCords.y === endCords.y) {
      setCurrentArrow(<circle cx={startCords.x} cy={startCords.y} r={squareWidth * coef / 2 - 0.25} stroke={color} strokeWidth='0.5' fill="transparent" />)
    } else {
      if (checkIsKnight(startCords, endCords)) {
        setCurrentArrow(<polygon fill={color} points={getKnightArrowPointCords(startCords, endCords)} style={{ pointEvents: "none" }} />)
      } else {
        setCurrentArrow(<polygon fill={color} points={getArrowPointCords(startCords, endCords)} transform={`rotate(${getArrowDeg(startCords, endCords)} ${startCords.x} ${startCords.y})`} style={{ pointEvents: "none" }} />)
      }
    }
  };

  const drawLine = (startCords, endCords, linesColor) => {
    checkIsKnight(startCords, endCords) ? drawKnightLines(startCords, endCords, linesColor) : drawStraightLine(startCords, endCords, linesColor);
  };

  const drawKnightLines = (startCords, endCords, linesColor) => {
    let dx = endCords.x - startCords.x;
    let dy = endCords.y - startCords.y;
    let points = getKnightArrowPointCords(startCords, endCords);
    let arrowGradient = linesColor;

    if ((dx < 0 && dy < 0) || (dx < 0 && dy > 0)) {
      arrowGradient = linesColor === 'green' ? 'green-reverse' : 'red-reverse';
    }

    setArrowsToRender([...arrowsToRender, <polygon fill={`url(#${arrowGradient}-gradient)`} key={`a${startCords.x}${startCords.y}${endCords.x}${endCords.y}`} points={points} style={{ pointEvents: "none" }} />])
  };

  const drawStraightLine = (startCords, endCords, linesColor) => {
    let deg = getArrowDeg(startCords, endCords);
    let points = getArrowPointCords(startCords, endCords);
    setArrowsToRender([...arrowsToRender, <polygon fill={`url(#${linesColor}-gradient)`} key={`a${startCords.x}${startCords.y}${endCords.x}${endCords.y}`} transform={`rotate(${deg} ${startCords.x} ${startCords.y})`} points={points} style={{ pointEvents: "none" }} />])
  };

  const getKnightArrowPointCords = (startCords, endCords) => {
    let lineWidth = squareWidth * 0.07 * coef;
    let gap = squareWidth * 0.1 * coef;
    let gapArrow = squareWidth * 0.12 * coef;
    let gapArrow2 = squareWidth * 0.4 * coef;
    let dx = endCords.x - startCords.x;
    let dy = endCords.y - startCords.y;
    let dxAbs = Math.abs(dx);
    let dyAbs = Math.abs(dy);
    let points;

    // oh shit I'm sorry

    if (dxAbs > dyAbs) {
      if (dx > 0 && dy > 0) {
        points = `
          ${startCords.x + gap} ${startCords.y - lineWidth},
          ${startCords.x + dxAbs + lineWidth} ${startCords.y - lineWidth},
          ${startCords.x + dxAbs + lineWidth} ${startCords.y + dyAbs - gapArrow2 + lineWidth},
          ${startCords.x + dxAbs + lineWidth + gapArrow} ${startCords.y + dyAbs - gapArrow2},
          ${startCords.x + dxAbs} ${startCords.y + dyAbs - gapArrow},
          ${startCords.x + dxAbs - lineWidth - gapArrow} ${startCords.y + dyAbs - gapArrow2},
          ${startCords.x + dxAbs - lineWidth} ${startCords.y + dyAbs - gapArrow2 + lineWidth},
          ${startCords.x + dxAbs - lineWidth} ${startCords.y + lineWidth},
          ${startCords.x + gap} ${startCords.y + lineWidth},
        `;
      } else if (dx < 0 && dy < 0) {
        points = `
          ${startCords.x - gap} ${startCords.y - lineWidth},
          ${startCords.x + dx + lineWidth} ${startCords.y - lineWidth},
          ${startCords.x + dx + lineWidth} ${startCords.y + dy + gapArrow2 - lineWidth},
          ${startCords.x + dx + lineWidth + gapArrow} ${startCords.y + dy + gapArrow2},
          ${startCords.x + dx} ${startCords.y + dy + gapArrow},
          ${startCords.x + dx - lineWidth - gapArrow} ${startCords.y + dy + gapArrow2},
          ${startCords.x + dx - lineWidth} ${startCords.y + dy + gapArrow2 - lineWidth},
          ${startCords.x + dx - lineWidth} ${startCords.y + lineWidth},
          ${startCords.x - gap} ${startCords.y + lineWidth},
        `;
      } else if (dx > 0 && dy < 0) {
        points = `
          ${startCords.x + gap} ${startCords.y + lineWidth},
          ${startCords.x + dx + lineWidth} ${startCords.y + lineWidth},
          ${startCords.x + dx + lineWidth} ${startCords.y + dy + gapArrow2 - lineWidth},
          ${startCords.x + dx + lineWidth + gapArrow} ${startCords.y + dy + gapArrow2},
          ${startCords.x + dx} ${startCords.y + dy + gapArrow},
          ${startCords.x + dx - lineWidth - gapArrow} ${startCords.y + dy + gapArrow2},
          ${startCords.x + dx - lineWidth} ${startCords.y + dy + gapArrow2 - lineWidth},
          ${startCords.x + dx - lineWidth} ${startCords.y - lineWidth},
          ${startCords.x + gap} ${startCords.y - lineWidth},
        `;
      } else if (dx < 0 && dy > 0) {
        points = `
          ${startCords.x - gap} ${startCords.y - lineWidth},
          ${startCords.x + dx - lineWidth} ${startCords.y - lineWidth},
          ${startCords.x + dx - lineWidth} ${startCords.y + dy - gapArrow2 + lineWidth},
          ${startCords.x + dx - lineWidth - gapArrow} ${startCords.y + dy - gapArrow2},
          ${startCords.x + dx} ${startCords.y + dy - gapArrow},
          ${startCords.x + dx + lineWidth + gapArrow} ${startCords.y + dy - gapArrow2},
          ${startCords.x + dx + lineWidth} ${startCords.y + dy - gapArrow2 + lineWidth},
          ${startCords.x + dx + lineWidth} ${startCords.y + lineWidth},
          ${startCords.x - gap} ${startCords.y + lineWidth},
        `;
      }
    } else {
      if (dx > 0 && dy > 0) {
        points = `
          ${startCords.x - lineWidth} ${startCords.y + gap},
          ${startCords.x - lineWidth} ${startCords.y + dyAbs + lineWidth},
          ${startCords.x + dxAbs - gapArrow2 + lineWidth} ${startCords.y + dyAbs + lineWidth},
          ${startCords.x + dxAbs - gapArrow2} ${startCords.y + dyAbs + lineWidth + gapArrow},
          ${startCords.x + dxAbs - gapArrow} ${startCords.y + dyAbs},
          ${startCords.x + dxAbs - gapArrow2} ${startCords.y + dyAbs - lineWidth - gapArrow},
          ${startCords.x + dxAbs - gapArrow2 + lineWidth} ${startCords.y + dyAbs - lineWidth},
          ${startCords.x + lineWidth} ${startCords.y + dyAbs - lineWidth},
          ${startCords.x + lineWidth} ${startCords.y + gap},
        `;
      } else if (dx < 0 && dy < 0) {
        points = `
          ${startCords.x - lineWidth} ${startCords.y - gap},
          ${startCords.x - lineWidth} ${startCords.y + dy + lineWidth},
          ${startCords.x + dx + gapArrow2 - lineWidth} ${startCords.y + dy + lineWidth},
          ${startCords.x + dx + gapArrow2} ${startCords.y + dy + lineWidth + gapArrow},
          ${startCords.x + dx + gapArrow} ${startCords.y + dy},
          ${startCords.x + dx + gapArrow2} ${startCords.y + dy - lineWidth - gapArrow},
          ${startCords.x + dx + gapArrow2 - lineWidth} ${startCords.y + dy - lineWidth},
          ${startCords.x + lineWidth} ${startCords.y + dy - lineWidth},
          ${startCords.x + lineWidth} ${startCords.y - gap},
        `;
      } else if (dx > 0 && dy < 0) {
        points = `
          ${startCords.x - lineWidth} ${startCords.y - gap},
          ${startCords.x - lineWidth} ${startCords.y + dy - lineWidth},
          ${startCords.x + dx - gapArrow2 + lineWidth} ${startCords.y + dy - lineWidth},
          ${startCords.x + dx - gapArrow2} ${startCords.y + dy - lineWidth - gapArrow}, 
          ${startCords.x + dx - gapArrow} ${startCords.y + dy},
          ${startCords.x + dx - gapArrow2} ${startCords.y + dy + lineWidth + gapArrow},
          ${startCords.x + dx - gapArrow2 + lineWidth} ${startCords.y + dy + lineWidth},
          ${startCords.x + lineWidth} ${startCords.y + dy + lineWidth},
          ${startCords.x + lineWidth} ${startCords.y - gap},
        `;
      } else if (dx < 0 && dy > 0) {
        points = `
          ${startCords.x - lineWidth} ${startCords.y + gap},
          ${startCords.x - lineWidth} ${startCords.y + dy - lineWidth},
          ${startCords.x + dx + gapArrow2 - lineWidth} ${startCords.y + dy - lineWidth},
          ${startCords.x + dx + gapArrow2} ${startCords.y + dy - lineWidth - gapArrow},
          ${startCords.x + dx + gapArrow} ${startCords.y + dy},
          ${startCords.x + dx + gapArrow2} ${startCords.y + dy + lineWidth + gapArrow},
          ${startCords.x + dx + gapArrow2 - lineWidth} ${startCords.y + dy + lineWidth},
          ${startCords.x + lineWidth} ${startCords.y + dy + lineWidth},
          ${startCords.x + lineWidth} ${startCords.y + gap},
        `;
      }
    }
    return points;
  };

  const getArrowPointCords = (startCords, endCords) => {
    let polygonLength = Math.sqrt((startCords.x - endCords.x) ** 2 + (startCords.y - endCords.y) ** 2);
    let lineWidth = squareWidth * 0.07 * coef;
    let gap = squareWidth * 0.1 * coef;
    let gapArrow = squareWidth * 0.12 * coef;
    let gapArrow2 = squareWidth * 0.4 * coef;
    return `
      ${startCords.x + gap} ${startCords.y - lineWidth},
      ${startCords.x + gap} ${startCords.y + lineWidth},
      ${startCords.x + polygonLength - gapArrow2 + lineWidth} ${startCords.y + lineWidth},
      ${startCords.x + polygonLength - gapArrow2} ${startCords.y + lineWidth + gapArrow},
      ${startCords.x + polygonLength - gapArrow} ${startCords.y},
      ${startCords.x + polygonLength - gapArrow2} ${startCords.y - lineWidth - gapArrow},
      ${startCords.x + polygonLength - gapArrow2 + lineWidth} ${startCords.y - lineWidth}
    `
  };

  const getArrowDeg = (startCords, endCords) => {
    let dx = endCords.x - startCords.x;
    let dy = endCords.y - startCords.y;
    let angle = Math.atan2(dy, dx);
    return 180 / Math.PI * angle;
  };

  const clearCanvas = () => {
    setArrowsToRender([]);
    setCoins([]);
  };

  const drawCircle = (x, y, linesColor) => {
    setArrowsToRender([...arrowsToRender, <circle key={`c${x}${y}`} cx={x} cy={y} r={squareWidth * coef / 2 - 0.25} stroke={linesColor === 'green' ? "rgba(0, 140, 10, 1)" : "rgba(120, 10, 10, 1)"} strokeWidth="0.5" fill="transparent" />])
  };

  function getBoardSize() {
    const BOARD_BORDER_WIDTH = 6;
    const DEFAULT_BOARD_SIZE = 433;
    const boardSize = {
        left: BOARD_BORDER_WIDTH,
        top: BOARD_BORDER_WIDTH,
        height: DEFAULT_BOARD_SIZE,
        width: DEFAULT_BOARD_SIZE,
    };
    // const boardIDs = ['BestMove', 'Repeat', 'PlayPosition', 'PlayWithHuman', 'PlayWithBot1', 'PlayWithBot', 'test'];
    // const boards = boardIDs.map((id) => document.querySelector(`[data-boardid=${id}]`)) || [];

    let currentBoard = document.querySelector('[data-boardid]');

    // let currentBoard = boards.filter(board => board !== null);

    if(currentBoard){
        const { top, left } = currentBoard.getBoundingClientRect();
        currentBoard = window.getComputedStyle(currentBoard, null);
        if(top !== undefined && left !== undefined && currentBoard){
          boardSize.width = currentBoard.width;
          boardSize.height = currentBoard.height;
          boardSize.left = nineHundredPx ? left + BOARD_BORDER_WIDTH: BOARD_BORDER_WIDTH;
          boardSize.top = withFigures ? top + BOARD_BORDER_WIDTH: BOARD_BORDER_WIDTH;
        }
    }

    return boardSize;
  }

  return (
    <svg
      className='chess__overlay'
      ref={ref}
      viewBox={`0 0 100 100`}
      width={overlayPosition.width}
      height={overlayPosition.height}
      style={{ top: overlayPosition.top, left: overlayPosition.left, pointerEvents: isDraw ? 'all' : 'none' }}
      onContextMenu={(e) => { e.preventDefault(); return false }}
      onContextMenuCapture={(e) => { e.preventDefault(); return false }}
      onMouseDown={mouseDown}
      onMouseLeave={onMouseOut}
    >
      <linearGradient id="green-gradient">
        <stop offset="0%" stopColor="rgba(0, 130, 10, 0.5)" />
        <stop offset="100%" stopColor="rgba(0, 130, 10, 1)" />
      </linearGradient>

      <linearGradient id="green-reverse-gradient">
        <stop offset="0%" stopColor="rgba(0, 130, 10, 1)" />
        <stop offset="100%" stopColor="rgba(0, 130, 10, 0.5)" />
      </linearGradient>

      <linearGradient id="red-gradient">
        <stop offset="0%" stopColor="rgba(120, 10, 10, 0.5)" />
        <stop offset="100%" stopColor="rgba(120, 10, 10, 1)" />
      </linearGradient>

      <linearGradient id="red-reverse-gradient">
        <stop offset="0%" stopColor="rgba(120, 10, 10, 1)" />
        <stop offset="100%" stopColor="rgba(120, 10, 10, 0.5)" />
      </linearGradient>
      {arrowsToRender.map(el => el)}
      {coins.map(el => el)}
      {showCurrentArrow && currentArrow}
    </svg>
  )
})

export default DrawOverlay;