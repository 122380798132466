import React, { useEffect, useMemo, useRef, useState } from "react";
import style from "./style.module.scss";
import { CopyIcon, CaretIcon, EmptyBanner } from "../../../../../assets/img/partners";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { openOverlayAction } from "../../../../../actions/overlay";
import StudentsList from "./StudentsList";
import classnames from "classnames";
import CancelLesson from "./CancelLesson";
import moment from "moment";
import useResolution from "../../../../../hooks/useResolution";
import { copyText } from "../../../../../utils/copyText";
import { showSuccessMessage } from "../../../../../helpers/notifications";
import {uploadLessonStatistic, uploadLessonStudentAnswers} from "../../../../../services/api";
import { convertDateFromLocale } from "../../../../../utils/convertDateFromLocale";
import { ReactComponent as CopyWebinarIcon } from '../../../../../assets/img/partners/copyIcon.svg';
import {ReactComponent as Trash}from "../../../../../assets/img/trash.svg";
import ModalConfirm from "components/common/ModalConfirm";

const Lesson = ({ lesson, lessonType, deleteLesson }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isMobile = useResolution(800);
    const [fileLink, setFileLink] = useState('');
    const [confirmModalOpened, setConfirmModalOpened] = useState(false);

    const linkRef = useRef();

    useEffect(() => {
        if (fileLink && linkRef.current) linkRef.current.click()
    }, [fileLink])

    const copyData = (e, data) => {
        e.preventDefault();
        copyText(data)
            .then(() => {
                showSuccessMessage(t('partners.successCopy'))
            })
    }

    const openStudentsList = (lessonId) => {
        dispatch(openOverlayAction(StudentsList, { lessonType, lessonId }))
    }

    const openCancelLesson = () => {
        dispatch(openOverlayAction(CancelLesson, { id: lesson.id, name: lesson.title, deleteLesson }))
    }

    const uploadStatistic = () => {
        uploadLessonStatistic(lesson.id)
            .then(res => setFileLink(res.data))
    }

    const uploadStudentAnswers = () => {
        uploadLessonStudentAnswers(lesson.id)
            .then(res => setFileLink(res.data))
    }

    const _delete_lesson = () => {
        setConfirmModalOpened(true);
    }

    const closeConfirmModal = () => {
        setConfirmModalOpened(false);
    }

    const onConfirm = () => {
        deleteLesson(lesson.id);
    }

    const date = useMemo(() => convertDateFromLocale(lesson.begin_date), [])
  
    return (
        <div className={style.lesson}>

            <div className={style.lesson_header}>
                <p className={style.lesson_header__name}>{lesson.title}</p>
                <div>
                    <p className={style.lesson_header__date}>{date.format('L')}</p>
                    <p>{date.format('HH:mm')}</p>
                </div>
            </div>

            <div className={style.lesson_info}>
                <div className={classnames({[style.lesson_info__container]: !isMobile})}>
                    <a target="_blank" href={lesson.banner_link} className={style.lesson_info__photo}>
                        {
                            lesson.banner
                                ? <img src={lesson.banner} alt="banner" />
                                : <EmptyBanner />
                        }
                    </a>
                    {lessonType === 'future' && !isMobile && <button onClick={openCancelLesson} className={classnames(style.lesson_info__button, style['lesson_info__button-grey'])}>{t('partners.cancelLesson')}</button>}
                    {lessonType === 'passing' && !isMobile && <button onClick={uploadStatistic} className={classnames(style.lesson_info__button, style['lesson_info__button-green'], style['lesson_info__button-margin'])}>{t('partners.uploadStatistic')}</button>}
                    {lessonType === 'passing' && !isMobile && <button onClick={uploadStudentAnswers} className={classnames(style.lesson_info__button, style['lesson_info__button-green'])}>{t('partners.uploadStudentAnswers')}</button>}
                    {fileLink && <a ref={linkRef} href={fileLink} className={style.fileHiddenLink} download target="_blank"></a>}
                </div>

                <div>
                    <a target="_blank" href={lesson.theme_link} className={style.lesson_info__link}>
                        <p>{t('partners.openTheme')}</p>
                        <CaretIcon className={style.lesson_info__icon} />
                    </a>

                    <div className={style.lesson_info__link} onClick={() => openStudentsList(lesson.id)}>
                        <p>{t('partners.studentsList')}</p>
                        <CaretIcon className={style.lesson_info__icon} />
                    </div>

                    <a target="_blank" href={lesson.class_link} className={style.lesson_info__link}>
                        <p>{t('partners.openLesson')}</p>
                        <CaretIcon className={style.lesson_info__icon} />
                    </a>


                    {lessonType === 'future' && (
                        <Link to={`/partner/lesson/${lesson.id}/edit`} className={style.lesson_info__link}>
                            <p>{t('partners.editLesson')}</p>
                            <CaretIcon className={style.lesson_info__icon} />
                        </Link>
                    )}

                    <div className={style.lesson_info__link}>
                        <div>
                            <p onClick={e => copyData(e, lesson.class_link)}>{t('partners.link')}</p>
                            <button onClick={e => copyData(e, lesson.class_link)}><CopyIcon className={style.lesson_info__icon} /></button>
                        </div>
                    </div>

                    <div className={style.lesson_info__link}>
                        <div>
                            <p>{t('partners.lessonPassword')}: {lesson.password}</p>
                            <button onClick={e => copyData(e, lesson.password)}><CopyIcon className={style.lesson_info__icon} /></button>
                        </div>
                    </div>
                </div>
                <div className={style.copy_block}>
                    <Trash className={style.trash_icon}
                    onClick={_delete_lesson}
                    />
                    <Link to={`/partner/lesson/create/${lesson.id}`} className={style.copy_block_btn}><CopyWebinarIcon /></Link>
                    
                </div>

                {
                    isMobile && (
                        <div>
                            {lessonType === 'future' && <button onClick={openCancelLesson} className={classnames(style.lesson_info__button, style['lesson_info__button-grey'])}>{t('partners.cancelLesson')}</button>}
                            {lessonType === 'passing' && <button onClick={uploadStatistic} className={classnames(style.lesson_info__button, style['lesson_info__button-green'], style['lesson_info__button-margin'])}>{t('partners.uploadStatistic')}</button>}
                            {lessonType === 'passing' && <button onClick={uploadStudentAnswers} className={classnames(style.lesson_info__button, style['lesson_info__button-green'])}>{t('partners.uploadStudentAnswers')}</button>}
                        </div>
                    )
                }
            </div>
            <ModalConfirm
                opened = { confirmModalOpened }
                action = { 'webinar_delete' }
                args = {{ class_id: lesson?.id }}
                close = { closeConfirmModal }
                onConfirm = { onConfirm }
            />
        </div>
    )
}

export default Lesson;
