import React, { useEffect, useState } from 'react';
import EntersList from './EntersList';
import LoginNavigation from './LoginNavigation';
import LoginForm from './LoginForm';
import Registration from './Registration';
import ResetPassword from './ResetPassword';
import Or from '../common/Or/Or';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from "react-i18next";
import './login.scss';
import './loginMobile.scss';
import ApproveMessage from './ApproveMessage';
import { AUTHENTICATION_ERROR } from "../../actions/types";
import { useDispatch, useSelector } from "react-redux";

function Login({ closeModal, ...props }) {
    const [popupMode, setPopupMode] = useState(props.type || 'initial');
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { error } = useSelector(state => state.auth)

    const setMode = (mode) => {
        setPopupMode(mode);
    };

    const successRegistration = () => {
        toast.success(t('auth.successRegistration'), {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    useEffect(() => {
        if (error) dispatch({ type: AUTHENTICATION_ERROR, payload: '' });
        return () => {
            dispatch({ type: AUTHENTICATION_ERROR, payload: '' });
        }
    }, [popupMode])

    const popupToRender = (mode) => {
        switch (mode) {
            case 'initial':
                return <LoginNavigation setPopupMode={setMode} closeModal={closeModal} />;
            case 'default':
            case 'partners':
                return <LoginForm setPopupMode={setMode} mode={mode} closeModal={closeModal} />;
            case 'registration':
                return <Registration setPopupMode={setMode} succesRegistration={successRegistration} closeModal={closeModal} />;
            case 'password_reset':
                return <ResetPassword setPopupMode={setMode} />;
            default:
                return <div></div>
        }
    };

    return (
        <div className='login'>
            <div className='login__container'>
                {popupMode !== 'password_reset' ?
                    <>
                        <div className='login__header'>
                            <p className='login__title'>
                                {
                                    popupMode === 'partners'
                                        ? <><span className='bold'>AB</span>CHESS {t('auth.forPartners')}</>
                                        : <>{t('auth.title')} <span className='bold'>AB</span>CHESS!</>
                                }
                            </p>
                        </div>
                        <div className='login__body'>
                            {popupToRender(popupMode)}

                            {
                                popupMode !== 'partners' && (
                                    <>
                                        <Or />
                                        <EntersList setPartnersMode={() => setMode('partners')} closeModal={closeModal} />
                                    </>
                                )
                            }
                            <ApproveMessage closeModal={closeModal} />
                        </div>
                    </>
                    :
                    <>
                        <div className='login__body login__body--reset'>
                            <ResetPassword setPopupMode={setMode} />
                        </div>
                        {/*<span className='login__close-button login__close-button--black' onClick={props.onClose}>*/}
                        {/*  <svg className='login__close-svg' width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                        {/*    <path className='login__close-svg-path login__close-svg-path--black' d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="#000000"/>*/}
                        {/*  </svg>*/}
                        {/*</span>*/}
                    </>
                }
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
}

export default Login;