import {
  AUTHENTICATED,
  UNAUTHENTICATED,
  AUTHENTICATION_ERROR,
  AUTHENTICATED_LOADING,
  SET_TEMPORARY_AUTH,
  CLEAR_AUTH_ERROR
} from '../actions/types';

export default function(state = {error: false, loading: false}, action) {
  // eslint-disable-next-line default-case
  switch(action.type) {
    case SET_TEMPORARY_AUTH:
      return { ...state, temporary_auth: action.payload };
    case AUTHENTICATED:
      return { ...state, authenticated: true, error: false, loading: false, temporary_auth: false };
    case UNAUTHENTICATED:
      return { ...state, authenticated: false, loading: false };
    case AUTHENTICATED_LOADING:
      return {...state, loading: true, error: false};
    case AUTHENTICATION_ERROR:
      return { ...state, error: action.payload, loading: false };
    case CLEAR_AUTH_ERROR:
      return {
        ...state,
        error: null
      }
  }
  return state;
}