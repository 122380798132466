import React from "react";
import PropTypes from "prop-types";

export default function PeopleCounter({ count }) {
  const indicatorCounter = () => {
    let arr = [];
    for (let i = 0; i < 10; i++) {
      if(i < count) {
        arr.push(<span className='chess-display__man man-colorfull__logo' key={`${i}man`}/>)
      } else {
        arr.push(<span className='chess-display__man man__logo' key={`${i}man`}/>)
      }
    }

    return arr;
  };

  return (
    <div>
      {indicatorCounter().map(el => el)}
    </div>
  )
}

PeopleCounter.propTypes = {
  count: PropTypes.number.isRequired
};