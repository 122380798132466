import React, { useCallback, useEffect, useState } from 'react';
import style from './style.module.scss';
import { ReactComponent as ArrowDown } from '../../svg/arrwo-down.svg';
import { ReactComponent as ArrowUp } from '../../svg/arrow-up.svg';
import CategoryAccordion from './CategoryAccordion';
import classnames from 'classnames';

export default function SupercategoryAccordion({
  supercategory,
  supercategoryActive,
  setSupercategoryActiveByIndex,
  index,
  setMainCourseId,
  setSuperCategory,
  setCategory,
}) {
  const [categoryActive, setCategoryActive] = useState([]);
  const [courseId, setCourseId] = useState([]);
  useEffect(() => {
    setCategoryActive(Array.from({ length: supercategory.categories.length }, () => false));
  }, [supercategory.categories.length]);

  const setCategoryActiveByIndex = useCallback((index) => {
    setCategoryActive((prevState) => {
      const newState = [...prevState]; // Создаем копию предыдущего состояния
      newState[index] = !newState[index]; // Вносим изменение в копию состояния
      return newState;
    });
  }, []);

  return (
    <div
      className={classnames(style['supercategory-accordion-item'], {
        [style['supercategory-accordion-item_active']]: supercategoryActive[index] === true,
      })}
      key={supercategory.supercategory_id}
    >
      <button
        onClick={() => {
          setSupercategoryActiveByIndex(index);
          setSuperCategory(supercategory.supercategory_name);
        }}
        className={classnames(
          style['supercategory-accordion-item__button'],
          style['supercategory-accordion-item__title'],
          {
            [style['supercategory-accordion-item__button_active']]: supercategoryActive[index] === true,
            [style['supercategory-accordion-item__button_no-border']]:
              supercategoryActive[index] === false && supercategoryActive[index + 1] === true,
          },
        )}
      >
        {supercategory.supercategory_name}
        {supercategoryActive[index] === true ? <ArrowDown /> : <ArrowUp />}
      </button>
      <div
        className={classnames({
          [style['supercategory-accordion-item_nested']]: supercategoryActive[index] === false,
        })}
      >
        {supercategory?.categories?.map((category, categoryIndex) => {
          return (
            <CategoryAccordion
              key={category.category_id}
              category={category}
              index={categoryIndex}
              categoryActive={categoryActive}
              setCategoryActiveByIndex={setCategoryActiveByIndex}
              courseId={courseId}
              setCourseId={setCourseId}
              setMainCourseId={setMainCourseId}
              setCategory={setCategory}
            />
          );
        })}
      </div>
    </div>
  );
}
