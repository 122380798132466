import React from 'react';
import CompletedCourseItem from './CompletedCourseItem';
import MyCoursesList from "../MyCoursesList";
import {useTranslation} from "react-i18next";
import {GET_COMPLETED_COURSES} from 'services/api';

export default function CompletedCoursesList() {
  const {t} = useTranslation();

  return (
    <MyCoursesList
      apiRoute={GET_COMPLETED_COURSES}
      noItemsMessage={t('courses.noCompleted')}
      ItemComponent={CompletedCourseItem}
    />
  )
}