import React, { useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { useHttp } from 'hooks/http.hook';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { COUNTRY_EN, COUNTRY_RU } from 'constants/countries';
import PasswordChangeForm from '../PasswordChangeForm';
import { formatPhoneNumber } from 'helpers/inputsHelpers';
import { getProfileInfo } from "actions/getProfile";
import SexInput from 'components/FormikInputs/SexInput';
import DateField from 'components/FormikInputs/DateTimeField/DatePicker';
import { TextField } from 'components/FormikInputs/TextInput';
import SelectField from 'components/FormikInputs/SelectInput';
import ModalWindow from 'components/common/ModalWindow';
import AvatarDropzone from 'components/FormikInputs/AvatarDropzone';
import { StringDateInput } from 'components/FormikInputs/StringDateInput';
import { POST_USER_INFO } from "services/api";
import { showSuccessMessage } from "helpers/notifications";
import PageTitle from "../../../common/PageHeader";
import ProfileCalendar from '../../../common/Calendare/Calendare';
import { ProfileDate } from 'components/FormikInputs/ProfileDatePicker/ProfileDate'
import { useOnClickOutsideWithBtn } from 'hooks/outsideClickWithBtn';
import useResolution from 'hooks/useResolution';
import { FETCH_PROFILE_SUCCESS } from 'actions/types';

const options = {
  month: '2-digit',
  day: '2-digit',
  year: 'numeric',
}

export default function ProfileForm() {
  const profileData = useSelector(state => state.profile.profileData);
  const profileFetch = useSelector(state => state.profile.loading);
  const lang = useSelector(state => state.settings.lang);
  const modalRef = useRef(null);
  const btnRef = useRef(null);
  const dateRef = useRef(null);
  const [openDatePicker, setOpenDatePicker] = React.useState(false)
  const isMobile = useResolution(541);
  useOnClickOutsideWithBtn(modalRef, () => { setOpenDatePicker(false) }, btnRef)


  const [initValues, setInitValues] = React.useState({
    name: profileData.name || '',
    surname: profileData.surname || '',
    phone_number: profileData.phone_number || '',
    birth_date: profileData.birth_date ? new Date(profileData.birth_date?.replace(/\-/g, '/')).toLocaleDateString() : '',
    coach: profileData.coach || '',
    chess_school: profileData.chess_school || '',
    rank: profileData.rank || '',
    sex: profileData.sex || '',
    country: profileData.country || '',
    about: profileData.about || ''
  })

  useEffect(() => {
    setInitValues(
      {
        name: profileData.name || '',
        surname: profileData.surname || '',
        phone_number: profileData.phone_number || '',
        birth_date: profileData.birth_date ? new Date(profileData.birth_date?.replace(/\-/g, '/')).toLocaleDateString('ru-RU', options) : '',
        coach: profileData.coach || '',
        chess_school: profileData.chess_school || '',
        rank: profileData.rank || '',
        sex: profileData.sex || '',
        country: profileData.country || '',
        about: profileData.about || ''
      }
    )
  }, [profileData])

  const { loading, request, error } = useHttp();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    !profileFetch && dispatch(getProfileInfo());
  }, []);

  useEffect(() => {
    const body = document.querySelector('body')
    const html = document.querySelector('html')

    if (openDatePicker && isMobile && !body.classList.contains('scroll-hidden')) {
      body.classList.add('scroll-hidden')
      html.classList.add('scroll-hidden')
    } else if (isMobile) {
      body.classList.remove('scroll-hidden')
      html.classList.remove('scroll-hidden')
    }
  }, [openDatePicker])

  return (
    <>
      <PageTitle>{t('profile.profile')}</PageTitle>
      <div className='profile__content'>
        <div className='profile__avatar-container'>
          <AvatarDropzone userImage={profileData.image} />
          <ModalWindow WindowComponent={PasswordChangeForm} hidden={true} ButtonComponent={ChangePasswordButton} />
        </div>

        <Formik
          enableReinitialize={true}
          initialValues={initValues}
          validationSchema={
            Yup.object({
              name: Yup.string().max(60, 'Максимум 60 символов'),
              surname: Yup.string().max(60, 'Максимум 60 символов'),
              birth_date: Yup.string().min(10, t('profile.invalidDate')),
            })
          }
          onSubmit={(values, { setSubmitting }) => {
            let dataToSend = { ...values };
            let formData = new FormData();
            if (values.birth_date) {
              let splittedDate = values.birth_date.split(values.birth_date.includes('.') ? '.' : '/');
              let formatDate = `${splittedDate[2]}-${splittedDate[1]}-${splittedDate[0]}`;
              dataToSend.birth_date = formatDate;
            }

            for (let key in dataToSend) {
              formData.append(key, dataToSend[key]);
            }
            request(POST_USER_INFO, 'POST', formData)
              .then((res) => {
                dispatch({ type: FETCH_PROFILE_SUCCESS, payload: { ...res.data, role: JSON.parse(localStorage.getItem('role')) || 'default' } });
                showSuccessMessage(t('profile.successChangedData'));
              });
            setSubmitting(false);
          }}
        >
          {({ errors, submitCount, values, setFieldValue }) => (
            <Form>
              <div className='profile__info'>
                <ul className='profile__info-list'>
                  <li className='profile__info-item'>
                    <TextField
                      name={'name'}
                      label={t('profile.name')}
                      labelClasses={'profile__label'}
                      inputClasses={'profile__input'}
                    />

                    <TextField
                      name={'surname'}
                      label={t('profile.surname')}
                      labelClasses={'profile__label'}
                      inputClasses={'profile__input'}
                    />

                    <label className='profile__label'>
                      Email
                      <input className='profile__input' value={profileData.email} disabled={true} />
                    </label>
                  </li>

                  <li className='profile__info-item'>
                    <TextField
                      name={'phone_number'}
                      label={t('profile.phone')}
                      labelClasses={'profile__label'}
                      inputClasses={'profile__input'}
                      format={formatPhoneNumber}
                      maxLength={13}
                    />

                    <SelectField
                      name={'country'}
                      label={t('profile.region')}
                      options={lang === 'eng' ? COUNTRY_EN : COUNTRY_RU}
                      labelClasses={'profile__label'}
                      inputClasses={'profile__input profile__input--select'}
                      listItemClasses={'profile__select-list-item'}
                      titleClasses={'profile__select-title-classes'}
                      listClasses={'profile__select-list-classes'}
                    />


                    <ProfileDate
                      name={'birth_date'}
                      label={t('profile.birth')}
                      inputClasses={'profile__input'}
                      labelClasses={'profile__label'}
                      placeholder={lang === 'eng' ? 'dd.mm.yyyy' : 'дд.мм.гггг'}
                      setOpenDatePicker={setOpenDatePicker}
                      openDatePicker={openDatePicker}
                      modalRef={modalRef}
                      btnRef={btnRef}
                      dateRef={dateRef}
                    />

                    <SexInput name={'sex'} />
                  </li>

                  <li className='profile__info-item'>
                    <TextField
                      name={'chess_school'}
                      label={t('profile.chessSchool')}
                      labelClasses={'profile__label'}
                      inputClasses={'profile__input'}
                    />

                    <TextField
                      name={'about'}
                      label={t('profile.howMuch')}
                      labelClasses={'profile__label'}
                      inputClasses={'profile__input'}
                    />

                    <TextField
                      name={'rank'}
                      label={t('profile.rate')}
                      labelClasses={'profile__label'}
                      inputClasses={'profile__input'}
                    />

                    <TextField
                      name={'coach'}
                      label={t('profile.coach')}
                      labelClasses={'profile__label'}
                      inputClasses={'profile__input'}
                    />
                  </li>

                  <li className='profile__info-item profile__info-item--buttons'>
                    {error && error.response?.data.message}
                    <button className='profile__button profile__button--save' disabled={loading} type='submit'>{t('profile.save')}</button>
                  </li>
                </ul>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  )
}

const ChangePasswordButton = (props) => {
  const { t } = useTranslation();

  return <span className='profile__avatar-text profile__avatar-text--password border'
    onClick={props.onClick}>{t('profile.changePassword')}</span>
};