import React, {useEffect, useState} from 'react';
import PageTitle from "components/common/PageHeader";
import PageContainer from "components/common/PageContainer";
import './mistakesTable.scss';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useHttp} from "hooks/http.hook";
import {GET_MISTAKES} from "services/api";
import Loader1 from "components/common/Loader/loader1";
import {useDispatch, useSelector} from "react-redux";
import Button from "components/common/Button";
import {TOGGLE_LOGIN_WINDOW} from "actions/types";

export default function MistakesTable() {
  const [data, setData] = useState([]);
  const {request, loading} = useHttp();
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth.authenticated);

  useEffect(() => {
    getMistakes()
  }, [auth]);

  const getMistakes = async() => {
    try {
      const res = await request(GET_MISTAKES, 'get');
      setData(res.data);
    } catch (e) {
      console.log(e)
    }
  };

  if(!auth) {
    return (
      <>
        <PageTitle>{t('mistakes.title')}</PageTitle>
        <PageContainer>
          <div style={{display: 'flex', flexDirection: 'column', maxWidth: '400px', margin: '0 auto'}}>
            <p style={{fontSize: '16px', margin: '15px 0', textAlign: 'center'}}>{t('mistakes.authOnly')}</p>
            <Button classes={'mistakes-table__button'} onClick={() => dispatch({type: TOGGLE_LOGIN_WINDOW})}>{t('main.logIn')}</Button>
          </div>
        </PageContainer>
      </>
    )
  }

  return (
    <>
      <PageTitle>{t('mistakes.title')}</PageTitle>
        <div className='mistakes-table-container'>
          <table className='mistakes-table'>
            <tr className='mistakes-table__header'>
              <th className='mistakes-table__header-ceil'>{t('mistakes.date')}</th>
              <th className='mistakes-table__header-ceil'>{t('mistakes.course')}</th>
              <th className='mistakes-table__header-ceil'>{t('mistakes.lesson')}</th>
              <th className='mistakes-table__header-ceil'>{t('mistakes.exercise')}</th>
              <th className='mistakes-table__header-ceil mistakes-table__header-ceil--center'>{t('mistakes.attempts')}</th>
            </tr>
            {data.map(el =>
              <tr className='mistakes-table__row'>
                <td className='mistakes-table__ceil'>
                  {el.date && new Date(el.date).toLocaleDateString()}
                </td>
                <td className='mistakes-table__ceil'>
                  <Link to={`/course/${el.course.id}`}>{el.course.name}</Link>
                </td>
                <td className='mistakes-table__ceil'>
                  <Link to={`/course/${el.course.id}/lesson/${el.lesson.id}`}>{el.lesson.name}</Link>
                </td>
                <td className='mistakes-table__ceil'>
                  <Link to={`/course/${el.course.id}/lesson/${el.lesson.id}?exercise=${el.exercise.id}`}>{el.exercise.name}</Link>
                </td>
                <td className='mistakes-table__ceil mistakes-table__ceil--center'>{el.attempt}</td>
              </tr>
            )}
          </table>
        </div>
        {loading && <Loader1/>}
        <p className='mistakes-table__message'>{t('mistakes.lastWeek')}</p>
    </>
  )
}