import React, {useEffect, useState} from 'react';
import './support.scss';
import SupportBlock from "./SupportBlock";
import Button from "components/common/Button";
import ModalWindow from "components/common/ModalWindow";
import {useTranslation} from "react-i18next";
import SupportForm from "./SupportForm";
import PageTitle from "components/common/PageHeader";
import ScrollToTopOnMount from "components/common/ScrollToTop";
import {useSelector} from "react-redux";
import {useHttp} from "hooks/http.hook";
import {getSupport} from "services/api";
import Loader1 from "components/common/Loader/loader1";
import {showErrorMessage} from "helpers/notifications";

export default function SupportPage() {
  const [data, setData] = useState({});
  const lang = useSelector(state => state.settings.lang);
  const {request, loading, error} = useHttp();
  const {t} = useTranslation();

  const fetchSupportData = async() => {
    try {
      const res = await request(getSupport(lang), 'get');
      setData(res.data)
    } catch (e) {
      console.log(e);
      showErrorMessage(t('errors.getError'))
    }
  };

  useEffect(() => {
    fetchSupportData();
  }, []);

  return (
    <>
      <ScrollToTopOnMount/>
      <div className='support'>
        <PageTitle>{t('support.title')}</PageTitle>
        {loading && <Loader1/>}
        <ul className='support__list'>
          {Object.entries(data).map(el =>
            <li className='support__item' key={el[0]}>
              <SupportBlock title={t(`support.${el[0]}`)} questions={el[1]}/>
            </li>
          )}
        </ul>
        <div className='support__container'>
          <p className='support__text'>{t('support.notFound')}</p>
          <ModalWindow ButtonComponent={SupportButton} WindowComponent={SupportForm}/>
        </div>
      </div>
    </>
  )
}

const SupportButton = (props) => {
  const {t} = useTranslation();
  return <Button classes='support__button' onClick={props.onClick}>{t('support.feedbackForm')}</Button>
};