import React from 'react';
import s from './style.module.scss';

const InputError = ({
  message
}) => (
  <div className = {s['u-input-error']}>
    <span>
      {
        message
      }
    </span>
  </div>
)

export default InputError;