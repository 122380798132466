import React from 'react';
import {useFormikContext} from "formik";
import './sexInput.scss';
import {useTranslation} from "react-i18next";

export default function SexInput(props) {
  const {values, setFieldValue} = useFormikContext();
  const {t} = useTranslation();

  const onRadioChange = (e) => {
    setFieldValue(props.name, e.target.value);
  };

  return (
    <div className='sex-input'>
      {t('profile.sex')}
      <div className='sex-input__container'>
        <input type={'radio'} value='MALE' name='sex' className='sex-input__input' id={'male'} onChange={onRadioChange} checked={values.sex === 'MALE'}/>
        <label className='sex-input__label' htmlFor={'male'}>{t('profile.male')}</label>
        <input type={'radio'} value='FEMALE' name='sex' className='sex-input__input' id={'female'} onChange={onRadioChange} checked={values.sex === 'FEMALE'}/>
        <label className='sex-input__label' htmlFor={'female'}>{t('profile.female')}</label>
      </div>
    </div>
  )
}