import React from 'react';
import style from './style.module.scss';
import { ReactComponent as InfoIcon } from '../../svg/info.svg';
import Cart from '../Cart';
import { openOverlayAction } from '../../../../../actions/overlay';
import { useDispatch } from 'react-redux';
import ProgressBar from '../ProgressBar';
import Button from '../../../../common/Button';
import { useTranslation } from 'react-i18next';

export default function BigProgressbar({ modal, label, stats }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <Cart classname={style.progressbar__cart}>
      <div className={style.progressbar}>
        <div className={style.progressbar__label}>
          <span className={style.progressbar__text}>{label}</span>
          <InfoIcon
            onClick={() => dispatch(openOverlayAction(modal, {}, false, true))}
            className={style.progressbar__icon}
          />
        </div>
        <ProgressBar
          progressHeight={'20px'}
          progressColor={'#71B9BA'}
          width={stats.percentage}
          percentageFontSize={'20px'}
          percentageFontWeight={700}
        />
      </div>
      <Button classes={style.progressbar__button}>
        {t('rating.level')} {stats.level ? stats.level : 1}
      </Button>
    </Cart>
  );
}
