export const COUNTRY_RU = [
  {
    "label": "Казахстан",
    "value": "Казахстан"
  },
  {
    "label": "Россия",
    "value": "Россия"
  },
  {
    "label": "Белоруссия",
    "value": "Белоруссия"
  },
  {
    "label": "Украина",
    "value": "Украина"
  },
  {
    "label": "Абхазия",
    "value": "Абхазия"
  },
  {
    "label": "Австралия",
    "value": "Австралия"
  },
  {
    "label": "Австрия",
    "value": "Австрия"
  },
  {
    "label": "Азербайджан",
    "value": "Азербайджан"
  },
  {
    "label": "Албания",
    "value": "Албания"
  },
  {
    "label": "Алжир",
    "value": "Алжир"
  },
  {
    "label": "Ангола",
    "value": "Ангола"
  },
  {
    "label": "Андорра",
    "value": "Андорра"
  },
  {
    "label": "Антигуа и Барбуда",
    "value": "Антигуа и Барбуда"
  },
  {
    "label": "Аргентина",
    "value": "Аргентина"
  },
  {
    "label": "Армения",
    "value": "Армения"
  },
  {
    "label": "Афганистан",
    "value": "Афганистан"
  },
  {
    "label": "Багамские Острова",
    "value": "Багамские Острова"
  },
  {
    "label": "Бангладеш",
    "value": "Бангладеш"
  },
  {
    "label": "Барбадос",
    "value": "Барбадос"
  },
  {
    "label": "Бахрейн",
    "value": "Бахрейн"
  },
  {
    "label": "Белиз",
    "value": "Белиз"
  },
  {
    "label": "Бельгия",
    "value": "Бельгия"
  },
  {
    "label": "Бенин",
    "value": "Бенин"
  },
  {
    "label": "Болгария",
    "value": "Болгария"
  },
  {
    "label": "Боливия\tСукре",
    "value": "Боливия\tСукре"
  },
  {
    "label": "Босния и Герцеговина",
    "value": "Босния и Герцеговина"
  },
  {
    "label": "Ботсвана",
    "value": "Ботсвана"
  },
  {
    "label": "Бразилия",
    "value": "Бразилия"
  },
  {
    "label": "Бруней",
    "value": "Бруней"
  },
  {
    "label": "Буркина-Фасо",
    "value": "Буркина-Фасо"
  },
  {
    "label": "Бурунди",
    "value": "Бурунди"
  },
  {
    "label": "Бутан",
    "value": "Бутан"
  },
  {
    "label": "Вануату",
    "value": "Вануату"
  },
  {
    "label": "Ватикан",
    "value": "Ватикан"
  },
  {
    "label": "Великобритания",
    "value": "Великобритания"
  },
  {
    "label": "Венгрия",
    "value": "Венгрия"
  },
  {
    "label": "Венесуэла",
    "value": "Венесуэла"
  },
  {
    "label": "Восточный Тимор",
    "value": "Восточный Тимор"
  },
  {
    "label": "Вьетнам",
    "value": "Вьетнам"
  },
  {
    "label": "Габон",
    "value": "Габон"
  },
  {
    "label": "Гаити",
    "value": "Гаити"
  },
  {
    "label": "Гайана",
    "value": "Гайана"
  },
  {
    "label": "Гамбия",
    "value": "Гамбия"
  },
  {
    "label": "Гана",
    "value": "Гана"
  },
  {
    "label": "Гватемала",
    "value": "Гватемала"
  },
  {
    "label": "Гвинея",
    "value": "Гвинея"
  },
  {
    "label": "Гвинея-Бисау",
    "value": "Гвинея-Бисау"
  },
  {
    "label": "Германия",
    "value": "Германия"
  },
  {
    "label": "Гондурас",
    "value": "Гондурас"
  },
  {
    "label": "Государство Палестина",
    "value": "Государство Палестина"
  },
  {
    "label": "Гренада",
    "value": "Гренада"
  },
  {
    "label": "Греция",
    "value": "Греция"
  },
  {
    "label": "Грузия",
    "value": "Грузия"
  },
  {
    "label": "Дания",
    "value": "Дания"
  },
  {
    "label": "Джибути",
    "value": "Джибути"
  },
  {
    "label": "Доминика",
    "value": "Доминика"
  },
  {
    "label": "Египет",
    "value": "Египет"
  },
  {
    "label": "Замбия",
    "value": "Замбия"
  },
  {
    "label": "Зимбабве",
    "value": "Зимбабве"
  },
  {
    "label": "Израиль",
    "value": "Израиль"
  },
  {
    "label": "Индия",
    "value": "Индия"
  },
  {
    "label": "Индонезия",
    "value": "Индонезия"
  },
  {
    "label": "Иордания",
    "value": "Иордания"
  },
  {
    "label": "Ирак",
    "value": "Ирак"
  },
  {
    "label": "Иран",
    "value": "Иран"
  },
  {
    "label": "Ирландия",
    "value": "Ирландия"
  },
  {
    "label": "Исландия",
    "value": "Исландия"
  },
  {
    "label": "Испания",
    "value": "Испания"
  },
  {
    "label": "Италия",
    "value": "Италия"
  },
  {
    "label": "Йемен",
    "value": "Йемен"
  },
  {
    "label": "Кабо-Верде",
    "value": "Кабо-Верде"
  },
  {
    "label": "Казахстан",
    "value": "Казахстан"
  },
  {
    "label": "Камбоджа",
    "value": "Камбоджа"
  },
  {
    "label": "Камерун",
    "value": "Камерун"
  },
  {
    "label": "Канада",
    "value": "Канада"
  },
  {
    "label": "Катар",
    "value": "Катар"
  },
  {
    "label": "Кения",
    "value": "Кения"
  },
  {
    "label": "Кипр",
    "value": "Кипр"
  },
  {
    "label": "Киргизия",
    "value": "Киргизия"
  },
  {
    "label": "Кирибати",
    "value": "Кирибати"
  },
  {
    "label": "Китай",
    "value": "Китай"
  },
  {
    "label": "КНДР",
    "value": "КНДР"
  },
  {
    "label": "Колумбия",
    "value": "Колумбия"
  },
  {
    "label": "Коморские Острова",
    "value": "Коморские Острова"
  },
  {
    "label": "Коста-Рика",
    "value": "Коста-Рика"
  },
  {
    "label": "Кот-д'Ивуар",
    "value": "Кот-д'Ивуар"
  },
  {
    "label": "Куба",
    "value": "Куба"
  },
  {
    "label": "Кувейт",
    "value": "Кувейт"
  },
  {
    "label": "Лаос",
    "value": "Лаос"
  },
  {
    "label": "Латвия",
    "value": "Латвия"
  },
  {
    "label": "Лесото",
    "value": "Лесото"
  },
  {
    "label": "Либерия",
    "value": "Либерия"
  },
  {
    "label": "Ливан",
    "value": "Ливан"
  },
  {
    "label": "Ливия",
    "value": "Ливия"
  },
  {
    "label": "Литва",
    "value": "Литва"
  },
  {
    "label": "Лихтенштейн",
    "value": "Лихтенштейн"
  },
  {
    "label": "Люксембург",
    "value": "Люксембург"
  },
  {
    "label": "Маврикий",
    "value": "Маврикий"
  },
  {
    "label": "Мавритания",
    "value": "Мавритания"
  },
  {
    "label": "Мадагаскар",
    "value": "Мадагаскар"
  },
  {
    "label": "Малави",
    "value": "Малави"
  },
  {
    "label": "Малайзия",
    "value": "Малайзия"
  },
  {
    "label": "Мали",
    "value": "Мали"
  },
  {
    "label": "Мальдивские Острова",
    "value": "Мальдивские Острова"
  },
  {
    "label": "Мальта",
    "value": "Мальта"
  },
  {
    "label": "Марокко",
    "value": "Марокко"
  },
  {
    "label": "Маршалловы Острова",
    "value": "Маршалловы Острова"
  },
  {
    "label": "Мексика",
    "value": "Мексика"
  },
  {
    "label": "Мозамбик",
    "value": "Мозамбик"
  },
  {
    "label": "Молдавия",
    "value": "Молдавия"
  },
  {
    "label": "Монако",
    "value": "Монако"
  },
  {
    "label": "Монголия",
    "value": "Монголия"
  },
  {
    "label": "Мьянма",
    "value": "Мьянма"
  },
  {
    "label": "Намибия",
    "value": "Намибия"
  },
  {
    "label": "Науру",
    "value": "Науру"
  },
  {
    "label": "Непал",
    "value": "Непал"
  },
  {
    "label": "Нигер",
    "value": "Нигер"
  },
  {
    "label": "Нигерия",
    "value": "Нигерия"
  },
  {
    "label": "Нидерланды",
    "value": "Нидерланды"
  },
  {
    "label": "Никарагуа",
    "value": "Никарагуа"
  },
  {
    "label": "Новая Зеландия",
    "value": "Новая Зеландия"
  },
  {
    "label": "Норвегия",
    "value": "Норвегия"
  },
  {
    "label": "ОАЭ",
    "value": "ОАЭ"
  },
  {
    "label": "Оман",
    "value": "Оман"
  },
  {
    "label": "Пакистан",
    "value": "Пакистан"
  },
  {
    "label": "Палау",
    "value": "Палау"
  },
  {
    "label": "Панама",
    "value": "Панама"
  },
  {
    "label": "Папуа - Новая Гвинея",
    "value": "Папуа - Новая Гвинея"
  },
  {
    "label": "Парагвай",
    "value": "Парагвай"
  },
  {
    "label": "Перу",
    "value": "Перу"
  },
  {
    "label": "Польша",
    "value": "Польша"
  },
  {
    "label": "Португалия",
    "value": "Португалия"
  },
  {
    "label": "Республика Конго",
    "value": "Республика Конго"
  },
  {
    "label": "Республика Корея",
    "value": "Республика Корея"
  },
  {
    "label": "Руанда",
    "value": "Руанда"
  },
  {
    "label": "Румыния",
    "value": "Румыния"
  },
  {
    "label": "Сальвадор",
    "value": "Сальвадор"
  },
  {
    "label": "Самоа",
    "value": "Самоа"
  },
  {
    "label": "Сан-Марино",
    "value": "Сан-Марино"
  },
  {
    "label": "Сан-Томе и Принсипи",
    "value": "Сан-Томе и Принсипи"
  },
  {
    "label": "Саудовская Аравия",
    "value": "Саудовская Аравия"
  },
  {
    "label": "Северная Македония",
    "value": "Северная Македония"
  },
  {
    "label": "Сейшельские Острова",
    "value": "Сейшельские Острова"
  },
  {
    "label": "Сенегал",
    "value": "Сенегал"
  },
  {
    "label": "Сент-Винсент и Гренадины",
    "value": "Сент-Винсент и Гренадины"
  },
  {
    "label": "Сент-Китс и Невис",
    "value": "Сент-Китс и Невис"
  },
  {
    "label": "Сент-Люсия",
    "value": "Сент-Люсия"
  },
  {
    "label": "Сербия",
    "value": "Сербия"
  },
  {
    "label": "Сингапур",
    "value": "Сингапур"
  },
  {
    "label": "Сирия",
    "value": "Сирия"
  },
  {
    "label": "Словакия",
    "value": "Словакия"
  },
  {
    "label": "Словения",
    "value": "Словения"
  },
  {
    "label": "Соломоновы Острова",
    "value": "Соломоновы Острова"
  },
  {
    "label": "Сомали",
    "value": "Сомали"
  },
  {
    "label": "Судан",
    "value": "Судан"
  },
  {
    "label": "Суринам",
    "value": "Суринам"
  },
  {
    "label": "США",
    "value": "США"
  },
  {
    "label": "Сьерра-Леоне",
    "value": "Сьерра-Леоне"
  },
  {
    "label": "Таджикистан",
    "value": "Таджикистан"
  },
  {
    "label": "Таиланд",
    "value": "Таиланд"
  },
  {
    "label": "Танзания",
    "value": "Танзания"
  },
  {
    "label": "Того",
    "value": "Того"
  },
  {
    "label": "Тонга",
    "value": "Тонга"
  },
  {
    "label": "Тринидад и Тобаго",
    "value": "Тринидад и Тобаго"
  },
  {
    "label": "Тувалу",
    "value": "Тувалу"
  },
  {
    "label": "Тунис",
    "value": "Тунис"
  },
  {
    "label": "Туркмения",
    "value": "Туркмения"
  },
  {
    "label": "Турция",
    "value": "Турция"
  },
  {
    "label": "Уганда",
    "value": "Уганда"
  },
  {
    "label": "Узбекистан",
    "value": "Узбекистан"
  },
  {
    "label": "Уругвай",
    "value": "Уругвай"
  },
  {
    "label": "Федеративные Штаты Микронезии",
    "value": "Федеративные Штаты Микронезии"
  },
  {
    "label": "Фиджи",
    "value": "Фиджи"
  },
  {
    "label": "Филиппины",
    "value": "Филиппины"
  },
  {
    "label": "Финляндия",
    "value": "Финляндия"
  },
  {
    "label": "Франция",
    "value": "Франция"
  },
  {
    "label": "Хорватия",
    "value": "Хорватия"
  },
  {
    "label": "ЦАР",
    "value": "ЦАР"
  },
  {
    "label": "Чад",
    "value": "Чад"
  },
  {
    "label": "Черногория",
    "value": "Черногория"
  },
  {
    "label": "Чехия",
    "value": "Чехия"
  },
  {
    "label": "Чили",
    "value": "Чили"
  },
  {
    "label": "Швейцария",
    "value": "Швейцария"
  },
  {
    "label": "Швеция",
    "value": "Швеция"
  },
  {
    "label": "Шри-Ланка",
    "value": "Шри-Ланка"
  },
  {
    "label": "Эквадор\tКито",
    "value": "Эквадор\tКито"
  },
  {
    "label": "Экваториальная Гвинея",
    "value": "Экваториальная Гвинея"
  },
  {
    "label": "Эритрея",
    "value": "Эритрея"
  },
  {
    "label": "Эсватини",
    "value": "Эсватини"
  },
  {
    "label": "Эстония",
    "value": "Эстония"
  },
  {
    "label": "Эфиопия",
    "value": "Эфиопия"
  },
  {
    "label": "ЮАР\tПретория",
    "value": "ЮАР\tПретория"
  },
  {
    "label": "Южная Осетия",
    "value": "Южная Осетия"
  },
  {
    "label": "Южный Судан",
    "value": "Южный Судан"
  },
  {
    "label": "Ямайка",
    "value": "Ямайка"
  },
  {
    "label": "Япония",
    "value": "Япония"
  }
];

export const COUNTRY_EN = [
    {
      "label": "Kazakhstan",
      "value": "Kazakhstan"
    },
    {
      "label": "Abkhazia",
      "value": "Abkhazia"
    },
    {
      "label": "Australia",
      "value": "Australia"
    },
    {
      "label": "Austria",
      "value": "Austria"
    },
    {
      "label": "Azerbaijan",
      "value": "Azerbaijan"
    },
    {
      "label": "Albania",
      "value": "Albania"
    },
    {
      "label": "Algeria",
      "value": "Algeria"
    },
    {
      "label": "Angola",
      "value": "Angola"
    },
    {
      "label": "Andorra",
      "value": "Andorra"
    },
    {
      "label": "Antigua and Barbuda",
      "value": "Antigua and Barbuda"
    },
    {
      "label": "Argentina",
      "value": "Argentina"
    },
    {
      "label": "Armenia",
      "value": "Armenia"
    },
    {
      "label": "Afghanistan",
      "value": "Afghanistan"
    },
    {
      "label": "Bahamas",
      "value": "Bahamas"
    },
    {
      "label": "Bangladesh",
      "value": "Bangladesh"
    },
    {
      "label": "Barbados",
      "value": "Barbados"
    },
    {
      "label": "Bahrain",
      "value": "Bahrain"
    },
    {
      "label": "Belize",
      "value": "Belize"
    },
    {
      "label": "Belarus",
      "value": "Belarus"
    },
    {
      "label": "Belgium",
      "value": "Belgium"
    },
    {
      "label": "Benin",
      "value": "Benin"
    },
    {
      "label": "Bulgaria",
      "value": "Bulgaria"
    },
    {
      "label": "Bolivia Sucre",
      "value": "Bolivia Sucre"
    },
    {
      "label": "Bosnia and Herzegovina",
      "value": "Bosnia and Herzegovina"
    },
    {
      "label": "Botswana",
      "value": "Botswana"
    },
    {
      "label": "Brazil",
      "value": "Brazil"
    },
    {
      "label": "Brunei",
      "value": "Brunei"
    },
    {
      "label": "Burkina Faso",
      "value": "Burkina Faso"
    },
    {
      "label": "Burundi",
      "value": "Burundi"
    },
    {
      "label": "Butane",
      "value": "Butane"
    },
    {
      "label": "Vanuatu",
      "value": "Vanuatu"
    },
    {
      "label": "Vatican",
      "value": "Vatican"
    },
    {
      "label": "Great Britain",
      "value": "Great Britain"
    },
    {
      "label": "Hungary",
      "value": "Hungary"
    },
    {
      "label": "Venezuela",
      "value": "Venezuela"
    },
    {
      "label": "East Timor",
      "value": "East Timor"
    },
    {
      "label": "Vietnam",
      "value": "Vietnam"
    },
    {
      "label": "Gabon",
      "value": "Gabon"
    },
    {
      "label": "Haiti",
      "value": "Haiti"
    },
    {
      "label": "Guyana",
      "value": "Guyana"
    },
    {
      "label": "Gambia",
      "value": "Gambia"
    },
    {
      "label": "Ghana",
      "value": "Ghana"
    },
    {
      "label": "Guatemala",
      "value": "Guatemala"
    },
    {
      "label": "Guinea",
      "value": "Guinea"
    },
    {
      "label": "Guinea bissau",
      "value": "Guinea bissau"
    },
    {
      "label": "Germany",
      "value": "Germany"
    },
    {
      "label": "Honduras",
      "value": "Honduras"
    },
    {
      "label": "State of Palestine",
      "value": "State of Palestine"
    },
    {
      "label": "Grenada",
      "value": "Grenada"
    },
    {
      "label": "Greece",
      "value": "Greece"
    },
    {
      "label": "Georgia",
      "value": "Georgia"
    },
    {
      "label": "Denmark",
      "value": "Denmark"
    },
    {
      "label": "Djibouti",
      "value": "Djibouti"
    },
    {
      "label": "Dominica",
      "value": "Dominica"
    },
    {
      "label": "Egypt",
      "value": "Egypt"
    },
    {
      "label": "Zambia",
      "value": "Zambia"
    },
    {
      "label": "Zimbabwe",
      "value": "Zimbabwe"
    },
    {
      "label": "Israel",
      "value": "Israel"
    },
    {
      "label": "India",
      "value": "India"
    },
    {
      "label": "Indonesia",
      "value": "Indonesia"
    },
    {
      "label": "Jordan",
      "value": "Jordan"
    },
    {
      "label": "Iraq",
      "value": "Iraq"
    },
    {
      "label": "Iran",
      "value": "Iran"
    },
    {
      "label": "Ireland",
      "value": "Ireland"
    },
    {
      "label": "Iceland",
      "value": "Iceland"
    },
    {
      "label": "Spain",
      "value": "Spain"
    },
    {
      "label": "Italy",
      "value": "Italy"
    },
    {
      "label": "Yemen",
      "value": "Yemen"
    },
    {
      "label": "Cape Verde",
      "value": "Cape Verde"
    },
    {
      "label": "Kazakhstan",
      "value": "Kazakhstan"
    },
    {
      "label": "Cambodia",
      "value": "Cambodia"
    },
    {
      "label": "Cameroon",
      "value": "Cameroon"
    },
    {
      "label": "Canada",
      "value": "Canada"
    },
    {
      "label": "Qatar",
      "value": "Qatar"
    },
    {
      "label": "Kenya",
      "value": "Kenya"
    },
    {
      "label": "Cyprus",
      "value": "Cyprus"
    },
    {
      "label": "Kyrgyzstan",
      "value": "Kyrgyzstan"
    },
    {
      "label": "Kiribati",
      "value": "Kiribati"
    },
    {
      "label": "China",
      "value": "China"
    },
    {
      "label": "DPRK",
      "value": "DPRK"
    },
    {
      "label": "Colombia",
      "value": "Colombia"
    },
    {
      "label": "Comoros",
      "value": "Comoros"
    },
    {
      "label": "Costa Rica",
      "value": "Costa Rica"
    },
    {
      "label": "Cote d'Ivoire",
      "value": "Cote d'Ivoire"
    },
    {
      "label": "Cuba",
      "value": "Cuba"
    },
    {
      "label": "Kuwait",
      "value": "Kuwait"
    },
    {
      "label": "Laos",
      "value": "Laos"
    },
    {
      "label": "Latvia",
      "value": "Latvia"
    },
    {
      "label": "Lesotho",
      "value": "Lesotho"
    },
    {
      "label": "Liberia",
      "value": "Liberia"
    },
    {
      "label": "Lebanon",
      "value": "Lebanon"
    },
    {
      "label": "Libya",
      "value": "Libya"
    },
    {
      "label": "Lithuania",
      "value": "Lithuania"
    },
    {
      "label": "Liechtenstein",
      "value": "Liechtenstein"
    },
    {
      "label": "Luxembourg",
      "value": "Luxembourg"
    },
    {
      "label": "Mauritius",
      "value": "Mauritius"
    },
    {
      "label": "Mauritania",
      "value": "Mauritania"
    },
    {
      "label": "Madagascar",
      "value": "Madagascar"
    },
    {
      "label": "Malawi",
      "value": "Malawi"
    },
    {
      "label": "Malaysia",
      "value": "Malaysia"
    },
  {
    "label": "Mali",
    "value": "Mali"
  },
  {
    "label": "Maldives",
    "value": "Maldives"
  },
  {
    "label": "Malta",
    "value": "Malta"
  },
  {
    "label": "Morocco",
    "value": "Morocco"
  },
  {
    "label": "Marshall Islands",
    "value": "Marshall Islands"
  },
  {
    "label": "Mexico",
    "value": "Mexico"
  },
  {
    "label": "Mozambique",
    "value": "Mozambique"
  },
  {
    "label": "Moldova",
    "value": "Moldova"
  },
  {
    "label": "Monaco",
    "value": "Monaco"
  },
  {
    "label": "Mongolia",
    "value": "Mongolia"
  },
  {
    "label": "Myanmar",
    "value": "Myanmar"
  },
  {
    "label": "Namibia",
    "value": "Namibia"
  },
  {
    "label": "Nauru",
    "value": "Nauru"
  },
  {
    "label": "Nepal",
    "value": "Nepal"
  },
  {
    "label": "Niger",
    "value": "Niger"
  },
  {
    "label": "Nigeria",
    "value": "Nigeria"
  },
  {
    "label": "Netherlands",
    "value": "Netherlands"
  },
  {
    "label": "Nicaragua",
    "value": "Nicaragua"
  },
  {
    "label": "New Zealand",
    "value": "New Zealand"
  },
  {
    "label": "Norway",
    "value": "Norway"
  },
  {
    "label": "UAE",
    "value": "UAE"
  },
  {
    "label": "Oman",
    "value": "Oman"
  },
  {
    "label": "Pakistan",
    "value": "Pakistan"
  },
  {
    "label": "Palau",
    "value": "Palau"
  },
  {
    "label": "Panama",
    "value": "Panama"
  },
  {
    "label": "Papua New Guinea",
    "value": "Papua New Guinea"
  },
  {
    "label": "Paraguay",
    "value": "Paraguay"
  },
  {
    "label": "Peru",
    "value": "Peru"
  },
  {
    "label": "Poland",
    "value": "Poland"
  },
  {
    "label": "Portugal",
    "value": "Portugal"
  },
  {
    "label": "Republic of the Congo",
    "value": "Republic of the Congo"
  },
  {
    "label": "The Republic of Korea",
    "value": "The Republic of Korea"
  },
  {
    "label": "Russia",
    "value": "Russia"
  },
  {
    "label": "Rwanda",
    "value": "Rwanda"
  },
  {
    "label": "Romania",
    "value": "Romania"
  },
  {
    "label": "Salvador",
    "value": "Salvador"
  },
  {
    "label": "Samoa",
    "value": "Samoa"
  },
  {
    "label": "San marino",
    "value": "San marino"
  },
  {
    "label": "Sao Tome and Principe",
    "value": "Sao Tome and Principe"
  },
  {
    "label": "Saudi Arabia",
    "value": "Saudi Arabia"
  },
  {
    "label": "North Macedonia",
    "value": "North Macedonia"
  },
  {
    "label": "Seychelles",
    "value": "Seychelles"
  },
  {
    "label": "Senegal",
    "value": "Senegal"
  },
  {
    "label": "Saint Vincent and the Grenadines",
    "value": "Saint Vincent and the Grenadines"
  },
  {
    "label": "Saint Kitts and Nevis",
    "value": "Saint Kitts and Nevis"
  },
  {
    "label": "Saint lucia",
    "value": "Saint lucia"
  },
  {
    "label": "Serbia",
    "value": "Serbia"
  },
  {
    "label": "Singapore",
    "value": "Singapore"
  },
  {
    "label": "Syria",
    "value": "Syria"
  },
  {
    "label": "Slovakia",
    "value": "Slovakia"
  },
  {
    "label": "Slovenia",
    "value": "Slovenia"
  },
  {
    "label": "Solomon islands",
    "value": "Solomon islands"
  },
  {
    "label": "Somalia",
    "value": "Somalia"
  },
  {
    "label": "Sudan",
    "value": "Sudan"
  },
  {
    "label": "Suriname",
    "value": "Suriname"
  },
  {
    "label": "USA",
    "value": "USA"
  },
  {
    "label": "Sierra leone",
    "value": "Sierra leone"
  },
  {
    "label": "Tajikistan",
    "value": "Tajikistan"
  },
  {
    "label": "Thailand",
    "value": "Thailand"
  },
  {
    "label": "Tanzania",
    "value": "Tanzania"
  },
  {
    "label": "Togo",
    "value": "Togo"
  },
  {
    "label": "Tonga",
    "value": "Tonga"
  },
  {
    "label": "Trinidad and Tobago",
    "value": "Trinidad and Tobago"
  },
  {
    "label": "Tuvalu",
    "value": "Tuvalu"
  },
  {
    "label": "Tunisia",
    "value": "Tunisia"
  },
  {
    "label": "Turkmenistan",
    "value": "Turkmenistan"
  },
  {
    "label": "Turkey",
    "value": "Turkey"
  },
  {
    "label": "Uganda",
    "value": "Uganda"
  },
  {
    "label": "Uzbekistan",
    "value": "Uzbekistan"
  },
  {
    "label": "Ukraine",
    "value": "Ukraine"
  },
  {
    "label": "Uruguay",
    "value": "Uruguay"
  },
  {
    "label": "Federated States of Micronesia",
    "value": "Federated States of Micronesia"
  },
  {
    "label": "Fiji",
    "value": "Fiji"
  },
  {
    "label": "Philippines",
    "value": "Philippines"
  },
  {
    "label": "Finland",
    "value": "Finland"
  },
  {
    "label": "France",
    "value": "France"
  },
  {
    "label": "Croatia",
    "value": "Croatia"
  },
  {
    "label": "CAR",
    "value": "CAR"
  },
  {
    "label": "Chad",
    "value": "Chad"
  },
  {
    "label": "Montenegro",
    "value": "Montenegro"
  },
  {
    "label": "Czech",
    "value": "Czech"
  },
  {
    "label": "Chile",
    "value": "Chile"
  },
  {
    "label": "Switzerland",
    "value": "Switzerland"
  },
  {
    "label": "Sweden",
    "value": "Sweden"
  },
  {
    "label": "Sri Lanka",
    "value": "Sri Lanka"
  },
  {
    "label": "Ecuador Quito",
    "value": "Ecuador Quito"
  },
  {
    "label": "Equatorial Guinea",
    "value": "Equatorial Guinea"
  },
  {
    "label": "Eritrea",
    "value": "Eritrea"
  },
  {
    "label": "Esvatini",
    "value": "Esvatini"
  },
  {
    "label": "Estonia",
    "value": "Estonia"
  },
  {
    "label": "Ethiopia",
    "value": "Ethiopia"
  },
  {
    "label": "South Africa Pretoria",
    "value": "South Africa Pretoria"
  },
  {
    "label": "South Ossetia",
    "value": "South Ossetia"
  },
  {
    "label": "South sudan",
    "value": "South sudan"
  },
  {
    "label": "Jamaica",
    "value": "Jamaica"
  },
  {
    "label": "Japan",
    "value": "Japan"
  }
];