import React from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import {useField} from "formik";
import CustomToggle from "../CustomToggle";
import style from "../style.module.scss";
import InputError from "../../../common/InputError";
import {getDatePickerLocale} from "../../../../utils/getDatePickerLocale";

const DateField = ({
                       name,
                       label,
                       minDate,
                       maxDate,
                       filterDate,
                       profileInput = false,
                       ...props
                   }) => {

    const [field, meta, helpers] = useField(name);
    const fieldError = () => meta.error && meta.touched ? meta.error : false;

    const handleChange = (date) => {
        try {
            helpers.setValue(moment(date).format('YYYY-MM-DD'))
            if (fieldError) helpers.setError('');
        }
        catch(error) {
            console.error(error);
        }
    }


    return (
        <div className={style.pickerWrapper}>
            {label && <label className={style.label} htmlFor={`${field.name}_${label}`}>{label}</label>}
            <DatePicker
                locale={getDatePickerLocale()}
                filterDate={filterDate}
                minDate={minDate || null}
                maxDate={maxDate || null}
                selected={field.value ? new Date(field.value) : new Date()}
                // dateFormat={'dd.mm.yyyy'}
                onChange={(date) => handleChange(date)}
                customInput={<CustomToggle
                    formatValue={field.value ? moment(field.value).format('ll') : field.value}
                    error={!!fieldError()}
                    profileInput
                />}
            />
            {fieldError() && <InputError>{fieldError()}</InputError>}
        </div>
    )
}

export default DateField;