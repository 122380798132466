import {useState, useEffect} from 'react';

export default function useDebounce(value, delay, immediate) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
      let handler = () => {};
      if (!debouncedValue && immediate) setDebouncedValue(value);
      else {
          handler = setTimeout(() => {
              setDebouncedValue(value);
          }, delay);
      }

      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay]
  );
  return debouncedValue;
}