import React from 'react';
import style from './style.module.scss';
import Cart from '../../../Cart';
import { Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import 'chart.js/auto';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  registerables,
  Title,
  Tooltip,
} from 'chart.js';
import { months, options, weekdays } from './lineChart.constant';

ChartJS.register(
  ...registerables,
  zoomPlugin,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  {
    id: 'uniqueid5', //typescript crashes without id
    afterDraw: function (chart, easing) {
      if (chart.tooltip._active && chart.tooltip._active.length && chart.scales.y?.bottom) {
        const activePoint = chart.tooltip._active[0];
        const ctx = chart.ctx;
        const x = activePoint.element.x;
        const y = activePoint.element.y + 10;
        const bottomY = chart.scales.y.bottom;
        const options = activePoint.element.options;
        ctx.save();
        ctx.beginPath();
        ctx.moveTo(x, y);
        ctx.lineTo(x, bottomY);
        ctx.lineWidth = 2;
        ctx.strokeStyle = '#2C2C2C';
        ctx.stroke();
        ctx.restore();
        options.radius = 5;
        options.backgroundColor = 'black';
        chart.update();
      }
    },
  },
);
export default function LineChart({ data }) {
  const lineChartData = {
    labels: data?.map((item) => {
      const [time, date] = item.date.split(' ');
      const [hours, minutes, seconds] = time.split(':');
      const [day, month, year] = date.split('.');

      const dateObject = new Date(
        `${year}-${month.length === 1 ? `${month}0` : month}-${day.length === 1 ? `${day}0` : day}T${hours}:${minutes}:${seconds}`,
      );
      return `${weekdays[dateObject.getDay()]} (${dateObject.getDate()} ${months[dateObject.getMonth()]})`;
    }),
    datasets: [
      {
        data: data?.map((item) => item.rating),
        borderColor: '#70C2BB',
        pointBackgroundColor: '#70C2BB',
        backgroundColor: (context) => {
          const bgColor = ['rgba(112, 194, 187, 0.9)', 'rgb(112, 194, 187, 0.7)', 'rgba(112, 194, 187, 0.2)'];
          console.log(context);
          if (!context.chart.chartArea) {
            return;
          }
          const {
            ctx,
            chartArea: { top, bottom },
          } = context.chart;
          const bg = ctx.createLinearGradient(0, top, 0, bottom);
          bg.addColorStop(0, bgColor[0]);
          bg.addColorStop(0.5, bgColor[1]);
          bg.addColorStop(1, bgColor[2]);
          return bg;
        },
        fill: true,
        tension: 0.3,
      },
    ],
  };

  return (
    <Cart classname={style.chart}>
      <span className={style.chart__text}>Балл</span>
      <div style={{ width: '100%', height: '100%' }}>
        <Line options={options} data={lineChartData} />
      </div>
    </Cart>
  );
}
