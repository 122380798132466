import React, {useEffect, useState} from 'react';
import {NavLink, useLocation} from "react-router-dom";

export default function SectionNavigationItemMobile({supercategory_name, supercategory_id, categories, handleOpen}) {

  return (
    <li className='section-navigation__item' key={supercategory_name}>
      <div className='section-navigation__super-category-group'>
        <NavLink className='section-navigation__link' to={`/courses/${supercategory_id}`} onClick={handleOpen}>
          {supercategory_name}
        </NavLink>
      </div>
      <ul className='section-navigation__sub-list'>
        {categories.map(el =>
          <li className='section-navigation__sub-item'>
            <NavLink className={`section-navigation__sub-link`} exact to={`/courses/${supercategory_id}/${el.category_id}`} onClick={handleOpen}>
              {el.category_name}
            </NavLink>
          </li>
        )}
      </ul>
    </li>
  )
}