import React from 'react';
import { Route, Switch } from 'react-router-dom';
import CompletedCoursesList from './CompletedCoursesList';
import InProgressCoursesList from './InProgressCoursesList';
import PurchasedCoursesList from './PurchasedCoursesList';

export default function CoursesRouter() {
  return (
    <Switch>
      <Route path='/my-courses/progress' component={InProgressCoursesList} />
      <Route path='/my-courses/completed' component={CompletedCoursesList} />
      <Route path='/my-courses/purchased' component={PurchasedCoursesList} />
    </Switch>
  )
}