import React, { useMemo } from 'react';
import SectionList from "../SectionsCoursesGrid/SectionGridList";
import './../SectionsCoursesGrid/sectionsCoursesGrid.scss';
import './../SectionsCoursesGrid/sectionsCoursesGridMobile.scss';
import Loader3 from "components/common/Loader/loader3";
import useResolution from "../../../../hooks/useResolution";
import LazyImg from "../../../common/LazyImg";

export default function SectionsSuperCoursesGrid({ data, loading, url }) {
    const isMobile = useResolution(650);

    const withBanner = useMemo(() => {
        if (data[0] && data[0].categories.length === 1) {
            return !!((!isMobile && data[0].categories[0].desktop_image) || (isMobile && data[0].categories[0].mobile_image))
        }
        else return false;
    }, [data]);

    return (
        <>
            {
                withBanner && (
                    <a target="_blank" href={data[0].categories[0].banner_link} className="banner">
                        <LazyImg
                            image={{ src: isMobile ? data[0].categories[0].mobile_image : data[0].categories[0].desktop_image, width: '100%', height: '100%' }}
                            classes={"banner__img"}
                            placeholder={<span className="banner__loading"></span>}
                        />
                        {
                            !isMobile && (
                                <div className="banner-text">
                                    <p className="banner-text__title">{data[0].categories[0].category_name}</p>
                                    <p className="banner-text__description" dangerouslySetInnerHTML={{ __html: data[0].categories[0].description }}></p>
                                </div>
                            )
                        }
                    </a>
                )
            }
            <div className='course-section__grid section-grid'>
                {loading && <Loader3 />}
                {
                    data.map(item => item.categories.map(el => <SectionList
                        title={withBanner ? false : (el.category_name || el.name)}
                        data={el.courses}
                        key={el.category_name}
                        isPrivate={true}
                        url={url}
                    />)

                    )}
            </div>
        </>
    );
}