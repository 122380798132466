import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import style from "./style.module.scss";
import { useTranslation } from "react-i18next";
import ChatPanel from "./ChatPanel";
import { ChatPointer } from "../../../../../assets/img/webinar";
import { WebinarContext } from "../../../../../constants/contexts";
import { getChatHistory } from "../../../../../services/api";
import useResolution from "../../../../../hooks/useResolution";
import classnames from "classnames";

const Chat = ({ closeMobileChat, forceUpdateTrigger }) => {
    const [isHidden, setHidden] = useState(false);
    const { t } = useTranslation();
    const ref = useRef(null);
    const { context } = useContext(WebinarContext)
    const [messages, setMessages] = useState([]);
    const isMobile = useResolution(900);

    useEffect(()=>{
        if (isMobile) closeMobileChat(isHidden)
    }, [isHidden])

    const hideClick = () => {
        setHidden(!isHidden)
    }

    useEffect(() => {

        context.socket.on('received_messages', message => {
            setMessages(prev => [...prev, { ...message.message }])
        })

        return function () {

            context.socket.off('received_messages', message => {
                setMessages(prev => [...prev, { ...message.message }])
            })
        }
    }, [])

    useEffect(() => {
        getChatHistory(context.accessToken).then(res => {
            setMessages(res.data);
        })
    }, [forceUpdateTrigger])

    useEffect(() => {
        //Скроллим окно сообщений вниз
        if (ref.current) {
            const elem = ref.current
            elem.scrollTop = elem.scrollHeight - elem.clientHeight;
        }
    }, [ref.current, messages.length])

    const sendMessage = useCallback((message) => {
        context.socket.emit('message', { message: message, room: context.hash })
    }, [])

    return (
        <div className={style.chat}>
            <div className={style['chat-header']}>
                <p className={style['chat-header__title']}>{t('webinar.chat')}</p>
                <button className={style['chat-header__button']} onClick={() => hideClick()}>
                    {isHidden ? t('webinar.show') : t('webinar.hide')}
                </button>
            </div>
            <div className={style['chat-messages']} ref={ref}>
                {
                    messages.map((item, index) => (
                        <div className={style['chat-messages__message']} key={`${item.id}_${index}`}>
                            <p>
                                <span className={classnames(style['chat-messages__message__name'], {
                                    [style['chat-messages__message__name_teacher']]: item.is_teacher
                                })}>
                                    {
                                        !item.surname && !item.name
                                            ? `user${item.id}`
                                            : `${item.name || ''} ${item.surname || ''}`
                                    }:
                                </span>
                                {item.message}
                            </p>
                        </div>
                    ))
                }
            </div>
            <ChatPanel sendMessage={sendMessage} />
            {isHidden && (
                <div className={style.chat__hidden}>
                    <p>{t('webinar.hiddenChatMessage')}</p>
                    <ChatPointer />
                </div>
            )}
        </div>
    )
}

export default React.memo(Chat, (prevProps, nextProps) => {
    return prevProps.forceUpdateTrigger === nextProps.forceUpdateTrigger
});
