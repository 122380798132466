import axios from 'axios';
import {
  FETCH_COURSES_SUCCESS,
  FETCH_COURSES_ERROR,
  FETCH_COURSES,
  SET_LATEST_COURSES,
} from './types';

// async function setCourseIdsMap(courses) {
//   const coursesMap = { slugs: {}, ids: {}, category_slugs: {}, category_ids: {} };
//   for (const supercategory of courses.supercategories) {
//     for (const category of supercategory.categories) {
//       category.courses.map((item) => {
//         coursesMap.slugs[item.slug] = item.id;
//         coursesMap.ids[item.id] = item.slug;
//       });
//       coursesMap.category_slugs[category.category_slug] = category.category_id;
//       coursesMap.category_ids[category.category_id] = category.category_slug;
//     }
//   }
//   return coursesMap;
// }

export function getCourses(URL) {
  return async (dispatch) => {
    dispatch({ type: FETCH_COURSES });
    try {
      const res = await axios.get(URL);
      dispatch({ type: FETCH_COURSES_SUCCESS, payload: res.data });
      // const resMap = await setCourseIdsMap(res.data);
      // dispatch({ type: FETCH_COURSES_SUCCESS_MAP, payload: resMap });
    } catch (error) {
      dispatch({
        type: FETCH_COURSES_ERROR,
        payload: error.message,
      });
    }
  };
}

export function getLatestCourses() {
  return async (dispatch) => {
    dispatch({ type: FETCH_COURSES });
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v3/user_latest_visited_courses_with_exercises`
      );
      dispatch({ type: SET_LATEST_COURSES, payload: res.data.latest_visited_courses_with_exercises });
    } catch (error) {
      dispatch({
        type: FETCH_COURSES_ERROR,
        payload: error.message,
      });
    }
  };
}
