import React from 'react';
import MySelect from '../common/MySelect';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setLang } from 'actions/setLang';
import { LANG } from 'constants/publicConstants';
import { useHistory } from 'react-router-dom';
import './language.scss';
import { savePlatform } from '../../services/api';

const LANGUAGE_OPTIONS = [
  { label: "RU", value: "rus" },
  { label: "EN", value: "eng" },
  { label: "KZ", value: "kz" },
  { label: "ES", value: "es" },
  { label: "TR", value: "tr" }
];

export default function HeaderLanguage() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const lang = useSelector((state) => state.settings.lang);

  const onLanguageChange = (value) => {
    if (LANG[value] !== LANG[lang]) {
      i18n.changeLanguage(value);
      dispatch(setLang(LANG[value]));
      history.push('/');
      savePlatform(value).finally(() => {
        window.location.reload();
      });
    }
  };

  const getDefaultView = () => {
    if (localStorage.getItem('i18nextLng')) {
      let languageItem = LANGUAGE_OPTIONS.filter((el) => el.value === localStorage.getItem('i18nextLng'));
      if (languageItem.length) {
        return languageItem[0].label;
      } else {
        return <span className={'flag flag--ru'} />;
      }
    } else {
    }
  };

  return (
    <div className='navigation__item'>
      <div className='navigation__select'>
        <MySelect
          options={LANGUAGE_OPTIONS}
          width={'30px'}
          hideArrow={true}
          handleChange={onLanguageChange}
          placeholder={getDefaultView()}
          titleClasses={'language__title'}
          listItemClasses={'language__item'}
          listClasses={'language__list'}
        />
      </div>
    </div>
  );
}
