export const QUEEN = 'q';
export const BISHOP = 'b';
export const ROOK = 'r';
export const KNIGHT = 'n';
export const DEFAULT_FEN = 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1';
export const TRANSITION_DURATION = 150;

export const DROP_SQUARE_STYLE = {boxShadow: 'inset 0 0 0 2px rgba(57, 91, 93, 0.8)'};

export const CHESS_SQUARE_COLORS = {
  main: {
    LIGHT: { backgroundColor: 'rgb(250,250,250)', backgroundImage: 'linear-gradient(135deg, rgb(225,225,225) 0%, rgb(250,250,250) 75%)' },
    DARK: { backgroundColor: 'rgb(116, 183, 187)', backgroundImage: 'linear-gradient(135deg, rgb(116, 183, 187) 0%, rgb(119,195,195) 75%)' },
    value: 'main'
  },
  brown: {
    LIGHT: { backgroundColor: '#ECDAB9'},
    DARK: { backgroundColor: '#AE8A68'},
    value: 'brown'
  },
  pink: {
    LIGHT: { backgroundColor: '#EEB1B1' },
    DARK: { backgroundColor: '#C04F4F' },
    value: 'pink'
  },
  pink2: {
    LIGHT: { backgroundColor: '#F9C3C3' },
    DARK: { backgroundColor: '#B82222' },
    value: 'pink2'
  },
  black2: {
    LIGHT: { backgroundColor: '#D1D1D1' },
    DARK: { backgroundColor: '#636363' },
    value: 'black2'
  },
  lightblue: {
    LIGHT: { backgroundColor: '#BDC9E5' },
    DARK: { backgroundColor: '#617EE4' },
    value: 'lightblue'
  },
  brown2: {
    LIGHT: { backgroundColor: '#E1C7B0' },
    DARK: { backgroundColor: '#75400F' },
    value: 'brown2'
  },
  purple: {
    LIGHT: { backgroundColor: '#F0C5E7' },
    DARK: { backgroundColor: '#DB63C0' },
    value: 'purple'
  },
  blue: {
    LIGHT: { backgroundColor: '#DFE3E6' },
    DARK: { backgroundColor: '#90A1AC' },
    value: 'blue'
  },
  dirtygreen: {
    LIGHT: { backgroundColor: '#FFFFE0' },
    DARK: { backgroundColor: '#8CA56D' },
    value: 'dirtygreen'
  },
  dirtygreen2: {
    LIGHT: { backgroundColor: '#F2F6BA' },
    DARK: { backgroundColor: '#669362' },
    value: 'dirtygreen2'
  },
  lightpurple: {
    LIGHT: { backgroundColor: '#E5DCEF' },
    DARK: { backgroundColor: '#967FB2' },
    value: 'lightpurple'
  },
  red: {
    LIGHT: { backgroundColor: '#EFF0C8' },
    DARK: { backgroundColor: '#E27E7B' },
    value: 'red'
  }
};

export const CHESS_HINT_COLOR = 'yellow';

export const BOARD_STYLE = {
  borderRadius: "5px",
  border: '6px solid #395b5d',
  boxSizing: 'content-box',
  boxShadow: `0 0 10px rgba(0, 0, 0, 0.3)`
};

export const DEFAULT_POSITION_OBJECT = {
  "e1": "wK",
  "a1": "wR",
  "h1": "wR",
  "g1": "wN",
  "b1": "wN",
  "f1": "wB",
  "c1": "wB",
  "d1": "wQ",
  "g2": "wP",
  "h2": "wP",
  "f2": "wP",
  "e2": "wP",
  "d2": "wP",
  "c2": "wP",
  "b2": "wP",
  "a2": "wP",
  "h7": "bP",
  "g7": "bP",
  "f7": "bP",
  "e7": "bP",
  "d7": "bP",
  "c7": "bP",
  "b7": "bP",
  "a7": "bP",
  "a8": "bR",
  "h8": "bR",
  "g8": "bN",
  "b8": "bN",
  "f8": "bB",
  "c8": "bB",
  "e8": "bK",
  "d8": "bQ"
};
