import axios from 'axios';

export default function invalidTokenInterception(callback) {
  if (localStorage.token) {
    axios.interceptors.response.use(function (response) {
      return response;
    }, function (error) {
      if (!error?.response) {
        return;
      }
      if (error.response.status === 400) {
        if(error.response.data.message === "Token is not valid.") {
          localStorage.removeItem("token");
          callback && callback();
        }
      }
      return Promise.reject(error)
    });
  }
}