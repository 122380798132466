import React, {useContext, useEffect, useState} from "react";
import style from "./style.module.scss";
import WebinarRating from "../../../common/Rating";
import ServiceRating from "../../../common/ServiceRating";
import Result from "./Result";
import {getLesson, getRating, getWebinarResults} from "../../../../services/api";
import {WebinarContext} from "../../../../constants/contexts";
import Confetti from 'react-confetti';
import useResolution from "../../../../hooks/useResolution";
import classnames from "classnames";
import {useTranslation} from "react-i18next";
import {BlackRatingIcon} from "../../../../assets/img/webinar";
import { useSelector } from "react-redux";

const FinishLobby = ({lessonData}) => {
    const [rating, setRating] = useState([]);
    const {context} = useContext(WebinarContext);
    const [isConfettiShow, setConfettiShow] = useState(true);
    const isMobile = useResolution(1200);
    const [ratingType, setRatingType] = useState('webinar');
    const {t} = useTranslation();
    const isAuth = useSelector(state => state.auth.authenticated)


    useEffect(() => {
        if(localStorage.hasOwnProperty('headerState') && !localStorage.getItem('headerState')){
            localStorage.setItem('headerState', 'true')
        }
        getRating(lessonData.id, context.accessToken)
            .then(res => {
                setRating(res.data)
            })
        const timeout = setTimeout(() => setConfettiShow(false), 4000)
        return () => {
            clearTimeout(timeout)
        }
    }, [])

    return (
        <>
            <Confetti
                recycle={isConfettiShow}
                numberOfPieces={400}
                width={window.innerWidth - 20}
                height={window.innerHeight}
            />
            <div className={style.container}>
                {(!isMobile || (isMobile && ratingType === 'all')) && <ServiceRating isHidden={!isAuth}/>}
                <Result lessonData={lessonData} />
                {isMobile && (
                    <div className={style.switch}>
                        <button className={classnames(style.switch__item, {
                            [style.switch__item_active]: ratingType === 'webinar'
                        })}  onClick={() => setRatingType('webinar')}>{t('webinar.rating')}<BlackRatingIcon/></button>
                        <button className={classnames(style.switch__item, {
                            [style.switch__item_active]: ratingType === 'all'
                        })} onClick={() => setRatingType('all')}>{t('webinar.top')}</button>
                    </div>
                )}
                {(!isMobile || (isMobile && ratingType === 'webinar')) && <WebinarRating students={rating}/>}
            </div>
        </>
    )
}

export default React.memo(FinishLobby, (prevProps, nextProps) => {
    return prevProps.lessonData.id === nextProps.lessonData.id
});
