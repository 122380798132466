import React, { useEffect, useState } from 'react';
import './mainSlider.scss';
import './mainSliderMobile.scss';
import MainSlide from './MainSlide';
import { ButtonBack, ButtonNext, CarouselProvider, Dot, Slide, Slider } from 'pure-react-carousel';
import { useHttp } from 'hooks/http.hook';
import { useSelector } from 'react-redux';
import { getAdvertisingCoursesUrl } from 'services/api';

export default function MainSlider() {
  const [slidesCountOnWindow] = useState(1);
  const [totalSlides, setTotalSlides] = useState(1);
  const [data, setData] = useState({ advertised_courses: [{}] });
  const lang = useSelector((state) => state.settings.lang);
  const { request, loading } = useHttp();

  const loadSlides = async () => {
    try {
      let res = await request(getAdvertisingCoursesUrl(lang), 'get');
      setTotalSlides(res.data.advertised_courses.length);
      setData(res.data);
    } catch (e) {
      setData({ advertised_courses: [] });
      setTotalSlides(0);
      // showErrorMessage(t('errors.getError'));
      console.log(e);
    }
  };

  useEffect(() => {
    lang && loadSlides();
  }, [lang]);

  const dotsToRender = () => {
    let dotNumbers = Math.ceil(totalSlides / slidesCountOnWindow);
    let dotsArray = [];
    for (let i = 0; i < dotNumbers; i++) {
      dotsArray.push(
        <Dot slide={i * slidesCountOnWindow} key={`dotmain${i}`}>
          <span className='slider-position' />
        </Dot>,
      );
    }

    return dotsArray;
  };

  return (
    <section className='main-slider'>
      <div className='main-slider__container'>
        <div className='main-slider__list'>
          <CarouselProvider
            naturalSlideWidth={100}
            naturalSlideHeight={30}
            totalSlides={totalSlides}
            visibleSlides={slidesCountOnWindow}
            infinite={true}
            isIntrinsicHeight={true}
            lockOnWindowScroll={true}
            interval={5000}
            isPlaying={true}
          >
            <Slider>
              {data.advertised_courses.map((el, index) => (
                <Slide className='main-slider__slide' index={index} key={`main${index}`}>
                  <MainSlide {...el} index={index} loading={loading} />
                </Slide>
              ))}
            </Slider>
            {totalSlides > 1 && (
              <div className='main-slider__options'>
                <ButtonBack>
                  <span className='slider-button slider-button--left' />
                </ButtonBack>
                <div className='slider-position-container'>{dotsToRender().map((el) => el)}</div>
                <ButtonNext>
                  <span className='slider-button slider-button--right' />
                </ButtonNext>
              </div>
            )}
          </CarouselProvider>
        </div>
      </div>
    </section>
  );
}
