import React from 'react';
import style from './style.module.scss';
import Cart from '../../../Cart';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { PERIODS } from '../../../../../../../constants/periods';

export default function TopHeader({ days, setDays }) {
  const { t } = useTranslation();
  return (
    <Cart classname={style.top__header}>
      <button
        onClick={() => {
          setDays(1);
        }}
        className={classnames(style.top__button, {
          [style.top__button_active]: days === PERIODS.DAY,
        })}
      >
        {t('rating.top.today')}
      </button>
      <button
        onClick={() => setDays(7)}
        className={classnames(style.top__button, {
          [style.top__button_active]: days === PERIODS.WEEK,
        })}
      >
        {t('rating.top.week')}
      </button>
      <button
        onClick={() => setDays(new Date().getDate())}
        className={classnames(style.top__button, {
          [style.top__button_active]: days === PERIODS.MONTH,
        })}
      >
        {t('rating.top.month')}
      </button>
      <button
        onClick={() => setDays()}
        className={classnames(style.top__button, {
          [style.top__button_active]: days === PERIODS.ALL,
        })}
      >
        {t('rating.top.all_time')}
      </button>
    </Cart>
  );
}
