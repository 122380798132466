import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useOnClickOutside} from "../../../hooks/outsideClick.hook";

function ModalWindow(props) {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  const {hidden} = props

  const handleClose = useCallback(() => {
    setOpen(false);
    window.document.querySelector('body').classList.remove('scroll-hidden');
    window.document.querySelector('html').classList.remove('scroll-hidden');
  }, []);

  const handleOpen = useCallback(() => {
    setOpen(true);
    window.document.querySelector('body').classList.add('scroll-hidden');
    window.document.querySelector('html').classList.add('scroll-hidden');
  }, []);

  useOnClickOutside(ref, handleOpen)

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        handleClose();
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  useEffect(() => {
    if(open) {
      window.document.querySelector('body').classList.add('scroll-hidden');
    } else {
      window.document.querySelector('body').classList.remove('scroll-hidden');
    }
  }, [open]);

  return (
    <>
      <props.ButtonComponent  {...props} onClick={handleOpen} mode={props.mode}/>
      {open &&
        <div className='overlay' onClick={()=>{!hidden && handleClose()}}>
          <div className='overlay__wrapper'>
            <props.WindowComponent ref={ref} {...props} handleClose={handleClose}/>
          </div>
        </div>
      }
    </>
  );
}


export default ModalWindow;