import React from 'react';
import style from './style.module.scss';

export default function Item({rating, index}) {
  const name = `${rating.name || ''} ${rating.surname || ''} ${
    rating.username || ''
  }`;
  return (
    <div className={style.item}>
      <span className={style.item__text}>{index + 1}</span>
      <span className={style.item__text}>{name === '  ' ? 'null' : name}</span>
      <span className={style.item__text}>{rating.count}</span>
    </div>
  );
}
