import React from "react";
import {Switch, Route} from "react-router-dom"
import Schedule from "./Schedule";
import Main from "./Main";
import Training from "./Training";
import style from "./style.module.scss"

const ABClass = () => {
    return (
        <div className={style.container}>
            <Switch>
                <Route exact path="/abclass" component={Main}/>
                <Route path="/abclass/training" component={Training}/>
                <Route path="/abclass/schedule" component={Schedule}/>
            </Switch>
        </div>
    )
}

export default ABClass;