import React from 'react';
import style from './style.module.scss';
import { ReactComponent as StarIcon } from '../../svg/star.svg';
import { ReactComponent as ListIcon } from '../../svg/list.svg';
import { ReactComponent as ChartIcon } from '../../svg/chart.svg';
import { ReactComponent as KnightIcon } from '../../svg/knight.svg';
import { ReactComponent as PieChartIcon } from '../../svg/pie_chart.svg';
import { ReactComponent as CupIcon } from '../../svg/cup.svg';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Header() {
  const { t } = useTranslation();
  const links = [
    {
      icon: <StarIcon />,
      text: t('rating.header.rating'),
      path: '/rating',
      exact: true,
    },
    {
      icon: <ListIcon />,
      text: t('rating.header.top'),
      path: '/rating/top',
    },
    {
      icon: <PieChartIcon />,
      text: t('rating.header.exercises'),
      path: '/rating/exercises',
    },
    {
      icon: <ChartIcon />,
      text: t('rating.header.cognitive_skills'),
      path: '/rating/cognitive_skills',
    },
    {
      icon: <KnightIcon />,
      text: t('rating.header.chess_knowledge'),
      path: '/rating/chess_knowledge',
    },
    {
      icon: <CupIcon />,
      text: t('rating.header.achievements'),
      path: '/rating/achievements',
    },
  ];

  return (
    <div className={style.header}>
      <p className={style.header__title}>{t('rating.rating')}</p>
      <div className={style.header__links}>
        {links.map((link) => (
          <NavLink
            key={link.text}
            exact={link.exact}
            to={link.path}
            className={style.link}
            activeClassName={style.link_active}
          >
            {link.icon}
            <span className={style.link__title}>{link.text}</span>
          </NavLink>
        ))}
      </div>
    </div>
  );
}
