import React from 'react';
import SubscriptionItem from "./SubscriptionItem";
import './subscriptionList.scss';
import './subscriptionListMobile.scss'
import {useTranslation} from "react-i18next";

export default function SubscriptionList({data = [], title, onItemClick}) {
  const {t} = useTranslation();

  return (
    <>
      <h2 className='subscription__item-title'>{title} {t('subscription.subscription')}</h2>
      <ul className='subscription__list'>
        {data.map(el => <SubscriptionItem key={el.id} {...el} onItemClick={onItemClick}/>)}
      </ul>
    </>
  )
}