import ru from "date-fns/locale/ru";
import en from "date-fns/locale/en-GB";
import kz from "date-fns/locale/kk";
import es from "date-fns/locale/es";
import tr from "date-fns/locale/tr";

export const getDatePickerLocale = () => {
    switch (localStorage.getItem('i18nextLng')) {
        case "ru":
            return ru;
        case "en":
           return en;
        case "kz":
           return kz;
        case "es":
            return es;
        case "tr":
            return tr;
        default :
            return ru;
    }
}