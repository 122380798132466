export const copyText = (str) => {
    return new Promise(function(resolve, reject) {
        let success = false;
        function listener(e) {
            e.clipboardData.setData("text/plain", str);
            e.preventDefault();
            success = true;
        }
        document.addEventListener("copy", listener);
        document.execCommand("copy");
        document.removeEventListener("copy", listener);
        if (success) resolve()
        else reject();
    });

}