import React from "react";
import {useTranslation} from "react-i18next";
import style from "./style.module.scss";

const BanPopup = ({closeModal}) => {
    const {t} = useTranslation();

    return (
        <div className={style.container}>
            <p className={style.message}>{t('webinar.youBanned')}</p>
            <button className={style.link} onClick={closeModal}>{t('webinar.goOut')}</button>
        </div>
    )
}

export default BanPopup;