import React from 'react';
import ApproveMessage from "./ApproveMessage";
import {useTranslation} from "react-i18next";

function LoginNavigation({ setPopupMode, closeModal }) {
  const {t} = useTranslation();

  return (
    <>
      <div className='login__navigation'>
        <button
          className='login__navigation-link login__navigation-link--big'
          type='button'
          onClick={() => setPopupMode('registration')}
        >
          {t('auth.registrationByEmail')}
        </button>
        <span className='login__navigation-wrapper'>
          {t('auth.alreadyHave')}
          <button
            className='login__navigation-link'
            type='button'
            onClick={() => setPopupMode('default')}
          >
            {t('auth.login')}
          </button>
        </span>
      </div>
      <ApproveMessage closeModal={closeModal} />
    </>
  );
}

export default LoginNavigation;