import React from 'react';
import style from './style.module.scss';
import classnames from 'classnames';

export default function Course({course, setCourseId, courseId, setMainCourseId}) {
  return (
    <div key={course.id}>
      <button
        onClick={() => {
          setCourseId(course.id);
          setMainCourseId(course.id);
        }}
        className={classnames(
          style['course__button'],
          style['course__title'],
          {
            [style['course__button_active']]: courseId === course.id,
          },
        )}
      >
        {course.title}
      </button>
    </div>
  );
}
