import React, { useMemo } from "react";
import style from "./style.module.scss";
import { CaretIcon, CopyIcon, EmptyBanner } from "../../../../../assets/img/partners";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { convertDateFromLocale } from "../../../../../utils/convertDateFromLocale";
import useResolution from "hooks/useResolution";

const WebinarCard = ({ webinar, webinarType }) => {
    const { t } = useTranslation();
    const isMobile = useResolution(540)

    const date = useMemo(() => {
        return convertDateFromLocale(webinar.begin_date)
    }, [])

    const month = t(
      `months.${moment(date)
        .locale('en')
        .format('LL')
        .match(/\b\D+\b/)[0]
        .trim()
        .toLowerCase()}`
    );

    return (
        <div className={style.webinar}>
            <div className={style['webinar-header']}>
                {
                    webinar.subject ? (
                        <>
                            <div className={style['webinar-header-level']}>
                                <div className={style['webinar-header-level__id']} style={{ background: `#${webinar.subject.level.color}` }}>{webinar.subject.level.name}</div>
                                <div className={style['webinar-header-level__dot']}></div>
                                <p>{webinar.subject.level.title}</p>
                            </div>
                            {!isMobile && <div className={style['webinar-header-lesson']}>
                                <p className={style['webinar-header-lesson__index']}>{webinar.subject.name}</p>
                                <div className={style['webinar-header-lesson__line']}></div>
                                <p className={style['webinar-header-lesson__name']}>{webinar.subject.title}</p>
                            </div>}
                        </>
                    )
                        : <p className={style['webinar-header__noLevel']}>{webinar.title}</p>
                }
            </div>

            <div className={style['webinar-info']}>
                <div>
                    <a target="_blank" href={webinar.banner_link} className={style['webinar-info__photo']}>
                        {
                            webinar.banner
                                ? <img src={`${process.env.REACT_APP_API_URL}/static/imgs/${webinar.banner}`} alt="banner" />
                                : <EmptyBanner />
                        }
                    </a>
                </div>
                <div className={style['webinar-info__middle']}>
                    <div className={style['webinar-info__teacher']}>
                        {isMobile && <div className={style['webinar-header-lesson']}>
                            <p className={style['webinar-header-lesson__index']}>{webinar.subject?.name}</p>
                            <p className={style['webinar-header-lesson__name']}>{webinar.subject?.title}</p>
                        </div>}
                        {isMobile && <div className={style['webinar-info-date']}>
                            <p className={style['webinar-info-date__day']}>
                                {date.format('DD')}
                            </p>
                            <p className={style['webinar-info-date__month']}>
                                {month}
                            </p>
                            <p className={style['webinar-info-date__time']}>
                                {date.format('LT')}
                            </p>
                        </div>}
                        <p className={style['webinar-info__teacher-lector']}>{t('abclass.teacher')}:</p>
                        <p className={style['webinar-info__teacher-name']}>{webinar.teacher.name}</p>
                    </div>
                    <div className={style['webinar-info__buttons']}>
                        {webinarType !== 'current' && <a target="_blank" href={webinar.theme_link} type="button" className={classnames(style['webinar-info__button'], style['webinar-info__button_outline'])}>{t('webinar.hint')}</a>}
                        {webinarType !== 'passing' && <Link to={`/webinar/${webinar.id}`} type="button" className={classnames(style['webinar-info__button'], style['webinar-info__button_green'])}>{t('main.logIn')}</Link>}
                        {webinarType === 'passing' && <Link to={`/webinar-end/${webinar.id}`} type="button" className={classnames(style['webinar-info__button'], style['webinar-info__button_red'])}>{t('webinar.results')}</Link>}
                    </div>

                </div>
                {!isMobile && <div className={style['webinar-info-date']}>
                    <p className={style['webinar-info-date__day']}>
                        {date.format('DD')}
                    </p>
                    <p className={style['webinar-info-date__month']}>
                        {month}
                    </p>
                    <p className={style['webinar-info-date__time']}>
                        {date.format('LT')}
                    </p>
                </div>}
            </div>
        </div>
    )
}

export default WebinarCard;
