import React from 'react';
import {Link} from 'react-router-dom';
import {getHighlightedText} from 'helpers/textHelpers';
import './searchCourse.scss';

export default function SearchCourseItem({ id, title, image, course_author, hideResults, search }) {
  return (
    <Link className='search__course-item search-course' to={`/course/${id}`} onClick={hideResults}>
      <img src={image || ''} className='search-course__image'/>
      <div className='search-course__container'>
        <p className='search-course__title'>{getHighlightedText(title, search)}</p>
        {course_author && <p className='search-course__text'>{getHighlightedText(course_author, search)}</p>}
      </div>
    </Link>
  )
}