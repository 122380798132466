import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import CourseImageBanner from "../../common/CourseImageBanner";

function StudySliderItem({latest_visited_course = {}, latest_visited_exercise = {}}) {
  const {t} = useTranslation();

  const {id, title, image, course_author, number_of_lessons, number_of_exercises, type_subscription, is_free} = latest_visited_course
  const {lesson_id } = latest_visited_exercise

  if(!title && !id) {
    return (
      <div className='study-slider__card study-slider__card--loading'>
        <span className='study-slider__card-image'/>
        <div className='study-slider__card-info'>
          <p className='study-slider__card-title'/>
          <p className='study-slider__card-name'/>
          <p className='study-slider__card-count'/>
        </div>
      </div>
    )
  }

  return (
    <Link className='study-slider__card' to={lesson_id ? `/course/${id}/lesson/${lesson_id}?exercise=${latest_visited_exercise.id}` : `/course/${id}`}>
      <CourseImageBanner
        image={image}
        is_free={is_free}
        type_subscription={type_subscription}
        imageClasses={'study-slider__card-image'}
      />
      <div className='study-slider__card-info'>
        <p className='study-slider__card-title'>{course_author}</p>
        <p className='study-slider__card-name'>{title}</p>
        <p className='study-slider__card-count'>{number_of_lessons} {t('courses.lessons')} / {number_of_exercises} {t('courses.exercises')}</p>
      </div>
    </Link>
  );
}

StudySliderItem.propTypes = {
  title: PropTypes.string,
  id: PropTypes.number,
  course_author: PropTypes.string,
  number_of_lessons: PropTypes.number,
  number_of_exercises: PropTypes.number,
  image: PropTypes.string
};

export default StudySliderItem;
