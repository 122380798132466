import React from 'react';
import style from './style.module.scss';
import Header from './components/Header';

export default function Rating({ children }) {
  return (
    <div className={style.rating_container}>
      <Header />
      <div className={style.container}>
        <div className={style.container__component}> {children}</div>
      </div>
    </div>
  );
}
