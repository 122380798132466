import {FETCH_PROFILE_ERROR, FETCH_PROFILE_START, FETCH_PROFILE_SUCCESS, UNAUTHENTICATED} from '../actions/types';
export default function(state = { profileData: {}, loading: false }, action) {
  switch(action.type) {
    case FETCH_PROFILE_SUCCESS:
      return { ...state, profileData: action.payload, loading: false };
    case FETCH_PROFILE_ERROR:
      return { ...state, error: action.payload, loading: false };
    case FETCH_PROFILE_START:
      return { ...state, loading: true };
    case UNAUTHENTICATED:
      return { ...state, profileData: {}, loading: false };
  }
  return state;
}