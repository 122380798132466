import React, {useCallback, useContext, useEffect, useState} from "react";
import style from "./style.module.scss";
import {useTranslation} from "react-i18next";
import {convertDateFromLocale} from "../../../../../../utils/convertDateFromLocale";
import {withZero} from "../../../../../../utils/withZero";
import {WebinarContext} from "../../../../../../constants/contexts";
import * as Sentry from '@sentry/react'


const TimeCountdown = ({setStart, beginDate}) => {
    const {t} = useTranslation();
    const {context} = useContext(WebinarContext);

    const [time, setTime] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });

    const getTimeParts = useCallback((date) => {
        const current = convertDateFromLocale(date);
        const days = beginDate.diff(current, 'days');
        current.add(days, 'days');
        const hours = beginDate.diff(current, 'hours');
        current.add(hours, 'hours');
        const minutes = beginDate.diff(current, 'minutes');
        current.add(minutes, 'minutes');
        const seconds = beginDate.diff(current, 'seconds');
        current.add(seconds, 'seconds');

        Sentry.captureMessage({
            date,
            beginDate
        }, "log")

        setTime(prev => {return {...prev, days, hours, minutes, seconds}})

        if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
            setStart(true)
        }

    }, [beginDate])



    useEffect(() => {
        context.socket.emit('start', {})
        context.socket.on('start', date => getTimeParts(date))
        return () => {
            context.socket.off('start', date => getTimeParts(date))
        }
    }, [])


    return (
        <div className={style.countdown}>
            <p className={style.countdown__title}>{t('webinar.webinarStart')}</p>
            <div className={style['countdown-time__wrapper']}>
                <div className={style['countdown-time__part']}>
                    <div className={style['countdown-time__part__item']}>
                       <p>{withZero(time.days)}</p>
                    </div>
                    <p className={style['countdown-time__part__name']}>{t('webinar.days')}</p>
                </div>
                <div className={style['countdown-time__separator']}>:</div>
                <div className={style['countdown-time__part']}>
                    <div className={style['countdown-time__part__item']}>
                        <p>{withZero(time.hours)}</p>
                    </div>
                    <p className={style['countdown-time__part__name']}>{t('webinar.hours')}</p>
                </div>
                <div className={style['countdown-time__separator']}>:</div>
                <div className={style['countdown-time__part']}>
                    <div className={style['countdown-time__part__item']}>
                        <p>{withZero(time.minutes)}</p>
                    </div>
                    <p className={style['countdown-time__part__name']}>{t('webinar.minutes')}</p>
                </div>
                <div className={style['countdown-time__separator']}>:</div>
                <div className={style['countdown-time__part']}>
                    <div className={style['countdown-time__part__item']}>
                        <p>{withZero(time.seconds)}</p>
                    </div>
                    <p className={style['countdown-time__part__name']}>{t('webinar.seconds')}</p>
                </div>
            </div>
        </div>
    )
}

export default React.memo(TimeCountdown);