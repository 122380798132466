import React, { useEffect, useState } from 'react';
import style from './style.module.scss';
import { getRatings } from '../../../../../../services/api';
import TopHeader from './TopHeader';
import TopList from './TopList';
import Rating from '../../../index';

export default function Top() {
  const [data, setData] = useState([]);
  const [topLoading, setTopLoading] = useState(true);
  const [days, setDays] = useState(1);

  useEffect(() => {
    setTopLoading(true);
    getRatings(days).then((res) => {
      setData(res.data.slice(0, 10));
      setTopLoading(false);
    });
  }, [days]);


  return (
    <Rating>
      <div className={style.top}>
        <TopHeader days={days} setDays={setDays} />
        <TopList ratings={data} loading={topLoading} />
      </div>
    </Rating>
  );
}
