import React from 'react';
import style from './style.module.scss';
import ProgressBar from '../ProgressBar';

export default function ProgressbarContainer({label, width, progressColor}) {
  return (
    <div className={style.container}>
      <span className={style.container__text}>{label}</span>
      <ProgressBar
        width={width}
        fontSize={'16px'}
        progressColor={progressColor}
      />
    </div>
  );
}
