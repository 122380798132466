import s from './WithoutRegistration.module.scss';

import React, { useContext, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import { useTranslation } from "react-i18next";
import { io } from 'socket.io-client';

import HidePass from "constants/SVG/HidePass";
import ShowPass from "constants/SVG/ShowPass";
import Dice from 'constants/SVG/Dice';
import { openOverlayAction } from "actions/overlay";
import {
  temporaryAuth,
  webinarSocketUrl,
  generateUsername,
  trainingWebinarSocketUrl,
} from 'services/api';
import { WebinarContext } from "constants/contexts";
import useResolution from 'hooks/useResolution';
import {
  CLEAR_AUTH_ERROR,
  SET_TEMPORARY_AUTH,

} from 'actions/types';
import { TwitchIcon, YoutubeIcon, ZoomIcon } from "assets/img/social_icons";
import Title from 'components/common/Title/Title';

import InputError from '../input-error';
import Login from '../../../Login/index'

const countOfElems = 3;
const listOfAnimals = [{ animal: 'Жираф', sex: 'ый' }, { animal: 'Слон', sex: 'ый' }, { animal: 'Тигр', sex: 'ый' }, { animal: 'Зебра', sex: 'ая' }]
const listOfAdj = ['Красив', 'Добр', 'Быстр', 'Хитр', 'Умн']

const generateName = () => {
  const l = listOfAnimals.length - 1
  const la = listOfAdj.length - 1
  const animal = listOfAnimals[Math.floor(Math.random() * (l - 0 + 1)) + 0]
  const adj = listOfAdj[Math.floor(Math.random() * (la - 0 + 1)) + 0]

  return `${adj}${animal?.sex} ${animal?.animal}`
}

const InputField = ({ value, setValue, label = '', type = 'text', required = false, password = false }) => {

  const [showPass, setShowPass] = React.useState(false);

  const handlerShowPass = React.useCallback(() => {
    setShowPass(!showPass)
  }, [showPass])

  const handlerChange = (e) => {
    e.preventDefault();
    setValue(e.currentTarget.value)
  }
  return (
    <div className={s['input-wrapper']}>
      <input className={s['input-wrapper__input']} type={password ? showPass ? 'text' : 'password' : type} value={value} onChange={handlerChange} placeholder={label} />
      {/* {required && <span className={s['input-wrapper__required']}>*</span>} */}
      {password &&
        <button className={s['input-wrapper__pass-btn']} onClick={handlerShowPass} type='button'>
          {showPass ? <HidePass /> : <ShowPass />}
        </button>}
    </div>
  )
}

const Button = ({ onClick = () => console.log('clicked'), type = 'fulled', text = '', className }) => {

  return (
    <button onClick={onClick} className={`${s['button']} ${s[type]} ${className}`}>
      {text}
    </button>
  )
}

const Line = () => {
  return (
    <div className={s['line']}></div>
  )
}

function WithoutRegistration({
  is_training,
  session_hash,
  temporary_auth,
  note,
  auth,
  webinar_id,
  auth_error,
  zoomLink,
  webinarPassword
}) {
  const [name, setName] = React.useState('');
  const [password, setPassword] = React.useState(webinarPassword);
  const { context, setContext } = useContext(WebinarContext);
  const dispatch = useDispatch();
  const not_desktop = useResolution(1280);
  const isMobile = useResolution(768);

  const [isLoading, setIsLoading] = React.useState(false);

  const { t } = useTranslation();

  const getUsername = async () => {
    let username;
    try {
      username = await generateUsername();
    }
    catch (err) {
      return generateName();
    }
    if (username) {
      return username;
    }

    return generateName();
  }

  const onGenerateClick = (auto) => {
    if (auto !== true) {
      dispatch({
        type: CLEAR_AUTH_ERROR
      });
    }
    const btn = document.getElementById('btn-dice').firstChild;
    btn.classList.add(s['cube_rotate']);
    return getUsername()
      .then(name => {
        setName(name);
        btn.classList.remove(s['cube_rotate']);
      });
  }

  const onRegisterClickHandler = (e) => {
    e.preventDefault()
    dispatch(openOverlayAction(Login, {}, true, false))
  }

  const onWithoutAuthHandler = (e) => {
    e.preventDefault();
    if (!isLoading) {
      setIsLoading(true);
      dispatch(
        temporaryAuth(name, session_hash, password, webinar_id, () => {
          setIsLoading(false);
        })
      );
    }
  }

  const onOpenHrefHandler = (e, link) => {
    e.preventDefault()
    window.open(link, "_blank")
  }

  useEffect(() => {
    if (auth_error) {
      dispatch({
        type: CLEAR_AUTH_ERROR
      });
    }
  }, [password, name]);

  useEffect(() => {
    setPassword(webinarPassword);
  }, [webinarPassword])

  useEffect(() => {

    if (temporary_auth && temporary_auth.hasOwnProperty('session_token')) {
      if (
        (!temporary_auth.webinar_id || !webinar_id) ||
        (temporary_auth.webinar_id.toString() !== webinar_id.toString())
      ) {
        return;
      }

      const socketEndpoint = is_training
        ? trainingWebinarSocketUrl
        : webinarSocketUrl;

      setContext({
        ...context,
        accessToken: temporary_auth.session_token,
        hash: session_hash || temporary_auth.session_hash,
        status: temporary_auth.class_status,
        socket: io(socketEndpoint(session_hash), {
          extraHeaders: {
            "Class-Session": temporary_auth.session_token
          }
        })
      });
    }
  }, [temporary_auth]);

  useEffect(() => {
    if (auth) {
      return;
    }
    getPreviousSession();
  }, []);

  function getPreviousSession() {
    const session_token = localStorage.getItem('webinar-session-token');
    const session_hash = localStorage.getItem('webinar-session-hash');
    const class_status = localStorage.getItem('webinar-class-status');
    const name = localStorage.getItem('webinar-name');
    const webinarId = localStorage.getItem('webinar-id');
    
    if (webinarId !== webinar_id?.toString()) {
      return;
    }
    if (name) {
      setName(name);
    }
    if (session_token) {
      dispatch({
        type: SET_TEMPORARY_AUTH,
        payload: {
          class_status,
          session_token,
          session_hash,
          name,
          webinar_id
        }
      })
    }
  }
  return (
    <div className={s['body']}>
      <section className={s['wrapper']}>
        <div className={s['wrapper__header']}>
          <h1 className={s['wrapper__header-title']}>AB-Class</h1>
        </div>
        <div className={`${s['wrapper__body']} ${zoomLink ? s['wrapper__body--with-padding'] : ''}`}>
          {!isMobile &&
            <div className={s['wrapper__body-motivation']}>
              <p className={s['wrapper__body-motivation__title']}>
                {t('webinar_login.motivation')}
              </p>
              <ul className={s['wrapper__body-motivation__list']}>
                <li className={s['wrapper__body-motivation__list__marker-car']}>{t('webinar_login.motivation_list_1')}</li>
                <li className={s['wrapper__body-motivation__list__marker-trophy']}>{t('webinar_login.motivation_list_2')}</li>
                <li className={s['wrapper__body-motivation__list__marker-smile']}>{t('webinar_login.motivation_list_3')}</li>
              </ul>

              <p className={s['wrapper__body-motivation__paragraph']}>
                {t('webinar_login.motivation_paragraph')}
              </p>
            </div>
          }
          <div className={s['wrapper__body-register']}>
            {zoomLink && //zoomlink
              <>
                <Title number={1} text={t('webinar.connect2Stream')} />
                <div className={s['stream-links-row']}>
                  <div className={s['icons-row']}>
                    <ZoomIcon />
                    <YoutubeIcon />
                    <TwitchIcon />
                  </div>
                  <div className={s['button-wrapper']}>
                    <Button className={s['connect-button']} onClick={(e) => { onOpenHrefHandler(e, zoomLink) }} text={t('webinar.connectToZoom')} />
                  </div>
                </div>
                <Line />
                <Title number={2} text={t('webinar.enter2Webinar')} />
              </>
            }
            <div className={zoomLink && s['wrapper__body-register--padding']}>
              <div className={`${s['wrapper__body-register-item']} ${(auth_error && typeof auth_error === 'string') && s['u-mb-none']}`} style={{ flex: `0 0 ${100 / countOfElems}%` }}>
                <InputField value={name} label={t('webinar_login.name_placeholder')} setValue={setName} />
                <button id={'btn-dice'} onClick={onGenerateClick} type='button' className={s['dice']}>
                  <Dice />
                </button>
              </div>
              {
                (auth_error && typeof auth_error === 'string') &&
                <InputError
                  message={auth_error}
                />
              }
              <div className={s['wrapper__body-register-item']} style={{ flex: `0 0 ${100 / countOfElems}%` }}>
                <InputField value={password} label={t('webinar_login.pass_placeholder')} setValue={setPassword} password={false} required={true} />
                {/* {true && <span><span className={s['red']}>*</span> Обязательное поле</span>} */}
                {
                  !not_desktop &&
                  <note.Component show={note.condition} />
                }
                {
                  (auth_error && typeof auth_error === 'string') &&
                  auth_error === 'Incorrect password' &&
                  <InputError
                    message={t('webinar_login.incorrect_pass')}
                  />
                }
              </div>

              <div className={`${s['wrapper__body-register__buttons']} ${s['wrapper__body-register-item']}`} style={{ flex: `0 0 ${100 / countOfElems}%` }}>
                <Button disabled style={{ pointerEvents: isLoading ? 'none' : undefined }} className={s['flex49']} text={t('webinar_login.log_in')} onClick={onWithoutAuthHandler} />
                <Button className={s['flex49']} type={'bordered'} text={t('webinar_login.sign_up')} onClick={onRegisterClickHandler} />
              </div>
            </div>
            {
              not_desktop &&
              <note.Component
                show={note.condition}
              />
            }
          </div>
        </div>
      </section>
    </div>
  )
}

export default connect(state => ({
  temporary_auth: state.auth.temporary_auth,
  auth_error: state.auth.error,
  auth: state.auth.authenticated
}), {})(WithoutRegistration);
