export const USER__RANKING = {
  pawn__rookie: {
    level: 'Пешечка-Новичок',
    title: 'Тот, кто только начинает знакомиться с шахматами',
  },
  agile__footman: {
    level: 'Ловкий Пехотинец',
    title: 'Ученик освоивший основные правила и ходы',
  },
  horse__schemer: {
    level: 'Конь-Комбинатор',
    title: 'Ученик освоивший простые комбинации и тактические ходы',
  },
  elephant__hunter: {
    level: 'Слон-Охотник',
    title: 'Шахматист замечающий тактические возможности и ловушки',
  },
  attack__master: {
    level: 'Мастер Атаки',
    title: 'Игрок умело использующий атакующие приемы на короля',
  },
  debut__architect: {
    title: 'Дебютный Архитектор',
    level: 'Шахматист специализируется на изучении и освоении дебютных ходов и стратегий',
  },
  rook__endgamer: {
    level: 'Ладейный Эндшпильдист',
    title: 'Игрок специализирующийся на завершающих стадиях игры и использующих ладью',
  },
  master__strategist: {
    level: 'Искусный стратег',
    title: 'Шахматист понимающий более сложные стратегии и позиции',
  },
  queen__commander: {
    level: 'Ферзь-Командир',
    title: 'Продвинутый ученик уверенно комбинирующий все фигуры и стратегии',
  },
  king__master: {
    level: 'Королевский Мастер',
    title: 'Тот, кто близок к мастерству и готовится к участию в турнирах',
  },
  champion: {
    level: 'Турнирный Чемпион',
    title: 'Опытный шахматист активно играющий и побеждающий в турнирах',
  },
  great__grandmaster: {
    level: 'Великий Гроссмейстер',
    title: 'Высший уровень, предназначенный для самых опытных и талантливых игроков',
  },
}