import React, { useEffect, useMemo, useState } from 'react';
import style from './style.module.scss';
import BigProgressbar from '../../BigProgressbar';
import { getUserStatisticsSkills } from '../../../../../../services/api';
import BottomCart from './BottomCart';
import Rating from '../../../index';
import { divideObjectProperties } from '../../../util/divideObjectProperties';
import { useTranslation } from 'react-i18next';
import ChessKnowledgeModal from '../../modal/ChessKnowledgeModal';

export default function ChessKnowledge() {
  const { t } = useTranslation();
  const [statisticsSkills, setStatisticsSkills] = useState();

  const chessKnowledgeStats = useMemo(() => {
    const ratingSum =
      statisticsSkills?.tactics +
      statisticsSkills?.strategy +
      statisticsSkills?.debut +
      statisticsSkills?.endshpil +
      statisticsSkills?.checkmate;
    return {
      level: Math.floor(ratingSum / 500),
      percentage: Math.floor((ratingSum % 500) / 5),
    };
  }, [statisticsSkills]);

  const chessSkillsStatistics = useMemo(() => {
    return divideObjectProperties(statisticsSkills);
  }, [statisticsSkills]);

  useEffect(() => {
    getUserStatisticsSkills(localStorage.getItem('token')).then((res) => {
      setStatisticsSkills(res.data);
    });
  }, []);

  return (
    <Rating>
      <div className={style.container}>
        <BigProgressbar
          label={t('statistics_skills.chess_knowledge')}
          stats={chessKnowledgeStats}
          modal={ChessKnowledgeModal}
        />
        <BottomCart chessSkillsStatistics={chessSkillsStatistics} />
      </div>
    </Rating>
  );
}
