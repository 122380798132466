import React, { useEffect, useMemo, useRef, useState } from "react";
import style from "./style.module.scss";
import { useTranslation } from "react-i18next";
import { CalendarIcon } from "../../../../../assets/img/abclass";
import classnames from "classnames";
import first from "../../../../../assets/img/abclass/carousel/1.png";
import second from "../../../../../assets/img/abclass/carousel/2.png";
import third from "../../../../../assets/img/abclass/carousel/3.png";
import fourth from "../../../../../assets/img/abclass/carousel/4.png";
import fifth from "../../../../../assets/img/abclass/carousel/5.png";

const Slider = () => {
    const [activeSlide, setActiveSlide] = useState(1);
    const [intervalId, setIntervalId] = useState(null);
    const { t } = useTranslation();
    const img = useRef(null);

    const slides = useMemo(() => [
        { id: 1, text: t('abclass.learnChess'), icon: <CalendarIcon /> },
        { id: 2, text: t('abclass.lessonsPerMonth'), icon: <CalendarIcon /> },
        { id: 3, text: t('abclass.statistic'), icon: <CalendarIcon /> },
        { id: 4, text: t('abclass.certificates'), icon: <CalendarIcon /> },
        { id: 5, text: t('abclass.homework'), icon: <CalendarIcon /> }
    ], []);

    const images = useMemo(() => [second, first, fourth, third, fifth], [])

    useEffect(() => {
        img.current.classList.remove(style.active)
        const classTimeout = setTimeout(() => {
            img.current.classList.add(style.active)
        }, 50)
        let interval = setTimeout(() => {
            if (activeSlide === slides.length) setActiveSlide(1)
            else setActiveSlide(activeSlide + 1)
        }, 5000)
        setIntervalId(interval)
        return () => {
            clearInterval(interval)
            clearInterval(classTimeout)
        }
    }, [activeSlide])


    return (
        <div className={style.container}>
            <div className={style.img}>
                <img
                    ref={img}
                    alt="slide"
                    src={images[activeSlide - 1]}
                />
            </div>
            <div className={style.slides}>
                {
                    slides.map(slide => (
                        <div
                            key={slide.id}
                            className={classnames(style.slides__slide, {
                                [style.slides__slide_active]: activeSlide === slide.id
                            })}
                            onClick={() => {
                                clearInterval(intervalId)
                                setActiveSlide(slide.id)
                            }}
                        >
                            {slide.icon}
                            <p>{slide.text}</p>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Slider;