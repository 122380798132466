import React from 'react';
import {Link} from "react-router-dom";

function HeaderLogo() {
  return (
    <Link to='/'>
      <div className={'header__logo'}/>
    </Link>
  );
}

export default HeaderLogo;