import React from "react";
import { useTranslation } from "react-i18next";

import style from "./style.module.scss";
import lesson from '../../../PartnerPage/Lessons/Lesson';

const Timer = ({ themeLink, lessonData }) => {
  const { t } = useTranslation();
  console.log(lessonData)

  return (
    <div className={style.container}>
      <div className={style.timer}>00:00</div>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={themeLink}
        className={style.theme}
        onClick={e => { if (!themeLink) e.preventDefault() }}
      >
        {t('webinar.hint')}
      </a>
    </div>
  )
}

export default Timer;
