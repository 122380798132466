import React from 'react';

export default function HidePass() {
  return (
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.1388 13.526L10.323 11.7091C9.62082 11.9602 8.86179 12.0067 8.13422 11.8432C7.40665 11.6797 6.74047 11.313 6.21317 10.7857C5.68588 10.2584 5.31916 9.59222 5.15568 8.86465C4.99221 8.13708 5.0387 7.37805 5.28975 6.67587L2.97225 4.35838C1.05525 6.06275 0 8 0 8C0 8 3.375 14.1875 9 14.1875C10.0805 14.1837 11.1487 13.9586 12.1388 13.526ZM5.86125 2.474C6.85131 2.04135 7.91954 1.81622 9 1.8125C14.625 1.8125 18 8 18 8C18 8 16.9436 9.93612 15.0289 11.6427L12.7091 9.323C12.9602 8.62082 13.0067 7.86179 12.8432 7.13422C12.6797 6.40665 12.313 5.74047 11.7857 5.21317C11.2584 4.68588 10.5922 4.31916 9.86465 4.15568C9.13708 3.99221 8.37805 4.0387 7.67587 4.28975L5.86125 2.47512V2.474Z" fill="#C4C4C4"/>
      <path d="M6.21563 7.60166C6.15375 8.03401 6.19341 8.47483 6.33146 8.88919C6.46952 9.30355 6.70219 9.68007 7.01102 9.9889C7.31985 10.2977 7.69636 10.5304 8.11073 10.6685C8.52509 10.8065 8.96591 10.8462 9.39826 10.7843L6.21451 7.60166H6.21563ZM11.7844 8.39816L8.60176 5.21441C9.0341 5.15253 9.47492 5.19219 9.88929 5.33024C10.3036 5.4683 10.6802 5.70097 10.989 6.0098C11.2978 6.31863 11.5305 6.69514 11.6685 7.10951C11.8066 7.52387 11.8463 7.96469 11.7844 8.39704V8.39816Z" fill="#C4C4C4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M15.3517 15.1481L1.85175 1.64806L2.64825 0.851562L16.1482 14.3516L15.3517 15.1481Z" fill="#C4C4C4"/>
    </svg>
  )
}