import React, {} from 'react';
import {useSelector} from "react-redux";
import Loader1 from "../../common/Loader/loader1";
// import FacebookEnterItem from "./FacebookEnterItem";
import GoogleEnterItem from "./GoogleEnterItem";
import VkEnterItem from "./VkEnterItem";
import AppleEnterItem from "./AppleEnterItem";
import {useTranslation} from "react-i18next";

function EntersList({setPartnersMode, closeModal}) {
  const loading = useSelector(state => state.auth.loading);
  const {t} = useTranslation();

  return (
    <>
      {loading && <Loader1/>}
      <ul className='login__enter-list'>
        {/* <li className='login__enter-item'>
          <FacebookEnterItem closeModal={closeModal} />
        </li> */}
        <li className='login__enter-item'>
          <GoogleEnterItem closeModal={closeModal} />
        </li>
        <li className='login__enter-item'>
          <VkEnterItem closeModal={closeModal} />
        </li>
        <li className='login__enter-item'>
          <AppleEnterItem closeModal={closeModal} />
        </li>
        <li className='login__enter-item'>
          <span className='login__enter-button' onClick={setPartnersMode}/>
          <span className='login__enter-logo chess__logo' />
          <span className='login__enter-text'>{t('auth.partnersLogin')}</span>
        </li>
      </ul>
    </>
  );
}

export default EntersList;
