import React from 'react';
import Chess from 'chess.js';
import Chessboard from 'chessboardjsx';
import PieceSelectingPopup from './PieceSelectingPopup';
import Loader3 from '../Loader/loader3';
import {
  checkIsDraw,
  getDepth,
  getDifficultySettingCommands,
  getStylesOfAvailableMoves,
  isPromotion,
  setBoardWidth,
} from '../chessHelperFuntions';
import { BOARD_STYLE, DEFAULT_FEN, DROP_SQUARE_STYLE, TRANSITION_DURATION } from '../ChessConst';
import { useSelector } from 'react-redux';
import { arrayToIncorrectPgnString } from 'helpers/chessHelpers';
import Confetti from 'react-confetti';
import GameEndMessage from './GameEndMessage';
import DrawOverlay from './DrawOverlay';
import { PIECES } from '../../../constants/chessPieces';

class PlayWithBotLogic extends React.Component {
  state = {
    fen: '',
    squareStyles: {},
    pieceSquare: '',
    square: '',
    history: [],
    newHistory: [], //bicycle for ios
    complete: false,
    lastEngineCommand: '',
    undoHistory: [],
    oppositeStep: false,
    turn: '',
    draw: false,
    lose: false,
    win: false,
    promotionPopup: false,
    loading: true,
    attempts: 0,
    // popupOpen: true
  };

  containerRef = React.createRef();
  moveSound = React.createRef();
  correct = React.createRef();
  chessRef = React.createRef();

  constructor(props) {
    super(props);
    // this.state.engine = new Worker('/stockfish11/stockfish.js');
    this.state.engine = window.STOCKFISH ? window.STOCKFISH() : new Worker('/stockfish11/stockfish.js');
    this.state.engine.onmessage = (event) => {
      const data = event.data ? event.data : event;

      this.setState({ lastEngineCommand: data });

      if (data.includes('bestmove')) {
        setTimeout(() => this.botMove(data), 500);
      }

      if (data === 'readyok') {
        if (this.props.type === '960') {
          this.sendUCI('setoption name UCI_Chess960 value true');
        }
        getDifficultySettingCommands(Number(this.props.difficulty)).forEach((el) => {
          this.sendUCI(el);
        });
        if (this.props.firstStep) {
          this.props.orientation[0] !== this.props.firstStep && this.sendPositionToEngine();
        } else if (this.props.orientation === 'black') {
          this.sendPositionToEngine();
        }
        this.setState({ loading: false });
      }
    };
  }

  componentDidMount() {
    const { fen } = this.props;

    this.create();

    if (fen) {
      this.game = new Chess(fen);
      this.setState({ fen: fen });
    } else {
      this.game = new Chess();
    }
    this.setState({ turn: this.game.turn() });
  }

  omGameEnd = () => {
    const { turn, attempts } = this.state;
    const { orientation, successCallback, sendGame } = this.props;

    let completeData = {
      startFen: DEFAULT_FEN,
      endFen: this.game.fen(),
      // pgn: arrayToIncorrectPgnString(history),
      botLevel: this.props.difficulty,
      userWon: 0,
      pgn: arrayToIncorrectPgnString(this.state.newHistory), //bicycle for ios
    };

    if (checkIsDraw(this.game)) {
      this.setState({ draw: true });
    } else if (turn === orientation[0]) {
      this.setState({ lose: true });
    } else {
      this.setState({ win: true });
      completeData.userWon = 1;
      successCallback && successCallback(attempts);
    }

    sendGame && sendGame(completeData);
    this.setState({ complete: true });
  };

  componentDidUpdate() {
    const { complete } = this.state;

    if (this.game.game_over() && !complete) {
      this.omGameEnd();
    }
  }

  sendUCI = (str) => {
    this.state.engine.postMessage(str);
  };

  create = () => {
    this.sendUCI('uci');
    this.sendUCI('ucinewgame');
    this.sendUCI('isready');
    this.sendUCI(`position fen ${this.props.fen}`);
  };

  sendPositionToEngine = () => {
    this.sendUCI(`position fen ${this.game.fen()}`);
    this.sendUCI(
      `go depth ${getDepth(Number(this.props.difficulty))} movetime 800${Number(this.props.difficulty) === 9 ? ' mate 10' : ''}`,
    );
  };

  onPieceSelect = (piece) => {
    if (this.state.complete) return null;
    this.setState({
      promotionPopup: false,
    });

    let move = this.game.move({
      from: this.state.promotionMove.from,
      to: this.state.promotionMove.to,
      promotion: piece,
    });

    this.makeMove(move);
  };

  playMoveSound = (ref) => {
    const { enableSound } = this.props;
    enableSound && ref.current.load();
    enableSound && ref.current && ref.current.play && typeof ref.current.play === 'function' && ref.current.play();
  };

  botMove = (command) => {
    this.game.move(command.split(' ')[1], { sloppy: true });
    this.sendUCI(`position fen ${this.game.fen()}`);
    this.setState(
      (state) => {
        return {
          turn: this.game.turn(),
          fen: this.game.fen(),
          history: this.game.history(),
          newHistory: [...state.newHistory, command.split(' ')[1]], //bicycle for ios
          pieceSquare: '',
          oppositeStep: false,
        };
      },
      () => setTimeout(() => this.playMoveSound(this.moveSound), 340),
    );
  };

  makeMove = (move) => {
    if (move === null) return;
    this.playMoveSound(this.moveSound);
    this.setState((state) => {
      return {
        fen: this.game.fen(),
        history: this.game.history(),
        newHistory: [...state.newHistory, move.from + move.to], //bicycle for ios
        pieceSquare: '',
        turn: this.game.turn(),
        oppositeStep: true,
        squareStyles: {},
        undoHistory: [],
      };
    });
    this.sendPositionToEngine();
  };

  onDrop = ({ sourceSquare, targetSquare }) => {
    console.log('onDrop');
    if (this.state.complete) return null;
    if (isPromotion({ chess: this.game, move: { from: sourceSquare, to: targetSquare } })) {
      this.setState({ promotionPopup: true, promotionMove: { from: sourceSquare, to: targetSquare } });
    } else {
      let move = this.game.move({
        from: sourceSquare,
        to: targetSquare,
      });

      return this.makeMove(move);
    }
  };

  onSquareClick = (square) => {
    console.log('onSquareClick');
    const { showAvailableMoves } = this.props;
    if (this.state.complete) return null;

    let moves = this.game.moves({
      square: square,
      verbose: true,
    });

    if (showAvailableMoves) {
      this.setState({
        pieceSquare: square,
        squareStyles: {
          ...getStylesOfAvailableMoves(moves),
          [square]: { boxShadow: 'inset 0 0 0 2px rgb(57, 91, 93)' },
        },
      });
    } else {
      this.setState({
        pieceSquare: square,
        squareStyles: { [square]: { boxShadow: 'inset 0 0 0 2px rgb(57, 91, 93)' } },
      });
    }

    if (isPromotion({ chess: this.game, move: { from: this.state.pieceSquare, to: square } })) {
      this.setState({ promotionPopup: true, promotionMove: { from: this.state.pieceSquare, to: square } });
    } else {
      let move = this.game.move({
        from: this.state.pieceSquare,
        to: square,
      });

      return this.makeMove(move);
    }
  };

  onResetClick = () => {
    this.game.load(this.props.fen);
    this.setState((state) => {
      return {
        fen: this.game.fen(),
        history: [],
        pieceSquare: '',
        draw: false,
        lose: false,
        complete: false,
        turn: this.game.turn(),
        squareStyles: {},
      };
    });
  };

  onBackClick = () => {
    let undo = this.game.undo();
    if (undo) {
      if (undo.color === this.props.orientation[0]) {
        this.setState((state) => {
          return { undoHistory: undo ? [undo, ...state.undoHistory] : state.undoHistory };
        });
      } else {
        let secondUndo = this.game.undo();
        this.setState((state) => {
          return { undoHistory: undo && secondUndo ? [secondUndo, undo, ...state.undoHistory] : state.undoHistory };
        });
      }
      this.setState({
        fen: this.game.fen(),
        history: this.game.history(),
        pieceSquare: '',
        turn: this.game.turn(),
        squareStyles: {},
      });
    }
  };

  onNextClick = () => {
    const { undoHistory } = this.state;
    if (undoHistory.length > 0) {
      let move = this.game.move(undoHistory[0].san);
      if (move) {
        let arr = [...undoHistory];
        if (move.color !== this.props.orientation[0]) {
          arr.shift();
        } else if (undoHistory[1]) {
          this.game.move(undoHistory[1].san);
          arr.shift();
          arr.shift();
        }
        this.setState({
          fen: this.game.fen(),
          history: this.game.history(),
          pieceSquare: '',
          undoHistory: arr,
          turn: this.game.turn(),
          squareStyles: {},
        });
      }
    }
  };
  //
  // componentWillUnmount() {
  //   this.engine = null;
  //   this.game = null;
  // }

  render() {
    const { fen, squareStyles, turn, draw, lose, win, promotionPopup, oppositeStep, loading } = this.state;
    const { TaskText, orientation, title, chessBoardColor } = this.props;

    if (loading)
      return (
        <div className='chess-display__popup'>
          <Loader3 />
        </div>
      );

    return (
      <>
        {win && <Confetti width={window.innerWidth - 20} height={window.innerHeight} />}
        <div className={'chess-display__chessboard'} ref={this.containerRef}>
          {oppositeStep && <div className='chess-display__description-overlay' />}
          {window.innerWidth > 900 && (
            <DrawOverlay
              ref={this.chessRef}
              squareWidth={setBoardWidth() / 8}
              containerRef={this.containerRef}
              fen={fen}
            />
          )}
          <div ref={this.chessRef}>
            <Chessboard
              id='PlayWithBot'
              orientation={orientation}
              calcWidth={setBoardWidth}
              position={fen}
              onDrop={this.onDrop}
              onSquareClick={this.onSquareClick}
              lightSquareStyle={chessBoardColor?.LIGHT || ''}
              darkSquareStyle={chessBoardColor?.DARK || ''}
              boardStyle={BOARD_STYLE}
              squareStyles={squareStyles}
              dropSquareStyle={DROP_SQUARE_STYLE}
              undo={true}
              transitionDuration={TRANSITION_DURATION}
              pieces={PIECES}
            />
          </div>
        </div>

        <TaskText
          onBackClick={this.onBackClick}
          onNextClick={this.onNextClick}
          onResetClick={this.onResetClick}
          turn={turn}
          title={title}
        />

        {draw && (
          <GameEndMessage
            redirectRoute={'/play'}
            text={'draw'}
            closeCallback={() => {
              this.setState({ draw: false });
            }}
          />
        )}
        {win && (
          <GameEndMessage
            redirectRoute={'/play'}
            text={'win'}
            closeCallback={() => {
              this.setState({ win: false });
            }}
            win={true}
          />
        )}
        {lose && (
          <GameEndMessage
            redirectRoute={'/play'}
            text={'lose'}
            closeCallback={() => {
              this.setState({ lose: false });
            }}
          />
        )}

        {promotionPopup && <PieceSelectingPopup onSelect={this.onPieceSelect} />}
        <audio id='audio' src={require('../../../assets/audio/move.mp3')} ref={this.moveSound} />
        <audio id='audio' src={require('../../../assets/audio/correct.wav')} ref={this.correct} />
      </>
    );
  }
}

export default function PlayWithBot({ startFen, successCallback, TaskText, title, settings, sendGame, firstStep }) {
  const sound = useSelector((state) => state.settings.sound);
  const moves = useSelector((state) => state.settings.moves);
  const chessBoardColor = useSelector((state) => state.settings.chessBoardColor);

  const orientationParser = () => {
    return settings.side === 'w' ? 'white' : 'black';
  };

  return (
    <PlayWithBotLogic
      fen={startFen || 'start'}
      type={settings.type}
      firstStep={firstStep}
      sendGame={sendGame}
      difficulty={settings.difficulty}
      successCallback={successCallback}
      title={title}
      TaskText={TaskText}
      orientation={orientationParser()}
      enableSound={sound}
      showAvailableMoves={moves}
      chessBoardColor={chessBoardColor}
    />
  );
}
