import React from 'react';
import { NavLink } from 'react-router-dom';
import './sectionsNavigation.scss';
import './sectionsNavigationMobile.scss';
import {useTranslation} from "react-i18next";
import SectionNavigationItem from "./SectionNavigationItem";

export default function SectionsNavigation({ data, slug, superId }) {
  const {t} = useTranslation();
  return (
    <nav className='course-section__navigation section-navigation'>
      <ul className='section-navigation__list'>
        <li className='section-navigation__item'>
          <NavLink className='section-navigation__link' exact to={`/courses`}>
            {t('courses.allCourses')}
          </NavLink>
        </li>
        {data.map(el =>
          <SectionNavigationItem {...el} superId={superId} key={el.supercategory_id || el.id} slug={slug}/>
         )}
      </ul>
    </nav>
  );
}