import React, {useEffect, useState} from "react";
import classnames from "classnames";
import style from "./style.module.scss";
import {useTranslation} from "react-i18next";
import plus from "../../../../assets/img/plus.svg";
import trash from "../../../../assets/img/trash.svg";
import reload from "../../../../assets/img/reloadPhoto.svg";

const PhotoField = ({field, form: {setFieldValue}}) => {
    const {t} = useTranslation();
    const [imageLink, setImageLink] = useState(
        field.value
            ? typeof field.value === 'string' ? field.value : URL.createObjectURL(field.value)
            : ''
    );

    useEffect(() => {
        if (field.value && !imageLink) {
            setImageLink(field.value)
        }
        return () => {
            URL.revokeObjectURL(imageLink)
        }
    }, [field.value])

    return (
        <div className={classnames(style.photo_container)}>
            {
                !field.value
                    ? <label className={style.photo_empty} htmlFor={`${field.name}_file`}>
                        <div className={style.photo_empty__icon}><img src={plus} alt="plus"/></div>
                        <p>{t('partners.addBanner')}</p>
                      </label>
                    : <>
                        {imageLink && <img className={style.photo} src={imageLink} alt="photo"/>}
                        <label
                            className={classnames(style.photo_button, style['photo_button-green'])}
                            htmlFor={`${field.name}_file`}
                        >
                            <img src={reload} alt="reload"/>
                        </label>
                        <button
                            type="button"
                            className={classnames(style.photo_button, style['photo_button-red'])}
                            onClick={() => setFieldValue(field.name, '')}
                        >
                            <img src={trash} alt="trash"/>
                        </button>
                      </>
            }
            <input
                type="file"
                className={style.photo_hiddenInput}
                id={`${field.name}_file`}
                onChange={e => {
                    setImageLink(URL.createObjectURL(e.target.files[0]));
                    setFieldValue(field.name, e.target.files[0]);
                }}
            />
        </div>
    )
}

export default React.memo(PhotoField, (prevProps, nextProps) => {
    return prevProps.field.value === nextProps.field.value
});