import {applyMiddleware, combineReducers, createStore} from 'redux';
import thunk from 'redux-thunk';
import authReducer from './reducers/auth';
import registrationReducer from './reducers/registration';
import coursesReducer from './reducers/courses';
import profileReducer from './reducers/profile';
import categoryReducer from './reducers/category';
import settingsReducer from './reducers/settings';
import completedExercisesReducer from './reducers/completedExercises';
import webinarReducer from './reducers/webinar';
import advertisingReducer from './reducers/advertising';
import {overlay} from './reducers/overlay';
import logger from 'redux-logger';

const rootReducer = combineReducers({
  auth: authReducer,
  advertising: advertisingReducer,
  registration: registrationReducer,
  courses: coursesReducer,
  profile: profileReducer,
  completedExercises: completedExercisesReducer,
  category: categoryReducer,
  settings: settingsReducer,
  webinar: webinarReducer,
  overlay,
});

const middleware = [thunk];

if (process.env.NODE_ENV === 'development') middleware.push(logger);

const store = createStore(rootReducer, applyMiddleware(...middleware));

export default store;
