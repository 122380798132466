import React from 'react';
import Portal from './portal';
import style from './modal.module.scss';

export default function Modal({
  children,
  opened,
  close
}) {
  const wrapper_id = 'confirm_modal_wrapper';
  if (!opened) {
    return <></>
  }

  const onClick = (e) => {
    const target_id = e.target.getAttribute('data-id');

    if (target_id === wrapper_id) {
      if (typeof close === 'function') {
        close();
      }
    }    
  }

  return (
    <Portal>
      <div
      data-id = { wrapper_id }
      onClick = { onClick }
      className = { style.modal_container }>
        <div className = { style.modal }>
          <div className = { style.modal_body }>
            {
              children
            }
          </div>
        </div>
      </div>
    </Portal>
  )
}