import React from 'react';
import { Link } from 'react-router-dom';
import './sectionsNavigation.scss';
import './sectionsNavigationMobile.scss';
import SectionNavigationItemMobile from "./SectionNavigationItemMobile";

export default function SectionsNavigationMobile({ data, handleOpen }) {
  return (
    <nav className={`course-section__navigation section-navigation section-navigation--mobile`}>
      <ul className='section-navigation__list'>
        <li className='section-navigation__item'>
          <Link className='section-navigation__link' onClick={handleOpen} to={`/courses`}>Все курсы</Link>
        </li>
        {data.map(el =>
          <SectionNavigationItemMobile {...el} handleOpen={handleOpen}/>
        )}
      </ul>
      <button className={'section-navigation__close close__logo'} onClick={handleOpen} type={'button'}/>
    </nav>
  );
}