import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CourseImageBanner from '../../common/CourseImageBanner';

function PublicSlide({
  id,
  slug,
  image,
  title,
  number_of_lessons,
  number_of_exercises,
  course_author,
  is_free,
  type_subscription,
}) {
  const { t } = useTranslation();

  if (!title && !id) {
    return (
      <div className='public-slider__card public-slider__card--loading'>
        <span className='public-slider__card-image' />
        <div className='public-slider__card-info'>
          <p className='public-slider__card-title' />
          <p className='public-slider__card-count' />
        </div>
      </div>
    );
  }

  return (
    <Link className='public-slider__card' to={`/course/${id}`}>
      <CourseImageBanner
        image={image}
        is_free={is_free}
        type_subscription={type_subscription}
        imageClasses={'public-slider__card-image'}
      />
      <div className='public-slider__card-info'>
        <p className='public-slider__card-title'>{title}</p>
        {course_author && <p className='public-slider__card-name'>{course_author}</p>}
        <p className='public-slider__card-count'>
          {number_of_lessons} {t('courses.lessons')} / {number_of_exercises} {t('courses.exercises')}
        </p>
      </div>
    </Link>
  );
}

PublicSlide.propTypes = {
  title: PropTypes.string,
  id: PropTypes.number,
  number_of_lessons: PropTypes.number,
  number_of_exercises: PropTypes.number,
};

export default PublicSlide;
