import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import classnames from 'classnames';
import style from './style.module.scss';
import { Link } from 'react-router-dom';
import { useOnClickOutside } from '../../../../../../hooks/outsideClick.hook';
import useResolution from 'hooks/useResolution';
import close from '../../../../../../assets/img/close.svg';

const WebinarCard = ({ webinar }) => {
  const [isCardOpen, toggleCard] = useState(false);
  const { t, i18n } = useTranslation();
  const ref = useRef(null);
  const isMobile = useResolution(1000);

  useOnClickOutside(ref, () => toggleCard(false));

  const day = moment(webinar.begin_date).format('D');
  const month = t(
    `months.${moment(webinar.begin_date)
      .locale('en')
      .format('LL')
      .match(/\b\D+\b/)[0]
      .trim()
      .toLowerCase()}`
  );

  const date = i18n.language === 'eng' ? `${month} ${day}` : `${day} ${month.toLowerCase()}`;

  return (
    <div className={style.container}>
      {isMobile && <div className={`${style['infoCard-backdrop']} ${isCardOpen && style['open']}`}></div>}
      <div
        className={classnames(style.level, {
          [style.level_trial]: !webinar.subject,
        })}
        style={{ borderLeftColor: webinar.subject ? `#${webinar.subject.level.color}` : '#F6F7FA' }}
        onClick={() => toggleCard(true)}>
        <p>{webinar.subject ? webinar.subject.level.name : webinar.title}</p>
      </div>
      {isCardOpen && (
        <div className={style.infoCard} ref={ref}>
          {isMobile && (
            <img onClick={() => toggleCard(false)} className={style['infoCard__close']} src={close} alt='X' />
          )}
          <div
            className={style.infoCard__level}
            style={{ '--back': `#${webinar.subject ? webinar.subject.level.color : '5EC7C0'}` }}>
            {webinar.subject && webinar.subject.level.name}
          </div>
          <div className={style['infoCard-wrapper']}>
            <div className={style['infoCard-info']}>
              {webinar.subject ? (
                <div>
                  <p className={style['infoCard-info__title']}>{t('mistakes.course')}:</p>
                  <p>{webinar.subject.level.title}</p>
                </div>
              ) : (
                <p className={style['infoCard-info__title_trial']}>{webinar.title}</p>
              )}
              {webinar.subject && (
                <div>
                  <p className={classnames(style['infoCard-info__title'], style['infoCard-info_grey'])}>
                    {webinar.subject.name}:
                  </p>
                  <p className={style['infoCard-info_grey']}>{webinar.subject.title}</p>
                </div>
              )}
              <div>
                <p className={classnames(style['infoCard-info__title'], style['infoCard-info_grey'])}>
                  {t('abclass.teacher')}:
                </p>
                <p className={style['infoCard-info_grey']}>{webinar.teacher ? webinar.teacher.name : ''}</p>
              </div>
              <div className={style['infoCard-info__date']}>
                {moment(webinar.normal_time).format('HH:mm')}
                <div className={style['infoCard-info__date__delimiter']}></div>
                {date}
              </div>
            </div>
            <div className={style['infoCard_bottom']}>
              <Link className={style['infoCard_bottom__enter']} to={`/webinar/${webinar.id}`}>
                {t('auth.login')}
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WebinarCard;
