import * as Sentry from '@sentry/react'
import React, { useContext, useEffect, useState } from "react";
import style from "./style.module.scss";
import { DurationContext, WebinarContext } from "../../../../../constants/contexts";
import useDebounce from "../../../../../hooks/debounce.hook";
import { useTranslation } from "react-i18next";

const Timer = ({ themeLink, endTimerFunc, currentExercise, currentId }) => {
    const [duration, setDuration] = useState(false);
    const [status, setStatus] = useState(true);
    const { context } = useContext(WebinarContext);
    const { setDurationContext } = useContext(DurationContext);
    const [isDurationRun, setDurationRun] = useState(false);
    const { t } = useTranslation();


    useEffect(() => {
        context.socket.emit('status_timer', { type: 'duration' })
        context.socket.on('status_timer', data => {
            if (!data.status && context.status !== 'NOT_BEGIN') {
                setStatus(false)
            }
        })
        context.socket.on('duration', time => {
            if (time) {
                setDurationRun(true)
                setDuration(time)
            }
            else {
                setDurationRun(false)
            }
        })
    }, [])

    const debounce = useDebounce(isDurationRun, 0, true)

    useEffect(() => {
        setDurationContext({ isDurationRun: debounce })
    }, [debounce])

    useEffect(() => {
        if ((duration === '00:00:00') || (!status && (duration === false))) {
            Sentry.captureMessage({
                duration,
                currentExercise,
                status,
                date: (new Date())
            })
            endTimerFunc()
            if (!status) setStatus(true)
        }
    }, [duration, status])


    return (
        <div className={style.container}>
            <div className={style.timer}>{duration ? duration?.slice(3) : '00:00'}</div>
            <a
                target="_blank"
                rel="noopener noreferrer"
                href={themeLink}
                className={style.theme}
                onClick={e => { if (!themeLink) e.preventDefault() }}
            >
                {t('webinar.hint')}
            </a>
        </div>
    )
}

// export default React.memo(Timer, (prevProps, nextProps) => {
//     return prevProps.exerciseDuration === nextProps.exerciseDuration &&
//            prevProps.currentId === nextProps.currentId
// });

export default Timer;