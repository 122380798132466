import React from 'react';
import Button from "../../../common/Button";
import {Link} from "react-router-dom";
import ModalWindow from "../../../common/ModalWindow";
import CancelSubscriptionConfirm from "./CancelSubscriptionConfirm";
import {useTranslation} from "react-i18next";

const CancelButton = (props) => {
  const {t} = useTranslation();
  return (
    <Button classes={'subscription__button subscription__button--red'} {...props} type={'button'}>{t('subscription.unsubscribe')}</Button>
  )
};

export default function SubscriptionCard({title, isActive, type, message, first_list, second_list, subscriptionData}) {
  const {t} = useTranslation();
  if(isActive) {
    return (
      <div className='subscription__block subscription__block--active'>
        <div className='subscription__container'>
          <p className='subscription__title'>{title}</p>
          <p className='subscription__type'>{t('subscription.active')}</p>
          <div className='subscription__rate-container'>
            {/*<p className='subscription__rate'>Тариф подписки {subscriptionData.rate} месяцев</p>*/}
            {subscriptionData && <p className='subscription__rate'>{subscriptionData?.amount} {subscriptionData?.currency} {t('subscription.startingFrom')} {(new Date(subscriptionData?.date)).toLocaleDateString()}</p>}
          </div>
        </div>
        <p className='subscription__text'>{message}</p>
        <ul className='subscription__options'>
          {first_list.map(el => <li className='subscription__options-item subscription__options-item--small' key={el}>{el}</li>)}
        </ul>
        <ul className='subscription__options subscription__options--marked subscription__options--column'>
          {second_list.map(el => <li className='subscription__options-item' key={el}>{el}</li>)}
        </ul>
        {subscriptionData && <ModalWindow ButtonComponent={CancelButton} WindowComponent={CancelSubscriptionConfirm} subscriptionType={type}/>}
      </div>
    )
  }

  return (
    <>
      <div className='subscription__block'>
        <div className='subscription__container'>
          <p className='subscription__title'>{title}</p>
          <p className='subscription__type'>{t('subscription.nonactive')}</p>
        </div>
        <p className='subscription__text'>{message}</p>
        <ul className='subscription__options'>
          {first_list.map(el => <li className='subscription__options-item subscription__options-item--small' key={el}>{el}</li>)}
        </ul>
        <ul className='subscription__options subscription__options--marked subscription__options--column'>
          {second_list.map(el => <li className='subscription__options-item' key={el}>{el}</li>)}
        </ul>
        <Link className={'button subscription__button'} to={`/subscription/${type}`}>{t('subscription.subscribe')}</Link>
      </div>
    </>
  )
}