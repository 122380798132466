export const SERVER_AUTH_ERRORS = [
  ['Incorrect email or password', 'incorrect'],
  ['Please activate your email', 'activate'],
  ['Could not verify user token', 'verify_token'],
  ['Could not get vk access token', 'vk_error'],
  ['Not correct', 'incorrect_data'],
  ['Not valid google token', 'not_valid_google'],
  ['No email from facebook data', 'no_email_facebook'],
  ['Could not get user permission for facebook', 'permission_facebook'],
  ['Not given permission for an email', 'permission_email'],
  ['Your email is private or your app is not abchess!', 'private_email'],
  ['Could not retrieve user data for facebook', 'facebook_no_data'],
  ['Email is waiting for verification. Check spam.', 'waitingForVerification'],
  ['Access denied', 'account_blocked'],
  ['Given email already exists', 'exist']
];
