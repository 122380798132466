import React, {useState} from "react";
import style from "./style.module.scss";
import {CaretIcon} from "../../../../../../assets/img/partners";
import classnames from "classnames";

const CoursesAccordion = ({list, margin, withCheckbox, name, exercises, setExercises}) => {

    const clickAccordion = (e) => {
        const parent = e.currentTarget.parentElement;
        // parent.classList.toggle(style.isOpen)
        if (parent.classList.contains(style.isOpen)) {
            parent.classList.remove(style.isOpen)
            parent.classList.add(style.isClose)
        }
        else {
            parent.classList.remove(style.isClose)
            parent.classList.add(style.isOpen)
        }
    }

    const addItem = (element) => {
        if (Array.isArray(element)) {
            element.forEach(item => {
                if (item.hasOwnProperty(item.sublist)) addItem(item.sublist)
                else if (!exercises.find(exercise => exercise.id === item.id)) setExercises(prev => [...prev, item])
            })
        }
        else if (exercises.find(exercise => exercise.id === element.id)) return false;
        else setExercises(prev => [...prev, element])
    }

    const removeItem = (element) => {
        if (Array.isArray(element)) {
            element.forEach(item => {
                if (item.hasOwnProperty(item.sublist)) removeItem(item.sublist)
                else setExercises(prev => [...prev.filter(el => el.id !== item.id)])
            })
        }
        else setExercises(prev => [...prev.filter(item => item.id !== element.id)])
    }

    const clickCheckbox = (e, element) => {
       if (e.target.classList.contains(style.active)) removeItem(element)
       else addItem(element);
    }

    return (
        <div className={style.coursesAccordion}>
            <button
                className={classnames(style.coursesAccordion__title, {[style.textBold]: !withCheckbox})}
                onClick={clickAccordion}
                style={{marginLeft: `${margin}px`}}
            >
                <div>
                    {
                        withCheckbox && <div
                                            onClick={(e) => clickCheckbox(e, list)}
                                            className={classnames(style.coursesAccordion__checkbox, {
                                                [style.active]: exercises.filter(exercise => Object.values(exercise).includes(name)).length === list.length
                                            })}
                                        ></div>
                    }
                    {name}
                </div>
                <CaretIcon />
            </button>
            {
                list.map(item => {
                    if (item.hasOwnProperty('sublist')) {
                        return <CoursesAccordion
                            key={item.id}
                            name={item.name}
                            list={item.sublist || []}
                            withCheckbox={true}
                            margin={margin + 40}
                            exercises={exercises}
                            setExercises={setExercises}
                        />
                    }
                    else return (
                        <div
                            className={style.coursesAccordion__item}
                            key={item.id}
                            onClick={(e) => clickCheckbox(e, item)}
                            style={{marginLeft: `${margin+40}px`}}
                        >
                            <div>
                                <div
                                    className={classnames(style.coursesAccordion__checkbox, {
                                       [style.active]: exercises.find(exercise => exercise.id === item.id)
                                    })}
                                ></div>
                                {item.name}
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default React.memo(CoursesAccordion, (prevProps, nextProps) => {
    return prevProps.name === nextProps.name &&
        prevProps.margin === nextProps.margin &&
        prevProps.exercises.length === nextProps.exercises.length
});