import React, { useCallback, useEffect, useState } from 'react';
import StudySliderHead from './StudySliderHead';
import StudySliderItem from './StudySliderItem';
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider, Dot } from 'pure-react-carousel';
import { useHttp } from 'hooks/http.hook';
import './studySlider.scss';
import './studySliderMobile.scss';
import { useWindowSize } from "hooks/resize.hook";
import { useSelector } from "react-redux";
import { GET_LATEST_PASSING_COURSES } from "services/api";

export default function StudySlider(props) {
  const [data, setData] = useState({ latest_passing_courses: [{}, {}] });
  const [isCoursesInProgress, setIsCoursesInProgress] = useState(false);
  const [slidesCountOnWindow, setSlidesCountOnWindow] = useState(0);
  const auth = useSelector(state => state.auth.authenticated);
  const { request, error } = useHttp();
  const windowSize = useWindowSize();
  const latestCourses = useSelector(state => state.courses.latest_courses)

  useEffect(() => {
    loadCourses();
  }, [auth]);

  const loadCourses = useCallback(async () => {
    try {
      const result = await request(GET_LATEST_PASSING_COURSES, 'get');
      setData(result.data);
      if (latestCourses.length === 0) {
        setIsCoursesInProgress(false);
      } else {
        setIsCoursesInProgress(true)
      }

    } catch (e) {
      console.log(e.response);
    }
  }, [auth, latestCourses]);

  useEffect(() => {
    if (windowSize.width > 750) {
      setSlidesCountOnWindow(2)
    } else {
      setSlidesCountOnWindow(1)
    }
  }, [windowSize]);


  const dotsToRender = () => {
    let dotNumbers = Math.ceil(props.totalSlides / slidesCountOnWindow);
    let dotsArray = [];
    for (let i = 0; i < dotNumbers; i++) {
      dotsArray.push(<Dot slide={i * slidesCountOnWindow} key={`dotstudy${i}`}><span className='slider-position' /></Dot>)
    }
    return dotsArray;
  };

  /*if (!isCoursesInProgress || error) {
    return <div />;
  }*/
  return (
    <div className='study-slider__wrapper'>
      <div className='study-slider'>
        <StudySliderHead />
        <div className='study-slider__body'>
          <CarouselProvider
            naturalSlideWidth={30}
            naturalSlideHeight={30}
            totalSlides={latestCourses?.length}
            visibleSlides={slidesCountOnWindow}
            infinite={true}
            isIntrinsicHeight={true}
            lockOnWindowScroll={true}
          >
            {/*{data.latest_passing_courses.length > slidesCountOnWindow &&*/}
            <ButtonBack>
              <span className='slider-button slider-button--left public-slider__button public-slider__button--left' />
            </ButtonBack>
            {/*}*/}
            <Slider>
              {latestCourses.map((el, index) =>
                <Slide className='study-slider__slide' index={index} key={`${index}s`}>
                  <StudySliderItem {...el} />
                </Slide>
              )}
            </Slider>
            {/*{data.latest_passing_courses.length > slidesCountOnWindow &&*/}
            <ButtonNext>
              <span className='slider-button slider-button--right public-slider__button public-slider__button--right' />
            </ButtonNext>
            {/*}*/}
            <div className='slider-position-container slider-position-container--mobile'>
              {dotsToRender().map(el => el)}
            </div>
          </CarouselProvider>
        </div>
      </div>
    </div>
  );
}
