import React, { useCallback, useContext, useEffect, useState } from 'react';
import style from './style.module.scss';
import Timer from './Timer';
import WebinarRating from '../../../common/Rating';
import Exercises from './Exercises';
import Chat from './Chat';
import { WebinarContext } from '../../../../constants/contexts';
import { updateWebinarCurrentExercise } from '../../../../services/api';
import useResolution from '../../../../hooks/useResolution';
import { ChatIcon, RatingIcon } from '../../../../assets/img/webinar';
import { convertDateFromLocale } from '../../../../utils/convertDateFromLocale';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector, connect } from 'react-redux';
import { closeOverlayAction, openOverlayAction } from '../../../../actions/overlay';
import EditNamePopup from './EditNamePopup';
import BanPopup from './BanPopup';
import { useHistory } from 'react-router-dom';
import defaultBanner from '../../../../assets/img/default_webinar_banner.png';
import {
  setWebinarRaiting,
  setWebinarCurrentExercise,
  updateWebinarRating,
  setWebinarTimestamp,
} from '../../../../actions/webinar';
import { getExerciseType } from '../lib/getExerciseType';
import classNames from 'classnames';
import { AnswersLog } from './AnswersLog/AnswersLog';
import { sendCompleteExercise } from '../lib/sendCompleteExercise';
import * as Sentry from '@sentry/react'

const Lobby = ({ lessonData, setEnd, students, current_name, webinar_id }) => {
  const [currentExercise, setCurrentExercise] = useState({});
  const [isPause, togglePause] = useState(false);
  const [isPauseVisible, setIsPauseVisible] = useState(false);
  //Необходимо, чтобы обновить историю чата, после изменения фамилии
  const [chatForceUpdate, setChatForceUpdate] = useState(0);
  const [timerForceUpdate, setTimerForceUpdate] = useState(0);
  const { id } = useSelector((state) => state.profile.profileData);
  const { timestamp } = useSelector((state) => state.webinar);
  const { context, setContext } = useContext(WebinarContext);
  const isMobile = useResolution(900);
  const [rating, setRating] = useState([]);
  const [windowContent, setWindowContent] = useState(null);
  const [showRating, setShowRating] = useState(false);
  const [isTeacher, setIsTeacher] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [studentsAnswers, setStudentsAnswers] = useState([]);

  useEffect(() => {
    const role = localStorage.getItem('role');
    if (role === 'teacher' || role === '"teacher"') {
      setIsTeacher(true);
    }
  }, []);

  useEffect(() => {
    if (timestamp === 0) {
      dispatch(setWebinarTimestamp(Date.now()));
    }

    const id = setInterval(() => {
      if (Date.now() - timestamp > 30 * 1000) {
        setTimerForceUpdate(Date.now());
      }
      dispatch(setWebinarTimestamp(Date.now()));
    }, 20 * 1000);
    return () => clearInterval(id);
  }, [timestamp, dispatch]);

  useEffect(() => {
    if (timerForceUpdate) {
      history.go(0);
    }
  }, [timerForceUpdate, history]);

  useEffect(() => {
    if (!isMobile || (isMobile && windowContent === 'rating')) {
      context.socket.on('rating', (rating) => {
        dispatch(setWebinarRaiting(rating));
      });
    } else context.socket.off('rating', (rating) => setRating([...rating]));
  }, [isMobile, windowContent]);

  useEffect(() => {
    context.socket.on('exercise', (exercise) => {
      setCurrentExercise(exercise);
      setIsPauseVisible(false);

      Sentry.captureMessage(exercise, "log")

      // Это вообще используется?
      dispatch(setWebinarCurrentExercise(exercise.exercise_id));
      dispatch(updateWebinarRating());
    });

    context.socket.on('start_change', (status) => {
      if (status.start_is_changed) {
        context.socket.disconnect();
        window.location.reload();
      }
    });

    context.socket.on('is_banned', (data) => {
      if (data.student_id && data.student_id === id) {
        context.socket.disconnect();
        dispatch(
          openOverlayAction(BanPopup, {}, false, true, () => {
            history.push('/');
            dispatch(closeOverlayAction());
          })
        );
      }
    });

    const onStudentAnswer = (data) => {
      console.log('teacher_answers: ', data);
      setStudentsAnswers((prev) => [...prev, data]);
    };

    context.socket.on('teacher_answers', onStudentAnswer);

    return () => {
      context.socket.disconnect();
    };
  }, []);

  const editName = useCallback(() => {
    const submitFunc = (name, surname) => {
      context.socket.emit('update_profile', { name, surname });
      setChatForceUpdate(Math.random());
      dispatch(
        setWebinarRaiting(
          students.map((student) => {
            if (student.student_name === current_name) {
              return {
                ...student,
                student_name: name,
              };
            }
            return student;
          })
        )
      );
    };

    dispatch(openOverlayAction(EditNamePopup, { submitFunc }));
  }, [students]);

  const hideScroll = (state = false) => {
    const body = document.querySelector('body');
    const html = document.querySelector('html');

    if (state && !body.classList.contains('scroll-hidden')) {
      body.classList.add('scroll-hidden');
      html.classList.add('scroll-hidden');
    } else {
      body.classList.remove('scroll-hidden');
      html.classList.remove('scroll-hidden');
    }
  };

  const openNextExercise = useCallback(
    (isPauseEnd) => {
      togglePause(true);
      setStudentsAnswers([]);

      const { exercise_id, is_last, pause_time, type } = currentExercise;

      const promise = isPauseEnd
        ? new Promise((resolve) => resolve('ok'))
        : new Promise((resolve, reject) => {
            context.correctAnswerPromise(resolve, reject);
          });

      promise
        .then(() => setIsPauseVisible(true))
        .then(() => {
          setContext({
            ...context,
            correctAnswerPromise: (resolve) => {
              if (JSON.parse(localStorage.getItem('role')) !== 'teacher') {
                sendCompleteExercise(context.socket, {
                  exercise_id: exercise_id,
                  exercise_correct: null,
                });
              }

              resolve('ok');
            },
            userAnswer: null,
          });

          if (is_last && (pause_time === '00:00:00' || isPauseEnd)) {
            setEnd(true);
          } else if (isPauseEnd || (pause_time === '00:00:00')) {
            if (pause_time === '00:00:00') {
              context.socket.emit('pause', {});
              setTimeout(() => context.socket.emit('exercise', {}), 1500);
            } else if (isPauseEnd) {
              context.socket.emit('exercise', {});
            }
            //
            updateWebinarCurrentExercise(context.accessToken, null).then((res) => {
              console.log('Load testing success, Pages/Webinar/Lobby/index.jsx', res.data)
              togglePause(false);
            }).catch(err => {
              console.error('Load testing error catch, Pages/Webinar/Lobby/index.jsx', err)
            })
          } else if (
            (context.userAnswer == null )&&
            (type !== 2) &&
            (JSON.parse(localStorage.getItem('role')) !== 'teacher')
          ) {
            sendCompleteExercise(context.socket, {
              exercise_id: exercise_id,
              exercise_correct: false,
            });

            //togglePause(true);
          }
        });
    },
    [currentExercise, context.correctAnswerPromise, context.userAnswer]
  );

  const onMakeMove = useCallback(
    (data, answer_correct, icon) => {
      console.log('Move: ', data);
      context.socket.emit('answer', { data, answer_correct, icon });
      Sentry.captureMessage({
        type: 'answer',
        data,
        answer_correct,
        icon
      })
    },
    [context.socket]
  );

  const currentExerciseType = getExerciseType(currentExercise?.exercise);
  const withShowAnswers =
    isTeacher && (currentExerciseType === 'BEST_MOVE' || currentExerciseType === 'TEST');

  return (
    <div className={style.container}>
      {isMobile && withShowAnswers && (
        <div className={classNames(style.answersLogWrapper, style.answersLogWrapper_mobile)}>
          <h6 className={style.answersLogWrapper__title}>{t('webinar.usersAnswers')}:</h6>
          <AnswersLog answers={studentsAnswers} />
        </div>
      )}
      <div className={style.exercisesWrapper}>
        <Exercises
          beginDate={convertDateFromLocale(lessonData.begin_date)}
          currentExercise={currentExercise}
          isPause={isPause}
          isPauseVisible={isPauseVisible}
          exercises={lessonData.exercises}
          endTimerFunc={openNextExercise}
          is_training={false}
          onMakeMove={isTeacher ? () => {} : onMakeMove}
          answers={isTeacher ? studentsAnswers : []}
          lessonId={lessonData.id}
        />
      </div>
      {((isMobile && windowContent === 'rating') || !isMobile) && (
        <>
          <div
            className={classNames(style.ratingWrapper, {
              [style.ratingWrapper_short]: withShowAnswers,
            })}
          >
            <WebinarRating
              isPause={isPause}
              editFunc={editName}
              showRating={showRating}
              current_name={current_name}
              isWebinar={true}
              students={students}
              className={style.rating}
              closeMobileRating={(state) => {
                setWindowContent(null);
                hideScroll(state);
              }}
              isShort={withShowAnswers}
            />
          </div>
          {withShowAnswers && (
            <div className={style.answersLogWrapper}>
              <h6 className={style.answersLogWrapper__title}>{t('webinar.usersAnswers')}:</h6>
              <AnswersLog answers={studentsAnswers} />
            </div>
          )}
        </>
      )}
      <div className={style.timerWrapper}>
        <Timer
          currentId={currentExercise.exercise_id}
          themeLink={lessonData.theme_link}
          endTimerFunc={openNextExercise}
          currentExercise={currentExercise}
        />
        {isMobile && (
          <>
            <button
              className={style.timerWrapper__openWindow}
              onClick={() => {
                setWindowContent('chat');
                hideScroll(true);
              }}
            >
              <ChatIcon />
              <span>{t('webinar.chat')}</span>
            </button>
            <button
              className={style.timerWrapper__openWindow}
              onClick={(state) => {
                setWindowContent('rating');
                hideScroll(state);
              }}
            >
              <RatingIcon />
              <span>{t('webinar.rating')}</span>
            </button>
          </>
        )}
      </div>
      {!isMobile && (
        <a
          target='_blank'
          href={lessonData.banner_link}
          className={style.bannerWrapper}
          onClick={(e) => {
            if (!lessonData.banner_link) e.preventDefault();
          }}
        >
          <img
            className={!lessonData.banner ? style.noCover : ''}
            src={lessonData.banner || defaultBanner}
            alt='banner'
          />
        </a>
      )}
      {((isMobile && windowContent === 'chat') || !isMobile) && (
        <div className={style.chatWrapper}>
          <Chat
            closeMobileChat={(state) => {
              setWindowContent(null);
              hideScroll(state);
            }}
            forceUpdateTrigger={chatForceUpdate}
          />
        </div>
      )}
    </div>
  );
};

export default connect((state) => ({
  students: state.webinar.rating,
  current_name: state.auth.temporary_auth.name,
}))(Lobby);
