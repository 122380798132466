import React, { useEffect, useState } from "react";
import Turn from "./Turn";
import { useTranslation } from "react-i18next";
import './PlayPosition.scss';
import ExerciseTitle from "./ExerciseTitle/ExerciseTitle";

const PlayPosition = ({update, counter, isCourse, onBackClick, onNextClick, onResetClick, turn, title, serviceMode, isPlayPosition = false, playPositionStatus = false }) => {
  const { t } = useTranslation();
  const [key, setKey] = useState(0);

  useEffect(()=>{
    setKey(Math.random()*10000000)
  }, [update])

  return (
    <div key={key} className={`chess-display__description ${isPlayPosition && 'fg-0'}`}>
      <ExerciseTitle title={t('exercises.playPosition')} counter={counter} isCourse={isCourse} />
      <div className='chess-display__statistic'>
        <div className='chess-display__statistic-turn'>
          <Turn turn={turn} />
        </div>
        {isPlayPosition && <div className={`chess-display__control-container ${isPlayPosition ? playPositionStatus ? 'show' : 'hide' : 'show'}`}>
          <button className='chess-display__control-button chess-display__control-button--back' onClick={onBackClick} />
          <button className='chess-display__control-button chess-display__control-button--forward' onClick={onNextClick} />
          {/*<button className='chess-display__control-button chess-display__control-button--repeat' onClick={onResetClick}/>*/}
        </div>}
      </div>
      {/*<span className='chess-display__dotes chess-display__dotes--desktop login__dotes login__dotes--task'/>*/}
    </div>
  )
}

export default React.memo(PlayPosition, (prevProps, nextProps) => {
  return prevProps.turn === nextProps.turn &&
    prevProps.title === nextProps.title

})