import {useState, useCallback} from 'react';
import axios from 'axios';

export const useHttp = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const request = useCallback(async (url, method = 'GET', data = null, headers = {}) => {
    setLoading(true);
    try {
      const response = await axios({method, url, data});
      setLoading(false);
      return response;
    } catch (e) {
      setLoading(false);
      setError(e.response?.data?.message || 'Неизвестная ошибка, попробуйте позже');
      throw e;
    }

  }, []);
  const clearError = () => setError(null);

  return {loading, request, error, clearError}
};