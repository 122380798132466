import React from 'react'
import LevelLabel from '../LevelLabel/LevelLabel'
import style from './style.module.scss'

const Certificate = ({ withLabel = true, certificate, }) => {

    if (!withLabel) {
        return (
            <div className={`${style['certificate-wrapper']}`}>
                <div className={style['certificate-wrapper__image-area']}>
                    <img src={certificate.image} alt={`certificate of the ${certificate.level} level`} />
                </div>
            </div>
        )
    }

    return (
        <div className={`${style['certificate-wrapper']}`} style={{padding: withLabel && '0'}}>
            <div className={`${style['certificate-wrapper__label']} ${withLabel && style['show-label']}`}>
                <LevelLabel level={certificate.level} />
            </div>
            <div className={style['certificate-wrapper__image-area']}>
                <img src={certificate.image} alt={`certificate of the ${certificate.level} level`} />
            </div>
        </div>
    )
}

export default Certificate