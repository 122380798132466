import React from 'react';
import {Link} from "react-router-dom";
import {getHighlightedText} from "helpers/textHelpers";

export default function SearchCategoryItem({ id, supercategory_id, name, hideResults, search }) {
  return (
    <Link className='search__category-item' to={`/courses/${supercategory_id}/${id}`} onClick={hideResults}>
      <p className='search__item-text'>{getHighlightedText(name, search)}</p>
    </Link>
  )
}