import React from "react";
import { useTranslation } from "react-i18next";
import style from "./style.module.scss";
import Level from "./Level";

const Levels = ({levels}) => {
  const {t} = useTranslation();
    const trialLevel = {
        name: t('levels.trial'),
        title: t('levels.trial_description'),
        is_trial: true,
        color: '#F6F7FA'
    }

    return (
        <div className={style.levels}>
            <p className={style.levels__title}>L1 - {levels.length} (Level 1 - {levels.length})</p>
            <div className={style.levels__wrapper}>
                {
                    levels.map(level => <Level key={level.id} level={level} />)
                }
                <Level level={trialLevel} />
            </div>
        </div>
    )
}

export default Levels;