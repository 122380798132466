import React from "react";
import {useTranslation} from "react-i18next";

export default function PlayWithBotControls({ onBackClick, onNextClick, turn }) {
  const {t} = useTranslation();

  return (
    <div className='chess-display__description'>
      {turn === 'w' ?
        <span className='chess-display__step'>{t('exercises.whiteStep')}</span>
        :
        <span className='chess-display__step'>{t('exercises.blackStep')}</span>
      }
      <div className='chess-display__control-container'>
        <button className='chess-display__control-button chess-display__control-button--back' onClick={onBackClick}/>
        <button className='chess-display__control-button chess-display__control-button--forward' onClick={onNextClick}/>
      </div>
      <span className='chess-display__dotes chess-display__dotes--mobile login__dotes login__dotes--task'/>
    </div>
  )
}