export const pgnParseToArray = (str) => {
  let cur = str.split(/\d+\./).map(el => el.trim());
  cur.shift();
  let res = '';
  cur.forEach(el => res += el + ' ');
  return res.split(' ').filter(el => el !== '...').filter(el => el);
};

export function isPromotion(cfg) {
  let piece = cfg.chess.get(cfg.move.from);

  if (cfg.move.from.charAt(1) === '7' && cfg.move.to.charAt(1) === '8' && piece && piece.type === 'p' && piece.color === 'w' && cfg.chess.turn() === 'w') {
    return true;
  }

  if (cfg.move.from.charAt(1) === '2' && cfg.move.to.charAt(1) === '1' && piece && piece.type === 'p' && piece.color === 'b' && cfg.chess.turn() === 'b') {
    return true;
  }

  return null;
}

export const squareStyling = ({ pieceSquare, history }) => {
  const sourceSquare = history.length && history[history.length - 1].from;
  const targetSquare = history.length && history[history.length - 1].to;

  return {
    [pieceSquare]: { boxShadow: 'inset 0 0 0 2px rgb(230, 50, 50)' },
    ...(history.length && {
      [sourceSquare]: {
        backgroundColor: "inherit"
      }
    }),
    ...(history.length && {
      [targetSquare]: {
        backgroundColor: "inherit"
      }
    })
  };
};

export const setBoardWidth = (mode = 'default', freeGame = false) => {
  if (mode === 'webinar') {
    return window.innerWidth > 445 ? 445 - 12 : window.outerWidth - 16
  }
  else {
    if (freeGame) {
      return window.innerWidth > 700 ? window.innerWidth * 0.9 / 3 : window.outerWidth - 20
    }
    return window.innerWidth > 700 ? window.innerWidth * 1.09 / 3 : window.outerWidth - 20 // 12 - custom border width of chessboard
  }
};

export const checkIsDraw = (game) => !!(game.in_draw() || game.in_stalemate() || game.in_threefold_repetition() || game.insufficient_material());

export const getStylesOfAvailableMoves = (moves) => {
  return moves.reduce((acc, el) => {
    if (el.captured) {
      acc[el.to] = {
        background: "rgba(0, 0, 0, 0) radial-gradient(closest-side, transparent 107%, lightblue 110%)",
      };
    } else {
      acc[el.to] = {
        background: "rgba(0, 0, 0, 0) radial-gradient(circle, lightblue 16%, transparent 22%)",
        borderRadius: "50%"
      };
    }
    return acc;
  }, {})
};

export const getDifficultySettingCommands = (difficulty) => {
  let commands = [];
  switch (difficulty) {
    case 1:
      commands = [
        'setoption name Skill Level value 0',
        'setoption name Skill Level Maximum Error value 5000',
        'setoption name Skill Level Probability value 1'
      ];
      break;
    case 2:
      commands = [
        'setoption name Skill Level value 0',
        'setoption name Skill Level Maximum Error value 2500',
        'setoption name Skill Level Probability value 10'
      ];
      break;
    case 3:
      commands = ['setoption name Skill Level value 2'];
      break;
    case 4:
      commands = ['setoption name Skill Level value 4'];
      break;
    case 5:
      commands = ['setoption name Skill Level value 6'];
      break;
    case 6:
      commands = ['setoption name Skill Level value 8'];
      break;
    case 7:
      commands = ['setoption name Skill Level value 10'];
      break;
    case 8:
      commands = ['setoption name Skill Level value 12'];
      break;
    case 9:
      commands = [
        'setoption name Skill Level value 20',
        'setoption name Skill Level Maximum Error value 0',
        'setoption name Skill Level Probability value 1000'
      ];
      break;
  }

  // option name Skill Level Maximum Error type spin default 200 min 0 max 5000
  // option name Skill Level Probability type spin default 128 min 1 max 1000
  // Уровень 1 - 2 (с доработками).
  // Уровень 2 - 2 (с доработками).
  // Уровень 3 - 2.
  // Уровень 4 - 4.
  // Уровень 5 - 6.
  // Уровень 6 - 8.
  // Уровень 7 - 10.
  // Уровень 8 - 12.
  // Гроссмейстер - 20.
  return commands;
};

export const getDepth = (difficulty) => {
  let depth = 1;
  switch (difficulty) {
    case 1:
      depth = 1;
      break;
    case 2:
      depth = 1;
      break;
    case 3:
      depth = 1;
      break;
    case 4:
      depth = 1;
      break;
    case 5:
      depth = 2;
      break;
    case 6:
      depth = 2;
      break;
    case 7:
      depth = 3;
      break;
    case 8:
      depth = 10;
      break;
    case 9:
      depth = 18;
      break;
  }
  return depth;
};