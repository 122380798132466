import React from "react";
import style from "./style.module.scss"
import classnames from "classnames";

const WebinarListMenu = ({links, setType, lessonType}) => {
    return (
        <div className={style.menu}>
            {
                links.map((item, index) => (
                    <div
                        key={item.text+index}
                        className={classnames(style.menu__item, {
                            [style['menu__item-active']]: item.query === lessonType
                        })}
                        onClick={() => setType(item.query)}
                    >
                        {item.text}
                    </div>
                ))
            }
        </div>
    )
}

export default WebinarListMenu;