import moment from "moment";
import 'moment/locale/ru';
import 'moment/locale/en-gb';
import 'moment/locale/es';
import 'moment/locale/kk';
import 'moment/locale/tr';

export const getMomentLocale = () => {
    switch (localStorage.getItem('i18nextLng')) {
        case "ru":
            moment.locale('ru');
            break;
        case "en":
            moment.locale('en-gb');
            break;
        case "kz":
            moment.locale('kk');
            break;
        case "es":
            moment.locale('es');
            break;
        case "tr":
            moment.locale('tr');
            break;
        default :
            moment.locale('ru');
            break;
    }
}