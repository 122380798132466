import {
  SET_LANG,
  TOGGLE_EXERCISES_LIST,
  SET_FULLSCREEN,
  DISABLE_FULLSCREEN,
  TOGGLE_LOGIN_WINDOW,
  DISABLE_LOGIN_WINDOW,
  TOGGLE_MOVES,
  TOGGLE_SOUND,
  SET_BOARD_COLOR,
  SHOW_JIVO
} from '../actions/types';
import {CHESS_SQUARE_COLORS} from "../components/common/ChessConst";

export default function(state = {
    lang: localStorage.getItem('lang'),
    sound: localStorage.getItem('sound') ? JSON.parse(localStorage.getItem('sound')) : true,
    moves: localStorage.getItem('moves') ? JSON.parse(localStorage.getItem('moves')) : true,
    chessBoardColor: localStorage.getItem('chessBoardColor') ? CHESS_SQUARE_COLORS[localStorage.getItem('chessBoardColor' )] : CHESS_SQUARE_COLORS.main,
    exercisesList: true,
    fullscreen: false,
    loginWindow: false,
    showJivo: false,
  }, action) {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case SET_LANG:
      return {...state, lang: action.payload};
    case TOGGLE_EXERCISES_LIST:
      return {...state, exercisesList: !state.exercisesList};
    case SET_FULLSCREEN:
      return {...state, fullscreen: true};
    case DISABLE_FULLSCREEN:
      return {...state, fullscreen: false};
    case TOGGLE_LOGIN_WINDOW:
      return {...state, loginWindow: !state.loginWindow};
    case DISABLE_LOGIN_WINDOW:
      return {...state, loginWindow: false};
    case TOGGLE_SOUND:
      return {...state, sound: !state.sound};
    case TOGGLE_MOVES:
      return {...state, moves: !state.moves};
    case SET_BOARD_COLOR:
      return {...state, chessBoardColor: CHESS_SQUARE_COLORS[action.payload]};
    case SHOW_JIVO:
      return {...state, showJivo: action.payload}
  }
  return state;
}