import React from "react";
import './loader.scss'
import {useTranslation} from "react-i18next";

export default function Loader3() {
  const {t} = useTranslation();

  return (
    <div className='loader3' style={{backgroundColor: "white"}}>
      <span className={'logo loader3__item3'}/>
      <span style={{color: 'black', fontSize: '16px', marginTop: '20px', fontWeight: "bold"}}>{t('loaders.loading')}...</span>
    </div>
  )
}