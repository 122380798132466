import React from 'react';
import {useTranslation} from "react-i18next";
import LessonsNavigationButton from "../LessonsNavigationButton";
import classnames from "classnames";

export default function CourseLoader({mode}) {
  const {t} = useTranslation();
  return (
      <div className={classnames('chess-display chess-display--loading', {
        'chess-display_webinar': mode === 'webinar'
      })}>
        <div className='chess-display__container chess-display__container--buttons'>
          <div className='chess-display__wrapper'>
            <div className='chess-display__picture'/>
            <div className='chess-display__description chess-display__description--repeat'>
              <p className='chess-display__title'/>
              <p className='chess-display__name'/>
              <ul className='chess-display__test-list'>
                <li className='chess-display__test-item'>
                  <label className='chess-display__test-label'/>
                </li>
                <li className='chess-display__test-item'>
                  <label className='chess-display__test-label'/>
                </li>
              </ul>
            </div>
          </div>
          {
            mode === 'default' && (
                <div className='chess-display__buttons-container'>
                  <LessonsNavigationButton loading={true}>{t('exercises.back')}</LessonsNavigationButton>
                  <LessonsNavigationButton loading={true}>{t('exercises.next')}</LessonsNavigationButton>
                </div>
            )
          }
        </div>
      </div>
  )
}