import React, {useMemo} from "react";
import {goldCup_big, silverCup_big, bronzeCup_big} from "../../../../../../assets/img/webinar/index";
import style from "./style.module.scss";
import {useTranslation} from "react-i18next";
import classnames from "classnames";


const Cup = ({position, winners, setFull, isFull}) => {
    const {t} = useTranslation();

    const cup = useMemo(() => {
        switch (position) {
            case 1:
                return <img className={style.gold} src={goldCup_big} alt="cup"/>
            case 2:
                return <img className={style.silver} src={silverCup_big} alt="cup"/>
            case 3:
                return <img className={style.bronze} src={bronzeCup_big} alt="cup"/>
        }
    }, [])

    const getUserName = (user) => {
        return !user.surname ? `${user.name}`: `${user.name} ${user.surname}`
    }

    return (
        <div className={style.cup}>
            {cup}
            {
                winners.length > 0 && (
                    <>
                        {
                            winners.length === 1
                                ? <p className={style.cup__name}>{getUserName(winners[0])}</p>
                                : !isFull
                                    ? <button className={style.allWinners} onClick={setFull}>{t('webinar.allWinners')}</button>
                                    : (
                                        <div className={style.cup__names}>
                                            {winners.map((item) => <p className={style.cup__name} key={item.id}>{getUserName(item)}</p>)}
                                        </div>
                                    )
                        }
                        <p className={classnames(style.cup__position, style.info, {
                            [style.cup__position_noFull]: !isFull &&  winners.length > 1
                        })}>{t('webinar.position')}: {position}</p>
                        <p className={style.info}>{t('partners.scores')}: {winners[0].scores}</p>
                    </>
                )
            }
        </div>
    )
}

export default Cup;