export const AUTHENTICATED_LOADING = 'authenticated_loading';
export const AUTHENTICATED = 'authenticated_user';
export const UNAUTHENTICATED = 'unauthenticated_user';
export const AUTHENTICATION_ERROR = 'authentication_error';
export const REGISTRATION = 'registration';
export const REGISTRATION_ERROR = 'registration_error';
export const FETCH_REGISTRATION = 'fetch_registration';
export const FETCH_COURSES_ERROR = 'fetch_courses_error';
export const FETCH_COURSES_SUCCESS = 'fetch_courses_success';
export const FETCH_COURSES = 'fetch_courses';
export const FETCH_PROFILE_SUCCESS = 'fetch_profile_success';
export const FETCH_PROFILE_ERROR = 'fetch_profile_error';
export const FETCH_PROFILE_START = 'fetch_profile_start';
export const ADD_EXERCISE = 'add_exercise';
export const SET_CATEGORY_PRO = 'set_category_pro';
export const SET_CATEGORY_BASIC = 'set_category_basic';
export const SET_LANG = 'set_rus';
export const TOGGLE_EXERCISES_LIST = 'toggle_exercises_list';
export const SET_FULLSCREEN = 'set_fullscreen';
export const DISABLE_FULLSCREEN = 'disable_fullscreen';
export const TOGGLE_LOGIN_WINDOW = 'toggle_login_window';
export const DISABLE_LOGIN_WINDOW = 'disable_login_window';
export const TOGGLE_MOVES = 'toggle_moves';
export const TOGGLE_SOUND = 'toggle_sound';
export const INCREASE_ADVERTISING = 'INCREASE_ADVERTISING';
export const TOGGLE_ADVERTISING = 'toggle_advertising';
export const SET_BOARD_COLOR = 'set_board_color';
export const FETCH_SUPER_CATEGORIES_ERROR = 'super_categories_error';
export const FETCH_SUPER_CATEGORIES = 'fetch_super_categories';
export const CLEAR_COURSES_DATA = 'CLEAR_COURSES_DATA';
export const SHOW_JIVO = 'SHOW_JIVO';
export const SET_LATEST_COURSES = 'SET_LATEST_COURSES';
export const SET_TEMPORARY_AUTH = 'SET_TEMPORARY_AUTH';
export const SET_WEBINAR_RAITING = 'SET_WEBINAR_RAITING';
export const SET_WEBINAR_CURRENT_EXERCISE = 'SET_WEBINAR_CURRENT_EXERCISE';
export const CLEAR_AUTH_ERROR = 'CLEAR_AUTH_ERROR';
export const SHOW_WEBINAR_SCORES = 'SHOW_WEBINAR_SCORES';
export const UPDATE_WEBINAR_RATING = 'UPDATE_WEBINAR_RATING';
export const SET_WEBINAR_TIMESTAMP = 'SET_WEBINAR_TIMESTAMP';
export const TRAINING_LESSON = 'TRAINING_LESSON';
export const DELETE_LESSON = 'DELETE_LESSON';
