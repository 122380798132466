import React from 'react';
import PurchasedCourseItem from "./PurchasedCourseItem";
import './purchased.scss';
import './purchasedMobile.scss';
import MyCoursesList from "../MyCoursesList";
import {useTranslation} from "react-i18next";
import {GET_BUYING_COURSES} from "services/api";

export default function PurchasedCoursesList() {
  const {t} = useTranslation();

  return (
    <MyCoursesList
      apiRoute={GET_BUYING_COURSES}
      noItemsMessage={t('courses.noPurchased')}
      ItemComponent={PurchasedCourseItem}
    />
  )
}