import React, {useEffect, useState} from 'react';
import {TOGGLE_ADVERTISING} from "actions/types";
import {useDispatch, useSelector} from "react-redux";
import {useHttp} from "hooks/http.hook";
import {getAdvertisingByOrder} from "services/api";
import './advertising.scss';
import {useLocation} from "react-router-dom";
import {useWindowSize} from "hooks/resize.hook";
import Overlay from "../Overlay";

export default function Advertising() {
  const [data, setData] = useState([]);
  const [currentNum, setCurrentNum] = useState(0);
  const advertisingWindow = useSelector(state => state.advertising.open);
  const advertisingCount = useSelector(state => state.advertising.count);
  const profileData = useSelector(state => state.profile.profileData);
  const location = useLocation();
  const dispatch = useDispatch();
  const {request} = useHttp();
  const windowSize = useWindowSize();

  const item = data.find(el => el.order === advertisingCount/5);

  const auditFilter = () => {

    if (item && item.platforms.includes('web') && item.languages.includes(localStorage.getItem('i18nextLng'))) {
      if(item.show_audit === 1) {
        return true
      }
      if(item.show_audit === 2) {
        if(profileData.is_premium_basic || profileData.is_premium_pro) {
          return true
        }
      }
      if(item.show_audit === 3) {
        if(!profileData.is_premium_basic && !profileData.is_premium_pro) {
          return true
        }
      }
    }
    return false
  };

  const getAdvertisingData = async() => {
    try { 
      let res = await request(getAdvertisingByOrder(advertisingCount/5));

      if(res.status === 200) {
        setData(res.data);
        !advertisingWindow && dispatch({type: TOGGLE_ADVERTISING})
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if(advertisingCount !== 0 && advertisingCount % 5 === 0) {
      currentNum !== advertisingCount && getAdvertisingData();
      setCurrentNum(advertisingCount);
    }
  }, [location]);

  return (
    <>
      {advertisingWindow && data.length !== 0 && auditFilter() && item &&
        <>
          {windowSize.width > 800 ?
            <div className='advertising'>
              <button className='advertising__close' type='button' onClick={() => dispatch({type: TOGGLE_ADVERTISING})}/>
              <a href={item.adverse_link || '#'} target={'_blank'}>
                <img className={'advertising__image'} src={item.desktop_image}/>
              </a>
            </div>
            :
            <Overlay>
              <div className='advertising'>
                <button className='advertising__close' type='button' onClick={() => dispatch({type: TOGGLE_ADVERTISING})}/>
                <a href={item.adverse_link || '#'} target={'_blank'}>
                  <img className={'advertising__image'} src={item.mobile_image}/>
                </a>
              </div>
            </Overlay>
          }
        </>
      }
    </>
  )
}
