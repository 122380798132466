import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import CourseImageBanner from "components/common/CourseImageBanner"
import './sectionGridItem.scss';

function SectionGridItem({id, image, title, number_of_lessons, number_of_exercises, course_author, is_free, type_subscription, isPrivate, url}) {
  const {t} = useTranslation();

  if(!id && !title) {
    return (
      <div className='section-grid__item section-grid__item--loading'>
        <span className='section-grid__card-image'/>
        <div className='section-grid__info'>
          <p className='section-grid__card-name'/>
          <p className='section-grid__card-title'/>
          <p className='section-grid__card-count'/>
        </div>
      </div>
    )
  }

  return (
    <Link className='section-grid__item' to={isPrivate ? `/super-categories/${url}/course/${id}` : `/course/${id}`}>
      <CourseImageBanner
        image={image}
        is_free={is_free}
        type_subscription={type_subscription}
        imageClasses={'section-grid__card-image'}
      />
      <div className='section-grid__info'>
        {course_author && <p className='section-grid__card-name'>{course_author || ''}</p>}
        <p className='section-grid__card-title'>{title}</p>
        <p className='section-grid__card-count'>{number_of_lessons || ''} {t('courses.lessons')} / {number_of_exercises || ''} {t('courses.exercises')}</p>
      </div>
    </Link>
  );
}

SectionGridItem.propTypes = {
  title: PropTypes.string,
  id: PropTypes.number,
  number_of_lessons: PropTypes.number,
  number_of_exercises: PropTypes.number
};

export default SectionGridItem;