import React, {useCallback, useEffect, useState} from "react";
import style from "./style.module.scss"
import {useTranslation} from "react-i18next";
import {ReactComponent as CloseIcon} from "../../../../../../assets/img/close-black.svg";
import {Arrow} from "../../../../../../assets/img/partners";
import {useDispatch} from "react-redux";
import {openOverlayAction} from "../../../../../../actions/overlay";
import axios from "axios";
import {GET_ALL_COURSES_LIST} from "../../../../../../services/api";
import {CaretIcon} from "../../../../../../assets/img/partners";
import Courses from "./Courses";
import Exercises from "../index";

const SuperCategories = ({closeModal, ...props}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [list, setList] = useState([]);

    useEffect(() => {
        axios.get(GET_ALL_COURSES_LIST)
            .then(res => setList(res.data.supercategories))
    }, []);

    const openCoursesModal = (id) => {
        dispatch(openOverlayAction(
            Courses,
            {...JSON.parse(sessionStorage.getItem('exercises')), setValue: props.setValue, categoryId: id},
            false,
            true,
            openRootModalFromCourses
        ))
    }


    const openRootModalFromCourses = useCallback(() => {
        dispatch(openOverlayAction(Exercises, {...JSON.parse(sessionStorage.getItem('exercises')), setValue: props.setValue}))
    }, [])

    const clickAccordion = (e) => {
        const parent = e.currentTarget.parentElement;
        if (parent.classList.contains(style.isOpen)) {
            parent.classList.remove(style.isOpen)
            parent.classList.add(style.isClose)
        }
        else {
            parent.classList.remove(style.isClose)
            parent.classList.add(style.isOpen)
        }
    }

    return (
        <div className={style.container}>
            <div className={style.add__navigation}>
                <div></div>
                <p>{t('partners.addExercise')}</p>
                <button onClick={closeModal}><CloseIcon /></button>
            </div>
            <div className={style.add__superCategories}>{t('partners.superCategories')}</div>
            <div>
                {
                    list.map(item => (
                        <div key={item.supercategory_id} className={style.accordion}>
                            <button className={style.accordion__title} onClick={clickAccordion}>{item.supercategory_name}<CaretIcon /></button>
                            {
                                item.categories.map(category => (
                                    <div
                                        className={style.accordion__item}
                                        key={category.category_id}
                                        onClick={() => openCoursesModal(category.category_id)}
                                    >
                                        {category.category_name}
                                    </div>
                                ))
                            }
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default SuperCategories;