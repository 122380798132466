import axios from 'axios';
import {FETCH_PROFILE_SUCCESS, FETCH_PROFILE_ERROR, FETCH_PROFILE_START} from './types';
import {GET_USER_INFO, POST_USER_INFO} from 'services/api';

export function getProfileInfo(callback) {
  return async (dispatch) => {
    if (JSON.parse(localStorage.getItem('role')) === 'teacher') return false;
    dispatch({ type: FETCH_PROFILE_START });

    try {
      const res = await axios.get(GET_USER_INFO);
      const finalRes = await res.data.name==null ? axios.post(POST_USER_INFO,{
        ...res.data, name: `user${res.data.id}`
      }) : res

      dispatch({ type: FETCH_PROFILE_SUCCESS, payload: {...finalRes.data, role: JSON.parse(localStorage.getItem('role')) || 'default'}});
      callback && callback()
    } catch(error) {
      dispatch({
        type: FETCH_PROFILE_ERROR,
        payload: error.message
      });
    }
  };
}