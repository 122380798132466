import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {signInAction} from "actions/signIn";
import scriptjs from "scriptjs";
import {useDispatch} from "react-redux";
import Loader2 from "../../common/Loader/loader2";
import {showErrorMessage} from 'helpers/notifications';

export default function AppleEnterItem({closeModal}) {
  const [loading, setLoading] = useState(true);
  const {t} = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    scriptjs.get('https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js', () => {
      const params = {
        clientId: 'web.abchess.kz',
        redirectURI: 'https://ab-chess.com/apple_auth',
        scope: 'email',
        usePopup : true
      };
      setLoading(false);
      window.AppleID.auth.init(params);
    });

    document.addEventListener('AppleIDSignInOnSuccess', onAppleSuccess);
    document.addEventListener('AppleIDSignInOnFailure', onAppleError);

    return () => {
      document.removeEventListener('AppleIDSignInOnSuccess', onAppleSuccess);
      document.removeEventListener('AppleIDSignInOnFailure', onAppleError);
    }
  }, []);

  const onAppleSuccess = (data) => {
    if (data.detail.authorization.id_token) {
      dispatch(signInAction({token: data.detail.authorization.id_token}, {login_type: 'apple_web'}, () => closeModal()));
    }
  };

  const onAppleError = (error) => {
    showErrorMessage(error);
  };

  if(loading) {
    return <Loader2/>
  }

  return (
    <>
      <span className='login__enter-button' onClick={() => window.AppleID.auth.signIn()}/>
      <span className='login__enter-logo apple__logo' />
      <span className='login__enter-text'>{t('auth.apple')}</span>
    </>
  )
}