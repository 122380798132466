import MySelect from 'components/common/MySelect';
import React, { useEffect, useState } from 'react'
import './gameSettings.scss';
import './gameSettingsMobile.scss';
import { useTranslation } from 'react-i18next';

const ChooseBotDifficulty = ({ setBotDifficulty, toggleShowBotDifficulty }) => {
    const [difficulty, setDifficulty] = useState()
    const { t } = useTranslation()

    useEffect(() => {
        setBotDifficulty(difficulty)
    }, [difficulty])

    const mockOptions = [
        { label: t('game.level') + ' 1', value: "1" },
        { label: t('game.level') + ' 2', value: "2" },
        { label: t('game.level') + ' 3', value: "3" },
        { label: t('game.level') + ' 4', value: "4" },
        { label: t('game.level') + ' 5', value: "5" },
        { label: t('game.level') + ' 6', value: "6" },
        { label: t('game.level') + ' 7', value: "7" },
        { label: t('game.level') + ' 8', value: "8" },
        { label: t('game.grandmaster'), value: "9" },
    ];

    return (
        <div className='game-settings__item'>
            <div className='game-settings__label' onClick={()=>toggleShowBotDifficulty()}>
                <span className='game-settings__text'>{t('game.computer')}</span>
                <MySelect
                    isCourse={true}
                    handleChange={(value) => { setDifficulty(value) }}
                    options={mockOptions}
                    defaultValue={mockOptions[mockOptions.length - 1]}
                    inputClasses={'game-settings__select-input'}
                    titleClasses={'game-settings__select-title'}
                    listItemClasses={'game-settings__select-item game-settings__select-item--difficulty'}
                    listClasses={'game-settings__select-list game-settings__select-list--difficulty'}
                />
            </div>
        </div>
    )
}

export default ChooseBotDifficulty;