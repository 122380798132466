import React, { useCallback, useEffect, useState } from 'react';
import SectionsNavigation from './SectionsNavigation';
import SectionsCoursesGrid from './SectionsCoursesGrid';
import SectionsSuperCoursesGrid from './SuperCategories/index';
import SectionsNavigationMobile from './SectionsNavigation/SectionsNavigationMobile';
import { useLocation } from 'react-router-dom';
import ScrollToTopOnMount from "components/common/ScrollToTop";
import { useDispatch, useSelector } from "react-redux";
import { getCourses } from "actions/getCourses";
import './sections.scss';
import './sectionsMobile.scss';
import { getCoursesUrl, getPrivateSuperCategories } from "services/api";
import { useWindowSize } from "hooks/resize.hook";

const INNER_WIDTH = 650;

export default function Sections(props) {
  const [open, setOpen] = useState(false);
  const [categories, setCategories] = useState({ superCategory: '', category: '' });
  const [navigationList, setNavigationList] = useState([]);
  const windowSize = useWindowSize();
  const location = useLocation();
  const [privateCategories, setPrivateCategories] = useState([])

  const lang = useSelector(state => state.settings.lang);
  const data = useSelector(state => state.courses.coursesData);
  const coursesLoading = useSelector(state => state.courses.loading);

  const handleOpen = () => {
    setOpen(!open)
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (props.match.params.url) {
      getPrivateSuperCategories(props.match.params.url, lang).then(data => { setPrivateCategories(data.data) })
    } else {
      data.supercategories && setNavigationList(data?.supercategories)
    }

  }, [data, lang, props.match.params.url]);

  useEffect(() => {
    if (privateCategories !== navigationList && privateCategories.id && props.match.params.url) {
      setNavigationList([{ supercategory_id: privateCategories.id, supercategory_name: privateCategories.name, categories: privateCategories.categories }])
    }
  }, [privateCategories])

  useEffect(() => {
    let URL = getCoursesUrl(lang);
    //data.supercategories.length === 0 && 
    dispatch(getCourses(URL));
  }, [lang]);

  useEffect(() => {
    let superCategory = location.pathname.split('/')[2];
    let category = location.pathname.split('/')[3];
    if (props.match.params.url){
      superCategory = privateCategories.id
      category = props.match.params.id
    }
    if (category !== categories.category || superCategory !== categories.superCategory) {
      setCategories({ category, superCategory });
    }
    
  }, [location.pathname, lang, privateCategories]);


  const getFilteredData = useCallback(() => {
    if (data.length === 0) return [];
    let filteredData = [...data.supercategories];
    if (categories.superCategory) {
      filteredData = filteredData.filter(el => Number(el.supercategory_id) === Number(categories.superCategory))
    }
    if (categories.category) {
      filteredData = filteredData.map(el => {
        return { ...el, categories: el.categories.filter(item => Number(item.category_id) === Number(categories.category)) }
      })
    }

    return filteredData
  }, [categories, data, lang]);

  const getFilteredPrivateData = useCallback(() => {
    if (privateCategories.length === 0) return [];
    let filteredData = [privateCategories];
    if (categories.category && filteredData) {
      filteredData = filteredData?.map(el => {
        return { ...el, categories: el.categories.filter(item => Number(item.id) === Number(categories.category)) }
      })
    }
    return filteredData
  })

  return (
    <>
      <ScrollToTopOnMount />
      <div className='course-section'>
        <div className='course-section__wrapper'>
          {windowSize.width > INNER_WIDTH ?
            <SectionsNavigation superId={privateCategories.id} data={navigationList} slug={props.match.params.url} />
            :
            <>
              {open && <SectionsNavigationMobile data={navigationList} handleOpen={handleOpen} />}
            </>
          }
          <div className='course-section__info'>

            {props.match.params.url == undefined ?
              <SectionsCoursesGrid data={getFilteredData()} loading={coursesLoading} /> :
              <SectionsSuperCoursesGrid data={getFilteredPrivateData()} loading={coursesLoading} url={props.match.params.url}/>
            }
          </div>
          {window.innerWidth < INNER_WIDTH && <button className='course-section__menu-button' type={'button'} onClick={handleOpen} />}
        </div>
      </div>
    </>
  );
}