import React, { useEffect, useState } from 'react';
import style from './style.module.scss';
import studentListStyle from '../../Pages/PartnerPage/Lessons/Lesson/StudentsList/style.module.scss';
import { HumanIcon } from '../../../assets/img/partners';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import RatingItem from './RatingItem';
import useResolution from '../../../hooks/useResolution';
import { useStore } from 'react-redux';

const Rating = ({
  students,
  withHead = true,
  currentExercise,
  showRating,
  closeMobileRating,
  className = '',
  editFunc,
  isWebinar = false,
  isPause,
  context,
  current_name,
  isShort = false
}) => {
  const { t } = useTranslation();
  const isMobile = useResolution(900);

  useEffect(() => {
    if (isMobile) window.document.querySelector('body').classList.add('scroll-hidden');
    return () => {
      if (isMobile) window.document.querySelector('body').classList.remove('scroll-hidden');
    };
  }, []);
  return (
    <div
      className={classnames(style.container, className, {
        [style.container_noRounding]: !withHead,
        [style.rating_short]: isShort,
      })}
    >
      {withHead && (
        <div className={studentListStyle.header}>
          <p className={studentListStyle.header__title}>{t('webinar.rating')}</p>
          <div className={studentListStyle.header__studentsCount}>
            <HumanIcon />
            <p className={studentListStyle.header__studentsCount__number}>{students.length}</p>
          </div>
          {isMobile && closeMobileRating ? (
            <button className={studentListStyle.header__hide} onClick={closeMobileRating}>
              {t('webinar.hide')}
            </button>
          ) : (
            <span className={studentListStyle.header__hide_desktop}></span>
          )}
        </div>
      )}
      <table className={classnames(studentListStyle.table, style.table)}>
        <thead>
          <tr>
            <th width='20%'>№</th>
            <th>{t('partners.name')}</th>
            <th width='25%'>{t('partners.scores')}</th>
          </tr>
        </thead>
        <tbody>
          {students.map((student, index) => (
            <RatingItem
              current_name={current_name}
              currentExercise={currentExercise}
              isPause={isPause}
              showRating={showRating}
              isWebinar={isWebinar}
              editFunc={editFunc}
              key={`${student.id}_${index}`}
              student={student}
              list={students}
              index={index}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Rating;
