import React from 'react';
import SearchPageCategoryItem from "./SearchPageCategotyItem";
import SectionGridItem from "../../../common/SectionGridItem/SectionGridItem";
import {useTranslation} from "react-i18next";

export default function SearchPageResults({data, search}) {
  const {t} = useTranslation();
  return (
    <div style={{display: 'flex', width: '100%', flexDirection: 'column'}}>
      {data.find_categories.length !== 0 &&
        <>
          <h2 className='search-page__title'>{t('search.categories')}</h2>
          <ul className='search-page__list search-page__list--categories'>
            {data.find_categories.map(el =>
              <SearchPageCategoryItem {...el} search={search}/>
            )}
          </ul>
        </>
      }
      {data.find_courses.length !== 0 &&
        <>
          <h2 className='search-page__title'>{t('search.courses')}</h2>
          <ul className='search-page__list'>
            {data.find_courses.map(el =>
              <SectionGridItem {...el} />
            )}
          </ul>
        </>
      }
    </div>
  )
}