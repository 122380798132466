import React from 'react';
import {useTranslation} from "react-i18next";

export default function VkEnterItem() {
  const {t} = useTranslation();
  return (
    <>
      <span className='login__enter-logo vk__logo' />
      <span className='login__enter-text'>{t('auth.vk')}</span>
      <a href={'https://oauth.vk.com/authorize?client_id=7141743&display=popup&scope=email&redirect_uri=https://ab-chess.com/vk_auth&response_type=token'} className='login__enter-button'>{t('auth.vk')}</a>
    </>
  )
}