import React from 'react';
import s from './Fullsize.module.scss';
import { ReactComponent as ArrowIcon } from './arrow.svg'

/**
 * 
 * @param {function} onClick function which toggles the header
 * @param {boolean} open state of header
 * @returns component 20x20px 
 */

export default function Fullsize({ open, onClick }) {

    return (
        <section onClick={onClick} className={`${s['fullsize-wrapper']} ${open && s['active']}`}>
            <div className={s['fullsize-body']}>
                <div className={`${s['fullsize-body-1']} ${open && s['a']}`}><ArrowIcon /></div>
                <div className={`${s['fullsize-body-2']} ${open && s['a']}`}><ArrowIcon /></div>
                <div className={`${s['fullsize-body-3']} ${open && s['a']}`}><ArrowIcon /></div>
                <div className={`${s['fullsize-body-4']} ${open && s['a']}`}><ArrowIcon /></div>
            </div>
        </section>
    )
}