import * as Yup from "yup";
import moment from "moment";
import momentTimezone from "moment-timezone";
import classnames from "classnames";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field } from "formik";

import style from "./style.module.scss";
import Header from "../Header";

import { REQUIRED_STRING, TRIM } from "../../../../constants/validation";
import { convertDateFromLocale } from "../../../../utils/convertDateFromLocale";
import { createLesson, getLesson, getLevels } from "../../../../services/api";
import { trainingLesson } from '../../../../actions/webinar';
import { openOverlayAction } from "../../../../actions/overlay";

import { TextField } from "../../../FormikInputs/TextInput";
import SelectInput from "../../../FormikInputs/SelectInput";
import DateField from "../../../FormikInputs/DateTimeField/DatePicker";
import TimeField from "../../../FormikInputs/DateTimeField/TimePicker";
import SelectInputModified from '../../../FormikInputs/SelectInput_modified';

import Exercises from "./Exercises";
import FormAlert from "./FormAlert";
import PhotoField from "./PhotoField";
import LessonValueUpdater from "./LessonValueUpdater"

const ManageLessons = ({ mode, match }) => {
    const isEdit = mode === 'edit';
    const isCopy = mode === 'copy';
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const [lesson, setLesson] = useState({});
    const [levels, setLevels] = useState([]);
    const lang = useSelector(state => state.settings.lang)

    useEffect(() => {

        if ((isEdit || isCopy) && +match.params.id) {
            getLesson(+match.params.id)
                .then(res => setLesson(res.data))
        }
        getLevels(lang)
            .then(res => {
                const arr = res.data.reduce((acc, category) => [...acc, ...category.levels], [])
                setLevels(arr)
            })
        return () => {
            sessionStorage.removeItem('exercises')
        }
    }, [])

    const openExercisesPopup = (exercises, setValue) => {
        dispatch(openOverlayAction(Exercises, { selectedExercises: exercises, setValue }))
    }

    const getUTC = (timezone) => {
        return moment.tz(new Date(), timezone).format().match(/(\+|-)\d{2}:.+/g);
    }

    const allTimezones = useMemo(() => {
        //Преобразовываем все возможные временные зоны типа Europa/Moscow в UTC
        const timezones = momentTimezone.tz.names().map(item => {
            let string = getUTC(item);
            return string ? `UTC${string[string.length - 1]}` : 'UTC+00:00';
        })
        // Удаляем дубли и сортируем
        return [...new Set(timezones)].sort((a, b) => parseInt(a.substring(3, 6)) - parseInt(b.substring(3, 6)));
    }, []);

    const date = useMemo(() => convertDateFromLocale(lesson.begin_date), [lesson.begin_date]);
    const selectLevels = useMemo(() => levels.map(level => { return { label: level.name, value: level.id } }), [levels.length])
    const selectLessons = useCallback((id) => {
        if (!id || levels.length === 0) return []
        else return levels.find(level => level.id === id).subjects.map(lesson => { return { value: lesson.id, label: lesson.name } })
    }, [selectLevels.length])

    const initialValues = {
        title: lesson.title || '',
        begin_date: date ? isEdit ? date.format('YYYY-MM-DD') : '' : '',
        begin_time: date ? isEdit ? `${date.format('HH:mm')}:00` : '' : '',
        theme_link: lesson.theme_link || '',
        utc_offset: lesson.utc_offset || `UTC${getUTC(momentTimezone.tz.guess())[0]}`,
        password: lesson.password || '',
        banner_link: lesson.banner_link || '',
        banner: lesson.banner || '',
        exercises: lesson.exercises || [],
        zoom_link: lesson.zoom_link || '',
        subject_id: lesson.subject ? lesson.subject.id : '',
        lesson_id: lesson.subject ? lesson.subject.level.id : '',
        description: lesson.description || '',
        is_training: false
    }

    Yup.addMethod(Yup.array, 'findIncorrectDuration', function (message, mapper = a => a) {
        return this.test('findIncorrectDuration', message, function (list) {
            return !(!!(list.find(item => item.duration === '00:00:00')));
        });
    });

    const schema = Yup.object({
        title: TRIM(REQUIRED_STRING(), 'title'),
        begin_date: REQUIRED_STRING(),
        begin_time: REQUIRED_STRING()
            .test('begin_time', t('partners.timeError'), value => /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]:00$/.test(value))
            .when('begin_date', (date, schema) => {
                return moment().format('YYYY-MM-DD') !== date
                    ? schema
                    : schema.test('begin_time_past', t('partners.pastTimeError'), value => {
                        const [hours, minutes] = moment().format('LT').split(':');
                        return +value?.slice(0, 2) > +hours || (+value?.slice(0, 2) === +hours && +value?.slice(3, 5) >= +minutes)
                    })
            })

        ,
        password: TRIM(REQUIRED_STRING(), 'password'),
        banner_link: TRIM(Yup.string(), 'banner_link'),
        exercises: Yup.array().min(1, t('partners.minExercisesError')).findIncorrectDuration(t('partners.durationError')),
        subject_id: Yup.string().when('lesson_id', (level, schema) => {
            return !!level ? schema.required(t('partners.requiredError')) : schema;
        }),
        is_training: Yup.boolean()
    });

    const handleSubmit = async (_values) => {
        const data = { ..._values };
        if (!data.subject_id) data.subject_id = null
        if (!data.description) data.description = null
        data.exercises = JSON.stringify(data.exercises)

        if (isEdit) data.class_id = match.params.id;

        const fakeData = new FormData();

        if(!data.is_training) {
          // ключ удаляется, потому что наличие поля is_training в запрос,
          // в том числе is_training:false - включает режим тренировки
          delete data['is_training'];
        }
        
        if(_values.banner) {
          fakeData.append('banner', _values.banner);
        } else if (typeof data.banner !== 'object' && data.banner.length !== 0) {
            const extension = data.banner.slice(data.banner.length - 6).split('.')[1]
            const response = await fetch(data.banner)
            const blob = await response.blob()
            data.banner = new File([blob], `image.${extension}`, { type: blob.type })
        }
        Object.entries(data).forEach(([key, value]) => {
          if(key !== 'banner') {
            fakeData.append(key, value)
          }
        })

        const training = _values.training_lesson;

        if (training) {
            
        }

        createLesson(fakeData)
            .then(() => history.push('/partner/lessons'))
    }


    const onSelectChangeCallback = (param_name, param_value) => {
      if (param_name !== 'is_training') {
        return;
      }

      dispatch(
        trainingLesson({
          webinar_name: '',
          value: param_value,
        })
      );
    };

    const trainingSelectOptions = [
        {
            label: t("partners.no"),
            value: false
        },
        {
            label: t("partners.yes"),
            value: true
        }
    ];

    return (
        <>
            <Header text={isEdit ? t('partners.edit') : t('partners.create')} />
            <div className={style.container}>
                <Formik
                    initialValues={initialValues}
                    onSubmit={values => handleSubmit(values)}
                    enableReinitialize={true}
                    validationSchema={schema}
                // validateOnBlur={false}
                // validateOnChange={false}
                >
                    {
                        ({ values, setFieldValue, errors, submitCount, dirty }) => (
                            <Form>
                                <LessonValueUpdater subjectId={values.subject_id} setValue={setFieldValue} />
                                <FormAlert errors={errors} submitCount={submitCount} />
                                <TextField
                                    name="title"
                                    label={t('partners.lessonName')}
                                    inputClasses={style.input}
                                    labelTextClasses={style.label}
                                />
                                <div className={style.row}>
                                    <DateField
                                        name="begin_date"
                                        label={t('partners.lessonDate')}
                                        minDate={new Date()}
                                    />
                                    <TimeField
                                        name="begin_time"
                                        label={t('partners.lessonTime')}
                                    />
                                </div>
                                <div className={style.row}>
                                    <TextField
                                        name="password"
                                        label={t('partners.lessonPassword')}
                                        inputClasses={style.input}
                                        labelTextClasses={style.label}
                                    />
                                    <SelectInput
                                        options={allTimezones}
                                        name="utc_offset"
                                        label={t('partners.timezone')}
                                        labelClasses={`profile__label ${style.label}`}
                                        inputClasses={style.input}
                                        listItemClasses={'profile__select-list-item'}
                                        titleClasses={style.label__title}
                                        listClasses={'profile__select-list-classes'}
                                    />
                                </div>
                                <div className={style.row_3}>
                                    <SelectInput
                                        options={selectLevels}
                                        name="lesson_id"
                                        label={t('partners.level')}
                                        labelClasses={`profile__label ${style.label}`}
                                        inputClasses={style.input}
                                        listItemClasses={'profile__select-list-item'}
                                        titleClasses={style.label__title}
                                        listClasses={'profile__select-list-classes'}
                                    />
                                    <SelectInput
                                        options={selectLessons(values.lesson_id)}
                                        name="subject_id"
                                        label={t('partners.lesson')}
                                        labelClasses={`profile__label ${style.label}`}
                                        inputClasses={style.input}
                                        listItemClasses={'profile__select-list-item'}
                                        titleClasses={style.label__title}
                                        listClasses={'profile__select-list-classes'}
                                    />
                                    <SelectInputModified
                                        onChangeCallback={onSelectChangeCallback}
                                        options={trainingSelectOptions}
                                        name="is_training"
                                        label={t('partners.trainingLessonLabel')}
                                        labelClasses={`profile__label ${style.label}`}
                                        inputClasses={style.input}
                                        listItemClasses={'profile__select-list-item'}
                                        titleClasses={style.label__title}
                                        listClasses={'profile__select-list-classes'}
                                        defaultValue={trainingSelectOptions[0]}
                                    />
                                </div>
                                <TextField
                                    name="theme_link"
                                    label={t('partners.lessonLink')}
                                    inputClasses={style.input}
                                    labelTextClasses={style.label}
                                />
                                <TextField
                                    name="zoom_link"
                                    label={t('partners.zoomLink')}
                                    inputClasses={style.input}
                                    labelTextClasses={style.label}
                                />
                                <TextField
                                    name="description"
                                    label={t('partners.description')}
                                    inputClasses={style.input}
                                    labelTextClasses={style.label}
                                />
                                <div className={style.line}></div>
                                <TextField
                                    name="banner_link"
                                    label={t('partners.lessonPhotoLink')}
                                    inputClasses={style.input}
                                    labelTextClasses={style.label}
                                />
                                <Field name="banner" component={PhotoField} />
                                <div className={style.line}></div>
                                <div className={style.row}>
                                    <button onClick={() => openExercisesPopup(values.exercises, setFieldValue)} className={classnames(style.form__button, style['form__button-yellow'])} type="button">{t('partners.exercises')}</button>
                                    <button disabled={!dirty && isEdit} className={classnames(style.form__button, style['form__button-green'])} type="submit">{isEdit ? t('partners.save') : t('partners.createLesson')}</button>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </div>
        </>
    )
}

export default ManageLessons;
