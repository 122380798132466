import React, { useContext, useEffect, useRef, useState } from "react";
import style from "./style.module.scss";
import { useTranslation } from "react-i18next";
import { goldCup_big, silverCup_big, bronzeCup_big } from "../../../../../assets/img/webinar/index";
import defaultBanner from "../../../../../assets/img/default_webinar_banner.png";
import useResolution from "../../../../../hooks/useResolution";
import html2canvas from 'html2canvas';
import classnames from "classnames";
import Cup from "./Cup";
import { getCurrentWebinarRating, getRating, getUserInfo, getWebinarResults } from "../../../../../services/api";
import { WebinarContext } from "../../../../../constants/contexts";

const Result = ({ lessonData }) => {
    const [results, setResults] = useState({});
    const [fileLink, setFileLink] = useState('');
    const [isFullList, toggleList] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const { t } = useTranslation();
    const { context } = useContext(WebinarContext);
    const isMobile = useResolution(1200);
    const ref = useRef(null);
    const linkRef = useRef(null);

    useEffect(() => {
        // setTimeout(() => {
        //     html2canvas(ref.current, {width: ref.current.clientWidth, height: ref.current.clientHeight})
        //         .then(canvas => {
        //             const image = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
        //             setFileLink(image)
        //         })
        // }, 200)
        getUserInfo(localStorage.getItem('token')).then(res => {
            setCurrentUser(res.data)
        })
        getWebinarResults(context.accessToken)
            .then(res => {
                setResults(res.data)
            })
    }, [])

    useEffect(() => {
        if (fileLink && linkRef.current) linkRef.current.click()
    }, [fileLink])

    if (!results.tops) return <div></div>

    return (
        <div className={classnames(style.container, {
            [style.container_full]: isFullList
        })}>
            {/*{fileLink && <a ref={linkRef} href={fileLink} style={{visibility: 'hidden'}} download="results.png" target="_blank"></a>}*/}
            <div className={classnames(style.results, {
                [style.results_full]: isFullList
            })} ref={ref}>
                <p className={style.results__title}>{t('webinar.webinarEnd')}</p>
                <div className={style['results-cups']}>
                    <Cup position={2} setFull={() => toggleList(true)} isFull={isFullList} winners={results.tops.second} />
                    <Cup position={1} setFull={() => toggleList(true)} isFull={isFullList} winners={results.tops.first} />
                    <Cup position={3} setFull={() => toggleList(true)} isFull={isFullList} winners={results.tops.third} />
                </div>
                {isFullList && <button className={style.hideWinners} onClick={() => toggleList(false)}>{t('webinar.hideList')}</button>}
                <div className={style['results-userResult']}>
                    <p className={style['results-userResult__name']}>{!results.student_surname ? results.student_name : `${results.student_name} ${results.student_surname}`} </p>
                    {
                        JSON.parse(localStorage.getItem('role')) !== 'teacher' && (
                            <>
                                <div className={style['results-userResult__result']}>
                                    <p className={style['results-userResult__result__name']}>{t('webinar.webinarResult')}:</p>
                                    <p className={style['results-userResult__result__score']}>+{results.class_scores} {t('webinar_rating.points')} {results.class_seat} {t('webinar_rating.place')}</p>
                                </div>
                                <div className={style['results-userResult__result']}>
                                    <p className={style['results-userResult__result__name']}>{t('webinar.monthResult')}:</p>
                                    <p className={style['results-userResult__result__score']}>{results.class_month_scores} {t('webinar_rating.points')} {results.class_month_seat} {t('webinar_rating.place')}</p>
                                </div>
                            </>
                        )
                    }
                </div>
            </div>

            {!isMobile && (
                <a
                    target="_blank"
                    href={lessonData.theme_link}
                    className={style.theme}
                    onClick={e => { if (!lessonData.theme_link) e.preventDefault() }}
                >
                    {t('webinar.hint')}
                </a>
            )}

            {!isMobile && (
                <a
                    target="_blank"
                    href={lessonData.banner_link || ''}
                    className={style.bannerWrapper}
                    onClick={e => { if (!lessonData.banner_link) e.preventDefault() }}
                >
                    <img className={!lessonData.banner ? style.noCover : ''} src={lessonData.banner || defaultBanner} alt="banner" />
                </a>
            )}
        </div>
    )
}

export default Result;
