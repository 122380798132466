import {
  FETCH_COURSES_ERROR,
  FETCH_COURSES_SUCCESS,
  FETCH_COURSES,
  CLEAR_COURSES_DATA,
  SET_LATEST_COURSES,
} from '../actions/types';

export default function (
  state = { coursesData: { supercategories: [] }, loading: false, latest_courses: [] },
  action
) {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case FETCH_COURSES:
      return { ...state, coursesData: { supercategories: [] }, loading: true };
    case FETCH_COURSES_SUCCESS:
      return { ...state, coursesData: action.payload, error: '', loading: false };
    case FETCH_COURSES_ERROR:
      return { ...state, error: action.payload, loading: false };
    case CLEAR_COURSES_DATA:
      return { ...state, coursesData: { supercategories: [] } };
    case SET_LATEST_COURSES:
      return { ...state, latest_courses: action.payload };
    default: 
    return state;
  }
  return state;
}
