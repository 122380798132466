import React, {useEffect, useRef, useState} from 'react';
import './customCarousel.scss';
import SliderSVG from "constants/SVG/SliderSVG";

export default function Carousel({data = []}) {
  const [currentClientWidth, setCurrentClientWidth] = useState(0);
  const [currentScroll, setCurrentScroll] = useState();
  const [currentScrollWidth, setCurrentScrollWidth] = useState();
  const [showButtons, setShowButtons] = useState(false);
  const container = useRef(null);
  const item = useRef();

  useEffect(() => {
    if(container.current) {
      setCurrentClientWidth(container.current.clientWidth);
      setCurrentScroll(container.current.scrollWidth - container.current.scrollLeft)
      setCurrentScrollWidth(container.current.scrollWidth);
    }
  }, [])

  const onNextClick = () => {
    container.current.scroll({
      top: 0,
      left: item.current.clientWidth + container.current.scrollLeft,
      behavior: 'smooth'
    });
    setCurrentClientWidth(container.current.clientWidth);
    setCurrentScroll(container.current.scrollWidth - container.current.scrollLeft)
    setCurrentScrollWidth(container.current.scrollWidth);
  };

  const onBackClick = () => {
    container.current.scroll({
      top: 0,
      left: container.current.scrollLeft - item.current.clientWidth,
      behavior: 'smooth'
    });
    setCurrentClientWidth(container.current.clientWidth);
    setCurrentScroll(container.current.scrollWidth - container.current.scrollLeft)
    setCurrentScrollWidth(container.current.scrollWidth);
  };

  const onContainerScroll = (e) => {
    let el = e.target;
    setCurrentClientWidth(el.clientWidth);
    setCurrentScroll(el.scrollWidth - el.scrollLeft);
    setCurrentScrollWidth(el.scrollWidth);
  };

  return (
    <>
      <div className='custom-carousel' onMouseOver={() => setShowButtons(true)} onMouseLeave={() => setShowButtons(false)}>
        <div className='custom-carousel__container' ref={container} onScroll={onContainerScroll}>
          {data.map(el =>
            <div className='custom-carousel__item' ref={item}>
              {el}
            </div>
          )}
        </div>
        {currentClientWidth !== currentScrollWidth &&
          <>
            {showButtons &&
            <>
              <button className='custom-carousel__button custom-carousel__button--back'
                      disabled={currentScroll === currentScrollWidth} onClick={onBackClick}>
                <SliderSVG side={'left'}/>
              </button>
              <button className='custom-carousel__button custom-carousel__button--next'
                      disabled={currentScroll === currentClientWidth} onClick={onNextClick}>
                <SliderSVG/>
              </button>
            </>
            }
          </>
        }
      </div>
    </>
  )
}