import React, {useCallback, useEffect, useRef} from 'react';
import './overlay.scss';
import './overlayMobile.scss';
import {useOnClickOutside} from "../../../hooks/outsideClick.hook";
import {useDispatch, useSelector} from "react-redux";
import {closeOverlayAction} from "../../../actions/overlay";
import classnames from "classnames";
import { useHistory, useLocation } from 'react-router';

const Overlay = () => {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const {modalBody: Component, params, withCloseBtn, isCentered, closeFunc} = useSelector(state => state.overlay);
  const history = useHistory()
  const location = useLocation()

  const closeModal = useCallback(() => {
    if(location.pathname.includes('webinar')){
      if(document.referrer.includes('ab-chess') || document.referrer.includes('abchess') || document.referrer.includes('localhost')){
        //history.goBack();
        
      }
    }
    if (closeFunc) {
      closeFunc()
    }
    else {
      dispatch(closeOverlayAction())
    }
  }, [closeFunc])


  const onEscPress = useCallback((e) => {
    e.keyCode === 27 && closeModal()
  }, [closeFunc]);


  useOnClickOutside(ref, closeModal)

  useEffect(() => {
    document.addEventListener('keydown', onEscPress);
    window.document.querySelector('body').classList.add('scroll-hidden');
    return () => {
      window.document.querySelector('body').classList.remove('scroll-hidden');
      document.removeEventListener('keydown', onEscPress);
    }
  }, [closeFunc]);

  return (
    <div className="overlay">
      <div className={classnames('overlay__wrapper', {
        ['isCentered']: isCentered
      })}>
        <div ref={ref} className="overlay__body">
          {withCloseBtn && (
            <span className='close-button' onClick={closeModal}>
              <svg className='close-svg' width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path className='close-svg-path' d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="#C5C6C6"/>
              </svg>
            </span>
          )}
          <Component closeModal={closeModal} {...params}/>
        </div>
      </div>
    </div>
  );
}

export default Overlay;