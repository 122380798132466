import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useHttp} from 'hooks/http.hook';
import SearchPageResults from './SearchPageResults';
import PageTitle from '../../common/PageHeader';
import Loader1 from "../../common/Loader/loader1";
import './searchPage.scss';
import {useTranslation} from "react-i18next";
import {getSearchUrl} from "services/api";

export default function SearchPage() {
  const {t} = useTranslation();
  const [searchData, setSearchData] = useState(
    {
      "find_categories": [],
      "find_courses": []
    }
  );
  const {request, loading} = useHttp();
  const location = useLocation();

  const getSearchData = async() => {
    let searchParams = new URLSearchParams(location.search);
    let key = searchParams.get('key');
    try {
      let res = await request(getSearchUrl(key));
      setSearchData(res.data)
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setSearchData(
      {
        "find_categories": [],
        "find_courses": []
      }
    );
    getSearchData();
  }, [location.search]);

  return (
    <>
      <PageTitle>{t('search.searchResults')}</PageTitle>
      <div className='search-page'>
        <div className='search-page__wrapper'>
          {loading && <Loader1/>}
          <SearchPageResults data={searchData} search={location.search && location.search.split('=')[1]}/>
        </div>
      </div>
    </>
  )
}