import React from 'react';
import GoogleLogin from "react-google-login";
import {useTranslation} from "react-i18next";
import {signInAction} from "actions/signIn";
import {useDispatch} from "react-redux";

export default function GoogleEnterItem({closeModal}) {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const responseGoogle = (response) => {
    if(response.tokenId) {
      dispatch(signInAction({token: response.tokenId}, {login_type: 'google'}, () => closeModal()));
    }
  };

  return (
    <GoogleLogin
      clientId="433794261926-vrfoqblkn8ab053pdlndia1aum24ua9s.apps.googleusercontent.com"
      buttonText={''}
      onSuccess={responseGoogle}
      onFailure={responseGoogle}
      icon={false}
      render={(props) =>
        <>
          <span className='login__enter-button' onClick={props.onClick}/>
          <span className='login__enter-logo google__logo' />
          <span className='login__enter-text'>{t('auth.google')}</span>
        </>
      }
    />
  )
}