import React, { useEffect, useState } from 'react';
import style from './style.module.scss';
import Chart from './Chart';
import Courses from './Courses';
import {
  getCoursesUrl,
  getUserStatisticsCategory,
  getUserStatisticsCourse,
  getUserStatisticsExercises,
  getUserStatisticsSuperCategory,
} from '../../../../../../services/api';
import { getCourses } from '../../../../../../actions/getCourses';
import { useDispatch, useSelector } from 'react-redux';
import Rating from '../../../index';

export default function QualitySolvedExercises() {
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.settings.lang);
  const [superCategory, setSuperCategory] = useState();
  const [category, setCategory] = useState();
  const [mainCourseId, setMainCourseId] = useState();
  const [statistics, setStatistics] = useState();

  useEffect(() => {
    let URL = getCoursesUrl(lang);
    dispatch(getCourses(URL));
  }, [lang]);

  useEffect(() => {
    if (superCategory) {
      getUserStatisticsSuperCategory(localStorage.getItem('token'), superCategory, 1).then((res) => {
        setStatistics(res.data);
      });
    }
  }, [superCategory]);

  useEffect(() => {
    if (category) {
      getUserStatisticsCategory(localStorage.getItem('token'), category, 1).then((res) => {
        setStatistics(res.data);
      });
    }
  }, [category]);

  useEffect(() => {
    if (mainCourseId) {
      getUserStatisticsCourse(localStorage.getItem('token'), mainCourseId, 1).then((res) => {
        setStatistics(res.data);
      });
    }
  }, [mainCourseId]);

  useEffect(() => {
    getUserStatisticsExercises(localStorage.getItem('token')).then((res) => {
      setStatistics(res.data);
    });
  }, []);

  return (
    <Rating>
      <div className={style.container}>
        <Chart statistics={statistics} />
        <Courses setMainCourseId={setMainCourseId} setSuperCategory={setSuperCategory} setCategory={setCategory} />
      </div>
    </Rating>
  );
}
