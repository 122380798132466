import { INCREASE_ADVERTISING, TOGGLE_ADVERTISING } from '../actions/types';
export default function(state = {open: false, count: sessionStorage.getItem('solvedCount') || 0}, action) {
  // eslint-disable-next-line default-case
  switch(action.type) {
    case INCREASE_ADVERTISING:
      return { ...state, count: state.count + 1 };
    case TOGGLE_ADVERTISING:
      return { ...state, open: !state.open };
  }
  return state;
}