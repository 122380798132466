import React, {useCallback, useEffect, useState} from 'react';
import PlayWithBot from "components/common/ChessTables/PlayWithBot";
import {useWindowSize} from 'hooks/resize.hook';
import './playWithBot.scss';
import './playWithBotMobile.scss';
import {useHistory, useLocation} from 'react-router-dom';
import PageTitle from "components/common/PageHeader";
import PlayWithBotControls from "./PlayWithBotControls";
import EmptyChessboard from "components/common/ChessTables/EmptyChessboard";
import {chess960} from "constants/chess960";
import GameSettings from "./GameSettings";
import {useHttp} from "hooks/http.hook";
import {useTranslation} from "react-i18next";
import {DEFAULT_FEN} from "components/common/ChessConst";
import {POST_GAME_BOT} from "services/api";
import {showSuccessMessage} from "helpers/notifications";
import PlayWithHuman from "../../common/ChessTables/PlayWithHuman";

const GAME_TYPES = {
  COMPUTER: "computer",
  LOCAL_HUMAN: "local_human"
};

export default function PlayWithBotPage() {
  const [isStart, setIsStart] = useState(false);
  const [settings, setSettings] = useState(false);
  const [gameType, setGameType] = useState('');
  const {t} = useTranslation();
  const {request} = useHttp();
  const windowSize = useWindowSize();
  const history = useHistory();
  const location = useLocation();

  const getRandomFen960 = () => {
    return chess960[Math.floor(Math.random() * Math.floor(chess960.length))];
  };

  useEffect(() => {
    if(!location.search) {
      setSettings(false);
      setIsStart(false);
    }
  }, [location]);

  const onStart = (gameSettings) => {
    setSettings(gameSettings);
    setIsStart(true);
    history.push('/play?start=true')
  };

  const getLevelString = () => {
    if(settings.difficulty === '9') {
      return 'Гроссмейстер'
    }
    return settings.difficulty
  };

  const sendGame = ({startFen, endFen, pgn, botLevel, userWon}) => {
    let data = new FormData();
    data.append('fen', startFen);
    data.append('end_position', endFen);
    data.append('pgn', pgn);
    data.append('bot_level', botLevel);
    data.append('user_won', userWon);

    request(POST_GAME_BOT, 'post', data)
      .then(() => {
        showSuccessMessage('Сессия успешно сохранена');
      });
  };

  const getGameType = useCallback(() => {
    if(gameType === GAME_TYPES.COMPUTER) {
      if(settings.type === 'free') {
        return <EmptyChessboard settings={settings}/>
      }
      if(settings.type === 'start') {
        return <PlayWithBot sendGame={sendGame} settings={settings} startFen={DEFAULT_FEN} TaskText={PlayWithBotControls}/>
      }
      if(settings.type === '960') {
        return <PlayWithBot settings={settings} startFen={getRandomFen960() + ' 0 1'} TaskText={PlayWithBotControls}/>
      }
    }

    if(gameType === GAME_TYPES.LOCAL_HUMAN) {
      if(settings.type === 'free') {
        return <EmptyChessboard type={'human'} settings={settings}/>
      }
      if(settings.type === 'start') {
        return <PlayWithHuman settings={settings} startFen={DEFAULT_FEN} TaskText={PlayWithBotControls}/>
      }
      if(settings.type === '960') {
        return <PlayWithHuman settings={settings} startFen={getRandomFen960() + ' 0 1'} TaskText={PlayWithBotControls}/>
      }
    }

  }, [settings]);

  const getPageTitle = () => {
    if (isStart) {
      if (gameType === GAME_TYPES.COMPUTER) {
        return t('game.level') + ': ' + getLevelString()
      }
    }
    return t('game.play')
  };

  return (
    <>
      <PageTitle>{getPageTitle()}</PageTitle>
      <div className='play-game'>
        <div className='play-game__container'>
          {!isStart &&
            <div className='play-game__type-container'>
              <div className={`play-game__type ${gameType === GAME_TYPES.COMPUTER ? 'play-game__type--active' : ''}`} onClick={() => setGameType(GAME_TYPES.COMPUTER)}>
                {t('game.playWithTheComputer')}
              </div>
              <div className={`play-game__type ${gameType === GAME_TYPES.LOCAL_HUMAN ? 'play-game__type--active' : ''}`} onClick={() => setGameType(GAME_TYPES.LOCAL_HUMAN)}>
                {t('game.playWithTheHuman')}
              </div>
            </div>
          }
          {/*<img className='game-settings__image' src={require('../../../assets/img/logo.svg')}/>*/}
          {gameType && <>{!isStart && <GameSettings mode={gameType} startCallback={onStart}/>}</>}
          {isStart && getGameType()}
        </div>
      </div>
    </>
  )
}