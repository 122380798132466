import React from 'react';
import style from './style.module.scss';

export default function ProgressBar({
                                      width,
                                      progressHeight,
                                      progressColor,
                                      percentageFontSize,
                                      percentageFontWeight,
                                    }) {
  return (
    <div className={style.progress__bar_container}>
      <div
        className={style.progress__bar}
        style={{
          height: progressHeight || '12px',
        }}
      >
        <div
          style={{
            width: `${width || 0}%`,
            backgroundColor: progressColor || '#4caf50',
            height: progressHeight || '12px',
          }}
        ></div>
      </div>
      <span
        className={style.progress__percentage}
        style={{
          fontSize: percentageFontSize || '16px',
          fontWeight: percentageFontWeight || 600,
        }}
      >
        {`${width || 0}%`}
      </span>
    </div>
  );
}
