import React from 'react';
import Loader1 from "../common/Loader/loader1";
import './startScreen.scss';

export default function StartScreen() {
  return (
    <div className='start-screen'>
      <Loader1/>
    </div>
  )
}