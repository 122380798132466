import React, { useEffect, useMemo, useState } from 'react';
import style from './style.module.scss';
import LeftCart from './components/LeftCart';
import RightCart from './components/RightCart';
import { getUserStatisticsSkills } from '../../../../../../services/api';
import BigProgressbar from '../../BigProgressbar';
import Rating from '../../../index';
import { divideObjectProperties } from '../../../util/divideObjectProperties';
import { useTranslation } from 'react-i18next';
import CognitiveSkillsModal from '../../modal/CognitiveSkillsModal';

export default function CognitiveSkills() {
  const { t } = useTranslation();
  const [statisticsSkills, setStatisticsSkills] = useState();

  const cognitiveStats = useMemo(() => {
    const ratingSum =
      statisticsSkills?.logic +
      statisticsSkills?.memory +
      statisticsSkills?.perception +
      statisticsSkills?.flexibility +
      statisticsSkills?.speed +
      statisticsSkills?.math +
      statisticsSkills?.general;
    return {
      level: Math.floor(ratingSum / 500),
      percentage: Math.floor((ratingSum % 500) / 5),
    };
  }, [statisticsSkills]);

  const cognitiveSkillsStats = useMemo(() => {
    return divideObjectProperties(statisticsSkills);
  }, [statisticsSkills]);

  useEffect(() => {
    getUserStatisticsSkills(localStorage.getItem('token')).then((res) => {
      setStatisticsSkills(res.data);
    });
  }, []);

  return (
    <Rating>
      <div className={style.container}>
        <BigProgressbar
          label={t('statistics_skills.cognitive_skills')}
          stats={cognitiveStats}
          modal={CognitiveSkillsModal}
        />
        <div className={style.bottom_carts}>
          <LeftCart statisticsSkills={cognitiveSkillsStats} />
          <RightCart statisticsSkills={cognitiveSkillsStats} />
        </div>
      </div>
    </Rating>
  );
}
