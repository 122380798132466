import React, { useEffect, useMemo, useState } from 'react';
import Cart from '../../Cart';
import style from './style.module.scss';
import Rating from '../../../index';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import LineChart from './LineChart';
import { getUserRatingDelta, getUserRatings } from '../../../../../../services/api';
import { END_LOW_RATING, START_HIGH_RATING } from './rating.const';
import { ReactComponent as InfoIcon } from '../../../svg/info.svg';
import { calculateRanking } from '../../../util/calculateRanking';


export default function MyRating() {
  const { t } = useTranslation();

  const [userRatings, setUserRatings] = useState([]);
  const [userRatingDelta, setUserRatingDelta] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    getUserRatings(localStorage.getItem('token')).then((res) => {
      setUserRatings(res.data);
    });

    getUserRatingDelta(localStorage.getItem('token')).then((res) => {
      setUserRatingDelta(res.data.delta_rating);
    });
  }, []);

  const rating = useMemo(() => {
    return userRatings && userRatings[userRatings?.length - 1]?.rating;
  }, [userRatings]);

  // Функция, которая возвращает описание рейтинга по его числовому значению
  const ratingLabel = calculateRanking(rating)

  return (
    <Rating>
      <div className={style.rating}>
        <div className={style.rating__top}>
          <Cart classname={style.left__cart}>
            <div className={style.rating__top_left}>
              <div className={style.box}>
                <div className={style.left__title}>
                  <h3 className={style.left__title__label}>{t('rating.my_rating')}</h3>
                  <span className={style.left__title__icon}><InfoIcon className={style.left__title__icon__button}
                                                                      onMouseLeave={() => setIsModalOpen(false)}
                                                                      onMouseEnter={() => setIsModalOpen(true)}/></span>
                  {isModalOpen && <div className={style.left__title__modal}>{ratingLabel?.title}</div>}
                </div>
              </div>
              <div className={style.left__body}>
                <div className={style.left__rating}>
                  <span className={style.left__my}>{rating || 0}</span>
                  <span
                    className={classnames(style.left__level, {
                      [style['left-green']]: rating >= START_HIGH_RATING,
                      [style['left-yellow']]: rating < END_LOW_RATING,
                    })}
                  >
                    {ratingLabel?.level}
                  </span>
                </div>
                <span
                  className={classnames(style.left__change, {
                    [style['left-green']]: userRatingDelta > 0,
                    [style['left-red']]: userRatingDelta < 0,
                  })}
                >
                  {userRatingDelta >= 0 ? `+${userRatingDelta}` : userRatingDelta} {t('rating.for_today')}
                </span>
              </div>
            </div>
          </Cart>
          <LineChart data={userRatings} />
        </div>
      {/*  /!*<Statistics />*!/*/}
      </div>
    </Rating>
  );
}
