import React, { useCallback, useMemo } from "react";

import VideoDisplay from "components/common/CourseInterface/MainDisplay/VideoDisplay";
import PictureDisplay from "components/common/CourseInterface/MainDisplay/PictureDisplay";
import PictureRadio from "components/common/CourseInterface/MainDisplay/TaskText/PictureRadio";
import TestRadio from "components/common/CourseInterface/MainDisplay/TaskText/TestRadio";
import TestCheckbox from "components/common/CourseInterface/MainDisplay/TaskText/TestCheckbox";
import RepeatTask from "components/common/CourseInterface/MainDisplay/TaskText/RepeatTask";
import ChessDisplay from "components/common/CourseInterface/MainDisplay/ChessDisplay";
import PlayPosition from "components/common/CourseInterface/MainDisplay/TaskText/PlayPosition";
import FindBest from "components/common/CourseInterface/MainDisplay/TaskText/FindBest";

const Exercise = ({ exercise, loading = false, isPause, counter, onMakeMove, answers = [] }) => {

  const correctAnswersLength = useMemo(() => {
    return exercise.content && exercise.content.answers && exercise.content.answers.filter(el => el.is_correct === true).length
  }, [exercise])

  const getExercise = useCallback(() => {
    switch (exercise.type) {
      case 2:
        return <VideoDisplay counter={counter} {...exercise} isPause={isPause} loading={loading} type="webinar" />
      case 3:
        return <ChessDisplay onMakeMove={onMakeMove} counter={counter} chessType="best" {...exercise} TaskText={FindBest} loading={loading} serviceMode="webinar" />
      case 4:
        return <ChessDisplay onMakeMove={onMakeMove} counter={counter} chessType="repeat" {...exercise} loading={loading} TaskText={RepeatTask} serviceMode="webinar" />
      case 5:
        return <ChessDisplay onMakeMove={onMakeMove} counter={counter} chessType="position" {...exercise} loading={loading} TaskText={PlayPosition} serviceMode="webinar" />
      case 6:
        return <PictureDisplay onMakeMove={onMakeMove} counter={counter} {...exercise} loading={loading} TaskText={correctAnswersLength === 1 ? TestRadio : TestCheckbox} type="chess" mode="webinar" />
      case 7:
        return <PictureDisplay onMakeMove={onMakeMove} counter={counter} {...exercise} loading={loading} TaskText={correctAnswersLength === 1 ? TestRadio : TestCheckbox} type="picture" mode="webinar" />
      case 8:
        return <PictureDisplay onMakeMove={onMakeMove} counter={counter} {...exercise} loading={loading} TaskText={PictureRadio} type="picture" mode="webinar" />
      default:
        return <></>
    }
  }, [exercise.id, isPause, loading])


  return (
    <>
      {getExercise()}
    </>
  )
}

export default React.memo(Exercise, (prevProps, nextProps) => {
  return prevProps.exercise.id === nextProps.exercise.id &&
    prevProps.isPause === nextProps.isPause &&
    prevProps.loading === nextProps.loading
});
