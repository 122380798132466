import React, {useEffect} from 'react';
import SubscriptionList from "../SubscriptionList";
import {useDispatch, useSelector} from "react-redux";
import {showSuccessMessage} from "helpers/notifications";
import { useHistory } from 'react-router-dom';
import {TOGGLE_LOGIN_WINDOW} from "actions/types";
import {getProfileInfo} from "../../../../actions/getProfile";
import {openOverlayAction} from "../../../../actions/overlay";
import Login from "../../../Login";

const proSubscription = [
  {id: 635602, month: "1"},
  {id: 635610, month: "3", isSpecial: true},
  {id: 635612, month: "6"}
];

export default function SubscriptionPro() {
  const auth = useSelector(state => state.auth.authenticated);
  const history = useHistory();
  const dispatch = useDispatch();
  const Paddle = window.Paddle;
  const profileData = useSelector(state => state.profile.profileData);


  useEffect(() => {
    Paddle.Setup({ vendor: 121557 });
    Paddle.Options({debug:true})
  }, []);

  const onLoginClick = () => {
    dispatch(openOverlayAction(Login))
  };

  const openCheckout  = (id) => {
    const checkout = { product: id, successCallback: successCallback };
    if(profileData.email) {
      checkout.email = profileData.email;
    }
    Paddle.Checkout.open(checkout);
  };

  const redirectToSubscription = () => {
    history.push('/subscription');
  };

  const successCallback = () => {
    dispatch(getProfileInfo(redirectToSubscription));
    showSuccessMessage('Подписка успешно куплена');
  };

  return (
    <div className='subscription__item-wrapper'>
      <SubscriptionList data={proSubscription} title={"PRO"} onItemClick={auth ? openCheckout : onLoginClick}/>
    </div>
  )
}