import React from 'react';
import {useTranslation} from "react-i18next";
import PageTitle from "../../../common/PageHeader";
import {useSelector} from "react-redux";
import ScrollToTopOnMount from "../../../common/ScrollToTop";
import SubscriptionCard from "./SubscriptionCard";
import {useWindowSize} from "hooks/resize.hook";

export default function SubscriptionMain() {
  const {t} = useTranslation();
  const profileData = useSelector(state => state.profile.profileData);
  const auth = useSelector(state => state.auth.authenticated);
  const lang = useSelector(state => state.settings.lang);
  const windowSize = useWindowSize();

  const subscriptionData = [
    {
      isActive: profileData.is_premium_basic,
      type: 'basic',
      title: 'BASIC',
      message: t('subscription.unlimitedBasic'),
      first_list: t('subscription.list_basic_1', { returnObjects: true }),
      second_list: t('subscription.list_basic_2', { returnObjects: true }),
      subscriptionData: profileData.basic_next_payment
    },
    {
      isActive: profileData.is_premium_pro,
      type: 'pro',
      title: 'PRO',
      message: t('subscription.unlimitedPro'),
      first_list: t('subscription.list_pro_1', { returnObjects: true }),
      second_list: t('subscription.list_pro_2', { returnObjects: true }),
      subscriptionData: profileData.pro_next_payment
    }
  ];

  return (
    <>
      <ScrollToTopOnMount/>
      <PageTitle>{auth ? <>{t('subscription.userSubscription')}: {profileData.email}</> : t('subscription.subscription')}</PageTitle>
      <div className='subscription'>
        <div className='subscription__info'>
          {subscriptionData.map(el => <SubscriptionCard {...el}/>)}
        </div>
        <div className='subscription__additional'>
          <p className='subscription__additional-message'>{t('subscription.additionalMessage')}</p>
        </div>
        {lang === 'eng' && <p className='subscription__additional-text'>Full 100% 30-day money back guaranteed. If ABCHESS is not for you, simply e-mail us and get your money back</p>}
        <div className='subscription__additional-list'>
          <p className='subscription__additional-item'>{t('subscription.listMessages1')}</p>
          <p className='subscription__additional-item'>{t('subscription.listMessages2')} <a href={'mailto:abchess.support@gmail.com'}>support@ab-chess.com</a></p>
        </div>
      </div>
    </>
  )
}