import React from "react";
import wQ from '../assets/img/pieces/wQ.svg';
import bQ from '../assets/img/pieces/bQ.svg';
import bK from '../assets/img/pieces/bK.svg';
import wK from '../assets/img/pieces/wK.svg';
import wB from '../assets/img/pieces/wB.svg';
import bB from '../assets/img/pieces/bB.svg';
import wP from '../assets/img/pieces/wP.svg';
import bP from '../assets/img/pieces/bP.svg';
import wR from '../assets/img/pieces/wR.svg';
import bR from '../assets/img/pieces/bR.svg';
import wH from '../assets/img/pieces/wH.svg';
import bH from '../assets/img/pieces/bH.svg';

export const PIECES = {
  wQ: ({ squareWidth }) => (
    <div
    style={{ width: squareWidth, height: squareWidth, background: `url(${wQ}) center center / 100% no-repeat `, willChange: 'transform', overflow: 'hidden' }}
    ></div>
  ),
  bQ: ({ squareWidth }) => (
    <div
      style={{ width: squareWidth, height: squareWidth, background: `url(${bQ}) center center / 100% no-repeat `, willChange: 'transform', overflow: 'hidden' }}
    ></div>
  ),
  wB: ({ squareWidth }) => (
    <div
      style={{ width: squareWidth, height: squareWidth, background: `url(${wB}) center center / 100% no-repeat `, willChange: 'transform', overflow: 'hidden' }}
    ></div>
  ),
  bB: ({ squareWidth }) => (
    <div
      style={{ width: squareWidth, height: squareWidth, background: `url(${bB}) center center / 100% no-repeat `, willChange: 'transform', overflow: 'hidden' }}
    ></div>
  ),
  wR: ({ squareWidth }) => (
    <div
      style={{ width: squareWidth, height: squareWidth, background: `url(${wR}) center center / 100% no-repeat `, willChange: 'transform', overflow: 'hidden' }}
    ></div>
  ),
  bR: ({ squareWidth }) => (
    <div
      style={{ width: squareWidth, height: squareWidth, background: `url(${bR}) center center / 100% no-repeat `, willChange: 'transform', overflow: 'hidden' }}
    ></div>
  ),
  wN: ({ squareWidth }) => (
    <div
      style={{ width: squareWidth, height: squareWidth, background: `url(${wH}) center center / 100% no-repeat `, willChange: 'transform', overflow: 'hidden' }}
    ></div>
  ),
  bN: ({ squareWidth }) => (
    <div
      style={{ width: squareWidth, height: squareWidth, background: `url(${bH}) center center / 100% no-repeat `, willChange: 'transform', overflow: 'hidden' }}
    ></div>
  ),

  bP: ({ squareWidth }) => (
    <div
      style={{ width: squareWidth, height: squareWidth, background: `url(${bP}) center center / 100% no-repeat `, willChange: 'transform', overflow: 'hidden' }}
    ></div>
  ),
  wP: ({ squareWidth }) => (
    <div
      style={{ width: squareWidth, height: squareWidth, background: `url(${wP}) center center / 100% no-repeat `, willChange: 'transform', overflow: 'hidden' }}
    ></div>
  ),
  bK: ({ squareWidth }) => (
    <div
      style={{ width: squareWidth, height: squareWidth, background: `url(${bK}) center center / 100% no-repeat `, willChange: 'transform', overflow: 'hidden' }}
    ></div>
  ),
  wK: ({ squareWidth }) => (
    <div
      style={{ width: squareWidth, height: squareWidth, background: `url(${wK}) center center / 100% no-repeat `, willChange: 'transform', overflow: 'hidden' }}
    ></div>
  )
};