import React, { useEffect } from 'react';
import { useField, useFormikContext } from 'formik';
import InputError from '../../common/InputError'
import ProfileCalendar from '../../common/Calendare/Calendare'
import useResolution from 'hooks/useResolution';
import closeIcon from '../../../assets/img/abclass/closeIcon.svg';

function formateDate(newDate) {
    let [date, month, year] = newDate.replace(/\./g, '/').split('/')
    if (parseInt(month) > 12) {
        [month, date] = [date, month]
    }
    return `${month}/${date}/${year}`
}

function generateResultDate(newDate) {
    let [month, date, year] = newDate.replace(/\./g, '/').split('/')
    if (parseInt(month) > 12) {
        [month, date] = [date, month]
    }
    return `${parseInt(date) < 10 ? "0" + parseInt(date) : date}.${parseInt(month) < 10 ? "0" + parseInt(month) : month}.${year}`
}

export const ProfileDate = ({ label, format, setOpenDatePicker, openDatePicker, modalRef, btnRef, additionalComponent, isRequired, labelClasses, dateRef, inputClasses, labelTextClasses, errorMode, ...props }) => {
    const [field, meta] = useField(props);
    const { setFieldValue, values } = useFormikContext();
    const isMobile = useResolution(541);
    const [calendarDate, setCalendarDate] = React.useState(formateDate(values.birth_date || new Date().toLocaleDateString()))
    useEffect(() => {
        setCalendarDate(formateDate(values.birth_date))
    }, [values.birth_date])

    const customOnChange = (e) => {
        setFieldValue(props.name, format ? format(e.target.value) : e.target.value);
    };

    const onDateSelected = React.useCallback((date) => {
        setFieldValue('birth_date', generateResultDate(date)); setOpenDatePicker(false); isMobile && setTimeout(() => {
            window.scrollTo(0, dateRef.current?.offsetTop)
        }, 1)
    }, [isMobile, dateRef])

    return (
        <label ref={dateRef} className={`${labelClasses ? labelClasses : ''}`} htmlFor={props.id || props.name}>

            <p className={`${labelTextClasses ? labelTextClasses : ''}`}>{label}{isRequired && <i className=''>*</i>}</p>
            <div style={{ position: 'relative' }}>
                <input className={`${inputClasses} ${meta.touched && meta.error ? 'text-input--warn' : ''}`} {...field} {...props} value={values.birth_date.replace(/\//g, '.')} onChange={customOnChange} />
                <button ref={btnRef} type='button' className={`calendar__icon`} onClick={() => setOpenDatePicker(!openDatePicker)}>
                    <svg xmlns="http://www.w3.org/2000/svg" ariaHidden="true" focusable="false" dataPrefix="far" dataIcon="calendar-alt" class="svg-inline--fa fa-calendar-alt fa-w-14" role="img" viewBox="0 0 448 512"><path fill="currentColor" d="M148 288h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm108-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 96v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm192 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96-260v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h48V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h128V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h48c26.5 0 48 21.5 48 48zm-48 346V160H48v298c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z" /></svg>
                </button>
            </div>

            <div ref={modalRef} className={`datepicker ${openDatePicker && 'open'}`}>
                {isMobile && <span onClick={() => {
                    setOpenDatePicker(false); setTimeout(() => {
                        window.scrollTo(0, dateRef.current?.offsetTop)
                    }, 1)
                }} className={'datepicker__close'}><img src={closeIcon} alt='X' /></span>}
                <ProfileCalendar datePosition={'center'} value={calendarDate} onClick={onDateSelected} />
            </div>

            {additionalComponent ? additionalComponent : ''}
            {meta.touched && meta.error ? (
                <InputError>{meta.error}</InputError>
            ) : null}
        </label>
    );
};