import React from "react";
import style from "./style.module.scss";
import classnames from "classnames";

const Level = ({level}) => {
    return (
        <div key={level.id} className={classnames(style.level, {
            [style.level_trial]: !!level.is_trial
        })}>
            <div style={{background: `#${level.color}`}} className={classnames({
                [style['level__logo-trial']]: !!level.is_trial,
                [style.level__logo]: !level.is_trial
            })}>{level.name}</div>
            <div className={style.level__dot}></div>
            <p className={style.level__description}>{level.title}</p>
        </div>
    )
}

export default Level;