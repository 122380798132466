import React, {useEffect, useState} from "react";
import style from "./style.module.scss";

const FormAlert = ({errors, submitCount}) => {
    const [trigger, setTrigger] = useState(submitCount);

    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
        const timer = setTimeout(() => {
            setTrigger(0);
        }, 5500);

        setTrigger(submitCount);

        return () => {
            clearTimeout(timer)
        }

    }, [submitCount])

    return (
        errors.exercises && trigger > 0
            ? <p className={style.formAlert}>{errors.exercises}</p>
            : <></>
    )
}

export default React.memo(FormAlert, (prevProps, nextProps) => {
    return prevProps.submitCount === nextProps.submitCount &&
        prevProps.errors.exercises === nextProps.errors.exercises
});