import style from './style.module.scss';

import classnames from 'classnames';
import React, { useContext, useEffect, useMemo, useState } from 'react';

import { WebinarContext } from 'constants/contexts';

import Exercise from './Exercise';
import TimeCountdown from './TimeCountdown';

const Exercises = ({
  beginDate,
  currentExercise,
  exercises,
  onMakeMove,
}) => {
  const { context } = useContext(WebinarContext);
  const [isStart, setStart] = useState(context.status === 'BEGIN');
  const [loading, setLoading] = useState(true);

  const counter = useMemo(() => {
    const index = exercises.findIndex((item) => item.id === currentExercise.exercise_id);
    return `${index + 1}/${exercises.length}`;
  }, [currentExercise.exercise_id, exercises]);

  // !!! Лоадер лучше не убирать, иначе сломается обновление упражнения
  useEffect(() => {
    let timeout;
    if (currentExercise.id) {
      setLoading(true);
      timeout = setTimeout(() => setLoading(false), 300);
    }
    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [currentExercise.id]);

  useEffect(() => {
    if (isStart) {
      context.socket.emit('exercise', {});
    }
  }, [context.socket, isStart]);

  return (
    <div
      className={classnames(style.container, {
        [style.notStart]: !isStart,
      })}>
      {isStart ? (
        currentExercise.hasOwnProperty('exercise') && (
          <Exercise
            counter={counter}
            exercise={currentExercise.exercise}
            isPause={false}
            loading={loading}
            is_training={true}
            onMakeMove={onMakeMove}
          />
        )
      ) : (
        <TimeCountdown setStart={setStart} beginDate={beginDate} />
      )}
    </div>
  );
};

export default React.memo(Exercises, (prevProps, nextProps) => {
  return (
    prevProps.currentExercise.id === nextProps.currentExercise.id
  );
});
