import React from 'react';
import {useLocation, Link} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {openOverlayAction} from "../../../../actions/overlay";
import Login from "../../../Login";
import SubscriptionPopup from "../../SubscriptionPopup";

export default function TaskItem({title, preview_image, isActive, number_of_video, number_id_exercise, id, is_free, data}) {
  const {t} = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const {authenticated} = useSelector(state => state.auth)

  const openModal = () => {
      if (authenticated) {
          dispatch(openOverlayAction(SubscriptionPopup, {
              paddleId: data.paddle_id || null,
              category: data.type_subscription,
              title: data.course_title
          }))
      }
      else {
          dispatch(openOverlayAction(Login, {}, true,false))
      }
  }

  const getItemClasses = () => {
    let classes = '';
    if(isActive) {
      classes += 'tasks__item tasks__item--active ';
    }
    if(!is_free) {
      classes += 'tasks__not-free';
    }
    return classes;
  };

  if(is_free) {
    return (
      <Link className={`tasks__item ${getItemClasses()}`} to={location.pathname.includes('super-categories') ? `${location.pathname}/lesson/${id}` :`${location.pathname}/lesson/${id}`}>
        <span className='tasks__image' style={{ backgroundImage: `url('${preview_image}')`}}>
          {!is_free && <span className='tasks__overlay'/>}
        </span>
        <div className='tasks__info'>
          <p className='tasks__title'>{title}</p>
          <p className='tasks__count'>{number_of_video ? `${t('exercises.video')}: ${number_of_video}` : ''} {number_id_exercise && number_of_video ? '/' : ''} {number_id_exercise ? `${t('exercises.exercises')}: ${number_id_exercise}` : ''}</p>
        </div>
      </Link>
    )
  }

  return (
    <div className={`tasks__item ${getItemClasses()}`} onClick={openModal}>
      <span className='tasks__image' style={{ backgroundImage: `url('${preview_image}')`}}>
        {!is_free && <span className='tasks__overlay'/>}
      </span>
      <div className='tasks__info'>
        <p className='tasks__title'>{title}</p>
        <p className='tasks__count'>{number_of_video ? `${t('exercises.video')}: ${number_of_video}` : ''} {number_id_exercise && number_of_video ? '/' : ''} {number_id_exercise ? `${t('exercises.exercises')}: ${number_id_exercise}` : ''}</p>
      </div>
    </div>

  )
}