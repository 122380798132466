import React, { useEffect, useState, useRef  } from 'react';
import './subscriptionPopup.scss';
import './subscriptionPopupMobile.scss';
import Or from "../Or/Or";
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {useSelector} from "react-redux";
import {showSuccessMessage} from "helpers/notifications";
import {getPriceFromPaddleGross} from "helpers/currencyHelper";
import Loader3 from "../Loader/loader3";

export default function SubscriptionPopup({ title, closeModal, category, paddleId }) {
  const [price, setPrice] = useState('');
  const [successPayment, setSuccessPayment] = useState(false);
  const Paddle = window.Paddle;
  const {t} = useTranslation();
  const profileData = useSelector(state => state.profile.profileData);

  useEffect(() => {
    Paddle.Setup({ vendor: 121557 });
  }, []);

  const getPrice = (data) => {
    let str = data.price?.gross || '';
    setPrice(getPriceFromPaddleGross(str))
  };

  useEffect(() => {
    if(paddleId) {
      Paddle && Paddle.Product.Prices(paddleId, 1, getPrice)
    }
  }, [paddleId]);


  const successCallback = () => {
    showSuccessMessage("Успешно");
    setSuccessPayment(true);
    setTimeout(() => {
      window.location.reload();
    }, 3000)
  };

  const openCheckout = () => {
    const checkout = { product: paddleId, quantity: 1, successCallback: successCallback };
    if(profileData.email) {
      checkout.email = profileData.email;
    }
    Paddle.Checkout.open(checkout);
  };

  if(successPayment) {
    return (
      <div className='subscription-popup'>
        <div className='subscription-popup__container'>
          <div className='subscription-popup__content-wrapper'>
            <Loader3/>
            {t('loaders.pleaseWait')}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='subscription-popup'>
      <div className='subscription-popup__container'>
        <div className='subscription-popup__content-wrapper'>
          {paddleId &&
            <>
              <p className='subscription-popup__title'>{t('payment.buyCourse')}</p>
              <p className='subscription-popup__title'>{title}</p>
              <p className='subscription-popup__text'>{t('payment.getAccessCourse')}</p>
              <button className='subscription-popup__button' type='button' onClick={openCheckout}>{t('payment.buyFor')} {price || ''}</button>
            </>
          }
          {category && paddleId && <Or/>}
          {!category && !paddleId &&
          <div className='subscription-popup__work-in-progress'>
            {t('payment.dev')}
          </div>
          }
          {!!category &&
            <>
              <p className='subscription-popup__title'>{t('payment.buySubscription')} {category.toUpperCase()}</p>
              <p className='subscription-popup__text'>{t('payment.getAccessLessons')} {category.toUpperCase()}</p>
              <Link className='subscription-popup__button' to={category && `/subscription/${category}`} style={{textTransform: 'none'}}>{t('payment.choosePlan')}</Link>
            </>
          }
        </div>
      </div>
    </div>
  )
}