import React, {useEffect} from "react";

const LessonValueUpdater = ({subjectId, setValue}) => {
    useEffect(() => {
        setValue('lesson', '')
    }, [subjectId])

    return <></>
}

export default React.memo(LessonValueUpdater, (prevProps, nextProps) => {
    return prevProps.subjectId === nextProps.subjectId
})