import React from "react";
import './video.scss';
import Player from '@vimeo/player/';
import axios from 'axios';
import { connect } from 'react-redux';
import {addCompletedExercises} from "actions/addCompletedExercise";
import {POST_COMPLETED_EXERCISE} from "services/api";

class VimeoComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
     completed: false,
    };

    this.myRef = React.createRef();
  }

  updateStateTime = () => {
    Promise.all([this.player.getDuration(), this.player.getPlayed()]).then(res => {
      if (res[0] / 2 <= this.getVideoPlayedTime(res[1])) {
        if (!this.state.completed && this.props.auth) {
          this.setState({completed: true});
          let formData = new FormData();
          formData.append('attempts', '0');
          formData.append('hint_is_used', '0');
          formData.append('exercise_id', this.props.id);
          axios.post(POST_COMPLETED_EXERCISE, formData);
          this.props.addCompletedExercises(this.props.id);
          this.player.off("play", this.updateStateTime);
        }
      }
    })
  };

  componentDidMount() {
    this.player = new Player(this.myRef.current);
    if(this.props.id) {
      this.player.on("timeupdate", this.updateStateTime);
    }
    this.player.on("loaded", error => {});
  }

  getTime = () => {
    return this.player.getCurrentTime().then((res) => {
      return res;
    })
  };

  getVideoPlayedTime = (arr) => {
    let time = 0;
    arr.forEach(el => {
      let played = el[1] - el[0];
      time+=played
    });
    return time
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.isPause !== this.props.isPause && this.props.isPause) {
      this.player.pause()
    }
  }


  componentWillUnmount() {
    this.player = null;
  }

  render() {
    const {data, videoSrc, exerciseData} = this.props;

    return (
      <div className='video-display' style={{backgroundColor: "black"}}>
        <div style={{padding: `${this.props.type === 'webinar' ? '445px' : '56.25%'} 0 0`, position:'relative'}}>
          {(data || exerciseData) &&
          <iframe
            id='Vimeo'
            title='Vimeo'
            src={videoSrc}
            style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', maxHeight: '100vh'}} frameBorder="0"
            allow="autoplay; fullscreen" allowFullScreen ref={this.myRef}/>
          }
        </div>
        <script src='https://player.vimeo.com/api/player.js'/>
      </div>
    )
  };
}


function mapStateToProps(state) {
  const { settings } = state;
  return { isFullscreen: settings.fullscreen, exercisesList: settings.exercisesList}
}

export default connect(mapStateToProps, { addCompletedExercises })(VimeoComponent);