import React from 'react';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

function ApproveMessage({ closeModal }) {
  const {t} = useTranslation();

  return (
    <div className='login__approve'>
      {t('auth.byRegister')}<br/><span className='login__approve-link'>{t('auth.termsOfUse')}</span> {t('auth.and')} <br/><Link to={'/politics'} onClick={closeModal} className='login__approve-link'>{t('auth.dataProtection')}</Link>
    </div>
  );
}

export default ApproveMessage;