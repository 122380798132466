import React, {useEffect, useState} from 'react';
import './buyCourseCard.scss';
import './buyCourseCardMobile.scss';
import {getPriceFromPaddleGross} from "helpers/currencyHelper";
import {showSuccessMessage} from "helpers/notifications";
import {useSelector} from "react-redux";
import {useWindowSize} from "hooks/resize.hook";
import {useTranslation} from "react-i18next";

export default function BuyCourseCard({data}) {
  const [price, setPrice] = useState('');
  const Paddle = window.Paddle;
  const profileData = useSelector(state => state.profile.profileData);
  const windowSize = useWindowSize();
  const {t} = useTranslation();

  useEffect(() => {
    Paddle.Setup({ vendor: 121557 });
  }, []);

  const getPrice = (data) => {
    let str = data.price?.gross || '';
    setPrice(getPriceFromPaddleGross(str))
  };

  useEffect(() => {
    if(data.paddle_id) {
      Paddle && Paddle.Product.Prices(data.paddle_id, 1, getPrice)
    }
  }, [data.paddle_id]);

  const successCallback = () => {
    showSuccessMessage("Успешно");
    setTimeout(() => {
      window.location.reload();
    }, 3000)
  };

  const openCheckout = () => {
    const checkout = { product: data.paddle_id, quantity: 1, successCallback: successCallback };
    if(profileData.email) {
      checkout.email = profileData.email;
    }
    Paddle.Checkout.open(checkout);
  };

  return (
    <div className='buy-card'>
      <div className='buy-card__container'>
        <div className='buy-card__header'>
          {windowSize.width > 700 && <img className='buy-card__image' src={data.course_image}/>}
          <div className='buy-card__info-wrapper'>
            <h2 className='buy-card__title'>{data.course_title}</h2>
            <p className='buy-card__author'>{t('courseBuy.author')}: {data.course_author}</p>
          </div>
          <span className='buy-card__price'>{price}</span>
        </div>
        <div className='buy-card__block'>
          <p className='buy-card__text'>{t('courseBuy.buyInclude')}:</p>
          <ul className='buy-card__list'>
            <li className='buy-card__list-item'>
              <span className='buy-card__item-img buy-card__item-img--exercise'/>
              <p>{data.count_exercises}</p>
            </li>
            <li className='buy-card__list-item'>
              <span className='buy-card__item-img buy-card__item-img--forever'/>
              <p>{t('courseBuy.lifetimeAccess')}</p>
            </li>
            {data.preview_video_duration &&
              <li className='buy-card__list-item'>
                <span className='buy-card__item-img buy-card__item-img--video'/>
                <p>{data.preview_video_duration}</p>
              </li>
            }
            <li className='buy-card__list-item'>
              <span className='buy-card__item-img buy-card__item-img--platform'/>
              <p>{t('courseBuy.pkAccess')}</p>
            </li>
          </ul>
        </div>
      </div>
      <div className='buy-card__button-container'>
        <button className='buy-card__button' type={'button'} onClick={openCheckout}>{t('courseBuy.buyFor')} {price}</button>
      </div>
    </div>
  )
}