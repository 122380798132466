import React from 'react';
import './about.scss';
import AboutImageContainer from "./AboutImageContainer";
import AboutDescription from "./AboutDescription";
import AboutSkills from "./AboutSkills";
import {useTranslation} from "react-i18next";
import AboutContacts from "./AboutContacts";
import ScrollToTopOnMount from "../../common/ScrollToTop";

export default function About() {
  const {t} = useTranslation();

  return (
    <>
      <ScrollToTopOnMount/>
      <div className='about'>
        <AboutImageContainer>{t('about.mainTitle')}</AboutImageContainer>
        <img className='about__logo' width={300} src={require('../../../assets/img/logo.svg')}/>
        <AboutDescription>
          {t('about.descr1')}
        </AboutDescription>
        <div className='about__description-wrapper'>
          <AboutDescription half={true}>
            <h2 className='about__description-title'>BASIC</h2>
            {t('about.descr2')}
          </AboutDescription>
          <AboutDescription half={true}>
            <h2 className='about__description-title'>PRO</h2>
            {t('about.descr3')}
          </AboutDescription>
        </div>
        <AboutSkills/>
        <AboutContacts/>
      </div>
    </>
  );
}