import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import ExerciseTitle from './ExerciseTitle/ExerciseTitle';

const MODES = {
  PLAY: 'play',
  GAME: 'game'
};

const RepeatTask = ({
  onPlayClick,
  onNextClick,
  onBackClick,
  onResetClick,
  onStartClick,
  onHintClick,
  mode,
  isPlaying,
  game,
  history,
  title,
  handleClickOrientation,
  hintIsAvailable,
  onPlayDisabled,
  serviceMode,
  isEnd,
  counter,
  isCourse
}) => {
  const [side, setSide] = useState(localStorage.getItem('side') || 'white');
  const { t } = useTranslation();

  useEffect(() => {
    handleClickOrientation(side);
  }, []);

  const onSideChange = (e) => {
    localStorage.setItem('side', e.target.value);
    handleClickOrientation(e.target.value);
    setSide(e.target.value);
  };

  return (
    <div className={classnames('chess-display__description chess-display__description--repeat', {
      'chess-display__description_webinar': serviceMode === 'webinar'
    })} style={{flex: 'auto'}}>
      <div>
        <ExerciseTitle title={t('exercises.repeat')} counter={counter} isCourse={isCourse}/>
        <p className='chess-display__name'>{title}</p>
      </div>
      <div className="chess-display__control-container chess-display__control-container_block">
        <div className='chess-display__step-container_repeat' onChange={onSideChange}>
          <p className='chess-display__side'>{t('exercises.side')}</p>
          <div>
            <input className='chess-display__radio-button-step' type='radio' name='side' id='white' value='white' checked={side === 'white'} />
            <label className='chess-display__chess-side white-pawn2__logo' htmlFor='white' />
            <input className='chess-display__radio-button-step' type='radio' name='side' id='black' value='black' checked={side === 'black'} />
            <label className='chess-display__chess-side black-pawn__logo' htmlFor='black' />
          </div>
        </div>
        <div className="marginBottom">
          {/*<span className='chess-display__dotes login__dotes login__dotes--task'/>*/}
          <p className='chess-display__side'>{t('exercises.learnSteps')}</p>
          {mode === MODES.GAME ?
            <div>
              <button className='chess-display__control-button chess-display__control-button--play' disabled={true} type={'button'} />
              <button className='chess-display__control-button chess-display__control-button--back' disabled={true} type={'button'} />
              <button className='chess-display__control-button chess-display__control-button--forward' disabled={true} type={'button'} />
              <button className='chess-display__control-button chess-display__control-button--repeat' disabled={isEnd} type={'button'} onClick={onResetClick} />
            </div>
            :
            <div>
              <button className={`chess-display__control-button chess-display__control-button--play ${isPlaying ? 'chess-display__control-button--active' : ''}`} disabled={serviceMode === 'webinar' && isEnd} type={'button'} onClick={isPlaying ? onPlayDisabled : onPlayClick} />
              <button className='chess-display__control-button chess-display__control-button--back' disabled={isPlaying} type={'button'} onClick={onBackClick} />
              <button className='chess-display__control-button chess-display__control-button--forward' disabled={isPlaying} type={'button'} onClick={onNextClick} />
              <button className='chess-display__control-button chess-display__control-button--repeat' disabled={isPlaying || isEnd} type={'button'} onClick={onResetClick} />
            </div>
          }
        </div>
        <p className='chess-display__side'>{t('exercises.repeat')}</p>
        <button className={`chess-display__start-button ${mode === MODES.GAME && 'chess-display__start-button--active'}`} disabled={serviceMode === 'webinar' && isEnd} type={'button'} onClick={() => onStartClick()}>{t('exercises.start')}</button>
      </div>
      <div>
        {/*<span className='chess-display__dotes login__dotes login__dotes--task'/>*/}
        {
          serviceMode !== 'webinar' && (
            <>
              {mode === MODES.GAME ?
                <button className={`chess-display__hint chess-display__hint--repeat hint__logo`} disabled={!hintIsAvailable} onClick={onHintClick} type={'button'} />
                :
                <button className={`chess-display__hint chess-display__hint--repeat hint__logo`} disabled={true} type={'button'} />
              }
            </>
          )
        }
      </div>
    </div>
  )
}

export default React.memo(RepeatTask, (prevProps, nextProps) => {
  return prevProps.isPlaying === nextProps.isPlaying &&
    prevProps.isEnd === nextProps.isEnd &&
    prevProps.mode === nextProps.mode &&
    prevProps.title === nextProps.title &&
    prevProps.game === nextProps.game &&
    prevProps.history.length === nextProps.history.length
})