import React, {useCallback, useRef, useState} from "react";
import style from "./style.module.scss";
import {EmojiIcon, TemplatesIcon, SendIcon} from "../../../../../../assets/img/webinar";
import {useTranslation} from "react-i18next";
import EmojiPicker from "./EmojiPicker";
import TemplatesPicker from "./TemplatesPicker";

const ChatPanel = ({sendMessage}) => {
    const [message, setMessage] = useState('');
    const [isEmojiOpen, toggleEmoji] = useState(false);
    const [isTemplatesOpen, toggleTemplates] = useState(false);
    const {t} = useTranslation();
    const ref = useRef(null);


    const checkMessage = (str) => {
        if (str.trim()) sendMessage(str.trim())
        else return false;
    }

    const enterClick = (e) => {
        if (e.key === 'Enter') {
            checkMessage(message)
            setMessage('')
        }
    }

    const selectEmoji = useCallback((event, emojiObj) => {
        setMessage(message + emojiObj.emoji)
        toggleEmoji(false)
        ref.current.focus()
    }, [message])

    const selectTemplate = useCallback((template) => {
        setMessage(message + template)
        toggleTemplates(false)
        ref.current.focus()
    }, [message])


    return (
        <div className={style.panel}>
            <div className={style['panel-emoji']} onClick={() => toggleEmoji(!isEmojiOpen)}>
                <EmojiIcon />
            </div>
            <div className={style['panel-templates']} onClick={() => toggleTemplates(!isTemplatesOpen)}>
                <TemplatesIcon />
            </div>
            <input
                type="text"
                ref={ref}
                placeholder={t('webinar.enterMessage')}
                className={style.panel__input}
                value={message}
                onKeyDown={enterClick}
                onChange={e => setMessage(e.target.value)}
            />
            <div className={style.panel__send} onClick={() => {
                checkMessage(message)
                setMessage('')
            }}>
                <SendIcon />
            </div>
            {isEmojiOpen && <EmojiPicker selectEmoji={selectEmoji} closePicker={() => toggleEmoji(false)} />}
            {isTemplatesOpen && <TemplatesPicker selectTemplate={selectTemplate} closePicker={() => toggleTemplates(false)}  />}
        </div>
    )
}

export default ChatPanel;