import React, { useEffect, useState, useRef } from 'react';
import './select.scss';

export default function MySelect({ handleChange, options, defaultValue, isWarn, placeholder, hideArrow, width, inputClasses, isCourse = false, toggleShowBotDifficulty, ...props }) {
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  useEffect(() => {
    const el = props.value ? options.find(item => item.value ? item.value === props.value : props.value === item) : ''
    setSelectedOption(el);
  }, [props.value, options.length])

  const ref = useRef();
  const handleSelect = (el) => {
    setOpen(false);
    setSelectedOption(el);
    toggleShowBotDifficulty && toggleShowBotDifficulty(false);
    handleChange && handleChange(el.value || el);
  };

  useEffect(() => {
    defaultValue && handleSelect(defaultValue);
  }, []);

  useEffect(() => {
    const listener = event => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      toggleShowBotDifficulty && toggleShowBotDifficulty(false)
      setOpen(false);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [toggleShowBotDifficulty]);

  const onKeyPress = (e, el) => {
    if (e.keyCode === 13) {
      handleSelect(el);
    }
  };

  const onOpenKeyPress = (e) => {
    if (e.keyCode === 13 || e.keyCode === 40) {
      setOpen(!open)
    }
  };

  return (
    <div className={`my-select ${isWarn ? 'my-select--warn' : ''} ${isCourse && 'course-style'} ${inputClasses}`} style={{ width: `${width || '100%'}` }} ref={ref} onClick={() => { setOpen(!open); toggleShowBotDifficulty && toggleShowBotDifficulty(!open) }} onKeyDown={onOpenKeyPress}>
      <div className='my-select__header' tabIndex={0}>
        <p className={`my-select__title ${open ? 'my-select__title--active' : ''} ${props.titleClasses ? props.titleClasses : ''}`}>{selectedOption ? selectedOption.label || selectedOption : placeholder || '-'}</p>
        {hideArrow ?
          null
          :
          <div className={`my-select__arrow-container ${open ? 'my-select__arrow-container--active' : ''}`}>
            <span className={`arrow arrow-bottom ${isCourse && 'course-arrow'}`} />
          </div>
        }
      </div>
      {open &&
        <ul className={`my-select__options-list ${props.listClasses ? props.listClasses : ''}`}>
          {options.map((el, index) =>
            <li className={`my-select__options-item ${props.listItemClasses ? props.listItemClasses : ''}`} onClick={() => handleSelect(el)} onKeyDown={(e) => onKeyPress(e, el)} key={`${el.value}_${index}`} tabIndex={0}>
              {el.label || el}
            </li>
          )}
        </ul>
      }
    </div>
  )
}
