import React from 'react'
import style from './LevelLabel.module.scss'

const LevelLabel = ({ level }) => {

    const levelColours = React.useMemo(() => ['#F5E97E', '#F5B07E', '#F57E7E', '#80D163', '#7ECAF5', '#7E91F5', '#D09DF8', '#7731CF'], [])
    return (
        <div className={style['levelLabel-wrapper']} style={{ background: levelColours[level - 1] }}>
            <h1 className={style['levelLabel-wrapper__title']}>L{level}</h1>
        </div>
    )
}

export default LevelLabel;