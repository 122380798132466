import React, {useCallback, useEffect} from 'react';
import PageTitle from "../../common/PageHeader";
import './settings.scss';
import './settingsMobile.scss';
import {signOutAction} from "actions/signOut";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from 'react-router-dom';
import RadioControls from "../../common/RadioControls";
import {SET_BOARD_COLOR, TOGGLE_MOVES, TOGGLE_SOUND} from "actions/types";
import {useTranslation} from "react-i18next";
import {CHESS_SQUARE_COLORS} from "../../common/ChessConst";

export default function SettingsPage() {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const sound = useSelector(state => state.settings.sound);
  const moves = useSelector(state => state.settings.moves);
  const chessBoardColor = useSelector(state => state.settings.chessBoardColor);

  const signOut = () => {
    dispatch(signOutAction(history));
  };

  const soundChange = useCallback(() => {
    dispatch({type: TOGGLE_SOUND});
    localStorage.setItem('sound', sound);
  }, []);

  const movesChange = useCallback(() => {
    dispatch({type: TOGGLE_MOVES});
  }, []);

  const colorChange = useCallback((item) => {
    dispatch({type: SET_BOARD_COLOR, payload: item});
    localStorage.setItem('chessBoardColor', item);
  }, []);

  useEffect(() => {
    localStorage.setItem('moves', moves);
  }, [moves]);

  useEffect(() => {
    localStorage.setItem('sound', sound);
  }, [sound]);

  return (
    <div className='settings'>
      <PageTitle>{t('settings.settings')}</PageTitle>
      <ul className='settings__list'>
        <li className='settings__item settings__item--control'>
          <span className='settings__text'>{t('settings.sound')}</span>
          <RadioControls isActive={sound} callback={soundChange}/>
        </li>
        <li className='settings__item settings__item--control'>
          <span className='settings__text'>{t('settings.moves')}</span>
          <RadioControls isActive={moves} callback={movesChange}/>
        </li>
        <li className='settings__item settings__item--column'>
          <span className='settings__text'>{t('settings.boardColor')}</span>
          <ul className='settings__colors-list'>
            {Object.entries(CHESS_SQUARE_COLORS).map(el =>
              <li className={`settings__colors-item ${el[1].value === chessBoardColor.value ? 'settings__colors-item--active' : ''}`} onClick={() => colorChange(el[1].value)} key={el[1].value}>
                <div className='settings__colors'>
                  <span className='settings__colors-example' style={el[1].DARK}/>
                  <span className='settings__colors-example' style={el[1].LIGHT}/>
                  <span className='settings__colors-example' style={el[1].LIGHT}/>
                  <span className='settings__colors-example' style={el[1].DARK}/>
                </div>
              </li>
            )}
          </ul>
        </li>
        <li className='settings__item settings__item--exit'>
          <button className='settings__exit-button' type={'button'} onClick={signOut}>{t('settings.signOut')}</button>
        </li>
      </ul>
    </div>
  )
}