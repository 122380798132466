const PAWN = 'p';
const KNIGHT = 'n';
const BISHOP = 'b';
const ROOK = 'r';
const QUEEN = 'q';
const KING = 'k';

const WHITE = 'w';
const BLACK = 'b';

const PIECE_TO_SYMBOL_MAP = {
  [PAWN + WHITE]: '♙',
  [KNIGHT + WHITE]: '♘',
  [BISHOP + WHITE]: '♗',
  [ROOK + WHITE]: '♖',
  [QUEEN + WHITE]: '♕',
  [KING + WHITE]: '♔',

  [PAWN + BLACK]: '♟',
  [KNIGHT + BLACK]: '♞',
  [BISHOP + BLACK]: '♝',
  [ROOK + BLACK]: '♜',
  [QUEEN + BLACK]: '♛',
  [KING + BLACK]: '♚',
};

export const getUnicodeSymbol = (piece, color) => PIECE_TO_SYMBOL_MAP[piece + color] || '';
