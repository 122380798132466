import { REGISTRATION, REGISTRATION_ERROR, FETCH_REGISTRATION } from '../actions/types';
export default function(state = { error: false, loading: false }, action) {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case REGISTRATION:
      return {...state, registered: true, error: false, loading: false};
    case REGISTRATION_ERROR:
      return {...state, error: action.payload, loading: false};
    case FETCH_REGISTRATION:
      return {...state, loading: true};
  }
  return state;
}

