import React from 'react';
import QuestionContainer from "components/common/QuestionContainer";

export default function SupportBlock({title, questions = []}) {
  return (
    <div>
      <h2>{title}</h2>
      {questions.map(el =>
        <QuestionContainer question={el.question} key={el.id}>
          {el.answer}
        </QuestionContainer>
      )}
    </div>
  )
}