import React from 'react';
import LazyImg from "../LazyImg";
import {useTranslation} from "react-i18next";
import './courseImage.scss';

export default function CourseImageBanner({image, is_free, type_subscription, imageClasses}) {
  const {t} = useTranslation();

  const getCourseType = () => {
    if(is_free) {
      return <span className='course-image__course-type course-image__course-type--free'>{t('main.free')}</span>
    } else if(!type_subscription) {
      return <span className='course-image__course-type'>{t('main.purchase')}</span>
    } else {
      return <span className='course-image__course-type course-image__course-type--subscription'>{t('main.subscription')} {type_subscription.toUpperCase()}</span>
    }
  };

  return (
    <div className={`course-image`}>
      <LazyImg
        image={{src : image || ''}}
        classes={`${imageClasses ? imageClasses : ''}`}
        placeholder={<span className={`${imageClasses ? imageClasses : ''}`}/>}
      />
      {getCourseType()}
    </div>
  )
}