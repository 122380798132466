import React from 'react';
import { useField } from 'formik';
import InputError from "../common/InputError";

export const TextAreaField = ({ label, labelClasses, inputClasses, errorMode, isRequired, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <label className={`${labelClasses ? labelClasses : ''}`} htmlFor={props.id || props.name}>
      {label}
      <textarea rows={6} className={`${inputClasses} ${meta.touched && meta.error ? 'text-input--warn': ''}`} {...field} {...props} />
      {meta.touched && meta.error ? (
        <InputError>{meta.error}</InputError>
      ) : null}
    </label>
  );
};