import React from 'react';
import PeopleCounter from 'components/common/PeopleCounter';
import Turn from './Turn';
import { useTranslation } from 'react-i18next';
import AudioHint from "./AudioHint";
import ExerciseTitle from './ExerciseTitle/ExerciseTitle';

const FindBest = ({ isCourse, counter, turn, title, difficulty, audioHint, complete, onBackClick, onNextClick, history, undoHistory }) => {
  const { t } = useTranslation();

  return (
    <div className='chess-display__description'>
      <ExerciseTitle title={t('exercises.bestMove')} counter={counter} isCourse={isCourse} />
      <p className='chess-display__name'>{title}</p>
      <div className='chess-display__statistic'>
        <div className='chess-display__statistic-turn'>
          <Turn turn={turn} />
        </div>
        {/*<span className='chess-display__dotes login__dotes--task'/>*/}
        {difficulty ?
          <div className="chess-display__difficulty">
            <PeopleCounter count={difficulty} />
            <p>{difficulty} {t('exercises.solved')}</p>
          </div> : null
        }
      </div>

      {complete &&
        <div className='chess-display__control-container' style={{ marginTop: '10px' }}>
          <button className='chess-display__control-button chess-display__control-button--back' disabled={history.length === 0} onClick={onBackClick} />
          <button className='chess-display__control-button chess-display__control-button--forward' disabled={undoHistory.length === 0} onClick={onNextClick} />
        </div>
      }
      {audioHint ? <AudioHint audioSrc={audioHint} /> : ''}
      <span className='chess-display__dotes chess-display__dotes--mobile login__dotes login__dotes--task' />
    </div>
  )
}

export default React.memo(FindBest, (prevState, nextState) => {
  return prevState.complete === nextState.complete &&
    prevState.audioHint === nextState.audioHint &&
    prevState.turn === nextState.turn &&
    prevState.history.length === nextState.history.length &&
    prevState.undoHistory.length === nextState.undoHistory.length
});
