export const getExerciseType = (exercise) => {
  switch (exercise?.type) {
    case 2:
      return 'VIDEO';
    case 3:
      return 'BEST_MOVE';
    case 4:
      return 'REPEAT_MOVE';
    case 5:
      return 'PLAY_POSITION';
    case 6:
    case 7:
    case 8:
      return 'TEST';
    default:
      return '';
  }
};
