import React from 'react';
import {Link} from 'react-router-dom';
import CourseImageBanner from "../../common/CourseImageBanner";
import {useWindowSize} from "hooks/resize.hook";

function MainSlide({id, title, course_author, image, index, loading, is_free, type_subscription}) {
  const windowSize = useWindowSize();

  if(loading) {
    return (
      <div className={`main-slider__item main-slider__item--loading`}>
        <div className='main-slider__item-wrapper'>
          <div className='main-slider__info'>
            <p className='main-slider__title'/>
            <p className='main-slider__text'/>
          </div>
          <div className='main-slider__image'/>
        </div>
      </div>
    )
  }

  return (
    <div className={`main-slider__item ${'main-slider__item--' + (index + 1)}`}>
      <div className='main-slider__item-wrapper'>
        <Link className='main-slider__info' to={`/course/${id}`}>
          <h2 className='main-slider__title'>{title || ''}</h2>
          <p className='main-slider__text'>{course_author || ''}</p>
        </Link>
        {windowSize.width > 650 &&
          <Link to={`/course/${id}`}>
            <CourseImageBanner
              image={image}
              is_free={is_free}
              type_subscription={type_subscription}
              imageClasses={'main-slider__image'}
            />
          </Link>
        }
      </div>
    </div>
  );
}

export default MainSlide;