import React, {useRef} from 'react';
import {useTranslation} from 'react-i18next';
import SearchCategoryItem from './SearchCategoryItem';
import SearchCourseItem from './SearchCourseItem';

export default function SearchResults({data, hideResults, search, loading}) {
  const {t} = useTranslation();
  const ref = useRef();

  if(loading) {
    return (
      <div className='search__results' ref={ref}>
        <p className='search__title'>{t('search.loading')}</p>
      </div>
    )
  }

  if(!search) {
    return (
      <div className='search__results' ref={ref}>
        <p className='search__title'>{t('search.typingMessage')}</p>
      </div>
    )
  }

  if(!data.find_categories.length && !data.find_courses.length) return <></>;

  return (
      <div className='search__results' ref={ref}>
        {data.find_categories.length > 0 &&
          <div className='search__result-item'>
            <p className='search__title'>{t('search.categories')}</p>
            <ul className='search__list'>
              {data.find_categories.map((el, index) =>
                <SearchCategoryItem {...el} hideResults={hideResults} search={search} key={'search_categories' + index}/>
              )}
            </ul>
          </div>
        }
        {data.find_courses.length > 0 &&
          <div className='search__result-item'>
            <p className='search__title'>{t('search.courses')}</p>
            <ul className='search__list'>
              {data.find_courses.map((el, index) =>
                <SearchCourseItem {...el} hideResults={hideResults} search={search} key={'search_courses' + index}/>
              )}
            </ul>
          </div>
        }
      </div>
  );
}