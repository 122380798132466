import React from 'react';
import style from './Title.module.scss';

const Title = ({ number, text }) => {
    return (
        <div className={style['title-wrapper']}>
            <div className={style['title-wrapper__number-wrapper']}>
                <div className={style['title-wrapper__number-container']}>
                    <span className={style['title-wrapper__number']}>{number}</span>
                </div>
            </div>
            <div className={style['title-wrapper__text-container']}>
                <h1 className={style['title-wrapper__text']}>{text}</h1>
            </div>
        </div>
    )
}

export default Title;