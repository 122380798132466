import React from "react";
import style from "./style.module.scss"
import classnames from "classnames";
import {useTranslation} from "react-i18next";
import {cancelLesson} from "../../../../../services/api";

const CancelLesson = ({closeModal, id, name, deleteLesson}) => {
    const {t} = useTranslation();

    const confirmCancellation = () => {
        cancelLesson(id)
            .then(() => {
                deleteLesson(id)
                closeModal();
            })
    }

    return (
        <div className={classnames(style['popup-body'], style['popup-body_big'])}>
            <p className={style['popup-body__title']}>{t('partners.cancelLessonTitle')}</p>
            <p className={style['popup-body__description']}>{t('partners.cancelDescription')} {name}?</p>
            <div className={style['popup-body__buttonsContainer']}>
                <button
                    className={classnames(style['popup-body__buttonsContainer__button'], style['popup-body__buttonsContainer__button_grey'])}
                    onClick={closeModal}
                >
                    {t('partners.no')}
                </button>
                <button
                    className={classnames(style['popup-body__buttonsContainer__button'], style['popup-body__buttonsContainer__button_red'])}
                    onClick={confirmCancellation}
                >
                    {t('partners.yes')}
                </button>
            </div>
        </div>
    )
}

export default CancelLesson