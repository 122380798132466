import React from 'react';
import Button from "../../../../common/Button";
import './confirm.scss';
import {useHttp} from "hooks/http.hook";
import {CANCEL_SUBSCRIPTION} from "services/api";
import {showErrorMessage, showSuccessMessage} from "helpers/notifications";
import {useDispatch} from "react-redux";
import {getProfileInfo} from "actions/getProfile";
import {useTranslation} from "react-i18next";

export default function CancelSubscriptionConfirm({handleClose, subscriptionType}) {
  const {t} = useTranslation();
  const {request, loading} = useHttp();
  const dispatch = useDispatch();

  const onApproveClick = async() => {
    try {
      const res = await request(CANCEL_SUBSCRIPTION, 'post', {type_sub: subscriptionType});
      if(res.status === 200) {
        dispatch(getProfileInfo());
        showSuccessMessage(t('subscription.successfullyCanceled'));
        handleClose();
      }
    } catch (e) {
      showErrorMessage(t('subscription.unsubscribeCanceled'));
      console.log(e)
    }
  };

  return (
    <div className='confirm'>
      <h2 className={'confirm__title'}>{t('subscription.cancelModalTitle')}</h2>
      <p className={'confirm__message'}>{t('subscription.cancelModalMessage')}</p>
      <div className={'confirm__buttons-container'}>
        <Button classes={'confirm__button confirm__button--cancel'} onClick={handleClose}>{t('subscription.cancel')}</Button>
        <Button classes={'confirm__button'} onClick={onApproveClick} disabled={loading}>{t('subscription.confirm')}</Button>
      </div>
    </div>
  )
}