import React from "react";
import style from "./style.module.scss";
import {useTranslation} from "react-i18next";
import classnames from "classnames";

const LessonProcess = () => {
    const {t} = useTranslation();

    return (
        <div className={style.container}>
            <div className={style.block}>
                <p className={style.block__title}>{t('abclass.lessonProcess')}</p>
                <div className={style['block-progress']}>
                    <div className={style['block-progress_red']}>
                        <div className={classnames(style['block-progress__tag'], style['block-progress__tag_top'])}>
                            <p>{t('abclass.theory')}</p>
                        </div>
                    </div>
                    <div className={style['block-progress_yellow']}>
                        <div className={classnames(style['block-progress__tag'], style['block-progress__tag_bottom'])}>
                            <p>{t('abclass.taskSolution')}</p>
                        </div>
                    </div>
                    <div className={style['block-progress_blue']}>
                        <div className={classnames(style['block-progress__tag'], style['block-progress__tag_top'])}>
                            <p>{t('abclass.gamePractice')}</p>
                        </div>
                    </div>
                    <div className={style['block-progress_purple']}>
                        <div className={classnames(style['block-progress__tag'], style['block-progress__tag_bottom'], style['block-progress__tag_reverse'])}>
                            <p>{t('abclass.independentWork')}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={style.video}>
                <iframe width="540" height="360" src="https://www.youtube.com/embed/FVflj1o6CpQ"
                        title="AB-Chess" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
            </div>
        </div>
    )
}

export default LessonProcess;