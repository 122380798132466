import React, { useEffect, useState } from 'react';
import Cart from '../../Cart';
import style from './style.module.scss';
import Rating from '../../../index';
import { getUserAchievements } from '../../../../../../services/api';
import { useTranslation } from 'react-i18next';
import Icon from './Icon/Icon';
import { useDispatch } from 'react-redux';
import { openOverlayAction } from '../../../../../../actions/overlay';
import RatingModal from '../../modal/RatingModal';
import { ReactComponent as InfoIcon } from '../../../svg/info.svg';

export default function Achievements() {
  const { t } = useTranslation();
  const [achievements, setAchievements] = useState([]);
  const [achievementsCount, setAchievementsCount] = useState(0);

  const dispatch = useDispatch();

  const achievementsCounting = (achievements) => {
    let count = 0;
    for (let i of achievements) {
      if (i.user_achieved) {
        count += 1;
      }
    }
    return count;
  };

  useEffect(() => {
    getUserAchievements(localStorage.getItem('token')).then((res) => {
      setAchievements(res.data);
      setAchievementsCount(achievementsCounting(res.data));
    });
  }, []);


  return (
    <Rating>
      <div className={style['achievements-container']}>
        <Cart classname={style.achievements__body}>
          <div className={style.achievements__header}>
            <span className={style.achievements__title}>
            {t('rating.header.achievements')}
              <InfoIcon className={style.button}
                        onClick={() => dispatch(openOverlayAction(RatingModal, {}, false, true))} />
          </span>
            <span>Получено достижений: {achievementsCount} / {achievements.length}</span>
          </div>
          <div className={style.achievements}>
            {achievements?.map((achievement, index) => (
              <Icon
                name={achievement.name}
                key={achievement.id}
                achievement={achievement}
                userAchieved={achievement.user_achieved}
                image={achievement.image}
                // description={achievement.description}/>
                description={achievement.description}/>
            ))}
          </div>
        </Cart>
      </div>
    </Rating>
  );
}
