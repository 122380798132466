import React, { useEffect, useMemo, useState } from "react";
import style from "./style.module.scss";
import ServiceRating from "../../../common/ServiceRating";
import { useTranslation } from "react-i18next";
import WebinarListMenu from "../../../common/WebinarListMenu";
import WebinarCard from "./WebinarCard";
import Navbar from "../Navbar";
import Banner from "../Banner";
import { bannerSchedule } from "../../../../assets/img/abclass/index";
import { getSchedule } from "../../../../services/api";
import LessonSignUp from "../LessonSignUp";
import useResolution from "hooks/useResolution";
import closeIcon from '../../../../assets/img/abclass/closeIcon.svg'
import { useSelector } from "react-redux";

const Schedule = () => {
    const [webinarType, setWebinarType] = useState('future');
    const [webinars, setWebinars] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showRating, setShowRating] = useState(false);
    const [openDropDown, setOpenDropDown] = useState(false);
    const isAuth = useSelector(state => state.auth.authenticated)
    const isMobile = useResolution(1023);
    const { t } = useTranslation();

    const menuLinks = useMemo(() => [
        { text: t('partners.futureLessons'), query: 'future' },
        { text: t('partners.presentLessons'), query: 'current' },
        { text: t('partners.pastLessons'), query: 'passing' },
    ], []);

    useEffect(() => {
        const body = document.querySelector('body')
        const html = document.querySelector('html')

        if (showRating && !body.classList.contains('scroll-hidden')) {
            body.classList.add('scroll-hidden')
            html.classList.add('scroll-hidden')
        } else {
            body.classList.remove('scroll-hidden')
            html.classList.remove('scroll-hidden')
        }
    }, [showRating])

    useEffect(() => {
        getSchedule(webinarType)
            .then(res => setWebinars(res.data))
    }, [webinarType])

    const Title = useMemo(() => (
        <div className={style.title}>
            {t('abclass.scheduleTitle')}
            <br />
            <p className={style.subTitle}>{t('abclass.scheduleSubtitle')}</p>
        </div>
    ), [])

    return (
        <>
            <Banner
                image={bannerSchedule}
                title={Title}
            />
            <Navbar />
            <div className={style.container}>
                <div className={style.container__left}>
                    {
                        webinars.map(item => <WebinarCard key={item.id} webinar={item} webinarType={webinarType} />)
                    }
                </div>
                {!isMobile ? <div className={style.container__right}>
                    <WebinarListMenu lessonType={webinarType} setType={setWebinarType} links={menuLinks} />
                    <div className={style.container__right__rating}>
                        <ServiceRating isHidden={!isAuth}/>
                    </div>
                </div> :
                    <div className={style.container__right}>
                        <select className={`${style.container__right__dropdown} `} value={webinarType} onChange={(e) => {
                            setWebinarType(e.currentTarget.value)
                        }} onClick={() => setOpenDropDown(!openDropDown)}>
                            {menuLinks.map((link, idx) => <option value={link.query} key={`link${idx}`}>{link.text}</option>)}
                        </select>
                        <span className={`${style['arrow']} ${openDropDown && style[`active-dropdown`]}`}></span>
                        <div className={style[`container__right__rating-area`]}>
                            <button className={style[`container__right__rating-area__open-rating`]} onClick={() => { setShowRating(true)}}>{t('abclass.openRating')}</button>
                            <div className={`${style[`container__right__rating-area__rating-list`]} ${showRating && style[`active-rating`]}`}>
                                <span onClick={() => { setShowRating(false) }} className={style['close']}><img src={closeIcon} alt='X' /></span>
                                <ServiceRating hideRating={() => setShowRating(false)} />
                            </div>
                        </div>
                    </div>}
            </div>
            <LessonSignUp />
        </>
    )
}

export default Schedule;