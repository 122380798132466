import React from 'react';
import style from './style.module.scss';
import { ReactComponent as ArrowDown } from '../../../svg/arrwo-down.svg';
import { ReactComponent as ArrowUp } from '../../../svg/arrow-up.svg';
import Course from './Course';
import classnames from 'classnames';

export default function CategoryAccordion({
  category,
  categoryActive,
  setCategoryActiveByIndex,
  index,
  courseId,
  setCourseId,
  setMainCourseId,
  setCategory,
}) {
  return (
    <div key={category.category_id}>
      <button
        onClick={() => {
          setCategoryActiveByIndex(index);
          setCategory(category.category_name);
        }}
        className={classnames(style['category-accordion-item__button'], style['category-accordion-item__title'], {
          [style['category-accordion-item__button_active']]: categoryActive[index] === true,
        })}
      >
        {category.category_name}
        {categoryActive[index] === true ? <ArrowDown /> : <ArrowUp />}
      </button>
      <div
        className={classnames({
          [style['category-accordion-item_nested']]: categoryActive[index] === false,
        })}
      >
        {category?.courses?.map((course) => {
          return (
            <Course
              key={course.id}
              course={course}
              courseId={courseId}
              setCourseId={setCourseId}
              setMainCourseId={setMainCourseId}
            />
          );
        })}
      </div>
    </div>
  );
}
