import Chess from 'chess.js';

const COLUMNS = 'abcdefgh'.split('');

function validSquare(square) {
  return typeof square === 'string' && square.search(/^[a-h][1-8]$/) !== -1;
}

function validPieceCode(code) {
  return typeof code === 'string' && code.search(/^[bw][KQRNBP]$/) !== -1;
}

export function validPositionObject(pos) {
  if (pos === null || typeof pos !== 'object') return false;

  for (let i in pos) {
    if (!pos.hasOwnProperty(i)) continue;

    if (!validSquare(i) || !validPieceCode(pos[i])) {
      return false;
    }
  }
  return true;
}

function squeezeFenEmptySquares(fen) {
  return fen
    .replace(/11111111/g, '8')
    .replace(/1111111/g, '7')
    .replace(/111111/g, '6')
    .replace(/11111/g, '5')
    .replace(/1111/g, '4')
    .replace(/111/g, '3')
    .replace(/11/g, '2');
}

function pieceCodeToFen(piece) {
  let pieceCodeLetters = piece.split('');

  if (pieceCodeLetters[0] === 'w') {
    return pieceCodeLetters[1].toUpperCase();
  }

  return pieceCodeLetters[1].toLowerCase();
}

export function objToFen(obj) {
  if (!validPositionObject(obj)) return false;

  let fen = '';

  let currentRow = 8;
  for (let i = 0; i < 8; i++) {
    for (let j = 0; j < 8; j++) {
      let square = COLUMNS[j] + currentRow;

      if (obj.hasOwnProperty(square)) {
        fen = fen + pieceCodeToFen(obj[square]);
      } else {
        fen = fen + '1';
      }
    }
    if (i !== 7) {
      fen = fen + '/';
    }
    currentRow = currentRow - 1;
  }
  fen = squeezeFenEmptySquares(fen);
  return fen;
}

function isString(s) {
  return typeof s === 'string';
}

function expandFenEmptySquares(fen) {
  return fen
    .replace(/8/g, '11111111')
    .replace(/7/g, '1111111')
    .replace(/6/g, '111111')
    .replace(/5/g, '11111')
    .replace(/4/g, '1111')
    .replace(/3/g, '111')
    .replace(/2/g, '11');
}

export function validFen(fen) {
  if (!isString(fen)) return false;
  fen = fen.replace(/ .+$/, '');

  fen = expandFenEmptySquares(fen);

  let chunks = fen.split('/');
  if (chunks.length !== 8) return false;

  for (let i = 0; i < 8; i++) {
    if (chunks[i].length !== 8 || chunks[i].search(/[^kqrnbpKQRNBP1]/) !== -1) {
      return false;
    }
  }

  return true;
}

const checkKingsInFen = (fen) => fen.includes('K') && fen.includes('k');
const checkPawnsInFen = (string) => !string.includes('P') && !string.includes('p');
const checkKingsCount = (string) => string.match(/k/g).length === 1 && string.match(/K/g).length === 1;

export const checkIsValidToStart = (fen) => {
  const fenArray = fen.split('/');
  if(checkKingsInFen(fen) && checkKingsCount(fen) && checkPawnsInFen(fenArray[0]) && checkPawnsInFen(fenArray[7])) {
    let chess = new Chess(fen + ' w - - 0 1');
    let chess2 = new Chess(fen + ' b - - 0 1');
    return !(
      chess.insufficient_material() ||
      chess.in_stalemate() ||
      chess.in_draw() ||
      chess.in_checkmate() ||
      chess.in_check() ||
      chess2.insufficient_material() ||
      chess2.in_stalemate() ||
      chess2.in_draw() ||
      chess2.in_checkmate() ||
      chess2.in_check()
    );
  }
  return false
};

export const arrayToIncorrectPgnString = (arr) => {
  let result = '';

  arr.forEach((el, index) => {
    result += `${index + 1}.${el} `;
  });

  return result;
};