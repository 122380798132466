import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import style from './style.module.scss';
import CreateLabel from '../Labels/Label';
import Button from 'components/common/Button';

const NewLabel = CreateLabel('#FF9345', '#fff', 'new');
const OnlineLabel = CreateLabel('#2D9AFF', '#fff', 'online');

const MobileMenu = ({ closeMenu, isBurgerOpen }) => {
  const ref = useRef(null);
  const { t } = useTranslation();
  const isTeacher = JSON.parse(localStorage.getItem('role')) === 'teacher';

  useEffect(() => {
    window.document.querySelector('body').classList.add('scroll-hidden');
    return () => {
      window.document.querySelector('body').classList.remove('scroll-hidden');
    };
  }, []);

  useEffect(() => {
    const listener = () => {
      if (!ref.current) {
        return;
      }
      closeMenu(false);
    };
    document.addEventListener('click', listener);

    return () => {
      document.removeEventListener('click', listener);
    };
  }, [ref]);

  return (
    <div
      className={`${style.wrapper} ${isBurgerOpen && style.open}`}
      onClick={() => {
        closeMenu(false);
      }}
    >
      <div className={style.menu}>
        <p className={style['navigation__item']}>
          <Link className={style['header__link']} to={isTeacher ? '/partner/lessons' : '/courses'}>
            {isTeacher ? t('partners.lessons') : t('main.allCourses')}
          </Link>
        </p>
        <p className={style['navigation__item']}>
          <Link className={style['header__link']} to='/abclass'>
            AB-Class
          </Link>
        </p>
        <p className={style['navigation__item']}>
          <a
            className={style['header__link']}
            href={i18next.language === 'rus' ? 'https://ab-chess.online/' : 'https://ab-chess.online/kids'}
            rel='noopener noreferrer'
            target='_blank'
          >
            AB-School
            <div className={style['item-label']}>
              <OnlineLabel />
            </div>
          </a>
        </p>

        <div className={style['second__block']}>
          <p className={`${style['navigation__item']} ${style['mh40']}`}>
            <Link className={style['header__link']} to={isTeacher ? '/partner' : '/play'}>
              {isTeacher ? t('partners.analytics') : t('menu.play')}
            </Link>
          </p>
          {!isTeacher && (
            <p className={`${style['navigation__item']} ${style['mh40']}`}>
              <Link className={style['header__link']} to={'/rating'}>
                {t('menu.rating')}
                <div className={style['item-label']}>
                  <NewLabel />
                </div>
              </Link>
            </p>
          )}
          <Link to={'/subscription'}>
            <Button classes={'header-profile__item header-profile__button'}>{t('main.subscription')}</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
