import { MAX_RATING } from '../../../../constants/maxRating';

export const divideObjectProperties = (statistics) => {
  if (typeof statistics !== 'object' || statistics === null) {
    return;
  }
  const currentStatistics = { ...statistics };

  Object.keys(currentStatistics).forEach((key) => {
    const statistic = currentStatistics[key];
    if (typeof statistic === 'number') {
      currentStatistics[key] = statistic > MAX_RATING ? 100 : Math.ceil((statistic / MAX_RATING) * 100);
    }
  });
  return currentStatistics;
};
