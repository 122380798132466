import React, {useRef} from "react";
import style from "../style.module.scss";
import {useTranslation} from "react-i18next";
import classnames from "classnames";
import {useOnClickOutside} from "../../../../../../hooks/outsideClick.hook";
import {banStudent} from "../../../../../../services/api";

const BanUserPopup = ({name, lessonId, studentId, isTemporary, closeModal, updateList}) => {
    const {t} = useTranslation();
    const ref = useRef(null);

    useOnClickOutside(ref, closeModal);

    const ban = () => {
        banStudent(lessonId, studentId, isTemporary)
            .then(() => {
                updateList()
                closeModal()
            })
    }


    return (
         <div className={style.popup}>
             <div className={style['popup-body']} ref={ref}>
                 <p className={style['popup-body__title']}>{t('partners.userBan')}</p>
                 <p className={style['popup-body__description']}>{t('partners.banDescription')} {name}?</p>
                 <div className={style['popup-body__buttonsContainer']}>
                     <button
                         className={classnames(style['popup-body__buttonsContainer__button'], style['popup-body__buttonsContainer__button_grey'])}
                         onClick={() => closeModal()}
                     >
                         {t('partners.cancel')}
                     </button>
                     <button
                         className={classnames(style['popup-body__buttonsContainer__button'], style['popup-body__buttonsContainer__button_red'])}
                         onClick={ban}
                     >
                         {t('partners.banAgree')}
                     </button>
                 </div>
             </div>
         </div>
    )
}
export default BanUserPopup;
