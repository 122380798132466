import React from 'react';
import './skills.scss';
import {useTranslation} from "react-i18next";
import AboutSkillItem from "./AboutSkillItem";

export default function AboutSkills() {
  const {t} = useTranslation();
  return (
    <div className='about__skills skills'>
      <p className='skills__title'>{t('about.skillsTitle')}</p>
      <ul className='skills__list'>
        <AboutSkillItem
          imageUrl={require('../../../../assets/img/chess-skill.png')}
          titleText={t('about.chessPlayers')}
          skillArray={[t('about.beginners'), t('about.amateurs'), t('about.professional')]}
        />
        <AboutSkillItem
          imageUrl={require('../../../../assets/img/monetization-skill.png')}
          titleText={t('about.trainers')}
          skillArray={[t('about.monetize'), t('about.audience'), t('about.promotion')]}
        />
        <AboutSkillItem
          imageUrl={require('../../../../assets/img/education-skill.png')}
          titleText={t('about.partners')}
          skillArray={[t('about.individualCoaches'), t('about.schools'), t('about.educationalInstitutions')]}
        />
      </ul>
    </div>
  )
}