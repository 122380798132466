import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './buyCoursePage.scss';
import './buyCoursePageMobile.scss';
import SectionGridItem from '../../common/SectionGridItem/SectionGridItem';
import TaskItem from '../../common/CourseInterface/TasksList/TaskItem';
import Carousel from '../../common/Carousel/Carousel';
import StudySliderItem from '../../Main/StudySlider/StudySliderItem';
import BuyCourseHeader from './BuyCourseHeader';
import ScrollToTopOnMount from '../../common/ScrollToTop';
import { useWindowSize } from '../../../hooks/resize.hook';
import PreviewModal from './BuyCoursePreview/PreviewWindow';
import PreviewButton from './BuyCoursePreview/PreviewButton';
import ModalWindow from '../../common/ModalWindow';
import { showSuccessMessage } from 'helpers/notifications';
import { useLocation } from 'react-router-dom';
import BuyCourseCard from './BuyCourseCard';
import { useTranslation } from 'react-i18next';
import useResolution from '../../../hooks/useResolution';
import { copyText } from '../../../utils/copyText';
import { jivoLangs } from 'App';

export default function BuyCoursePage({
  data = {
    related_courses: [],
    lessons: [],
    courses_images: [],
  },
}) {
  const windowSize = useWindowSize();
  const [showCard, setShowCard] = useState(false);
  const location = useLocation();
  const { t } = useTranslation();
  const isMobile = useResolution(700);

  useEffect(() => {
    window.jivo_init && window.jivo_init();
  }, []);

  useEffect(() => {
    if (location.search) {
      if (location.search.split('=')[1]) {
        setShowCard(true);
      }
    } else {
      setShowCard(false);
    }
  }, [location]);

  const onShareClick = () => {
    copyText(window.location.href).then(() => {
      showSuccessMessage(t('courseBuy.copy'), 'copy');
    });
  };

  if (showCard) {
    return <BuyCourseCard data={data} />;
  }

  return (
    <>
      <ScrollToTopOnMount />
      <div className='buy-course'>
        <BuyCourseHeader
          exercises={data.count_exercises}
          video={data.preview_video_duration}
          update={data.updated_date}
          title={data.course_title}
          description={data.adverse_description}
          subscription={data.type_subscription}
          paddleId={data.paddle_id}
          previewVideo={data.vimeo_video}
          courseImage={data.course_image}
          id={data.course_id}
        />
        <div className='buy-course__content'>
          <div className='buy-course__content-container'>
            <div className='buy-course__content-main'>
              {windowSize.width <= 950 && (
                <div className='buy-course__main-block buy-course__main-block--flex'>
                  {data.vimeo_video && (
                    <ModalWindow
                      WindowComponent={PreviewModal}
                      ButtonComponent={PreviewButton}
                      previewVideo={data.vimeo_video}
                    />
                  )}
                  <button className='buy-course__share-button' type={'button'} onClick={onShareClick} />
                </div>
              )}
              <div className='buy-course__main-block'>
                <h3 className='buy-course__title'>{t('courseBuy.description')}</h3>
                <p
                  className='buy-course__text'
                  dangerouslySetInnerHTML={{ __html: data.course_style_description }}></p>
              </div>

              <div className='buy-course__main-block'>
                <h3 className='buy-course__title'>{t('courseBuy.authorAbout')}</h3>
                <div className='buy-course__author-container'>
                  <img className='buy-course__author-image' src={data.author_image} />
                  <div className='buy-course__author-info'>
                    <h4 className='buy-course__author-name'>{data.course_author}</h4>
                    {data.author_about && (
                      <p
                        className='buy-course__author-text'
                        dangerouslySetInnerHTML={{ __html: data.author_about }}></p>
                    )}
                  </div>
                </div>
              </div>

              {data.courses_images && data.courses_images.length > 0 && (
                <div className='buy-course__main-block'>
                  <h3 className='buy-course__title'>{t('courseBuy.positions')}</h3>
                  <Carousel
                    data={data.courses_images.map((el) => (
                      <img className='buy-course__board-image' src={el} />
                    ))}
                  />
                </div>
              )}

              {data.related_courses.length > 0 && !isMobile && (
                <div className='buy-course__main-block'>
                  <h3 className='buy-course__title'>{t('courseBuy.sameCourses')}</h3>
                  <Carousel
                    data={data.related_courses.map((el) => <SectionGridItem {...el} />).splice(0, 3)}
                  />
                </div>
              )}
            </div>

            <div className='buy-course__content-bar'>
              {windowSize.width > 950 && (
                <div className='buy-course__content-bar-block'>
                  <button className='buy-course__share-button' type='button' onClick={onShareClick}>
                    {t('courseBuy.share')}
                  </button>
                </div>
              )}
              <div className='buy-course__content-bar-block buy-course__content-bar-block--task-list'>
                <h3 className='buy-course__title'>{t('courseBuy.themes')}</h3>
                <div className='buy-course__task-list'>
                  {data.lessons.map((el) => (
                    <TaskItem {...el} data={data} />
                  ))}
                </div>
              </div>
              {data.related_courses && data.related_courses.length > 3 && (
                <div className='buy-course__content-bar-block buy-course__content-bar-block--gap buy-course__content-bar-block--courses'>
                  <h3 className='buy-course__title'>{t('courseBuy.interest')}</h3>
                  <div>{data.related_courses.map((el) => <StudySliderItem {...el} />).slice(3)}</div>
                </div>
              )}
            </div>
            {isMobile && (
              <div className='buy-course__content-main'>
                {data.related_courses.length > 0 && (
                  <div className='buy-course__main-block'>
                    <h3 className='buy-course__title'>{t('courseBuy.sameCourses')}</h3>
                    <Carousel
                      data={data.related_courses.map((el) => <SectionGridItem {...el} />).splice(0, 3)}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
