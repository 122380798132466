import React, { useCallback, useEffect, useMemo, useState } from "react";
import classnames from "classnames";
import studentListStyle from "../../Pages/PartnerPage/Lessons/Lesson/StudentsList/style.module.scss";
import style from "./style.module.scss";
import { useSelector } from "react-redux";
import { bronzeCup_small, goldCup_small, silverCup_small, Pencil } from "../../../assets/img/webinar";

const RatingItem = ({ student, list, index, editFunc, isWebinar, show, showRating, isPause, current_name }) => {
    const { id, name } = useSelector(state => state.profile.profileData);
    const auth = useSelector(state => state.auth.temporary_auth);
    let student_name = '';
    if(name){
        student_name = name;
    }
    else if (auth) {
        student_name = auth.name
    }

    const [showNextScores, setShowNextScores] = useState(showRating);
    useEffect(() => {
        if (!(student.exercise_correct == null)) {
            setShowNextScores(true)
        } else {
            setShowNextScores(false)
        }
    }, [showRating, student.exercise_correct])

    const getPrize = useCallback((index) => {
        switch (index) {
            case 0:
                return <img src={goldCup_small} alt="cup" />
            case 1:
                return <img src={silverCup_small} alt="cup" />
            case 2:
                return <img src={bronzeCup_small} alt="cup" />
            default:
                return null
        }
    }, [])

    const getExerciseState = useCallback((state) => {
        switch (state) {
            case true:
                return 'success'
            case false:
                return 'failed'
            default:
                return 'in_progress'
        }
    }, [])

    const getPosition = (student, index) => {
        if (list.filter(item => item.scores === student.scores).length === 1) return `${index + 1}`
        else if (list.findIndex(item => item.scores === student.scores) === index) {
            let arrCopy = [...list];
            const lastGroupIndex = arrCopy.reduceRight((a, item, index, array) => {
                if (item.scores === student.scores) {
                    // Уменьшаем количество итераций
                    array.length = 0;
                    return a + index + 1
                }
                else return a
            }, 0)
            return `${index + 1}-${lastGroupIndex}`
        }
        else return ''
    }

    const uniqueScores = useMemo(() => {
        return [...new Set(list.map(student => student.scores))];
    }, [list])

    const isStudentDisconnected = (student) => {
        if (!student) {
            return true;
        }
        if (student.hasOwnProperty('is_active') && student.is_active === false) {
            return true;
        }
        return false;
    }

    const renderScores = (student) => {
        if (student.show_scores && isWebinar) {
            return student.scores + ` +${student.last_scores}`;
        }

        return student.scores;
    }

    return (
        <tr className={classnames(studentListStyle.table__user, {
            [style.table__currentUser]: (student.student_id === id) || (student.student_name === student_name)
        })}>
            <td width="20%" className={style.table__prize}>
                {
                    list.findIndex(item => item.scores === student.scores) === index && (
                        <div>{getPrize(uniqueScores.findIndex(item => item === student.scores))}</div>
                    )
                }
                {getPosition(student, index)}
            </td>
            <td className={style.name}>
                <p>
                    {
                        !student.student_surname && !student.student_name
                            ? `user${student.student_id}`
                            : `${student.student_name || ''} ${student.student_surname || ''}`
                    }
                </p>
                {
                    (
                        (student.student_id === id) || (current_name === student.student_name) ||
                        (current_name === `${student.student_name} ${student.student_surname}`)
                    ) &&
                    editFunc &&
                    <button className={style.pencil} onClick={editFunc}><Pencil /></button>}
            </td>
            <td width="25%" className={
                student.show_scores ? (isWebinar) ?
                style[getExerciseState(student.exercise_correct)] : '' : 'in_progress'
                }>
                {
                    renderScores(student)
                }
            </td>

        </tr>
    )
}

export default RatingItem;