import React from 'react';
import TestItem from './TestListItem';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import './testList.scss'
import './testListMobile.scss';
import Advertising from "../../Advertising";

const mockArr = [{},{},{},{},{},{},{}];

export default function TestsList({ coursesData, loading }) {
  const open = useSelector(state => state.settings.exercisesList);
  const {t} = useTranslation();

  if(loading) {
    return (
      <div className={`interface__tasks-container test test--loading ${open ? '' : 'test--hidden'}`}>
        <div className='test__list'>
          <div className='test__item test__item--head'>
            <span className='tasks__image'/>
            <div className='tasks__info'>
              <p className='tasks__title'/>
              <p className='tasks__count'/>
            </div>
          </div>
          {mockArr.map((el, index) => <TestItem {...el} key={'mock' + index}/>)}
        </div>
      </div>
    )
  }

  return (
    <>
      <Advertising/>
      <div className={`interface__tasks-container test ${open ? '' : 'test--hidden'}`}>
        <div className='test__list'>
          <div className='test__item test__item--head'>
            <span className='tasks__image' style={{backgroundImage: `url('${coursesData.preview_image}')`}}/>
            <div className='tasks__info'>
              <p className='tasks__title'>{coursesData.title}</p>
              <p className='tasks__count'>{coursesData.number_of_video ? `${t('exercises.video')}: ${coursesData.number_of_video}` : ''} {coursesData.number_id_exercise && coursesData.number_of_video ? '/' : ''} {coursesData.number_id_exercise ? `${t('exercises.exercises')}: ${coursesData.number_id_exercise}` : ''}</p>
            </div>
          </div>
          {coursesData && coursesData.exercises && coursesData.exercises.map(el => <TestItem {...el} key={el.id + 'test'}/>)}
        </div>
      </div>
    </>
  )
}