import React from 'react';
import Cart from '../../../Cart';
import style from './style.module.scss';
import ProgressbarContainer from '../../../ProgressbarContainer';
import { useTranslation } from 'react-i18next';

export default function BottomCart({ chessSkillsStatistics }) {
  const { t } = useTranslation();
  return (
    <Cart classname={style.container}>
      <ProgressbarContainer
        progressColor={'#ADE398'}
        width={chessSkillsStatistics?.checkmate}
        label={t('statistics_skills.checkmate')}
      />
      <ProgressbarContainer
        progressColor={'#0CB8FF'}
        width={chessSkillsStatistics?.tactics}
        label={t('statistics_skills.tactics')}
      />
      <ProgressbarContainer
        progressColor={'#FEDB49'}
        width={chessSkillsStatistics?.strategy}
        label={t('statistics_skills.strategy')}
      />
      <ProgressbarContainer
        progressColor={'#E65E87'}
        width={chessSkillsStatistics?.endshpil}
        label={t('statistics_skills.endshpil')}
      />
      <ProgressbarContainer
        progressColor={'#615EE6'}
        width={chessSkillsStatistics?.debut}
        label={t('statistics_skills.debut')}
      />
    </Cart>
  );
}
