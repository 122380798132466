import React, { useEffect, useState } from 'react';
import axios from 'axios';
import FindBestChessTable from 'components/common/ChessTables/FindBestChessTable';
import RepeatChessTable from 'components/common/ChessTables/RepeatChessTable';
import PlayPositionChessTable from 'components/common/ChessTables/PlayPositionChessTable';
import CompletePopup from '../CompletePopup';
import { addCompletedExercises } from 'actions/addCompletedExercise';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import CourseLoader from "../../CourseLoader";
import './chessDisplay.scss';
import './chessDisplayMobile.scss';
import { POST_COMPLETED_EXERCISE } from 'services/api';
import LessonsNavigationButton from 'components/common/LessonsNavigationButton';
import { INCREASE_ADVERTISING } from 'actions/types';
import { openOverlayAction } from "../../../../../actions/overlay";
import classnames from "classnames";

export default function ChessDisplay({
  counter,
  TaskText,
  chessType,
  content,
  onNextClick,
  onBackClick,
  id,
  title,
  loading,
  difficulty,
  class_points,
  serviceMode = 'default',
  onMakeMove,
  lessonId
}) {
  const [open, setOpen] = useState(false);
  const [scores, setScores] = useState({});
  const auth = useSelector((state) => state.auth.authenticated);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleOpen = (attempts) => {
    dispatch({ type: INCREASE_ADVERTISING });
    if (auth) {
      let data = new FormData();
      data.append('attempts', attempts);
      data.append('hint_is_used', '0');
      data.append('exercise_id', id);
      axios
        .post(POST_COMPLETED_EXERCISE, data)
        .then((res) => {
          setScores(res.data);
          setOpen(true);
          dispatch(addCompletedExercises(id));
        })
        .catch((e) => {
          console.log(e.response);
        });
    } else {
      setOpen(true);
    }
  };

  useEffect(() => {
    if (open) dispatch(openOverlayAction(CompletePopup, { onNextClick, scores }));
  }, [dispatch, onNextClick, open, scores]);

  if (loading) {
    return <CourseLoader mode={serviceMode} />;
  }

  return (
    <>
      <div
        className={classnames('chess-display', {
          'chess-display_webinar': serviceMode === 'webinar',
        })}
      >
        <div className='chess-display__container chess-display__container--buttons'>
          <div
            className={classnames('chess-display__wrapper', {
              'chess-display__wrapper_webinar': serviceMode === 'webinar',
            })}
          >
            {chessType === 'best' && (
              <FindBestChessTable
                counter={counter}
                startFen={content.fen}
                pgn={content.pgn}
                points={class_points}
                id={id}
                title={title}
                serviceMode={serviceMode}
                audioHint={content.hint}
                successCallback={handleOpen}
                TaskText={TaskText}
                difficulty={difficulty}
                onMakeMove={onMakeMove}
                lessonId={lessonId}
              />
            )}
            {chessType === 'repeat' && (
              <RepeatChessTable
                counter={counter}
                startFen={content.fen}
                pgn={content.pgn}
                title={title}
                id={id}
                points={class_points}
                audioHint={content.hint}
                successCallback={handleOpen}
                TaskText={TaskText}
                serviceMode={serviceMode}
                lessonId={lessonId}
              />
            )}
            {chessType === 'position' && (
              <PlayPositionChessTable
                counter={counter}
                allowedDraw={{
                  allowed_complexity_choice: content.allowed_complexity_choice,
                  allowed_draw: content.allowed_draw,
                }}
                startFen={content.fen}
                pgn={content.pgn}
                title={title}
                id={id}
                points={class_points}
                serviceMode={serviceMode}
                audioHint={content.hint}
                successCallback={handleOpen}
                TaskText={TaskText}
                lessonId={lessonId}
              />
            )}
          </div>
          {serviceMode === 'default' && (
            <div className='chess-display__buttons-container'>
              <LessonsNavigationButton loading={loading} onClick={onBackClick}>
                {t('exercises.back')}
              </LessonsNavigationButton>
              <LessonsNavigationButton loading={loading} onClick={onNextClick}>
                {t('exercises.next')}
              </LessonsNavigationButton>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
