import React from 'react';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function GameEndMessage({redirectRoute, text, closeCallback, win}) {
  const {t} = useTranslation();

  return (
    <div className='selectPopup' onClick={closeCallback && closeCallback}>
      <div className='chess-display__popup-container'>
        {win && <div className='chess-display__popup-image'/>}
        <p className='chess-display__popup-title'
          style={{
            fontSize: "1.5rem",
            textAlign: "center",
            margin: "10px 0",
            fontWeight: "bold",
            padding: "10px 15px"
          }}
        >{t(`game.${text}`)}</p>
        <Link className='chess-display__popup-button' style={{textAlign: "center", color: "white", cursor: 'pointer'}} to={redirectRoute}>{t('game.playAgain')}</Link>
      </div>
    </div>
  )
}