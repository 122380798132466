import React from "react";
import { useTranslation } from "react-i18next";
import style from "./style.module.scss";
import { useDispatch } from 'react-redux';
import { openOverlayAction } from '../../../../actions/overlay';
import Login from '../../../Login';
import styles_reg from './style_reg.module.scss';


const RegistrationHeader = ({
    open
}) => {
    const dispatch = useDispatch();
    const openRegistrationWindow = () => {
        dispatch(openOverlayAction(Login, { type: 'registration' }, true, false));
    }
    const {t} = useTranslation();
    const title = () => <p className = { styles_reg['title'] }>{t('registration_banner.part_1')} <span> </span>
        <span onClick = {openRegistrationWindow}>{t('registration_banner.link_text')}</span>{' '}{t('registration_banner.part_2')}</p>
    return (
        <div className = { `${styles_reg['container']} ${open && styles_reg['show']}` }>
            <div className = { styles_reg['title_wrapper'] }>
                {
                    title()
                }
            </div>
        </div>
    )
}

const Header = ({ text, open = true, type }) => {
    const isRegistrationHeader = type === 'registration';
    return (
        <>
        {
            isRegistrationHeader ?
            <RegistrationHeader
            open = { open }
            />
            :
            <div className={`${style.container} ${open && style.show}`}>
                <div className={style.title__wrapper}>
                    <p className={style.title}>{text}</p>
                </div>
            </div>
        }

        </>
    )
}

export default Header;