import React, { useEffect, useState } from 'react';
import style from './style.module.scss';
import Cart from '../../../Cart';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Doughnut } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

ChartJS.register(ArcElement, Tooltip, ChartDataLabels, Legend);

export default function Chart({ statistics }) {
  const { t } = useTranslation();
  const [chartStatistics, setChartStatistics] = useState([]);

  const statisticsCourseMap = new Map([
    ['zero_tries', { color: '#AEE298', label: t('rating.exercises.zero_tries') }],
    ['first_try', { color: '#0EB4FA', label: t('rating.exercises.first_try') }],
    ['second_try', { color: '#FADE45', label: t('rating.exercises.second_try') }],
    ['many_tries', { color: '#E6625E', label: t('rating.exercises.many_tries') }],
    ['first', { color: '#AEE298', label: t('rating.exercises.zero_tries') }],
    ['second', { color: '#0EB4FA', label: t('rating.exercises.first_try') }],
    ['third', { color: '#FADE45', label: t('rating.exercises.second_try') }],
    ['more', { color: '#E6625E', label: t('rating.exercises.many_tries') }],
  ]);

  const createChart = (statistics) => {
    const currentChartStatistics = [];
    let sum = 0;
    Object.keys(statistics).forEach((key) => {
      sum += statistics[key];
    });
    console.log(statistics);
    Object.keys(statistics).forEach((key) => {
      if (statistics[key] !== 0) {
        currentChartStatistics.push({
          label: statisticsCourseMap.get(key).label,
          value: ((statistics[key] / sum) * 100).toFixed(1),
          color: statisticsCourseMap.get(key).color,
          cutout: '50%',
        });
      }
    });
    setChartStatistics(currentChartStatistics);
  };
  useEffect(() => {
    if (statistics) {
      createChart(statistics);
    }
  }, [statistics]);

  const options = {
    plugins: {
      datalabels: {
        formatter: function (value) {
          return value >= 7 ? `${value}%` : '';
        },
        color: '#262626',
        font: {
          weight: 600,
          size: 14,
          family: 'Open Sans',
        },
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return ` ${tooltipItem.label}: ${tooltipItem.raw}%`;
          },
        },
      },
      legend: {
        display: false,
      },
      responsive: true,
    },
    cutout: chartStatistics.map((item) => item.cutout),
  };

  const finalChartStatistics = {
    labels: chartStatistics.map((item) => item.label),
    datasets: [
      {
        data: chartStatistics.map((item) => item.value),
        backgroundColor: chartStatistics.map((item) => item.color),
        borderColor: chartStatistics.map((item) => item.color),
        borderWidth: 1,
        dataVisibility: new Array(chartStatistics.length).fill(true),
      },
    ],
  };
  console.log(chartStatistics);
  return (
    <Cart classname={style.chart}>
      <span className={style.chart__title}>{t('rating.exercises.quality')}</span>
      {chartStatistics.length !== 0 && (
        <div className={style.chart_info__container}>
          <div className={style.chart__container}>
            <Doughnut data={finalChartStatistics} options={options} />
          </div>
          <div className={style.chart_info}>
            <span className={style.chart_info__title}>{t('rating.exercises.general')}</span>
            <ul className={style.chart_info__list}>
              {chartStatistics?.map((item) => {
                return (
                  <li key={item.label} className={style.list__item} style={{ '--after-color': item.color }}>
                    {item.label}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}
    </Cart>
  );
}
