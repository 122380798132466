import React, {useMemo, useRef} from "react";
import style from "./style.module.scss";
import {useOnClickOutside} from "../../../../../../hooks/outsideClick.hook";
import {useTranslation} from "react-i18next";

const TemplatesPicker = ({selectTemplate, closePicker}) => {
    const ref = useRef(null);
    const {t} = useTranslation();

    useOnClickOutside(ref, closePicker);

    const templates = useMemo(() => [
        {id: 1, text: t('templates.decide')},
        {id: 2, text: t('templates.wrong')},
    ], []);

    return (
        <div ref={ref} className={style.templatesPicker}>
            {
                templates.map(item => (
                    <p
                        key={item.id}
                        onClick={() => selectTemplate(item.text)}
                        className={style.templatesPicker__template}
                    >
                        {item.text}
                    </p>
                ))
            }
        </div>
    )
}

export default TemplatesPicker;