import React from 'react';
import { Route, Redirect} from 'react-router-dom';
import {useSelector} from "react-redux";

export default function PrivateRoute({component: Component, ...rest}) {
  const auth = useSelector(state => state.auth.authenticated);

  const access = rest.access !== undefined ? rest.access : !!auth;

  return (
    <Route
      {...rest}
      render={(props) => access
        ? <Component {...props} />
        : <Redirect to={{pathname: '/', state: {from: props.location}}} />}
    />
  )
}