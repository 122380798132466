import React from 'react';
import InProgressCourseItem from './InProgressCourseItem';
import {useTranslation} from "react-i18next";
import './progress.scss';
import './progressMobile.scss';
import MyCoursesList from "../MyCoursesList";
import {GET_ALL_PASSING_COURSES} from "services/api";

export default function InProgressCoursesList() {
  const {t} = useTranslation();

  const sortFunction = (data) => {
    return data.sort((a,b) => b.percentage_exercises_completed - a.percentage_exercises_completed)
  };

  return (
    <MyCoursesList
      apiRoute={GET_ALL_PASSING_COURSES}
      noItemsMessage={t('courses.noStarted')}
      ItemComponent={InProgressCourseItem}
      sortFunction={sortFunction}
    />
  )
}