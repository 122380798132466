import React, { useContext, useEffect, useMemo, useState } from 'react';
import style from './style.module.scss';
import classnames from 'classnames';
import TimeCountdown from './TimeCountdown';
import Exercise from './Exercise';
import Pause from './Pause';
import { DurationContext, WebinarContext } from '../../../../../constants/contexts';
import { Counter } from '../../../../../assets/img/webinar';

const Exercises = ({
  beginDate,
  currentExercise,
  isPause,
  endTimerFunc,
  exercises,
  isPauseVisible,
  is_training,
  onMakeMove,
  lessonId,
}) => {
  const { context, setContext } = useContext(WebinarContext);
  const { durationContext } = useContext(DurationContext);
  const [isStart, setStart] = useState(context.status === 'BEGIN');
  const [loading, setLoading] = useState(true);


  const toggleHeader = () => {
    let state = !context.isHeaderShow;

    if (
      !localStorage.hasOwnProperty('headerState') ||
      JSON.parse(localStorage.getItem('headerState')) !== state
    ) {
      localStorage.setItem('headerState', `${state}`);
    }

    setContext({ ...context, isHeaderShow: state });
  };

  const counter = useMemo(() => {
    const index = exercises.findIndex((item) => item.id === currentExercise.exercise_id);
    return `${index + 1}/${exercises.length}`;
  }, [currentExercise]);

  // !!! Лоадер лучше не убирать, иначе сломается обновление упражнения
  useEffect(() => {
    let timeout;
    if (currentExercise.id) {
      setLoading(true);
      timeout = setTimeout(() => setLoading(false), 300);
    }
    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [currentExercise.id]);

  useEffect(() => {
    if (isStart) {
      context.socket.emit('exercise', {});
    }
  }, [isStart]);

  return (
    <div
      className={classnames(style.container, {
        [style.notStart]: !isStart,
      })}>
      {/*isStart && !loading && currentExercise.hasOwnProperty('exercise') && <button onClick={()=>toggleHeader()} className={`interface__full-button`}></button>*/}
      {/*isStart && !loading && currentExercise.hasOwnProperty('exercise') && currentExercise.exercise.type !== 2 && (
                <div className={classnames(style.counter, {
                    [style.counter_picture]: currentExercise.exercise.type === 7 || currentExercise.exercise.type === 8,
                    [style.board]: currentExercise.exercise.type === 3 || currentExercise.exercise.type === 4 || currentExercise.exercise.type === 5 || currentExercise.exercise.type === 6
                })}>
                    <Counter />
                    {counter}
                </div>
            )*/}
      {isStart ? (
        currentExercise.hasOwnProperty('exercise') && (
          <Exercise
            counter={counter}
            exercise={currentExercise.exercise}
            isPause={isPause}
            loading={loading}
            is_training={is_training}
            onMakeMove={onMakeMove}
            lessonId={lessonId}
          />
        )
      ) : (
        <TimeCountdown setStart={setStart} beginDate={beginDate} />
      )}
      {!is_training && isPause && !durationContext.isDurationRun && (
        <Pause
          exercise_id={currentExercise.exercise_id}
          pauseTime={currentExercise.pause_time}
          endTimerFunc={endTimerFunc}
          isPauseVisible={isPauseVisible}
        />
      )}
    </div>
  );
};

export default React.memo(Exercises, (prevProps, nextProps) => {
  return (
    prevProps.isPause === nextProps.isPause &&
    prevProps.currentExercise.id === nextProps.currentExercise.id &&
    prevProps.isPauseVisible === nextProps.isPauseVisible
  );
});
