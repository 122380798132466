import React from 'react';
import { useField, useFormikContext } from 'formik';
import InputError from "../common/InputError";

export const TextField = ({ label, format, additionalComponent, isRequired, labelClasses, inputClasses, labelTextClasses, errorMode, ...props }) => {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();

  const customOnChange = (e) => {
    setFieldValue(props.name, format ? format(e.target.value) : e.target.value);
  };

  return (
    <label className={`${labelClasses ? labelClasses : ''}`} htmlFor={props.id || props.name}>
      <p className={`${labelTextClasses ? labelTextClasses : ''}`}>{label}{isRequired && <i className=''>*</i>}</p>
      <input className={`${inputClasses} ${meta.touched && meta.error ? 'text-input--warn' : ''}`} {...field} {...props} onChange={customOnChange} />
      {additionalComponent ? additionalComponent : ''}
      {meta.touched && meta.error ? (
        <InputError>{meta.error}</InputError>
      ) : null}
    </label>
  );
};