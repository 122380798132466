import React, {useEffect, useState} from "react";
import style from "./style.module.scss";
import {useTranslation} from "react-i18next";
import {HumanIcon} from "../../../../../../assets/img/partners";
import BanButton from "./BanButton";
import BanUserPopup from "./BanUserPopup";
import {getWebinarStudentsList} from "../../../../../../services/api";

const StudentsList = ({lessonType, lessonId}) => {
    const [students, setStudents] = useState([]);
    const [banModalState, setBanModalState] = useState({
        isOpen: false,
        id: 0,
        name: '',
        isTemporary: false,
    });
    const [updateTrigger, setUpdateTrigger] = useState(0);


    const {t} = useTranslation();
    const isPresent = lessonType === 'future' || lessonType === 'current';

    useEffect(() => {
        getWebinarStudentsList(lessonId)
            .then(res => setStudents(res.data))
    }, [updateTrigger])

    return (
        <div className={style.container}>
            <div className={style.header}>
                <p className={style.header_title}>{t('partners.usersList')}</p>
                <div className={style.header__studentsCount}>
                    <HumanIcon />
                    <p className={style.header__studentsCount__number}>{students.length}</p>
                </div>
            </div>
            <table className={style.table}>
                <thead>
                    <tr>
                        <th width="10%">№</th>
                        <th>{t('partners.name')}</th>
                        <th>{t('partners.email')}</th>
                        <th>{isPresent ? t('partners.ban') : t('partners.scores')}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        students.map((student, index) => (
                            <tr key={student.student_id} className={style.table__user}>
                                <td width="10%">{index+1}.</td>
                                <td>{student.name} {student.surname}</td>
                                <td>{student.email}</td>
                                <td>
                                    {
                                        !isPresent
                                            ? student.score
                                            : <BanButton
                                                studentId={student.student_id}
                                                lessonId={lessonId}
                                                isTemporary={student.is_temporary}
                                                updateList={() => setUpdateTrigger(Math.random())}
                                                isBan={student.is_locked}
                                                openBanConfirmation={() =>
                                                  setBanModalState({
                                                    isOpen: true,
                                                    name: student.name,
                                                    id: student.student_id,
                                                    isTemporary: student.is_temporary,
                                                  })
                                                }
                                            />
                                    }
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            {banModalState.isOpen && <BanUserPopup
                name={banModalState.name}
                studentId={banModalState.id}
                isTemporary={banModalState.isTemporary}
                lessonId={lessonId}
                updateList={() => setUpdateTrigger(Math.random())}
                closeModal={() => setBanModalState({isOpen: false, name: '', id: 0, isTemporary: false })}
            />}
        </div>
    )
}

export default StudentsList;
