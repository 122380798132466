import React from 'react';
import SectionGridItem from '../../../common/SectionGridItem/SectionGridItem';
import PropTypes from 'prop-types';

export default function SectionList({title, data = [], isPrivate, url}) {
  if(data.length === 0) {
    return <></>
  }

  return (
    <>
      {title && <h2 className='section-grid__title'>{title}</h2>}
      <div className='section-grid__list'>
        {data.map(el => <SectionGridItem {...el} key={el.id} isPrivate={isPrivate} url={url}/>)}
      </div>
    </>
  );
}

SectionList.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
};

