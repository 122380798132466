import React from "react";
import Banner from "../Banner";
import style from "./style.module.scss";
import Navbar from "../Navbar";
import {useTranslation} from "react-i18next";
import Slider from "./Slider";
import {bannerMain} from "../../../../assets/img/abclass/index";
import Advantages from "./Advantages";
import LessonSignUp from "../LessonSignUp";
import Teachers from "./Teachers";
import SaleBanner from "./SaleBanner";
import LessonProcess from "./LessonProcess";

const Main = () => {
    const {t} = useTranslation();
    return (
        <>
            <Banner
                title={<p className={style.title}>{t('abclass.mainTitle')}</p>}
                image={bannerMain}
            />
            <Navbar />
            <Advantages />
            <LessonProcess />
            <SaleBanner />
            <div className={style.container}>
                <Slider />
            </div>
            <Teachers />
            <LessonSignUp />
        </>
    )
}

export default Main;