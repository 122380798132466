import React from 'react';
import s from './Pagination.module.scss'
import SingleNumber from './SingleNumber/SingleNumber';
import { ReactComponent as StartIcon } from './start.svg';
import { ReactComponent as EndIcon } from './end.svg';

export default function Pagination({ items, itemsToShow = 5, withSearch = true }) {
    const [currentPage, setCurrentPage] = React.useState(1)
    const count = items.length

    const onPaginClick = (page) => {
        setCurrentPage(page)
    }

    return (
        <section className={s['pagination-wrapper']}>
            <div className={s['pagination-body']}>
                {items.slice((currentPage - 1) * itemsToShow, currentPage * itemsToShow).map((item) => item)}
            </div>
            <div className={s['pagination-buttons']}>
                <div className={s['pagination-scrollable']} style={{ left: (currentPage - 1) * (-44) }}>
                    {Array.from(Array(Math.ceil(count / itemsToShow)).keys()).map((item, key) => <SingleNumber active={item + 1 === currentPage} number={item + 1} key={key} onClick={() => { onPaginClick(item + 1) }} />)}
                </div>
            </div>
            <div className={`${s['pagination-buttons']} ${count !== 0 ? s['buttons-show'] : s['buttons-hide']}`}>
                <div className={s['pagination-buttons-start']}>
                    <SingleNumber number={<StartIcon />} active={true} onClick={() => { onPaginClick(1) }} />
                </div>
                <div className={s['pagination-buttons-end']}>
                    <SingleNumber number={<EndIcon />} active={true} onClick={() => { onPaginClick(Math.ceil(count / itemsToShow)) }} />
                </div>
            </div>
        </section>
    )
}