import React from 'react';
import Cart from '../../../../Cart';
import style from '../LeftCart/style.module.scss';
import ProgressbarContainer from '../../../../ProgressbarContainer';
import { useTranslation } from 'react-i18next';

export default function RightCart({ statisticsSkills }) {
  const { t } = useTranslation();
  return (
    <Cart classname={style.container}>
      <ProgressbarContainer
        progressColor={'#73B5B7'}
        width={statisticsSkills?.math}
        label={t('statistics_skills.math')}
      />
      <ProgressbarContainer
        progressColor={'#73B5B7'}
        width={statisticsSkills?.general}
        label={t('statistics_skills.leadership')}
      />
    </Cart>
  );
}
