import React, {useMemo} from "react";
import style from "./style.module.scss";
import Navbar from "../Navbar";
import Banner from "../Banner";
import {useTranslation} from "react-i18next";
import {bannerTraining} from "../../../../assets/img/abclass/index";
import Calendar from "./Calendar";
import Program from "./Program";
import LessonSignUp from "../LessonSignUp";
import Certificates from "../Certificates";

const Training = () => {
    const {t} = useTranslation();

    const Title = useMemo(() => (
        <div className={style.title}>
            {t('abclass.trainingTitle')}
            <br />
            <p className={style.subTitle}>{t('abclass.trainingSubtitle')}</p>
        </div>
    ), [])

    return (
        <>
            <Banner title={Title} isReverse={true} image={bannerTraining} />
            <Navbar />
            <div className={style.container}>
                <p className={style.container__title}>{t('abclass.welcomeToCalendar')}</p>
                <Calendar />
            </div>
            <Program />
            <Certificates />
            <LessonSignUp />
        </>
    )
}

export default Training;