import React, { useMemo, useState, useEffect } from 'react';
import style from './style.module.scss';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import BA from '../../../../../assets/img/abclass/teachers/BA.png';
import CK from '../../../../../assets/img/abclass/teachers/CK.png';
import FA from '../../../../../assets/img/abclass/teachers/FA.png';
import RK from '../../../../../assets/img/abclass/teachers/RK.png';
import RR from '../../../../../assets/img/abclass/teachers/RR.png';
import VZ from '../../../../../assets/img/abclass/teachers/VZ.png';

const Teachers = () => {
  const { t } = useTranslation();
  const slides = useMemo(
    () => [
      {
        id: 0,
        name: t('teachers.name_amonatov'),
        level: t('teachers.level_grossmeister'),
        class: t('teachers.class_amonatov'),
        avatar: FA,
      },
      {
        id: 1,
        name: t('teachers.name_berik'),
        level: t('teachers.level_master'),
        class: t('teachers.class_berik'),
        avatar: BA,
      },
      {
        id: 2,
        name: t('teachers.name_zharavin'),
        level: t('teachers.level_1'),
        class: t('teachers.class_zharavin'),
        avatar: VZ,
      },
      {
        id: 3,
        name: t('teachers.name_rahmanova'),
        level: t('teachers.level_1'),
        class: t('teachers.class_rahmanova'),
        avatar: RR,
      },
      {
        id: 4,
        name: t('teachers.name_kablashev'),
        level: t('teachers.level_candidate_master'),
        class: t('teachers.class_kablashev'),
        avatar: CK,
      },
      {
        id: 5,
        name: t('teachers.name_kairbekov'),
        level: t('teachers.level_master'),
        class: t('teachers.class_kairbekov'),
        avatar: RK,
      },
    ],
    []
  );

  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setWindowSize(window.innerWidth);
    });

    return function () {
      window.removeEventListener('resize', () => {
        setWindowSize(window.innerWidth);
      });
    };
  });

  return (
    <div className={style.container}>
      <p className={style.title}>{t('abclass.teachers')}</p>
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={125}
        totalSlides={slides.length}
        visibleSlides={windowSize <= 540 ? 1 : windowSize <= 1024 ? 2 : 3}>
        <Slider>
          {slides.map((item) => (
            <Slide key={item.id} index={item.id}>
              <div className={style.slide}>
                <div>
                  <img className={style.slide__avatar} src={item.avatar} alt='avatar' />
                </div>
                <p className={style.slide__name}>{item.name}</p>
                <p className={style.slide__level}>{item.level}</p>
                <p className={style.slide__class}>{item.class}</p>
              </div>
            </Slide>
          ))}
        </Slider>
        <ButtonBack className={classnames(style.navButton, style.navButton_left)}>
          <svg width='21' height='36' viewBox='0 0 21 36' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M20.8667 32.4025L17.7692 35.5L0.269195 18L17.7692 0.5L20.8667 3.5975L6.4642 18L20.8667 32.4025Z'
              fill='black'
            />
          </svg>
        </ButtonBack>
        <ButtonNext className={classnames(style.navButton, style.navButton_right)}>
          <svg width='21' height='36' viewBox='0 0 21 36' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M0.13355 3.5975L3.23105 0.500004L20.7311 18L3.23105 35.5L0.133545 32.4025L14.5361 18L0.13355 3.5975Z'
              fill='black'
            />
          </svg>
        </ButtonNext>
      </CarouselProvider>
    </div>
  );
};

export default Teachers;
