import React from 'react';
import LogoSingle from "../../../common/LogoSingle";
import BackToButton from "../../../common/BackToButton";
import {useLocation} from 'react-router-dom';
import './noAccess.scss';
import {useTranslation} from "react-i18next";

export default function NoAccessComponent() {
  const location = useLocation();
  const {t} = useTranslation();

  return (
    <div className='no-access'>
      <div className='no-access__background'>
        <div className='no-access__chess-board'/>
        <div className='no-access__background-text-container'>
          <p className='no-access__background-text'>Пешечные эндшпили – это гольф.</p>
          <p className='no-access__background-text'>Я вообще считаю, что в шахматах всё держит­ся на тактике. Если стратегия — это глыба мрамора, то тактика — это резец, которым действует мастер, создавая произведение шахматного искусства.</p>
          <p className='no-access__background-text'>Шахматы- это тихая игра музыки раума.</p>
        </div>
      </div>
      <LogoSingle classes='not-found__image'/>
      <p className='no-access__text'>{t('noAccess.noAccessToLesson')}</p>
      <BackToButton route={'/course/' + location.pathname.split('/')[2]}>
        {t('noAccess.backToLessonsList')}
      </BackToButton>
    </div>
  )
}