import React from 'react';
import MySelect from '../common/MySelect';
import { useField, useFormikContext } from 'formik';
import InputError from "../common/InputError";

export default function SelectField({ label, toggleShowBotDifficulty, onChangeCallback, ...props }) {
  const [field, meta] = useField(props);
  const { setFieldValue, values } = useFormikContext();

  const customChange = (value) => {
    if(props.name === 'is_training'){
      const val = typeof value === 'object' ? !!value?.value : value;
      setFieldValue(props.name, val);
      (typeof onChangeCallback === 'function') && onChangeCallback(props.name, val);
    }
    else {
      setFieldValue(props.name, value);
    }
  };

  return (
    <label className={`${props.labelClasses ? props.labelClasses : ''}`} htmlFor={props.id || props.name}>
      {props.isRequired ? <p className=''>{label}<i className=''>*</i></p> : <p>{label}</p>}
      <MySelect {...field} {...props} toggleShowBotDifficult={toggleShowBotDifficulty} isWarn={meta.touched && meta.error} handleChange={customChange} placeholder={values.country || ''} />
      {meta.touched && meta.error ? (
        <InputError>{meta.error}</InputError>
      ) : null}
    </label>
  )
}
