import React, { useCallback, useContext, useEffect, useState } from "react";
import Header from "../PartnerPage/Header";
import style from "./style.module.scss";
import Lobby from "./Lobby";
import { getLesson, getLastUserAnswer } from "../../../services/api";
import { useHistory } from "react-router-dom";
import { WebinarContext } from "../../../constants/contexts";
import FinishLobby from "./FinishLobby";
import Loader3 from "../../common/Loader/loader3";
import SignIn from "./SignIn";
import { useDispatch, useSelector } from "react-redux";
import useResolution from "../../../hooks/useResolution";
import WithoutRegistration from './WIthoutRegistration/index';
import moment from "moment";
import { trainingLesson } from '../../../actions/webinar';

import LobbyTrainingMode from '../WebinarTrainingMode/Lobby';

const Webinar = ({ match, end }) => {
  const history = useHistory();
  // Здесь loading строка, т.к. нужно доп. состояние, чтобы lastExercise не прокидывался раньше времени
  const [state, setState] = useState({
    isEnd: false,
    loading: 'open',
    lessonData: null
  })
  const { authenticated } = useSelector(state => state.auth);
  const training_lesson = useSelector(state => state.webinar.training_lesson);

  const dispatch = useDispatch();
  const isMobile = useResolution(900);
  const { context, setContext } = useContext(WebinarContext);
  const [webinarId, setWebinarId] = useState(undefined);
  const [webinarPassword, setWebinarPassword] = useState('');

  useEffect(() => {
    if (!end) {

      /*if (!authenticated) {
          dispatch(openOverlayAction(Login, {}, true, false))
      }*/

      if (+match.params.id) {
        getLesson(match.params.id)
          .then(res => {
            const is_training = !!res.data.is_training;
            if (is_training) {
              dispatch(trainingLesson({
                value: true,
                webinar_id: res.data.id
              }));
            }
            if (res.data && (res.data.end_date || is_training)) {
              setWebinarId(res.data.id);
              //Для корректной работы с safari используется moment.js вместо new Date() 
              const current_date = moment.utc();
              //const current_timezone = moment(current_date).utcOffset();
              const end_date = moment.utc(res.data.end_date);
              const begin_date = moment.utc(res.data.begin_date);
              // end_date.setMilliseconds(3 * 60 * 60 * 1000);
              // begin_date.setMilliseconds(3 * 60 * 60 * 1000);
              if (current_date.isAfter(end_date) && !is_training) {
                localStorage.setItem('webinar-class-status', 'FINISHED')
              }
              else if (current_date.isBefore(begin_date)) {
                localStorage.setItem('webinar-class-status', 'NOT_BEGIN');
              }
              else {
                localStorage.setItem('webinar-class-status', 'BEGIN');
              }
            }
            setState({
              ...state,
              loading: 'sign_in',
              lessonData: res.data,
              session_hash: res.data.class_link.substring(
                res.data.class_link.indexOf('session_id=') + 11
              )
            })
          })
          .catch(() => {
            history.goBack()
          })
      }
    } else {
      getLesson(match.params.id)
        .then(res => {
          setState({ isEnd: true, loading: 'close', lessonData: res.data })
        })
        .catch(() => {
          history.goBack()
        })
    }

  }, [authenticated])
  
  useEffect(() => {
    if (context.accessToken && state.lessonData && !end) {
      getLastUserAnswer(context.accessToken)
        .then(res => {

          setContext({ ...context, userAnswer: res.data.last_answer })
          setState({
            ...state,
            isEnd: context.status === 'FINISHED',
            loading: 'close'
          })
        })
    } else {
      setState({
        ...state,
        isEnd: true,
        loading: 'close'
      })
    }
  }, [context.accessToken])

  useEffect(() => {
    // Если вебинар создан с комментарием 'passw: пароль'
    if (
      state.lessonData &&
      state.lessonData.description &&
      typeof state.lessonData.description === 'string' &&
      state.lessonData.description.matchAll
    ) {
      const passwordRegExp = Array.from(state.lessonData?.description.matchAll(/^passw: {0,}(.*){0,}/gi));
      if (passwordRegExp.length && passwordRegExp[0][1]) {
        setWebinarPassword(passwordRegExp[0][1]);
      }
    }
  }, [state.lessonData, authenticated]);

  /*if (!authenticated && !end) return <WithoutRegistration />

  if (state.loading === 'open' || state.lessonData == null) return <Loader3 />*/

  const NoteWithDescription = ({ show, height }) => (
    show
      ? <div className={`${style["description"]} ${show ? style["show"] : ""} ${height ? style["fixed-height"] : ""}`}>
        <p className={style["description-text"]}>
          {state.lessonData?.description}
        </p>
      </div>
      : null
  );

  
  const header_type = !authenticated ? 'registration' : undefined;
  const is_training = training_lesson.value === true && training_lesson.webinar_id === state.lessonData?.id;
  
  const onEnd = useCallback((bool) => {
    setState((state) => ({ ...state, isEnd: bool }));
  }, []);

  return (
    <>
      <Header
        text={state.lessonData?.title}
        open={(!isMobile && context.isHeaderShow)}
        type={header_type}
      />
      {
        (state.loading === 'open' || state.lessonData == null) ? <Loader3 /> :
          (context.accessToken /*|| end*/) && state.loading === 'close'
            ? (
              <div className={style.container}>
                {
                  !state.isEnd
                    ? is_training 
                      ? <LobbyTrainingMode 
                          lessonData={state.lessonData}
                          setEnd={onEnd} 
                        /> 
                      : <Lobby
                          webinar_id={state.lessonData?.id}
                          lessonData={state.lessonData}
                          setEnd={bool => setState({ ...state, isEnd: bool })}
                        />
                    : <FinishLobby lessonData={state.lessonData} />
                }

              </div>
            ) : (!authenticated && !end) ?
              <WithoutRegistration
                is_training={is_training}
                webinar_id={webinarId}
                session_hash={state.session_hash}
                zoomLink={state.lessonData.zoom_link}
                webinarPassword={webinarPassword}
                note={{
                  Component: NoteWithDescription,
                  condition:
                    state.lessonData &&
                    state.lessonData?.description &&
                    state.lessonData?.description !== 'null' &&
                    webinarPassword === ''
                }}
              />
              :
              <SignIn
                is_training={is_training}
                hash={state.lessonData.class_link.match(/id=.+/)[0].slice(3)}
                zoomLink={state.lessonData.zoom_link}
                webinarPassword={webinarPassword}
                note={{
                  Component: NoteWithDescription,
                  condition:
                    state.lessonData &&
                    state.lessonData?.description &&
                    state.lessonData?.description !== 'null' &&
                    webinarPassword === ''
                }}
              />
      }
    </>
  )
}

export default Webinar;
