import React from 'react'
import s from './Label.module.scss'

export default function CreateLabel(color = '#FF9345', textColor = '#fff', text) {
    return function () {
        return (
            <div className={s['label-body']} style={{background: color}}>
                <h3 className={s['label-text']} style={{color: textColor}}>{text}</h3>
            </div>
        )
    }
}