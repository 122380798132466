import React, { useState, useEffect } from 'react';
import MainDisplay from './MainDisplay';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import './interface.scss';
import './interfaceMobile.scss';
import InteractiveContainer from "./MainDisplay/InteractiveContainer";
import CourseTitle from "./CourseTitle";
import TaskDescription from "./MainDisplay/TaskDescription";
import { useWindowSize } from "hooks/resize.hook";

const TASKS_LIST = 'task list';
const DESCRIPTION = 'description';

export default function CourseInterface({ ListToDisplay, DisplayComponent, data, coursesData, loading, title, type, isPrivate }) {
  const [componentToDisplay, setComponentToDisplay] = useState(TASKS_LIST);
  const windowSize = useWindowSize();
  const fullscreen = useSelector(state => state.settings.fullscreen);
  const exList = useSelector(state=>state.settings.exercisesList);

  const toggleComponent = (string) => {
    setComponentToDisplay(string);
  };

  useEffect(() => {
    window.jivo_destroy && window.jivo_destroy();
  }, []);

  return (
    <div className='course-interface'>
      <CourseTitle title={title} show={!fullscreen} isPrivate={isPrivate} type={type}/>
      <div className='interface'>
        <MainDisplay toggleComponent={toggleComponent} data={data} type={type}>
          <InteractiveContainer>
            <DisplayComponent
              toggleComponent={toggleComponent}
              componentToDisplay={componentToDisplay}
              data={data}
              loading={loading}
              type={type}
            />
          </InteractiveContainer>
          {windowSize.width < 900 ? componentToDisplay === DESCRIPTION && <TaskDescription data={data} /> : <TaskDescription data={data} />}
        </MainDisplay>
        <div className={`interface__tasks-wrapper ${(windowSize.width >= 900 && exList) && 'show-tasks'}`}>
          <ListToDisplay
            coursesData={coursesData}
            data={data}
            loading={loading}
          />
        </div>
        {(windowSize.width < 900 && componentToDisplay === TASKS_LIST) &&
          <ListToDisplay
            coursesData={coursesData}
            data={data}
            loading={loading}
          />
        }
      </div>
    </div>
  )
}

CourseInterface.propTypes = {
  ListToDisplay: PropTypes.elementType.isRequired,
  DisplayComponent: PropTypes.elementType.isRequired,
  type: PropTypes.string
};