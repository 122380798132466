const locales = {
  ru: 'rus',
  tr: 'tr',
  kz: 'kz',
  kk: 'kz',
  es: 'es',
  en: 'eng',
};

export function getBrowserLang() {
  let lang = window.navigator
    ? window.navigator.language || window.navigator.systemLanguage || window.navigator.userLanguage
    : 'ru';
  lang = lang.substr(0, 2).toLowerCase();

  return locales[lang] || 'rus';
}


export function getSavedLang() {
  return localStorage.getItem('i18nextLng') || 'eng';
}
