import React from 'react';

export default function AboutSkillItem({imageUrl, titleText, skillArray}) {
  return (
    <li className='skills__item'>
      <img className='skills__image' src={imageUrl}/>
      <p className='skills__skill-title'>{titleText}</p>
      <div className='skills__text-container'>
        {skillArray.map(el =>
          <span className='skills__text' key={el}>- {el}</span>
        )}
      </div>
    </li>
  )
}