import {SERVER_AUTH_ERRORS} from "constants/errors";

export const parseErrorMessage = (errorMessage) => {

  if(!errorMessage) {
    return '';
  }

  let errorElement = SERVER_AUTH_ERRORS.filter(el => errorMessage.startsWith(el[0]));
  if(errorElement.length) {
    return errorElement[0][1];
  }
};