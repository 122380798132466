import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import setAuthorizationToken from './utils/setAuthorizationToken';
import { AUTHENTICATED, UNAUTHENTICATED } from './actions/types';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';
import { getProfileInfo } from './actions/getProfile';
import './i18n';
import "react-datepicker/dist/react-datepicker.css"
import { LANG } from './constants/publicConstants';
import { setLang } from './actions/setLang';
import invalidTokenInterception from "./utils/invalidTokenInterceptor";
import StartScreen from "./components/StartScreen";
import { getMomentLocale } from "./utils/getMomentLocale";
import {ErrorBoundary} from './HOC/ErrorBoundary';

import { getBrowserLang } from 'utils/getBrowserLang';
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://3a51a1567e0abaa934286eb49db1fe29@o4507689548251136.ingest.us.sentry.io/4507725019611136",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const tokenData = JSON.parse(localStorage.getItem('token'));

axios.defaults.baseURL = process.env.REACT_APP_API_URL

getMomentLocale();

if (tokenData) {
  setAuthorizationToken();
  invalidTokenInterception(() => store.dispatch({ type: UNAUTHENTICATED }));
  store.dispatch({ type: AUTHENTICATED });
  store.dispatch(getProfileInfo());
}

const lang = getBrowserLang();

store.dispatch(setLang(LANG[localStorage.getItem('i18nextLng')] || lang));

window.store = store;

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <BrowserRouter>
        <Suspense fallback={<StartScreen />}>
          <App />
        </Suspense>
      </BrowserRouter>
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root')
);


//register
serviceWorker.unregister();
