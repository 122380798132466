import React, {useCallback, useEffect, useState} from "react";
import style from "./style.module.scss";
import {ReactComponent as CloseIcon} from "../../../../../../assets/img/close-black.svg";
import {useDispatch} from "react-redux";
import {openOverlayAction} from "../../../../../../actions/overlay";
import SuperCategories from "./SuperCategories";
import {Arrow, CaretIcon} from "../../../../../../assets/img/partners";
import Exercises from "../index";
import {useTranslation} from "react-i18next";
import classnames from "classnames";
import CoursesAccordion from "./CoursesAccordion";
import {getSpecificCoursesList} from "../../../../../../services/api";

const Courses = ({closeModal, selectedExercises: exercises, categoryId, ...props}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const [selectedExercises, setSelectedExercises] = useState(exercises || []);

    const [list, setList] = useState([]);

    useEffect(() => {
        getSpecificCoursesList(categoryId)
            .then(res => setList(res.data))
    }, [])


    const openRootModal = useCallback(() => {
        dispatch(openOverlayAction(Exercises, {...JSON.parse(sessionStorage.getItem('exercises')), setValue: props.setValue}))
    }, [])

    const openSuperCategoriesModal = () => {
        dispatch(openOverlayAction(
            SuperCategories,
            {...JSON.parse(sessionStorage.getItem('exercises')), setValue: props.setValue},
            false,
            true,
            openRootModal
        ))
    }

    const handleSubmit = () => {
        sessionStorage.setItem(
            'exercises',
            JSON.stringify({
                selectedExercises: [...selectedExercises.map(item => {
                    if (item.duration && item.pause_time) return item;
                    else return {...item, duration: '00:01:00', pause_time: '00:00:12'};
                })]
            }));
        openRootModal();
    }


    return (
        <div className={style.container}>
            <div className={style.add__navigation}>
                <button onClick={openSuperCategoriesModal}><Arrow/></button>
                <p>{t('partners.addExercise')}</p>
                <button onClick={closeModal}><CloseIcon /></button>
            </div>

            <div>
                {
                    list.map(item => <CoursesAccordion
                        list={item.sublist}
                        margin={0}
                        key={item.id}
                        name={item.name}
                        exercises={selectedExercises}
                        setExercises={setSelectedExercises}
                        withCheckbox={false}
                    />)
                }
            </div>

            <div className={style.add__buttonsWrapper}>
                <button
                    onClick={openSuperCategoriesModal}
                    className={classnames(style.add__buttonsWrapper__button, style.add__buttonsWrapper__button_grey)}
                >
                    {t('partners.cancel')}
                </button>
                <button
                    onClick={handleSubmit}
                    className={classnames(style.add__buttonsWrapper__button, style.add__buttonsWrapper__button_green)}
                >
                    {t('partners.save')}
                </button>
            </div>
        </div>
    )
}

export default React.memo(Courses, (prevProps, nextProps) => {
    return prevProps.categoryId === nextProps.categoryId
});
