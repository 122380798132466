import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Profile from '../components/Pages/Profile';
import SettingsPage from '../components/Pages/SettingsPage';
import SupportPage from '../components/Pages/SupportPage';
import Lessons from '../components/Pages/PartnerPage/Lessons';
import ManageLessons from '../components/Pages/PartnerPage/ManageLessons';
import Webinar from '../components/Pages/Webinar';
import { DurationContextProvider, WebinarContextProvider } from '../constants/contexts';
import ABClass from '../components/Pages/ABClass';

const TeacherRouter = () => {
  return (
    <Switch>
      <Route exact path='/' render={() => <Redirect to='/partner/lessons' />} />
      <Route path='/partner/lessons' component={Lessons} />
      <Route
        exact
        path='/partner/lesson/create'
        render={(props) => <ManageLessons {...props} mode='create' />}
      />
      <Route
        exact
        path='/partner/lesson/create/:id'
        render={(props) => <ManageLessons {...props} mode='copy' />}
      />
      <Route path='/partner/lesson/:id/edit' render={(props) => <ManageLessons {...props} mode='edit' />} />
      <Route path='/settings' component={SettingsPage} />
      <Route path='/support' component={SupportPage} />
      <Route path='/abclass' component={ABClass} />
      <Route
        path='/webinar/:id'
        render={(props) => (
          <DurationContextProvider>
            <WebinarContextProvider>
              <Webinar {...props} />
            </WebinarContextProvider>
          </DurationContextProvider>
        )}
      />
      <Route render={() => <Redirect to='/' />} />
    </Switch>
  );
};

export default TeacherRouter;
