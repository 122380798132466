import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import './interface.scss';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';

export default function CourseTitle(props) {
  const { t } = useTranslation();
  const location = useLocation();
  const isAuth = useSelector(state => state.auth.authenticated);
  const getLinkUrl = () => {
    if (!location.search) {

      return props.isPrivate ? `/super-categories/${props.isPrivate}` : '/courses'
    }

    let linkArray = location.pathname.split('/');

    return `/course/${linkArray[2]}`
  };

  return (
    <div className={`course-interface__header-container ${props.show && 'show-header'}`}>
      <div className='course-interface__header'>
        <h1 className='course-interface__header-title'>{props.title}</h1>
        {props.name && <p className='course-interface__header-name'>{isAuth ? props.name : 'Зарегестрируйтесь'}</p>}
      </div>
      <Link to={getLinkUrl} className='course-interface__header-button'>{(getLinkUrl() === '/courses' || getLinkUrl() === `/super-categories/${props.isPrivate}`) ? `${t('courses.backToCourseList')}` : `${t('courses.backToLessonList')}`}</Link>
    </div>
  )
}

CourseTitle.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string
};