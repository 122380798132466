import React, {useState} from 'react';
import MySelect from "components/common/MySelect";
import './gameSettings.scss';
import './gameSettingsMobile.scss';
import {useTranslation} from "react-i18next";

export default function GameSettings({startCallback, mode='computer'}) {
  const [settings, setSettings] = useState({
    side: 'w',
    type: 'start',
    difficulty: "1"
  });
  const {t} = useTranslation();

  const mockOptions = [
    {label:  t('game.level') +' 1', value: "1"},
    {label: t('game.level') + ' 2', value: "2"},
    {label: t('game.level') + ' 3', value: "3"},
    {label: t('game.level') + ' 4', value: "4"},
    {label: t('game.level') + ' 5', value: "5"},
    {label: t('game.level') + ' 6', value: "6"},
    {label: t('game.level') + ' 7', value: "7"},
    {label: t('game.level') + ' 8', value: "8"},
    {label: t('game.grandmaster'), value: "9"},
  ];

  const sideOptions = [
    {label: t('game.white'), value: "w"},
    {label: t('game.black'), value: "b"}
  ];

  const gameTypeOptions = [
    {label: t('game.startPosition'), value: "start"},
    {label: t('game.freePosition'), value: "free"},
    {label: t('game.chess960'), value: "960"},
  ];

  const onStartClick = () => {
    startCallback(settings);
  };

  return (
    <div className='game-settings'>
      <ul className='game-settings__list'>
        <li className='game-settings__item'>
          <div className='game-settings__label'>
            <span className='game-settings__text'>{t('game.side')}</span>
            <MySelect
              handleChange={(value) => setSettings({...settings, side: value})}
              options={sideOptions}
              defaultValue={sideOptions[0]}
              inputClasses={'game-settings__select-input'}
              titleClasses={'game-settings__select-title'}
              listItemClasses={'game-settings__select-item'}
              listClasses={'game-settings__select-list'}
            />
          </div>
        </li>
        <li className='game-settings__item'>
          <div className='game-settings__label'>
            <span className='game-settings__text'>{t('game.gameType')}</span>
            <MySelect
              handleChange={(value) => setSettings({...settings, type: value})}
              options={gameTypeOptions}
              defaultValue={gameTypeOptions[0]}
              inputClasses={'game-settings__select-input'}
              titleClasses={'game-settings__select-title'}
              listItemClasses={'game-settings__select-item'}
              listClasses={'game-settings__select-list'}
            />
          </div>
        </li>
        {mode === 'computer' &&
        <li className='game-settings__item'>
          <div className='game-settings__label'>
            <span className='game-settings__text'>{t('game.computer')}</span>
            <MySelect
              handleChange={(value) => setSettings({...settings, difficulty: value})}
              options={mockOptions}
              defaultValue={mockOptions[0]}
              inputClasses={'game-settings__select-input'}
              titleClasses={'game-settings__select-title'}
              listItemClasses={'game-settings__select-item game-settings__select-item--difficulty'}
              listClasses={'game-settings__select-list game-settings__select-list--difficulty'}
            />
          </div>
        </li>
        }
        <li className='game-settings__item game-settings__item--button'>
          <button className='game-settings__start-button' type={'button'} onClick={onStartClick}>{t('game.start')}</button>
        </li>
      </ul>
    </div>
  )
}