import { useEffect } from 'react';

export const useOnClickOutsideWithBtn = (ref, handler, btnRef) => {
  useEffect(() => {
    
    const listener = event => {
      
      if (!ref.current || ref.current.contains(event.target) || btnRef.current.contains(event.target)) {
        return
      }
      handler();


    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler, btnRef]);
};