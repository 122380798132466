import React from 'react';
import './pageContainer.scss';

export default function PageContainer({ children }) {
  return (
    <div className='page-container'>
      <div className='page-container__wrapper'>
        {children}
      </div>
    </div>
  )
}