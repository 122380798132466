import React, {useMemo} from "react";
import style from "./style.module.scss";
import {useTranslation} from "react-i18next";
import {MoneyIcon, HappyIcon, WomanIcon, TeacherIcon} from "../../../../../assets/img/abclass";

const Advantages = () => {
    const {t} = useTranslation();

    const advantages = useMemo(() => [
        {id: 2, title: t('abclass.comfortably'), description: t('abclass.bestTeachers'), img: WomanIcon},
        {id: 1, title: t('abclass.quality'), description: t('abclass.infinityExercises'), img: TeacherIcon},
        {id: 3, title: t('abclass.funny'), description: t('abclass.learningInPlay'), img: HappyIcon},
        {id: 4, title: t('abclass.available'), description: t('abclass.lowPrice'), img: MoneyIcon}
    ], [])

    return (
        <div className={style.container}>
            <p className={style.header}>{t('abclass.advantages')}</p>
            <div className={style.advantages}>
                {
                    advantages.map(item => (
                        <div key={item.id} className={style['advantages-advantage']}>
                            <item.img className={style['advantages-advantage__img']} />
                            <p className={style['advantages-advantage__title']}>{item.title}</p>
                            <p className={style['advantages-advantage__description']}>{item.description}</p>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Advantages