import React from 'react';
import ProfileForm from './ProfileForm';
import './profile.scss';
import './profileMobile.scss';
import {Route, Switch} from "react-router-dom";

export default function Profile() {
  return (
    <section className='profile'>
      <Switch>
        <Route path='/profile' exact component={ProfileForm}/>
      </Switch>
    </section>
  )
}