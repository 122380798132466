import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import HeaderLogo from './HeaderLogo';
import ProfileButton from './ProfileButton';
import { Link } from 'react-router-dom';
import HeaderLanguage from './HeaderLanguage';
import { useTranslation } from 'react-i18next';
import './header.scss';
import './headerMobile.scss';
import { useDispatch, useSelector } from 'react-redux';
import HeaderSearch from './HeaderSearch';
import Button from '../common/Button';
import { openOverlayAction } from '../../actions/overlay';
import Login from '../Login';
import useResolution from '../../hooks/useResolution';
import MobileMenu from './MobileMenu';
import CreateLabel from './Labels/Label';

const NewLabel = CreateLabel('#FF9345', '#fff', 'new');
const OnlineLabel = CreateLabel('#2D9AFF', '#fff', 'online');

const BurgerMenu = ({ isOpen }) => {
  return (
    <div className={`burger-wrapper ${isOpen && 'open-burger'}`}>
      <span className={'burger-line-1'}></span>
      <span className={'burger-line-2'}></span>
      <span className={'burger-line-3'}></span>
    </div>
  );
};

function Header({ show }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useResolution(1024);
  const hideRegistration = useResolution(1300);
  const auth = useSelector((state) => state.auth.authenticated);
  const profileName = useSelector((state) => state.profile.profileData.name);
  const [isBurgerOpen, toggleBurger] = useState(false);

  //const {role} = useSelector(state => state.profile.profileData);

  const isTeacher = JSON.parse(localStorage.getItem('role')) === 'teacher';

  const onLoginClick = () => {
    dispatch(openOverlayAction(Login, { type: 'default' }, true, false));
  };

  const onRegistrationClick = () => {
    dispatch(openOverlayAction(Login, { type: 'registration' }, true, false));
  };

  useEffect(() => {
    if (isBurgerOpen) {
      document.querySelector('body').classList.add('scroll-hidden');
    } else {
      document.querySelector('body').classList.remove('scroll-hidden');
    }
  }, [isBurgerOpen]);

  return (
    <header className={`header ${show && 'show-header'}`}>
      <div className='header__container'>
        {isMobile && (
          <button
            className={'burger-place'}
            onClick={() => {
              toggleBurger(!isBurgerOpen);
            }}
          >
            <BurgerMenu isOpen={isBurgerOpen} />
          </button>
        )}
        <HeaderLogo />
        {!isMobile && (
          <nav className='header__item navigation'>
            <p className='navigation__item'>
              <Link className={'header__link'} to={isTeacher ? '/partner/lessons' : '/courses'}>
                {isTeacher ? t('partners.lessons') : t('main.allCourses')}
              </Link>
            </p>
            <p className='navigation__item'>
              <Link className={'header__link'} to='/abclass'>
                AB-Class
              </Link>
            </p>
            <p className='navigation__item'>
              <a
                className={'header__link'}
                href={i18next.language === 'rus' ? 'https://ab-chess.online/' : 'https://ab-chess.online/kids'}
                target='_blank'
                rel='noopener noreferrer'
              >
                <div className='item-label' style={{ transform: 'translate(65%)' }}>
                  <OnlineLabel />
                </div>
                AB-School
              </a>
            </p>
          </nav>
        )}
        {!isTeacher && <HeaderSearch closeMenu={toggleBurger} />}
        {!isMobile && (
          <p className='navigation__item'>
            <Link className={'header__link'} to={isTeacher ? '/partner' : '/play'}>
              {isTeacher ? t('partners.analytics') : t('menu.play')}
            </Link>
          </p>
        )}
        {!isMobile && !isTeacher && auth &&(
          <p className='navigation__item'>
            <Link className={'header__link'} to={'/rating'}>
              <div className='item-label'>
                <NewLabel />
              </div>
              {t('menu.rating')}
            </Link>
          </p>
        )}
        {!auth && (
          <>
            <p className='header-profile__item header__link header__enter-block bordered' onClick={onLoginClick}>
              <span>{t('main.logIn')}</span>
            </p>
            {!hideRegistration && (
              <p className='header-profile__item header__link header__enter-block fulled' onClick={onRegistrationClick}>
                <span>{t('auth.registration')}</span>
              </p>
            )}
          </>
        )}
        <div className='header__item header-profile'>
          {!isTeacher && auth && !isMobile && (
            <Link to={'/subscription'}>
              <Button classes={'header-profile__item header-profile__button header-profile__button--mobile'}>
                {t('main.subscription')}
              </Button>
            </Link>
          )}

          {auth && (
            <div className='profile-section'>
              <ProfileButton />
              <span className='profile-name'>
                {profileName?.length > 11 ? `${profileName.slice(0, 11)}...` : profileName}
              </span>
            </div>
          )}
        </div>
        <div className='header_lang'>
          <HeaderLanguage />
        </div>
      </div>
      <MobileMenu
        isBurgerOpen={isBurgerOpen}
        closeMenu={() => {
          toggleBurger(false);
          document.querySelector('body').classList.remove('scroll-hidden');
        }}
      />
    </header>
  );
}

export default Header;
