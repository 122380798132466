import React, { useCallback, useContext, useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import style from './style.module.scss';
import { ChatIcon, RatingIcon } from 'assets/img/webinar';
import defaultBanner from 'assets/img/default_webinar_banner.png';
import useResolution from 'hooks/useResolution';
import { hideScroll } from 'helpers/hideScroll';
import { convertDateFromLocale } from 'utils/convertDateFromLocale';
import { closeOverlayAction, openOverlayAction } from 'actions/overlay';
import { setWebinarRaiting, setWebinarCurrentExercise, updateWebinarRating } from 'actions/webinar';
import WebinarRating from 'components/common/Rating';
import { WebinarContext } from 'constants/contexts';
import Loader from 'components/common/Loader/loader5';
import Chat from '../../Webinar/Lobby/Chat';
import BanPopup from '../../Webinar/Lobby/BanPopup';
import EditNamePopup from '../../Webinar/Lobby/EditNamePopup';
import Timer from './Timer';
import Exercises from './Exercises';
import { WebinarService } from '../WebinarService';
import { AnswersLog } from 'components/Pages/Webinar/Lobby/AnswersLog/AnswersLog';
import { getExerciseType } from 'components/Pages/Webinar/lib/getExerciseType';
import classNames from 'classnames';

const Lobby = ({ lessonData, students, current_name, setEnd }) => {
  const [chatForceUpdate, setChatForceUpdate] = useState(0);
  const [currentExercise, setCurrentExercise] = useState({});
  const [nextButtonEnabled, setNextButtonEnabled] = useState(true);
  const shownAnswersIds = useRef([]);
  const [studentsAnswers, setStudentsAnswers] = useState([]);

  const [windowContent, setWindowContent] = useState(null);

  const dispatch = useDispatch();
  const history = useHistory();

  const { context, setContext } = useContext(WebinarContext);

  const isMobile = useResolution(900);
  const { t } = useTranslation();
  const { id } = useSelector((state) => state.profile.profileData);

  const isTeacher = 'teacher'.includes(JSON.parse(localStorage.getItem('role')));

  useEffect(() => {
    const webinarService = new WebinarService(context.socket);

    webinarService.onRating((rating) => {
      dispatch(setWebinarRaiting(rating));
    });

    webinarService.onStart((status) => {
      if (status.start_is_changed) {
        webinarService.disconnect();
        window.location.reload();
      }
    });

    return () => webinarService.disconnect();
  }, [context.socket, dispatch, id, setEnd]);

  useEffect(() => {
    const webinarService = new WebinarService(context.socket);

    const onEnd = () => {
      context.correctAnswerPromise(() => {
        setEnd(true);
      });
    };

    webinarService.onEnd(onEnd);

    return () => webinarService.offEnd(onEnd);
  }, [context, setEnd]);

  useEffect(() => {
    const webinarService = new WebinarService(context.socket);

    const onBan = (data) => {
      if (data.student_id && data.student_id === id) {
        context.socket.disconnect();
        dispatch(
          openOverlayAction(BanPopup, {}, false, true, () => {
            history.push('/');
            dispatch(closeOverlayAction());
          })
        );
      }
    };

    const onStudentAnswer = (data) => {
      console.log('teacher_answers: ', data);
      setStudentsAnswers((prev) => [...prev, data]);
    };

    webinarService.onBan(onBan);
    webinarService.onTeacherAnswers(onStudentAnswer);

    return () => {
      webinarService.offBan(onBan);
      webinarService.offTeacherAnswers(onStudentAnswer);
    };
  }, [context.socket, dispatch, history, id]);

  useEffect(() => {
    const webinarService = new WebinarService(context.socket);

    const resetWebinarContext = () => {
      setContext({
        ...context,
        correctAnswerPromise: (resolve) => resolve('ok'),
        userAnswer: null,
      });
    };

    const onExerciseChange = (exercise) => {
      if (!shownAnswersIds.current.includes(exercise.id)) {
        shownAnswersIds.current = [...shownAnswersIds.current, exercise.id];

        context.correctAnswerPromise(() => {
          resetWebinarContext();
          setCurrentExercise(exercise);
          dispatch(setWebinarCurrentExercise(exercise.exercise_id));
          dispatch(updateWebinarRating());

          setTimeout(() => {
            setNextButtonEnabled(true);
          }, 2500);
        });
      }
    };

    webinarService.onExercise(onExerciseChange);

    return () => webinarService.offExercise(onExerciseChange);
  }, [context, dispatch, setContext, shownAnswersIds]);

  const editName = useCallback(() => {
    const submitFunc = (name, surname) => {
      context.socket.emit('update_profile', { name, surname });

      const updatedStudents = students.map((student) => ({
        ...student,
        student_name: student.student_name === current_name ? name : student.student_name,
      }));

      dispatch(setWebinarRaiting(updatedStudents));
      setChatForceUpdate(Math.random());
    };

    dispatch(openOverlayAction(EditNamePopup, { submitFunc }));
  }, [context.socket, current_name, dispatch, students]);

  function nextExerciseBtnHandler() {
    if (nextButtonEnabled) {
      setNextButtonEnabled(false);
      context.socket.emit('complete', {
        exercise_id: currentExercise.exercise_id,
        exercise_correct: null,
      });
      context.socket.emit('update_exercise', {});
      setStudentsAnswers([]);
    }
  }

  const onMakeMove = useCallback(
    (data, answer_correct, icon = "") => {
      context.socket.emit('answer', { data, answer_correct, icon });
    },
    [context.socket]
  );

  const currentExerciseType = getExerciseType(currentExercise?.exercise);
  const withShowAnswers =
    isTeacher && (currentExerciseType === 'BEST_MOVE' || currentExerciseType === 'TEST');

  return (
    <div className={style.container}>
      {isMobile && withShowAnswers && (
        <div className={classNames(style.answersLogWrapper, style.answersLogWrapper_mobile)}>
          <h6 className={style.answersLogWrapper__title}>{t('webinar.usersAnswers')}:</h6>
          <AnswersLog answers={studentsAnswers} />
        </div>
      )}
      <div className={style.exercisesWrapper}>
        <Exercises
          beginDate={convertDateFromLocale(lessonData.begin_date)}
          currentExercise={currentExercise}
          exercises={lessonData.exercises}
          onMakeMove={isTeacher ? () => {} : onMakeMove}
        />
      </div>
      {((isMobile && windowContent === 'rating') || !isMobile) && (
        <>
          <div
            className={classNames(style.ratingWrapper, {
              [style.ratingWrapper_short]: withShowAnswers,
            })}
          >
            <WebinarRating
              editFunc={editName}
              showRating={false}
              current_name={current_name}
              isWebinar={true}
              students={students}
              className={style.rating}
              closeMobileRating={(state) => {
                setWindowContent(null);
                hideScroll(state);
              }}
              isShort={withShowAnswers}
            />
          </div>
          {withShowAnswers && (
            <div className={style.answersLogWrapper}>
              <h6 className={style.answersLogWrapper__title}>{t('webinar.usersAnswers')}:</h6>
              <AnswersLog answers={studentsAnswers} />
            </div>
          )}
        </>
      )}
      <div className={style.timerWrapper}>
        {isTeacher ? (
          <button
            className={style.nextExerciseBtn}
            onClick={nextExerciseBtnHandler}
            disabled={!nextButtonEnabled}
            children={nextButtonEnabled ? t('webinar.nextExerciseBtn') : <Loader />}
          />
        ) : (
          <Timer themeLink={lessonData.theme_link} lessonData={lessonData}/>
        )}

        {isMobile && (
          <>
            <button
              className={style.timerWrapper__openWindow}
              onClick={() => {
                setWindowContent('chat');
                hideScroll(true);
              }}
            >
              <ChatIcon />
              <span>{t('webinar.chat')}</span>
            </button>
            <button
              className={style.timerWrapper__openWindow}
              onClick={(state) => {
                setWindowContent('rating');
                hideScroll(state);
              }}
            >
              <RatingIcon />
              <span>{t('webinar.rating')}</span>
            </button>
          </>
        )}
      </div>
      {!isMobile && (
        <a
          target='_blank'
          rel='noopener noreferrer'
          href={lessonData.banner_link}
          className={style.bannerWrapper}
          onClick={(e) => {
            if (!lessonData.banner_link) e.preventDefault();
          }}
        >
          <img
            className={!lessonData.banner ? style.noCover : ''}
            src={lessonData.banner || defaultBanner}
            alt='banner'
          />
        </a>
      )}
      {((isMobile && windowContent === 'chat') || !isMobile) && (
        <div className={style.chatWrapper}>
          <Chat
            closeMobileChat={(state) => {
              setWindowContent(null);
              hideScroll(state);
            }}
            forceUpdateTrigger={chatForceUpdate}
          />
        </div>
      )}
    </div>
  );
};

export default connect((state) => ({
  students: state.webinar.rating,
  current_name: state.auth.temporary_auth.name,
}))(Lobby);
