import React, {useEffect, useState } from 'react';
import StudySlider from './StudySlider';
import MainSlider from './MainSlider';
import PublicSlider from './PublicSlider';
import { useDispatch, useSelector } from 'react-redux';
import {getCoursesUrl} from "services/api";
import {useHttp} from "hooks/http.hook";
import Loader1 from "../common/Loader/loader1";
import {showErrorMessage} from "helpers/notifications";
import {useTranslation} from "react-i18next";
import { getLatestCourses } from 'actions/getCourses';

function Main() {
  const [isFetching, setIsFetching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(2);
  const [page, setPage] = useState(1);
  const [pageData, setPageData] = useState({supercategories: []});
  const {request, error} = useHttp();
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const lang = useSelector(state => state.settings.lang);
  const data = useSelector(state => state.courses.coursesData);
  const auth = useSelector(state => state.auth.authenticated);

  useEffect(() => {
    document.querySelector('body').classList.remove('scroll-hidden')
    if(data.supercategories.length === 0) {
      fetchData();
      window.addEventListener('scroll', handleScroll);
    }
  }, []);

  const handleScroll = () => {
    const height = document.body.offsetHeight;
    const screenHeight = window.innerHeight;
    const scrolledHeight = window.scrollY;

    const threshold = height - screenHeight / 4;
    const position = scrolledHeight + screenHeight;
    
    if (position <= threshold || isFetching || page >= totalItems) {
      return;
    }
    setIsFetching(true);
  };

  useEffect(()=>{
    if(auth){
      dispatch(getLatestCourses())
    }
  }, [auth])

  const fetchData = async () => {
    setLoading(true);
      setTimeout(async () => {
        try {
          const result = await request(getCoursesUrl(lang, '', page));
          setPage(page + 1);
          setTotalItems(Number(result.data.total_count));
          setPageData({supercategories: [...pageData.supercategories, ...result.data.supercategories]})
          setLoading(false);
        } catch(e) {
          showErrorMessage(t('errors.getError'));
          setLoading(false)
        }
      }, 50);
  };

  useEffect(() => {
    if (!isFetching) return;
    fetchMoreListItems();
  }, [isFetching]);

  const fetchMoreListItems = () => {
    (!loading && page <= totalItems) && fetchData();
    setIsFetching(false);
  };

  useEffect(() => {
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, []);

  return (
    <>
      {auth && <StudySlider/>}
      <MainSlider/>
      {(data.supercategories.length > 0 ? data : pageData).supercategories.map(el =>
        el.categories.map((item, index) => {
          if (item.courses.length === 0) {
            return <></>
          }
          return <PublicSlider type={item.category_name} supercategoryId={el.supercategory_id} pageId={item.category_id}
                        data={item.courses} key={'pblsr' + index}/>
        })
      )}
      {loading && page < 2 && <PublicSlider type={''} data={[{},{},{},{}]} loading={loading}/>}
      {loading && page >= 2 && <Loader1/>}
    </>
  );
}

export default Main;
