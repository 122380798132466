import React from 'react';
import './radioControls.scss';

export default function RadioControls({ callback, isActive }) {
  const handleClick = () => {
    callback && callback();
  };

  return (
    <div className={`radio-control${JSON.parse(isActive) ? ' radio-control--active' : ''}`} onClick={handleClick}>
      <span className='radio-control__container'>
        <span className='radio-control__circle'/>
      </span>
    </div>
  )
}