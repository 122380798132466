export const OPEN_OVERLAY = 'OPEN_OVERLAY';
export const CLOSE_OVERLAY = 'CLOSE_OVERLAY';

export const openOverlayAction = (body, params, withCloseBtn = false, isCentered = true, closeFunc = null) => {
    return {
        type: OPEN_OVERLAY,
        modalBody: body,
        params: {...params},
        withCloseBtn: withCloseBtn,
        isCentered: isCentered,
        closeFunc: closeFunc
    }
}

export const closeOverlayAction = () => {
    return {
        type: CLOSE_OVERLAY
    }
}