import React, { useEffect, useState } from 'react';
import './App.scss';
import './assets/fonts/fonts.css';
import Router from './routes/Router';
import Header from './components/Header';
import Footer from './components/Footer';
import { useSelector } from 'react-redux';
import Overlay from './components/common/Overlay';
import { ToastContainer } from 'react-toastify';
import TeacherRouter from './routes/TeacherRouter';
import { useHistory } from 'react-router-dom';
import ManageLessons from './components/Pages/PartnerPage/ManageLessons';

export const jivoLangs = {
  rus: `//code-ya.jivosite.com/widget/gnUfosl3dI`,
  eng: `//code-ya.jivosite.com/widget/mrTZfPX3sA`,
  kz: `//code-ya.jivosite.com/widget/E4qWZgMlxX`,
  es: `//code-ya.jivosite.com/widget/T6wt0eLRfG`,
  tr: `//code-ya.jivosite.com/widget/RTEAO9XDdZ`,
};

const excludeJivoURLS = [
  'webinar',
  'partner',
  'play',
  'my-courses',
  'profile',
  'settings',
  'support',
];

function App() {
  const fullscreen = useSelector((state) => state.settings.fullscreen);
  const isShow = useSelector((state) => state.overlay.isShow);
  const auth = useSelector((state) => state.auth.authenticated);
  const [role, setRole] = useState('');
  const history = useHistory();
  const currentLang = useSelector((state) => state.settings.lang);

  useEffect(() => {
    setRole(JSON.parse(localStorage.getItem('role')));
  }, [auth]);

  useEffect(() => {
    history.listen((location) => {
      if (auth) {
        return;
      }
      if (new RegExp(excludeJivoURLS.join('|')).test(location.pathname)) {
        if (window.jivo_destroy) {
          window.jivo_destroy();
        }
      } else if (!document.querySelector('#jchat') && !auth) {
        (async function () {
          let newJchat = document.createElement('script');

          newJchat.async = true;
          newJchat.src = jivoLangs[currentLang];
          newJchat.id = 'jchat';

          document.querySelector('head').appendChild(newJchat);
        })();
      } else if (window.jivo_init) {
        window.jivo_init();
      }
    });
  }, [currentLang, history, auth]);

  // Следим за появлением кастомного тега JIVO-чата в dom
  // и убираем его, для авторизованного пользователя
  useEffect(() => {
    const observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutation) {
        mutation.addedNodes.forEach((node) => {
          if (auth && node.nodeName === 'JDIV') {
            if (window.jivo_destroy) {
              window.jivo_destroy();
            }
          }
        });
      });
    });

    observer.observe(document.querySelector('body'), { childList: true });

    return () => {
      observer.disconnect();
    };
  }, [auth]);

  // Восстанавливаем чат при выходе
  useEffect(() => {
    if (auth && document.querySelector('jdiv') && window.jivo_destroy) {
      window.jivo_destroy();
    } else if (!auth && window.jivo_init) {
      window.jivo_init();
    }
  }, [auth]);

  return (
    <div className='App'>
      <Header show={!fullscreen} />
      <main>{role === 'teacher' ? <TeacherRouter /> : <Router />}</main>
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {isShow && <Overlay />}
      {!fullscreen && <Footer />}
    </div>
  );
}

export default App;
