import React from 'react';

export default function Loader5() {
  return (
    <div class='loader5'>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}
