import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import TasksList from '../../common/CourseInterface/TasksList';
import VideoDisplay from '../../common/CourseInterface/MainDisplay/VideoDisplay';
import CourseInterface from '../../common/CourseInterface';
import { useDispatch, useSelector } from 'react-redux';
import { getFullCourseUrl } from 'services/api';
import { DISABLE_FULLSCREEN } from 'actions/types';
import BuyCourse from '../BuyCoursePage';
import Loader3 from '../../common/Loader/loader3';
import { getLatestCourses } from 'actions/getCourses';

export default function Course(props) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [access, setAccess] = useState(true);
  const location = useLocation();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth.authenticated);

  useEffect(() => {
    dispatch({ type: DISABLE_FULLSCREEN });
    setLoading(true);

    axios
      .get(getFullCourseUrl(props.match.params.id ? props.match.params.id : location.pathname.split('/')[2]))
      .then((res) => {
        if (res.data.lessons.some((el) => !el.is_free)) {
          setAccess(false);
        }
        else {
          setAccess(true);
        }
        setData(res.data);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {
      auth && dispatch(getLatestCourses());
    };
  }, [location.pathname, auth, props.match.params.id, dispatch]);

  if (loading) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '50px' }}>
        <Loader3 />
      </div>
    );
  }

  return !access ? (
    <BuyCourse data={data} />
  ) : (
    <CourseInterface
      type={'course'}
      ListToDisplay={TasksList}
      DisplayComponent={VideoDisplay}
      title={data.course_title || ''}
      data={data}
      coursesData={data.lessons}
      loading={loading}
      isPrivate={props.match.params.url}
    />
  );
}
