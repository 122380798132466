import {CLOSE_OVERLAY, OPEN_OVERLAY} from "../actions/overlay";

const initialState = {
    isShow: false,
    params: {},
    modalBody: null,
    withCloseBtn: false,
    isCentered: true,
    closeFunc: null
};

export const overlay = (state = initialState, action) => {
    switch (action.type) {
        case OPEN_OVERLAY:
            return {
                ...state,
                isShow: true,
                params: {...action.params},
                modalBody: action.modalBody,
                withCloseBtn: action.withCloseBtn,
                isCentered: action.isCentered,
                closeFunc: action.closeFunc
            }
        case CLOSE_OVERLAY:
            return {...initialState}
        default:
            return state
    }
}