import React, { useContext } from 'react';
import Chess from 'chess.js';
import Chessboard from 'chessboardjsx';
import PieceSelectingPopup from './PieceSelectingPopup';
import {
  isPromotion,
  setBoardWidth,
  getStylesOfAvailableMoves,
  checkIsDraw,
  getDifficultySettingCommands,
  getDepth,
} from '../chessHelperFuntions';
import { BOARD_STYLE, DROP_SQUARE_STYLE, TRANSITION_DURATION } from '../ChessConst';
import { useSelector } from 'react-redux';
import DrawOverlay from './DrawOverlay';
import { PIECES } from '../../../constants/chessPieces';
import { WebinarContext } from '../../../constants/contexts';
import { useTranslation } from 'react-i18next';
import ChooseBotDifficulty from './ChooseBotDifficulty/ChooseBotDifficulty';
import { sendCompleteExercise } from 'components/Pages/Webinar/lib/sendCompleteExercise';

class PlayPositionChessLogic extends React.Component {
  state = {
    fen: 'start',
    allowedComplexity: false,
    allowedDraw: false,
    squareStyles: {},
    pieceSquare: '',
    history: [],
    complete: false,
    undoHistory: [],
    oppositeStep: false,
    turn: '',
    draw: false,
    lose: false,
    promotionPopup: false,
    loading: true,
    attempts: 0,
    exerciseMessage: '',
    userColor: '',
    playPosition: false,
    difficulty: '9',
    update: false,
    isBotDifficultyOpen: false,
    lastEngineCommand: '',
  };
  containerRef = React.createRef();
  moveSound = React.createRef();
  correct = React.createRef();
  incorrect = React.createRef();
  chessRef = React.createRef();

  constructor(props) {
    super(props);
    // this.state.engine = new Worker("/stockfish11/stockfish.js");
    this.state.engine = window.STOCKFISH
      ? window.STOCKFISH()
      : new Worker('/stockfish11/stockfish.js');

    this.state.engine.onmessage = (event) => {
      const data = event.data ? event.data : event;

      this.setState(() => ({ lastEngineCommand: data }));
      if (data === 'readyok') {
        this.sendUCI('setoption name Skill Level Maximum Error value 0');
        this.sendUCI('setoption name Skill Level Probability value 1000');
        this.setState(() => ({ loading: false }));
        getDifficultySettingCommands(Number(this.state.difficulty)).forEach((el) => {
          this.sendUCI(el);
        });
      }
      if (data.includes('bestmove')) {
        setTimeout(() => {
          this.botMove(data);
        }, 500);
      }
    };

    this.state.engine.onerror = (event) => {
      this.forceUpdate();
    };
  }

  componentDidMount() {
    const { fen, context, setContext, serviceMode, allowedDraw } = this.props;

    this.create();

    if (fen) {
      this.game = new Chess(fen);
      this.setState({
        fen: fen,
        turn: this.game.turn(),
        userColor: this.game.turn(),
      });
    } else {
      this.game = new Chess();
    }

    if (!this.game || !this.state.engine) {
      this.forceUpdate();
    }

    if (allowedDraw) {
      this.setState(() => ({
        allowedComplexity: allowedDraw.allowed_complexity_choice,
        allowedDraw: allowedDraw.allowed_draw,
      }));
    }

    if (serviceMode === 'webinar') {
      if (context.userAnswer !== null) {
        this.setState(() => ({
          exerciseMessage: this.props.t(
            context.userAnswer === 'true' ? 'webinar.correctlyDone' : 'webinar.incorrectlyDone'
          ),
          lose: context.userAnswer === 'false',
          complete: context.userAnswer === 'true',
        }));
        context.userAnswer === 'true'
          ? this.playMoveSound(this.correct)
          : this.playMoveSound(this.incorrect);
      }
      setContext({
        ...context,
        correctAnswerPromise: (resolve, reject) => {
          if (!this.state.exerciseMessage) {
            this.setState(() => ({
              exerciseMessage: this.props.t('webinar.incorrectlyDone'),
              lose: true,
            }));
            this.playMoveSound(this.incorrect);
          }
          return setTimeout(() => resolve('finish'), 3000);
        },
      });
    }
  }

  componentDidUpdate() {
    const { complete, playPosition, lose, draw, update } = this.state;

    if (!this.game || !this.state.engine) {
      this.forceUpdate();
    }

    if (this.game.game_over() && !complete) {
      this.onGameOver();
      setTimeout(() => {
        if (playPosition && (!lose || !draw)) {
          this.setState(() => ({ update: !update }));
        }
      }, 1500);
    }
  }

  attemptsCounter = () => {
    this.setState((state) => {
      return {
        attempts: state.attempts + 1,
      };
    });
  };

  onGameOver = () => {
    const { userColor, turn, attempts, allowedDraw } = this.state;
    this.setState(() => ({ playPosition: true, complete: true }));
    if (checkIsDraw(this.game)) {
      this.setState(() => ({ draw: true }));
      if (this.props.serviceMode === 'webinar') {
        if (!allowedDraw) {
          if (JSON.parse(localStorage.getItem('role')) !== 'teacher') {
            sendCompleteExercise(this.props.context.socket, {
              exercise_id: this.props.id,
              exercise_correct: false,
            })
          }

          this.setState(() => ({ exerciseMessage: this.props.t('webinar.incorrectlyDone') }));
          //updateWebinarCurrentExercise(this.props.context.accessToken, 'false')
          this.props.setContext({ ...this.props.context, userAnswer: 'false' });
        } else {
          if (JSON.parse(localStorage.getItem('role')) !== 'teacher') {
            sendCompleteExercise(this.props.context.socket, {
              exercise_id: this.props.id,
              exercise_correct: true,
              info: {
                exerciseId: this.props.id,
                lessonId: this.props.lessonId,
              },
            })
          }
 
          this.setState(() => ({ exerciseMessage: this.props.t('webinar.correctlyDone') }));
          //updateWebinarCurrentExercise(this.props.context.accessToken, 'true')
          this.props.setContext({ ...this.props.context, userAnswer: 'true' });
        }
      } else {
        allowedDraw ? this.props.successCallback(attempts) : this.attemptsCounter();
      }
      allowedDraw ? this.playMoveSound(this.correct) : this.playMoveSound(this.incorrect);
    } else if (turn === userColor) {
      this.setState(() => ({ lose: true }));
      if (this.props.serviceMode === 'webinar') {
        if (JSON.parse(localStorage.getItem('role')) !== 'teacher') {
          sendCompleteExercise(this.props.context.socket, {
            exercise_id: this.props.id,
            exercise_correct: false,
          })
        }         

        this.setState(() => ({ exerciseMessage: this.props.t('webinar.incorrectlyDone') }));
        //updateWebinarCurrentExercise(this.props.context.accessToken, 'false')
        this.props.setContext({ ...this.props.context, userAnswer: 'false' });
      } else this.attemptsCounter();
      this.playMoveSound(this.incorrect);
    } else {
      if (this.props.serviceMode === 'webinar') {
        this.setState(() => ({ draw: true }));

        if (JSON.parse(localStorage.getItem('role')) !== 'teacher') {
          sendCompleteExercise(this.props.context.socket, {
            exercise_id: this.props.id,
            exercise_correct: true,
            info: {
              exerciseId: this.props.id,
              lessonId: this.props.lessonId,
            },
          })
        }

        this.setState(() => ({ exerciseMessage: this.props.t('webinar.correctlyDone') }));
        //updateWebinarCurrentExercise(this.props.context.accessToken, 'true')
        this.props.setContext({ ...this.props.context, userAnswer: 'true' });
      } else {
        this.props.successCallback(attempts);
      }
      this.playMoveSound(this.correct);
    }
    this.setState(() => ({ turn: this.state.turn === 'w' ? 'b' : 'w' }));
  };

  sendUCI = (str) => {
    this.state.engine && this.state.engine.postMessage(str);
  };

  create = () => {
    this.sendUCI('uci');
    this.sendUCI('ucinewgame');
    this.sendUCI('isready');
    this.sendUCI(`position fen ${this.props.fen}`);
  };

  sendPositionToEngine = () => {
    this.sendUCI(`position fen ${this.game.fen()}`);
    this.sendUCI(
      `go depth ${getDepth(Number(this.state.difficulty))} movetime 800${
        Number(this.state.difficulty) === 9 ? ' mate 10' : ''
      }`
    );
  };

  onPieceSelect = (piece) => {
    this.setState(() => ({
      promotionPopup: false,
    }));

    let move = this.game.move({
      from: this.state.promotionMove.from,
      to: this.state.promotionMove.to,
      promotion: piece,
    });

    this.makeMove(move);
  };

  playMoveSound = (ref) => {
    const { enableSound } = this.props;
    enableSound &&
      ref.current &&
      ref.current.play &&
      typeof ref.current.play === 'function' &&
      ref.current.play();
  };

  botMove = (command) => {
    this.game.move(command.split(' ')[1], { sloppy: true });
    this.sendUCI(`position fen ${this.game.fen()}`);
    this.setState(
      (state) => {
        return {
          fen: this.game.fen(),
          history: this.game.history(),
          pieceSquare: '',
          oppositeStep: false,
          turn: this.game.turn(),
        };
      },
      () => {
        setTimeout(() => this.playMoveSound(this.moveSound), 340);

        if (this.game.game_over() && !this.state.complete) {
          this.setState(() => ({ playPosition: true }));
        }
      }
    );
  };

  makeMove = (move) => {
    if (move === null) return;
    this.playMoveSound(this.moveSound);
    this.setState(() => ({
      fen: this.game.fen(),
      history: this.game.history(),
      pieceSquare: '',
      turn: this.game.turn(),
      oppositeStep: true,
      squareStyles: {},
      undoHistory: [],
    }));
    this.sendPositionToEngine();
    // if (this.props.serviceMode === 'webinar') {
    //   updateWebinarCurrentExercise(this.props.context.accessToken, this.game.fen())
    //   this.props.setContext({...this.props.context, userAnswer: this.game.fen()})
    // }
  };

  onDrop = ({ sourceSquare, targetSquare }) => {
    if (isPromotion({ chess: this.game, move: { from: sourceSquare, to: targetSquare } })) {
      this.setState(() => ({
        promotionPopup: true,
        promotionMove: { from: sourceSquare, to: targetSquare },
      }));
    } else {
      let move = this.game.move({
        from: sourceSquare,
        to: targetSquare,
      });

      return this.makeMove(move);
    }
  };

  onSquareClick = (square) => {
    const { showAvailableMoves } = this.props;

    let moves = this.game.moves({
      square: square,
      verbose: true,
    });

    if (showAvailableMoves) {
      this.setState(() => ({
        pieceSquare: square,
        squareStyles: {
          ...getStylesOfAvailableMoves(moves),
          [square]: { boxShadow: 'inset 0 0 0 2px rgb(57, 91, 93)' },
        },
      }));
    } else {
      this.setState(() => ({
        pieceSquare: square,
        squareStyles: { [square]: { boxShadow: 'inset 0 0 0 2px rgb(57, 91, 93)' } },
      }));
    }

    if (isPromotion({ chess: this.game, move: { from: this.state.pieceSquare, to: square } })) {
      this.setState(() => ({
        promotionPopup: true,
        promotionMove: { from: this.state.pieceSquare, to: square },
      }));
    } else {
      let move = this.game.move({
        from: this.state.pieceSquare,
        to: square,
      });

      return this.makeMove(move);
    }
  };

  onResetClick = () => {
    this.game.load(this.props.fen);
    this.setState(() => ({
      fen: this.game.fen(),
      history: [],
      pieceSquare: '',
      draw: false,
      lose: false,
      complete: false,
      turn: this.game.turn(),
      squareStyles: {},
    }));
  };

  onBackClick = () => {
    const { userColor } = this.state;

    let undo = this.game.undo();
    if (undo) {
      if (undo.color === userColor) {
        this.setState((state) => {
          return { undoHistory: undo ? [undo, ...state.undoHistory] : state.undoHistory };
        });
      } else {
        let secondUndo = this.game.undo();
        this.setState((state) => {
          return {
            undoHistory:
              undo && secondUndo ? [secondUndo, undo, ...state.undoHistory] : state.undoHistory,
          };
        });
      }
      this.setState(() => ({
        fen: this.game.fen(),
        history: this.game.history(),
        pieceSquare: '',
        turn: this.game.turn(),
        squareStyles: {},
      }));
    }
  };

  onNextClick = () => {
    const { undoHistory, userColor } = this.state;

    if (undoHistory.length > 0) {
      let move = this.game.move(undoHistory[0].san);
      if (move) {
        let arr = [...undoHistory];
        if (move.color !== userColor) {
          arr.shift();
        } else if (undoHistory[1]) {
          this.game.move(undoHistory[1].san);
          arr.shift();
          arr.shift();
        }
        this.setState(() => ({
          fen: this.game.fen(),
          history: this.game.history(),
          pieceSquare: '',
          undoHistory: arr,
          turn: this.game.turn(),
          squareStyles: {},
        }));
      }
    }
  };

  componentWillUnmount() {
    this.game = null;
    this.state.engine && this.state.engine.terminate && this.state.engine.terminate();
  }

  setDifficulty(value) {
    this.setState({ difficulty: value }, () => {
      this.create();
    });
  }

  setOpenBotDifficulty(value) {
    this.setState(() => ({ isBotDifficultyOpen: value ? value : !this.state.isBotDifficultyOpen }));
  }

  render() {
    const {
      fen,
      squareStyles,
      turn,
      draw,
      lose,
      // complete,
      promotionPopup,
      oppositeStep,
      exerciseMessage,
      allowedDraw,
      allowedComplexity,
      history,
      playPosition,
      update,
      isBotDifficultyOpen,
    } = this.state;
    const { counter, TaskText, orientation, title, chessBoardColor, serviceMode, points, context } =
      this.props;

    return (
      <>
        <div className={'chess-display__chessboard'} ref={this.containerRef}>
          {oppositeStep && <div className='chess-display__description-overlay' />}
          {window.innerWidth > 300 && (
            <DrawOverlay
              ref={this.chessRef}
              squareWidth={setBoardWidth(serviceMode) / 8}
              containerRef={this.containerRef}
              fen={fen}
            />
          )}
          <div ref={this.chessRef}>
            <Chessboard
              id='PlayPosition'
              orientation={orientation}
              calcWidth={() => setBoardWidth(serviceMode)}
              position={fen}
              onDrop={this.onDrop}
              lightSquareStyle={chessBoardColor?.LIGHT || ''}
              darkSquareStyle={chessBoardColor?.DARK || ''}
              boardStyle={BOARD_STYLE}
              squareStyles={squareStyles}
              dropSquareStyle={DROP_SQUARE_STYLE}
              onSquareClick={this.onSquareClick}
              undo={true}
              transitionDuration={TRANSITION_DURATION}
              pieces={PIECES}
            />
          </div>
        </div>

        <div
          style={{
            maxWidth: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflowX: 'hidden',
          }}>
          <TaskText
            isCourse={this.props.serviceMode !== 'webinar'}
            counter={counter}
            onBackClick={this.onBackClick}
            onNextClick={this.onNextClick}
            onResetClick={this.onResetClick}
            turn={turn}
            title={title}
            serviceMode={serviceMode}
            isPlayPosition={true}
            update={update}
            playPositionStatus={
              lose || draw || this.props.serviceMode !== 'webinar' || playPosition
            }
          />
          {/*<div className={`chess-display__control-container`} style={{ opacity: (lose || draw || this.props.serviceMode !== 'webinar') ? '1' : '0' }}>
            <button className='chess-display__control-button chess-display__control-button--back' onClick={this.onBackClick} />
            <button className='chess-display__control-button chess-display__control-button--forward' onClick={this.onNextClick} />
            {/*<button className='chess-display__control-button chess-display__control-button--repeat' onClick={onResetClick}/>
    </div>*/}
          <span className='chess-display__dotes chess-display__dotes--mobile login__dotes login__dotes--task' />
          <div
            style={{
              marginTop: '10px',
              display: allowedComplexity && history.length === 0 ? 'block' : 'none',
              transition: '.4s ease',
              maxWidth: '100%',
              height: isBotDifficultyOpen ? '270px' : '',
            }}>
            <ChooseBotDifficulty
              setBotDifficulty={this.setDifficulty.bind(this)}
              toggleShowBotDifficulty={this.setOpenBotDifficulty.bind(this)}
            />
          </div>
        </div>

        {draw && !allowedDraw && serviceMode !== 'webinar' && (
          <div className='selectPopup'>
            <div className='chess-display__popup-container'>
              <p className='chess-display__popup-title'>{this.props.t('game.draw')}</p>
              <button
                className='chess-display__popup-button'
                type='button'
                onClick={this.onResetClick}>
                {this.props.t('game.tryAgain')}
              </button>
            </div>
          </div>
        )}

        {lose && serviceMode !== 'webinar' && (
          <div className='selectPopup'>
            <div className='chess-display__popup-container'>
              <p className='chess-display__popup-title'>{this.props.t('game.lose')}</p>
              <button
                className='chess-display__popup-button'
                type='button'
                onClick={this.onResetClick}>
                {this.props.t('game.tryAgain')}
              </button>
            </div>
          </div>
        )}

        {serviceMode === 'webinar' && exerciseMessage && (
          <div
            className={`chess-display_webinar__message ${
              context.userAnswer === 'false' || context.userAnswer == null
                ? 'chess-display_webinar__message_incorrect'
                : 'chess-display_webinar__message_correct'
            }`}>
            {exerciseMessage}
            <p>
              {context.userAnswer === 'false' || context.userAnswer == null
                ? `0 ${this.props.t('webinar_rating.points')}`
                : `+${points} ${this.props.t('webinar_rating.points')}`}
            </p>
          </div>
        )}

        {promotionPopup && <PieceSelectingPopup onSelect={this.onPieceSelect} />}
        <audio id='audio' src={require('../../../assets/audio/move.mp3')} ref={this.moveSound} />
        <audio id='audio' src={require('../../../assets/audio/correct.wav')} ref={this.correct} />
        <audio
          id='audio'
          src={require('../../../assets/audio/incorrect.wav')}
          ref={this.incorrect}
        />
      </>
    );
  }
}

export default function PlayPositionChessTable({
  counter,
  startFen,
  pgn,
  successCallback,
  points,
  TaskText,
  title,
  serviceMode,
  id,
  allowedDraw,
  lessonId
}) {
  const sound = useSelector((state) => state.settings.sound);
  const moves = useSelector((state) => state.settings.moves);
  const chessBoardColor = useSelector((state) => state.settings.chessBoardColor);
  const { context, setContext } = useContext(WebinarContext);
  const { t } = useTranslation();

  const orientationParser = () => {
    const side = startFen.split(' ')[1];
    return side === 'w' ? 'white' : 'black';
  };

  return (
    <PlayPositionChessLogic
      counter={counter}
      fen={startFen}
      allowedDraw={allowedDraw}
      pgn={pgn}
      id={id}
      successCallback={successCallback}
      chessBoardColor={chessBoardColor}
      title={title}
      context={context}
      points={points}
      setContext={setContext}
      TaskText={TaskText}
      t={t}
      serviceMode={serviceMode}
      orientation={orientationParser()}
      enableSound={sound}
      showAvailableMoves={moves}
      lessonId={lessonId}
    />
  );
}
