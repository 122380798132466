import React from "react";
import style from "./style.module.scss";
import {useTranslation} from "react-i18next";
import classnames from "classnames";

const Button = ({onClick, classname}) => {
    const {t} = useTranslation();
    return (
        <button className={classnames(style.button, classname)} onClick={onClick}>{t('abclass.signUp')}</button>
    )
}

export default Button;