import React, { useCallback, useEffect, useRef, useState } from 'react';
import { setBoardWidth } from 'components/common/chessHelperFuntions';
import { BOARD_STYLE, CHESS_SQUARE_COLORS } from 'components/common/ChessConst';
import { useDispatch, useSelector } from 'react-redux';
import { addCompletedExercises } from 'actions/addCompletedExercise';
import Chessboard from 'chessboardjsx';
import axios from "axios";
import CompletePopup from "../CompletePopup";
import { useTranslation } from "react-i18next";
import CourseLoader from "../../CourseLoader";
import DrawOverlay from "../../../ChessTables/DrawOverlay";
import { POST_COMPLETED_EXERCISE } from "services/api";
import LessonsNavigationButton from "../../../LessonsNavigationButton";
import { INCREASE_ADVERTISING } from "actions/types";
import { closeOverlayAction, openOverlayAction } from "../../../../../actions/overlay";
import classnames from "classnames";

export default function PictureDisplay({ counter, lessonId, TaskText, content, type, class_points, title, loading, onBackClick, onNextClick, id, mode = 'default', onMakeMove }) {
  const [open, setOpen] = useState(false);
  const [scores, setScores] = useState({});
  const { t } = useTranslation();
  const auth = useSelector(state => state.auth.authenticated);
  const chessBoardColor = useSelector(state => state.settings.chessBoardColor);
  const dispatch = useDispatch();
  const containerRef = useRef();
  const chessRef = useRef();

  const handleClose = useCallback(() => {
    dispatch(closeOverlayAction());
    Array.from(document.querySelectorAll('.chess-display__test-label--warn')).forEach(el => {
      el.classList.remove('chess-display__test-label--warn');
    });
  }, []);

  useEffect(() => {
    if (open) dispatch(openOverlayAction(CompletePopup, { onNextClick, scores }, false, true, handleClose))
  }, [open])

  const handleOpen = (attempts) => {
    dispatch({ type: INCREASE_ADVERTISING });
    if (auth) {
      let data = new FormData();
      data.append('attempts', attempts);
      data.append('hint_is_used', '0');
      data.append('exercise_id', id);
      axios.post(POST_COMPLETED_EXERCISE, data)
        .then((res) => {
          setScores(res.data);
          setOpen(true);
          dispatch(addCompletedExercises(id))
        })
        .catch(e => {
          console.log(e.response)
        });
    } else {
      setOpen(true);
    }
  };


  if (loading) {
    return (
      <CourseLoader loading={loading} mode={mode} />
    );
  }

  return (
    <div className={classnames('chess-display', {
      'chess-display_webinar': mode === 'webinar'
    })}>
      <div className='chess-display__container chess-display__container--buttons'>
        <div className={classnames('chess-display__wrapper', {
          'chess-display__wrapper_webinar': mode === 'webinar'
        })}>
          {type === 'picture' &&
            <div
              className={classnames('chess-display__picture', {
                'chess-display__picture_webinar': mode === 'webinar'
              })}
              style={{ backgroundImage: `url('${content.fen}')` }} />
          }
          {type === 'chess' &&
            <div className='chess-display__chessboard' ref={containerRef}>
              <div className='chess-display__description-overlay' />
              {window.innerWidth > 500 && <DrawOverlay ref={chessRef} squareWidth={setBoardWidth(mode) / 8} containerRef={containerRef} />}
              <div ref={chessRef}>
                <Chessboard
                  id='test'
                  calcWidth={() => setBoardWidth(mode)}
                  position={content.fen}
                  lightSquareStyle={chessBoardColor?.LIGHT || ''}
                  darkSquareStyle={chessBoardColor?.DARK || ''}
                  boardStyle={BOARD_STYLE}
                />
              </div>
            </div>
          }

          <TaskText
            isCourse={mode !== 'webinar'}
            counter={counter}
            type={type}
            id={id}
            points={class_points}
            question={content.question}
            answers={content.answers}
            title={title}
            onNextClick={onNextClick}
            mode={mode}
            successCallback={handleOpen}
            onMakeMove={onMakeMove}
            lessonId={lessonId}
          />

        </div>
        {
          mode !== 'webinar' && (
            <div className='chess-display__buttons-container'>
              <LessonsNavigationButton loading={loading} onClick={onBackClick}>{t('exercises.back')}</LessonsNavigationButton>
              <LessonsNavigationButton loading={loading} onClick={onNextClick}>{t('exercises.next')}</LessonsNavigationButton>
            </div>
          )
        }
      </div>
    </div>
  )
}
