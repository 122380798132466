import React, { useEffect, useRef, useState } from 'react';
import Chess from 'chess.js';
import Chessboard from 'chessboardjsx';
import {
  setBoardWidth,
  getStylesOfAvailableMoves,
  checkIsDraw,
  isPromotion
} from '../chessHelperFuntions';
import { BOARD_STYLE, CHESS_SQUARE_COLORS, DROP_SQUARE_STYLE, TRANSITION_DURATION } from '../ChessConst';
import { useSelector } from "react-redux";
import DrawOverlay from "./DrawOverlay";
import GameEndMessage from "./GameEndMessage";
import PieceSelectingPopup from "./PieceSelectingPopup";
import { PIECES } from "constants/chessPieces";

const PlayWithHumanLogic = ({ startFen, pgn, successCallback, enableSound, showAvailableMoves, TaskText, orientation, title, difficulty, audioHint }) => {
  const [fen, setFen] = useState('start');
  const [squareStyles, setSquareStyles] = useState({});
  const [pieceSquare, setPieceSquare] = useState('');
  const [complete, setComplete] = useState(false);
  const [history, setHistory] = useState('');
  const [undoHistory, setUndoHistory] = useState('');
  const [promotionPopup, setPromotionPopup] = useState(false);
  const [turn, setTurn] = useState('');
  const [draw, setDraw] = useState(false);
  const [lose, setLose] = useState(false);
  const [win, setWin] = useState(false);
  const [promotionMove, setPromotionMove] = useState({});
  const [game, setGame] = useState();
  const chessBoardColor = useSelector(state => state.settings.chessBoardColor);

  const containerRef = useRef();
  const moveSound = useRef();
  const correct = useRef();
  const chessRef = useRef();

  useEffect(() => {
    let newGame;
    if (startFen) {
      newGame = new Chess(startFen);
      setGame(newGame);
      setFen(startFen);
    } else {
      newGame = new Chess();
      setGame(newGame);
    }
    setTurn(newGame.turn());
  }, []);

  const omGameEnd = () => {
    if (checkIsDraw(game)) {
      setDraw(true);
    } else if (turn === orientation[0]) {
      setLose(true);
    } else {
      setWin(true)
    }
    setComplete(true);
  };

  useEffect(() => {
    if (game) {
      if (game.game_over() && !complete) {
        omGameEnd();
      }
    }
  }, [fen]);

  const onPieceSelect = (piece) => {
    if (complete) return null;


    let move = game.move({
      from: promotionMove.from,
      to: promotionMove.to,
      promotion: piece
    });

    makeMove(move);
    setPromotionPopup(false);
  };

  const playMoveSound = (ref) => {
    enableSound && ref.current.load();
    enableSound &&
    ref.current &&
    ref.current.play &&
    typeof ref.current.play === 'function' &&
    ref.current.play();
  };

  const makeMove = (move) => {
    if (move === null) return;
    playMoveSound(moveSound);
    setFen(game.fen());
    setHistory(game.history());
    setPieceSquare('');
    setTurn(game.turn());
    setSquareStyles({});
  };

  const onDrop = ({ sourceSquare, targetSquare }) => {
    if (complete) return null;
    if (isPromotion({ chess: game, move: { from: sourceSquare, to: targetSquare } })) {
      setPromotionPopup(true);
      setPromotionMove({ from: sourceSquare, to: targetSquare });
    } else {
      let move = game.move({
        from: sourceSquare,
        to: targetSquare
      });

      return makeMove(move)
    }
  };

  const onSquareClick = square => {
    if (complete) return null;

    let moves = game.moves({
      square: square,
      verbose: true
    });

    if (showAvailableMoves) {
      setSquareStyles({ ...getStylesOfAvailableMoves(moves), [square]: { boxShadow: 'inset 0 0 0 2px rgb(57, 91, 93)' } });
    } else {
      setSquareStyles({ [square]: { boxShadow: 'inset 0 0 0 2px rgb(57, 91, 93)' } });
    }
    setPieceSquare(square);
    if (isPromotion({ chess: game, move: { from: pieceSquare, to: square } })) {
      setPromotionMove({ from: pieceSquare, to: square });
      setPromotionPopup(true);
    } else {
      let move = game.move({
        from: pieceSquare,
        to: square
      });

      return makeMove(move)
    }
  };

  const onResetClick = () => {
    game.load(startFen);
    setFen(game.fen());
    setHistory([]);
    setPieceSquare('');
    setDraw(false);
    setLose(false);
    setWin(false);
    setComplete(false);
    setTurn(game.turn());
    setSquareStyles({});
  };

  const onBackClick = () => {
    let undo = game.undo();
    if (undo) {
      setUndoHistory(undo ? [undo, ...undoHistory] : undoHistory);
      setFen(game.fen());
      setHistory(game.history());
      setPieceSquare('');
      setTurn(game.turn());
      setSquareStyles({});
    }
  };

  const onNextClick = () => {
    if (undoHistory.length > 0) {
      let move = game.move(undoHistory[0].san);
      if (move) {
        let arr = [...undoHistory];
        arr.shift();
        setFen(game.fen());
        setHistory(game.history());
        setPieceSquare('');
        setUndoHistory(arr);
        setTurn(game.turn());
        setSquareStyles({});
      }
    }
  };

  return (
    <>
      <div className={'chess-display__chessboard'} ref={containerRef}>

        {window.innerWidth > 900 && <DrawOverlay ref={chessRef} squareWidth={setBoardWidth() / 8} containerRef={containerRef} fen={fen} />}
        <div ref={chessRef}>
          <Chessboard
            id='PlayWithHuman'
            orientation={orientation}
            calcWidth={setBoardWidth}
            position={fen}
            onDrop={onDrop}
            lightSquareStyle={chessBoardColor?.LIGHT || ''}
            darkSquareStyle={chessBoardColor?.DARK || ''}
            boardStyle={BOARD_STYLE}
            squareStyles={squareStyles}
            dropSquareStyle={DROP_SQUARE_STYLE}
            onSquareClick={onSquareClick}
            undo={true}
            transitionDuration={TRANSITION_DURATION}
            pieces={PIECES}
          />
        </div>
      </div>

      <TaskText
        onBackClick={onBackClick}
        onNextClick={onNextClick}
        onResetClick={onResetClick}
        turn={turn}
        title={title}
      />

      {draw && <GameEndMessage redirectRoute={'/play'} text={'draw'} closeCallback={() => setDraw(false)} />}
      {win && <GameEndMessage redirectRoute={'/play'} text={orientation[0] === 'w' ? 'whiteVictory' : 'blackVictory'} closeCallback={() => setWin(false)} />}
      {lose && <GameEndMessage redirectRoute={'/play'} text={orientation[0] === 'b' ? 'whiteVictory' : 'blackVictory'} closeCallback={() => setLose(false)} />}

      {promotionPopup && <PieceSelectingPopup onSelect={onPieceSelect} />}
      <audio id="audio" src={require('../../../assets/audio/move.mp3')} ref={moveSound} />
      <audio id="audio" src={require('../../../assets/audio/correct.wav')} ref={correct} />
    </>
  )
};

export default function PlayWithHuman({ startFen, successCallback, TaskText, title, settings, sendGame }) {
  const sound = useSelector(state => state.settings.sound);
  const moves = useSelector(state => state.settings.moves);

  const orientationParser = () => {
    return settings.side === 'w' ? 'white' : 'black'
  };

  return (
    <PlayWithHumanLogic
      startFen={startFen || 'start'}
      type={settings.type}
      sendGame={sendGame}
      successCallback={successCallback}
      title={title}
      TaskText={TaskText}
      orientation={orientationParser()}
      enableSound={sound}
      showAvailableMoves={moves}
    />
  );
}