import * as Sentry from '@sentry/react';

export function sendCompleteExercise(socket, payload) {
  if (!payload || !payload.exercise_id) return;

  if (payload.info) {
    Sentry.captureMessage({
      ...socket,
      ...payload.info,
      userAnswerArr: payload.info?.userAnswer ?
        payload.info.userAnswer.toString() : null,
      extraHeaders: socket._opts.extraHeaders,
    });
  }

  socket.emit('complete', {
    exercise_id: payload.exercise_id,
    exercise_correct: payload.exercise_correct,
  });
}
