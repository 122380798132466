import React from 'react';
import './supportForm.scss';
import {Form, Formik} from "formik";
import {TextField} from "components/FormikInputs/TextInput";
import {TextAreaField} from "components/FormikInputs/TextAreaInput";
import Button from "components/common/Button";
import * as Yup from "yup";
import CrossIcon from "constants/SVG/CrossIcon";
import {useSelector} from "react-redux";
import {formatPhoneNumber} from "helpers/inputsHelpers";
import {useHttp} from "hooks/http.hook";
import {POST_SEND_QUESTION} from "services/api";
import {showErrorMessage, showSuccessMessage} from "helpers/notifications";
import {useTranslation} from "react-i18next";

export default function SupportForm({handleClose}) {
  const {request, loading, error} = useHttp();
  const profileData = useSelector(state => state.profile.profileData);
  const {t} = useTranslation();

  const validationSchema = Yup.object({
    name: Yup.string().max(150, t('support.max', {count: 150})).required(t('support.required')),
    surname: Yup.string().max(150, t('support.max', {count: 150})).required(t('support.required')),
    email: Yup.string().email(t('support.incorrectEmail')).required(t('support.required')),
    message: Yup.string().min(5, t('support.min', {count: 5})).max(500, t('support.max', {count: 500})).required(t('support.required')),
  });

  return (
    <div className='support-form'>
      <Formik
        enableReinitialize={true}
        validationSchema={validationSchema}
        initialValues={{
          name: profileData.name,
          surname: profileData.surname,
          email: profileData.email,
          phone_number: profileData.phone_number,
          message: ''
        }}
        onSubmit={(values, { setSubmitting }) => {
          request(POST_SEND_QUESTION, 'post', values)
            .then(() => {
              showSuccessMessage(t('support.successMessage'));
              handleClose()
            })
            .catch(() => {
              showErrorMessage(t('support.errorMessage'))
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({ errors, submitCount, isSubmitting  }) => (
          <Form>
            <ul className='support-form__list'>
              <li className='support-form__item'>
                <TextField
                  name={'name'}
                  label={t('support.name')}
                  labelClasses={'support-form__label support-form__label--half'}
                  inputClasses={'support-form__input'}
                  maxLength={150}
                />
                <TextField
                  name={'surname'}
                  label={t('support.surname')}
                  labelClasses={'support-form__label support-form__label--half'}
                  inputClasses={'support-form__input'}
                  maxLength={150}
                />
              </li>
              <li className='support-form__item'>
                <TextField
                  name={'email'}
                  label={t('support.email')}
                  labelClasses={'support-form__label'}
                  inputClasses={'support-form__input'}
                />
              </li>
              <li className='support-form__item'>
              <TextField
                  name={'phone_number'}
                  label={t('support.phone')}
                  labelClasses={'support-form__label'}
                  inputClasses={'support-form__input'}
                  format={formatPhoneNumber}
                  maxLength={13}
                />
              </li>
              <li className='support-form__item'>
                <TextAreaField
                  name={'message'}
                  label={t('support.message')}
                  labelClasses={'support-form__label'}
                  inputClasses={'support-form__input'}
                  maxLength={1000}
                />
              </li>
              <li className='support-form__item'>
                <a href={'mailto:support@ab-chess.com'} className='support-form__link'>support@ab-chess.com</a>
              </li>
              <li className='support-form__item'>
                <Button classes='support-form__button' type={'submit'} disabled={isSubmitting}>{t('support.submit')}</Button>
              </li>
              <span className='support-form__close' onClick={handleClose}>
                <CrossIcon/>
              </span>
            </ul>
          </Form>
        )}
      </Formik>
    </div>
  )
}