import React from 'react';
import style from './style.module.scss';
import Button from '../../../../../common/Button';
import {closeOverlayAction} from '../../../../../../actions/overlay';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import classnames from "classnames";

export default function CognitiveSkillsModal() {
    const dispatch = useDispatch();
    const lang = useSelector((state) => state.settings.lang);
    const {t} = useTranslation();
    return (
        <div className={style.modal}>
            <div className={style.modal__header}>
                <span className={style.modal__title}>{t('statistics_skills.cognitive_skills')}</span>
            </div>
            <div className={style.modal__body}>
                {lang === 'rus' || lang === 'eng' || lang === 'es' ? (
                    <>
                        {lang === 'rus' ? (
                            <p className={style.body__header}>{t('statistics_skills.modal.cognitive_skills.header')}</p>
                        ) : (
                            <>
                                <p className={style.body__header}>{t('statistics_skills.modal.cognitive_skills.header1')}</p>
                                <p className={style.body__header}>{t('statistics_skills.modal.cognitive_skills.header2')}</p>
                            </>
                        )}
                        <p className={style.body__text}>{t('statistics_skills.modal.cognitive_skills.points')}</p>
                        <p className={style.body__text}>
              <span className={classnames(style['body__text-bold'], style.body__text)}>
                {t('statistics_skills.logic')}
              </span>
                            {t('statistics_skills.modal.cognitive_skills.logic_definition')}
                        </p>
                        <p className={style.body__text}>
              <span className={classnames(style['body__text-bold'], style.body__text)}>
                {t('statistics_skills.memory')}
              </span>
                            {t('statistics_skills.modal.cognitive_skills.memory_definition')}
                        </p>
                        <p className={style.body__text}>
              <span className={classnames(style['body__text-bold'], style.body__text)}>
                {t('statistics_skills.focus')}
              </span>
                            {t('statistics_skills.modal.cognitive_skills.focus_definition')}
                        </p>
                        <p className={style.body__text}>
              <span className={classnames(style['body__text-bold'], style.body__text)}>
                {t('statistics_skills.flexibility')}
              </span>
                            {t('statistics_skills.modal.cognitive_skills.flexibility_definition')}
                        </p>
                        <p className={style.body__text}>
              <span className={classnames(style['body__text-bold'], style.body__text)}>
                {t('statistics_skills.planning')}
              </span>
                            {t('statistics_skills.modal.cognitive_skills.planning_definition')}
                        </p>
                        <p className={style.body__text}>{t('statistics_skills.modal.cognitive_skills.explanations_level_rise')}</p>
                        <span className={style.body__text}>{t('statistics_skills.modal.start_level')}</span>
                        <span className={style.body__text}>{t('statistics_skills.modal.luck')}</span>
                    </>
                ) : (
                    ''
                )}

                <div className={style.modal__button_container}>
                    <Button onClick={() => dispatch(closeOverlayAction())} classes={style.modal__button}>
                        {t('statistics_skills.modal.button_text')}
                    </Button>
                </div>
            </div>
        </div>
    );
}
