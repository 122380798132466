import React from 'react';
import { Link } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import CourseImageBanner from "../../../common/CourseImageBanner";

export default function PurchasedCourseItem({category_title, id, image, number_of_exercises, number_of_lessons, title, type_subscription, is_free}) {
  const {t} = useTranslation();

  return (
    <Link className='courses__item' to={`/course/${id}`}>
      <CourseImageBanner
        image={image}
        is_free={is_free}
        type_subscription={type_subscription}
        imageClasses={'courses__item-image'}
      />
      <div className='courses__info'>
        <p className='courses__name'>{category_title}</p>
        <h3 className='courses__title'>{title}</h3>
        <p className='courses__count'>{number_of_lessons} {t('courses.lessons')} / {number_of_exercises} {t('courses.exercises')}</p>
      </div>
      <span className='courses__medal'/>
    </Link>
  )
}