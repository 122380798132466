import * as Yup from "yup";
import {useTranslation} from "react-i18next";

export const REQUIRED_STRING = () => {
    const {t} = useTranslation();
    return Yup.string().required(t('partners.requiredError'))
}

export const TRIM = (schema, name) => {
    const {t} = useTranslation();
    return schema.test(name, t('partners.trimError'), value => {
        return !(/^[\s_,.?|\\()=+;:"'`~&$@!*^%<\->/]+$/.test(value));
    })
}