import React from 'react';
import { ROOK, QUEEN, BISHOP, KNIGHT } from '../ChessConst';
import {useTranslation} from 'react-i18next';
import './pieceSelectingPopup.scss';

export default function PieceSelectingPopup({ onSelect }) {
  const {t} = useTranslation();
  return (
    <>
    {/*<div className='chess-display__piece-selecting piece-selecting'>*/}
      <div className={'selectPopup'}>
        <div className='piece-selecting__container'>
          <p className='piece-selecting__title'>
            {t('exercises.promotion')}
          </p>
          <button className='piece-selecting__item' type='button' onClick={() => onSelect(QUEEN)}>
            {t('exercises.queen')}
          </button>
          <button className='piece-selecting__item' type='button' onClick={() => onSelect(KNIGHT)}>
            {t('exercises.knight')}
          </button>
          <button className='piece-selecting__item' type='button' onClick={() => onSelect(ROOK)}>
            {t('exercises.rook')}
          </button>
          <button className='piece-selecting__item' type='button' onClick={() => onSelect(BISHOP)}>
            {t('exercises.bishop')}
          </button>
        </div>
      </div>
    </>
  )
}