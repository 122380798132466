import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import WebinarRating from '../Rating';
import style from './style.module.scss';
import classnames from 'classnames';
import { getAllUsersRating } from '../../../services/api';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { openOverlayAction } from '../../../actions/overlay';
import Login from '../../Login';

const CallToActionBanner = ({ openRegistrationWindow }) => {
  const { t } = useTranslation();
  return (
    <div className={style['cta-banner']}>
      <div className={style['cta-banner__title']}>
        <span>{t('action_banner.title')}</span>
      </div>
      <div className={style['cta-banner__content']}>
        <p>
          {t('action_banner.part_1')}{' '}
          <span onClick={openRegistrationWindow}>{t('action_banner.link_text')}</span>
          {' '}{t('action_banner.part_2')}
        </p>
      </div>
    </div>
  );
};

const ServiceRating = ({ hideRating, isHidden }) => {
  const [period, setPeriod] = useState('week');
  const [students, setStudents] = useState([]);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const periods = useMemo(() => {
    return [
      { id: 1, text: t('webinar.week'), value: 'week' },
      { id: 2, text: t('webinar.month'), value: 'month' },
      { id: 3, text: t('webinar.allTime'), value: 'all_time' },
    ];
  }, []);

  useEffect(() => {
    getAllUsersRating().then((res) => setStudents(res.data.rating[period]));
  }, [period]);

  const onRegistrationClick = () => {
    dispatch(openOverlayAction(Login, { type: 'registration' }, true, false));
  };

  return (
    <div className={`${style.container}`}>
      <div className={`${style.hide_box} ${isHidden && style.hide_box_show}`}>
        <span className={style.hide_box_msg}>
          <CallToActionBanner openRegistrationWindow={onRegistrationClick} />
        </span>
      </div>
      <div className={style.head}>
        <p className={style.head__title}>{t('webinar.top')}</p>
        <div className={style['head-period']}>
          {periods.map((item) => (
            <div
              key={item.id}
              className={classnames(style['head-period__item'], {
                [style['head-period__item_active']]: item.value === period,
              })}
              onClick={() => setPeriod(item.value)}>
              {item.text}
            </div>
          ))}
        </div>
      </div>
      <WebinarRating students={students} withHead={false} />
    </div>
  );
};

export default React.memo(ServiceRating);
