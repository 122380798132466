export const userRatingsData = [
  {
    date: '18:31:10 03.06.2024',
    rating: 100,
  },
  {
    date: '18:31:18 04.06.2024',
    rating: 342,
  },
  {
    date: '06:47:23 05.06.2024',
    rating: 346,
  },
  {
    date: '11:22:45 06.06.2024',
    rating: 355,
  },
  {
    date: '09:10:12 07.06.2024',
    rating: 360,
  },
  {
    date: '14:55:30 08.06.2024',
    rating: 357,
  },
  {
    date: '08:17:09 09.06.2024',
    rating: 362,
  },
  {
    date: '12:45:56 10.06.2024',
    rating: 364,
  },
  {
    date: '19:29:21 11.06.2024',
    rating: 369,
  },
  {
    date: '10:01:33 12.06.2024',
    rating: 367,
  },
  {
    date: '15:10:44 13.06.2024',
    rating: 375,
  },
  {
    date: '07:53:08 14.06.2024',
    rating: 380,
  },
  {
    date: '17:15:29 15.06.2024',
    rating: 375,
  },
  {
    date: '13:40:59 16.06.2024',
    rating: 379,
  },
  {
    date: '09:05:04 17.06.2024',
    rating: 387,
  },
  {
    date: '07:30:11 18.06.2024',
    rating: 396,
  },
  {
    date: '14:48:33 19.06.2024',
    rating: 398,
  },
  {
    date: '17:19:55 20.06.2024',
    rating: 403,
  },
  {
    date: '10:22:06 21.06.2024',
    rating: 407,
  },
  {
    date: '12:59:42 22.06.2024',
    rating: 410,
  },
  {
    date: '16:33:18 23.06.2024',
    rating: 415,
  },
  {
    date: '11:05:27 24.06.2024',
    rating: 418,
  },
  {
    date: '08:40:52 25.06.2024',
    rating: 422,
  },
  {
    date: '19:04:11 26.06.2024',
    rating: 426,
  },
  {
    date: '15:30:18 27.06.2024',
    rating: 430,
  },
  {
    date: '13:01:25 28.06.2024',
    rating: 435,
  },
  {
    date: '10:40:31 29.06.2024',
    rating: 440,
  },
  {
    date: '17:25:45 30.06.2024',
    rating: 445,
  },
  {
    date: '08:15:54 1.07.2024',
    rating: 450,
  },
  {
    date: '12:37:08 2.07.2024',
    rating: 455,
  },
  {
    date: '15:54:21 3.07.2024',
    rating: 1460,
  },
];

export const months = [
  'января',
  'февраля',
  'марта',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августа',
  'сентября',
  'октября',
  'ноября',
  'декабря',
];

export const weekdays = ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'];

export const options = {
  responsive: true,
  maintainAspectRatio: false, // отключаем сохранение соотношения сторон
  plugins: {
    datalabels: {
      display: false,
    },
    interaction: {
      mode: 'nearest',
    },
    tooltip: {
      callbacks: {
        title: function () {},
      },
      displayColors: false,
      yAlign: 'bottom',
    },
    legend: {
      display: false,
    },
    zoom: {
      pan: {
        enabled: true,
        mode: 'x',
      },
      zoom: {
        wheel: {
          enabled: true,
        },
        pinch: {
          enabled: true,
        },
        mode: 'x',
      },
    },
  },
  scales: {
    x: {
      ticks: {
        autoSkip: false, // отключить автоматическое пропускание меток
      },
    },
    y: {
      ticks: {
        stepSize: 250, // шаг между значениями
        beginAtZero: true, // начать с 0
      },
    },
  },
};
