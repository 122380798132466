import React, { useMemo, useRef } from 'react';
import { signOutAction } from 'actions/signOut';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../common/Button';
import { useOnClickOutsideWithBtn } from 'hooks/outsideClickWithBtn';

export default function HeaderProfileCard(props) {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile.profileData);
  const { t } = useTranslation();
  const ref = useRef();

  const isTeacher = useMemo(() => {
    return JSON.parse(localStorage.getItem('role')) === 'teacher';
  }, []);

  useOnClickOutsideWithBtn(ref, () => props.togglePopup(false), props.btnRef);

  const signOut = () => {
    dispatch(signOutAction());
    props.togglePopup(false);
  };

  return (
    <div className='header-profile__card' ref={ref}>
      <Link
        to={'/profile'}
        onClick={(e) => {
          if (isTeacher) e.preventDefault();
          else props.togglePopup(false);
        }}
      >
        {profile.image ? (
          <img src={profile.image} className='header-profile__avatar' />
        ) : (
          <span className='header-profile__avatar header-profile__avatar--placeholder' />
        )}
      </Link>
      {!isTeacher && (
        <>
          <p className='header-profile__name'>
            {profile.name || ''} {profile.surname || ''}
          </p>
          <Link to='/profile' onClick={() => props.togglePopup(false)}>
            <Button classes={'header-profile__button--gap'}>{t('profileCard.myProfile')}</Button>
          </Link>
        </>
      )}
      <span className='header-profile__dotes' />
      <nav className='header-profile__navigation'>
        <ul className='header-profile__navigation-list'>
          {!isTeacher && (
            <li className='header-profile__navigation-item'>
              <Link
                className='header-profile__navigation-link'
                to='/my-courses/progress'
                onClick={() => props.togglePopup(false)}
              >
                {t('profileCard.myCourses')}
              </Link>
            </li>
          )}
          {/*<li className='header-profile__navigation-item header-profile__navigation-item--only-mobile'>
            <Link className='header-profile__navigation-link' to='/subscription' onClick={() => props.togglePopup(false)}>
              {t('profileCard.subscription')}
            </Link>
          </li>*/}
          <li className='header-profile__navigation-item'>
            <Link className='header-profile__navigation-link' to='/settings' onClick={() => props.togglePopup(false)}>
              {t('profileCard.settings')}
            </Link>
          </li>
          <li className='header-profile__navigation-item'>
            <Link className='header-profile__navigation-link' to={'/support'} onClick={() => props.togglePopup(false)}>
              {t('profileCard.support')}
            </Link>
          </li>
          {!isTeacher && (
            <li className='header-profile__navigation-item'>
              <Link
                className='header-profile__navigation-link'
                to={'/profile/mistakes'}
                onClick={() => props.togglePopup(false)}
              >
                {t('mistakes.title')}
              </Link>
            </li>
          )}
          <li className='header-profile__navigation-item'>
            <span className='header-profile__navigation-link' onClick={signOut}>
              {t('profileCard.exit')}
            </span>
          </li>
        </ul>
      </nav>
    </div>
  );
}
