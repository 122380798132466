import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import Chess from "chess.js";
import Chessboard from "chessboardjsx";
import classnames from "classnames";
import { getStylesOfAvailableMoves, setBoardWidth, pgnParseToArray } from '../chessHelperFuntions';
import {
  BOARD_STYLE,
  CHESS_HINT_COLOR,
  CHESS_SQUARE_COLORS,
  DROP_SQUARE_STYLE,
  TRANSITION_DURATION
} from '../ChessConst';
import { useSelector } from "react-redux";
import DrawOverlay from "./DrawOverlay";
import { PIECES } from "constants/chessPieces";
import { WebinarContext } from "../../../constants/contexts";
import { useTranslation } from "react-i18next";
import { updateWebinarCurrentExercise } from "../../../services/api";
import { sendCompleteExercise } from "components/Pages/Webinar/lib/sendCompleteExercise";

const MODES = {
  PLAY: 'play',
  GAME: 'game'
};

const RepeatChessLogic = ({ counter, startFen, pgn, successCallback, points, enableSound, showAvailableMoves, TaskText, orientation, handleClickOrientation, title, serviceMode, id, lessonId }) => {
  const [fen, setFen] = useState('start');
  const [squareStyles, setSquareStyles] = useState({});
  const [pieceSquare, setPieceSquare] = useState('');
  const [halfMovesArray, setHalfMovesArray] = useState([]);
  const [totalMoves, setTotalMoves] = useState();
  const [currentMoveNumber, setCurrentMoveNumber] = useState(0);
  const [complete, setComplete] = useState(false);
  const [exerciseMessage, setExerciseMessage] = useState('');
  const [attempts, setAttempts] = useState(0);
  const [history, setHistory] = useState([]);
  const [mode, setMode] = useState(MODES.PLAY);
  const [isPlaying, setIsPlaying] = useState(false);
  const [hintsIsUsed, setHintsIsUsed] = useState(0);
  const [maxHintsCount] = useState(2);
  const [intervalId, setIntervalId] = useState(null);
  const [game, setGame] = useState();
  const [isExerciseEnd, setExerciseEnd] = useState(false);
  const { context, setContext } = useContext(WebinarContext);
  const { t } = useTranslation();
  const chessRef = useRef(null);

  const chessBoardColor = useSelector(state => state.settings.chessBoardColor);

  const containerRef = useRef();
  const moveSound = useRef();
  const correct = useRef();
  const incorrect = useRef();

  useEffect(() => {
    let newGame;
    if (startFen) {
      newGame = new Chess(startFen);
      setGame(newGame);
      setFen(startFen);
    } else {
      newGame = new Chess();
      setGame(newGame);
    }

    let parsedPgn = pgnParseToArray(pgn);
    setHalfMovesArray(parsedPgn);
    setTotalMoves(parsedPgn.length);

    return () => {
      if (intervalId) clearInterval(intervalId)
    }
  }, []);

  useEffect(() => {
    if (serviceMode === 'webinar' && game) {
      if (context.userAnswer !== null) {
        setExerciseMessage(t(context.userAnswer === 'true' ? 'webinar.correctlyDone' : 'webinar.incorrectlyDone'))
        context.userAnswer === 'true' ? playMoveSound(correct) : playMoveSound(incorrect);
      }
      setContext({
        ...context, correctAnswerPromise: (resolve, reject) => {
          setExerciseEnd(true);
          if (!exerciseMessage) {
            if (JSON.parse(localStorage.getItem('role')) !== 'teacher') {
              sendCompleteExercise(context.socket, { exercise_id: id, exercise_correct: false });
            }

            setExerciseMessage(t('webinar.incorrectlyDone'))
            playMoveSound(incorrect);
          }
          onStartClick(serviceMode);
          onPlayClick(resolve);
        }
      })
    }
  }, [game, exerciseMessage])

  useEffect(() => {

    if (totalMoves === currentMoveNumber && !complete) {
      serviceMode === 'default' && successCallback(attempts);
      playMoveSound(correct);
      setComplete(true);
      if (serviceMode === 'webinar' && context.userAnswer == null) {
        setExerciseMessage(t('webinar.correctlyDone'));

        if (JSON.parse(localStorage.getItem('role')) !== 'teacher') {
          sendCompleteExercise(context.socket, { exercise_id: id, exercise_correct: true, info: {
              exerciseId: id,
              userAnswer: history.length && history,
              lessonId,
            }, });
        }

        updateWebinarCurrentExercise(context.accessToken, 'true').then((res) => {
          console.log('Load testing success, components/common/ChessTables/RepeatChessTable.jsx N1', res.data)
          setContext({ ...context, userAnswer: 'true' })
        }).catch(err => {
          console.error('Load testing error catch, components/common/ChessTables/RepeatChessTable.jsx N1', err)
        })
      }
    }
  }, [totalMoves, currentMoveNumber]);

  const playMoveSound = (ref) => {
    //enableSound && ref.current.load();
    enableSound &&
    ref.current &&
    ref.current.play &&
    typeof ref.current.play === 'function' &&
    ref.current.play();
  };

  const attemptsCounter = () => {
    setAttempts(attempts + 1);
  };

  const makeMove = (move) => {

    if (move === null) return;

    if (move.san === halfMovesArray[currentMoveNumber]) {
      setFen(game.fen());
      setHistory(game.history({ verbose: true }));
      setPieceSquare('');
      setSquareStyles({});
      setCurrentMoveNumber(currentMoveNumber + 1);
      playMoveSound(moveSound);
      playMoveSound(correct);
    } else {
      if (serviceMode === 'webinar' && !exerciseMessage) {
        setExerciseMessage(t('webinar.incorrectlyDone'))
        updateWebinarCurrentExercise(context.accessToken, 'false').then((res) => {
          console.log('Load testing success, components/common/ChessTables/RepeatChessTable.jsx N2', res.data)
          setContext({ ...context, userAnswer: 'false' })
        }).catch(err => {
          console.error('Load testing error catch, components/common/ChessTables/RepeatChessTable.jsx N2', err)
        })
      }
      playMoveSound(incorrect);
      game.undo();
      attemptsCounter();
    }
  };

  const onDrop = ({ sourceSquare, targetSquare }) => {
    let move = game.move({
      from: sourceSquare,
      to: targetSquare,
      promotion: "q" // always promote to a queen for example simplicity
    });

    return makeMove(move)
  };

  const onSquareClick = square => {
    let turn = game.turn();

    let moves = game.moves({
      square: square,
      verbose: true
    });

    setPieceSquare(square);
    if (showAvailableMoves) {
      setSquareStyles({ ...getStylesOfAvailableMoves(moves), [square]: { boxShadow: 'inset 0 0 0 2px rgb(57, 91, 93)' } })
    } else {
      setSquareStyles({ [square]: { boxShadow: 'inset 0 0 0 2px rgb(57, 91, 93)' } });
    }

    let move = game.move({
      from: pieceSquare,
      to: square,
      promotion: "q" // always promote to a queen for example simplicity
    });

    return makeMove(move, turn)
  };

  const onElementPlay = (el) => {
    let squareToHighlite = game.moves({ verbose: true }).filter(item => item.san === el)[0];
    squareToHighlite && setSquareStyles({
      [squareToHighlite.to]: { backgroundColor: CHESS_HINT_COLOR },
      [squareToHighlite.from]: { backgroundColor: CHESS_HINT_COLOR }
    });
    game.move(el);
    setFen(game.fen());
    setHistory(game.history());
    setPieceSquare('');
  };

  const onPlayClick = (resolve) => {
    if (isPlaying) {
      return false;
    }
    let arr = halfMovesArray.filter(el => !history.includes(el));
    let currentEl = 0;

    if (arr.length !== 0) {
      setIsPlaying(!isPlaying);
    } else {

      setIsPlaying(false);
    }

    let timerId = setInterval(() => {
      if (arr[currentEl]) {
        onElementPlay(arr[currentEl], timerId);
        currentEl += 1;
      } else if (!arr[currentEl]) {
        setSquareStyles({});
        setIsPlaying(false);
        clearInterval(timerId)
        if (resolve) return setTimeout(() => resolve(complete ? 'correct' : 'error'), 1000)
      }
    }, 2400);
    setIntervalId(timerId);
  };

  const onPlayDisabled = useCallback(() => {
    clearInterval(intervalId);
    setIntervalId('');
    setSquareStyles({});
    setIsPlaying(false);
  }, [intervalId]);

  const onResetClick = useCallback(() => {
    game.load(startFen);
    setIsPlaying(false);
    intervalId && clearInterval(intervalId);
    setFen(game.fen());
    setHistory([]);
    setPieceSquare('');
    setMode(MODES.PLAY);
    setCurrentMoveNumber(0);
    setSquareStyles({});
  }, [game, intervalId]);

  const onBackClick = useCallback(() => {
    game.undo();
    setFen(game.fen());
    setHistory(game.history());
    setPieceSquare('');
  }, [game]);

  const onNextClick = useCallback(() => {
    let stepNumber = history.length;
    game.move(halfMovesArray[stepNumber]);
    setFen(game.fen());
    setHistory(game.history());
    setPieceSquare('');
  }, [history, halfMovesArray, game]);

  const onStartClick = useCallback((serviceMode = 'default') => {
    setIsPlaying(false);
    intervalId && clearInterval(intervalId);
    game.load(startFen);
    setFen(startFen);
    setHistory([]);
    setPieceSquare('');
    setCurrentMoveNumber(0);
    setSquareStyles({});

    if (serviceMode === 'default') {
      if (mode === MODES.PLAY) {
        setMode(MODES.GAME)
      } else {
        setMode(MODES.PLAY)
      }
    }
  }, [game]);

  const onHintClick = useCallback(() => {
    if (maxHintsCount === hintsIsUsed) {
      return null
    }
    attemptsCounter();
    let squareToHighlite = game.moves({ verbose: true }).filter(el => el.san === halfMovesArray[currentMoveNumber])[0];
    squareToHighlite && setSquareStyles({ [squareToHighlite.to]: { backgroundColor: 'yellow' }, [squareToHighlite.from]: { backgroundColor: 'yellow' } });
    setHintsIsUsed(hintsIsUsed + 1);
  }, [game, halfMovesArray, hintsIsUsed]);

  return (
    <>
      <div className={'chess-display__chessboard'} ref={containerRef}>
        {mode === MODES.PLAY && <div className='chess-display__description-overlay' />}
        {window.innerWidth > 300 && <DrawOverlay ref={chessRef} squareWidth={setBoardWidth(serviceMode) / 8} containerRef={containerRef} fen={fen} />}
        <div ref={chessRef}>
          <Chessboard
            id="Repeat"
            calcWidth={() => setBoardWidth(serviceMode)}
            orientation={orientation}
            position={fen}
            onDrop={onDrop}
            lightSquareStyle={chessBoardColor?.LIGHT || ''}
            darkSquareStyle={chessBoardColor?.DARK || ''}
            boardStyle={BOARD_STYLE}
            squareStyles={squareStyles}
            dropSquareStyle={DROP_SQUARE_STYLE}
            onSquareClick={onSquareClick}
            undo={true}
            transitionDuration={TRANSITION_DURATION}
            pieces={PIECES}
          />
        </div>
      </div>
      <TaskText
        isCourse={serviceMode !== 'webinar'}
        counter={counter}
        handleClickOrientation={handleClickOrientation}
        onPlayClick={() => onPlayClick()}
        onBackClick={onBackClick}
        onNextClick={onNextClick}
        onResetClick={onResetClick}
        onStartClick={onStartClick}
        onHintClick={onHintClick}
        game={game}
        history={history}
        isEnd={isExerciseEnd}
        onPlayDisabled={onPlayDisabled}
        hintIsAvailable={maxHintsCount > hintsIsUsed}
        isPlaying={isPlaying}
        mode={mode}
        serviceMode={serviceMode}
        title={title}
      />
      {serviceMode === 'webinar' && exerciseMessage &&
        <div className={classnames('chess-display_webinar__message', {
          'chess-display_webinar__message_correct': complete,
          'chess-display_webinar__message_incorrect': !complete
        })}>
          {exerciseMessage}
          <p>{complete ? `+${points} ${t('webinar_rating.points')}` : `0 ${t('webinar_rating.points')}`}</p>
        </div>
      }
      <audio id="audio" src={require('../../../assets/audio/move.mp3')} ref={moveSound} />
      <audio id="audio" src={require('../../../assets/audio/correct.wav')} ref={correct} />
      <audio id="audio" src={require('../../../assets/audio/incorrect.wav')} ref={incorrect} />
    </>
  )
};

export default function RepeatChessTable({ counter, startFen, pgn, successCallback, points, TaskText, title, serviceMode, id, lessonId }) {
  const sound = useSelector(state => state.settings.sound);
  const moves = useSelector(state => state.settings.moves);

  const [orientation, setOrientation] = useState('white');

  const handleClickOrientation = (str) => {
    setOrientation(str);
  };

  return (
    <RepeatChessLogic
      counter={counter}
      startFen={startFen}
      pgn={pgn}
      id={id}
      title={title}
      points={points}
      successCallback={successCallback}
      TaskText={TaskText}
      orientation={orientation}
      handleClickOrientation={handleClickOrientation}
      enableSound={sound}
      serviceMode={serviceMode}
      showAvailableMoves={moves}
      lessonId={lessonId}
    />
  );
}
