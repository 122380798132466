export class WebinarService {
  #socket = null;

  constructor(socket) {
    this.#socket = socket;
  }

  onEnd(handler) {
    this.#socket.on('training_end', handler);
  }

  offEnd(handler) {
    this.#socket.off('training_end', handler)
  }

  onRating(handler) {
    this.#socket.on('rating', handler);
  }

  onExercise(handler) {
    this.#socket.on('exercise', handler);
  }

  offExercise(handler) {
    this.#socket.off('exercise', handler);
  }

  onStart(handler) {
    this.#socket.on('start_change', handler);
  }

  onBan(handler) {
    this.#socket.on('is_banned', handler);
  }

  offBan(handler) {
    this.#socket.off('is_banned', handler);
  }

  onPause(handler) {
    this.#socket.on('pause', handler);
  }

  onTeacherAnswers(handler) {
    this.#socket.on('teacher_answers', handler);
  }

  offTeacherAnswers(handler) {
    this.#socket.off('teacher_answers', handler);
  }

  disconnect() {
    this.#socket.disconnect();
  }
}
