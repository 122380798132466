import React, { useCallback, useEffect, useState } from "react";
import style from "./style.module.scss";
import { useTranslation } from "react-i18next";
import { ReactComponent as CloseIcon } from "../../../../../assets/img/close-black.svg";
import Exercise from "./Exercise";
import { GreenPlus } from "../../../../../assets/img/partners";
import { useDispatch, useSelector } from "react-redux";
import { openOverlayAction } from "../../../../../actions/overlay";
import SuperCategories from "./AddExercise/SuperCategories";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import TimePicker from "./Exercise/TimePicker";

function parseTimeString(time_string) {
    const _time_string = time_string.slice();
    const _time = _time_string.split(':').reverse();
    const multiplier = {
        0: 1,
        1: 60,
        2: [60, 60]
    }
    let time_in_seconds = 0;
    for (let x = 0; x < _time.length; x ++) {
        const num = parseInt(_time[x]);
        if (Array.isArray(multiplier[x])) {
            for (const m of multiplier[x]) {
                time_in_seconds += num * m;
            }
            continue;
        }
        time_in_seconds += num * multiplier[x];
    }
    return time_in_seconds;
}

function toHHMMSS (secs) {
    var sec_num = parseInt(secs, 10);
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    return hours+':'+minutes+':'+seconds;
}

const Exercises = ({ selectedExercises, closeModal, setValue }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const training_lesson = useSelector(state => state.webinar.training_lesson);
    const [exercises, setExercises] = useState(selectedExercises || []);
    const [totalExerciseTime, setTotalExerciseTime] = useState('');

    useEffect(() => {
        sessionStorage.setItem('exercises', JSON.stringify({ selectedExercises: exercises }))
        let total_time = 0;
        for (const exercise of exercises) {
            const duration = exercise.duration;
            const time = parseTimeString(duration);
            total_time += time;
        }
        setTotalExerciseTime(toHHMMSS(total_time));
        // Автосохранение при случайном закрытии модалки
        return () => {
            setValue('exercises', [...exercises]);
        }
    }, [exercises])

    const deleteExercise = (id) => {
        const newArr = [...exercises.filter(exercise => exercise.id !== id)];
        setExercises(newArr);
    }

    const changeTime = (time, timeProperty, id) => {
        const newArr = [...exercises.map(exercise => {
            if (exercise.id === id) return { ...exercise, [timeProperty]: time }
            else return exercise
        })];
        setExercises(newArr);
    }

    const openRootModal = useCallback(() => {
        dispatch(openOverlayAction(Exercises, { ...JSON.parse(sessionStorage.getItem('exercises')), setValue }))
    }, []);


    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragExercise = (result) => {
        if (!result.destination) return
        const items = reorder(exercises, result.source.index, result.destination.index)
        setExercises(items)
    }

    const getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: "none",
        background: isDragging ? "#74B7BB" : "#fff",
        transform: isDragging ? 'rotate(10deg)' : 'rotate(0deg)',
        ...draggableStyle
    });

    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? "lightblue" : "lightgrey",
    });

    const openExerciseAdding = () => {
        dispatch(openOverlayAction(
            SuperCategories,
            { exercises, setValue },
            false,
            true,
            openRootModal
        ))
    }


    const handleSubmit = () => {
        setValue('exercises', [...exercises]);
        closeModal();
    };
    const is_training = training_lesson.value === true;
    return (
        <div className={style.container}>
            <div className={style.content}>
                <div className={style.content__title}>
                    <div></div>
                    {
                        is_training ?
                        <div style={{
                            display: 'flex'
                        }}>
                            <p style={{
                                marginRight: '5px'
                            }}>{t('partners.exercises')}</p>
                            <p style={{
                                opacity: .6
                            }}>{t('partners.trainingLessonModeLabel')}</p>
                        </div>
                        :
                        <p>{t('partners.exercises')}</p>
                    }

                    <button onClick={closeModal}><CloseIcon /></button>
                </div>
                <table className={style['content-table']}>
                    <thead>
                        <tr>
                            <th width="5%" className={style['content-table__number']}>№</th>
                            <th>{t('partners.course')}</th>
                            <th>{t('partners.lesson')}</th>
                            <th>{t('partners.lessonName')}</th>
                            {
                                !is_training &&
                                <>
                                    <th>{t('partners.duration')}</th>
                                    <th>{t('partners.pause')}</th>
                                </>
                            }
                            <th width="10%"></th>
                        </tr>
                    </thead>

                    <DragDropContext onDragEnd={onDragExercise}>
                        <Droppable droppableId="droppable">
                            {(provided) => (
                                <tbody {...provided.droppableProps}
                                    ref={provided.innerRef}>
                                    {
                                        exercises.map((exercise, index) =>
                                            <Draggable key={exercise.id} draggableId={`${exercise.id}`} index={index}>
                                                {(provided, snapshot) => (
                                                    <tr className={style.exercise} ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps} style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                        )}>
                                                        <Exercise
                                                            key={exercises.id}
                                                            number={index + 1}
                                                            exercise={exercise}
                                                            deleteExercise={deleteExercise}
                                                            changeTime={changeTime}
                                                            is_training={is_training}
                                                        />
                                                    </tr>
                                                )}
                                            </Draggable>
                                        )
                                    }
                                    {provided.placeholder}
                                </tbody>
                            )}

                        </Droppable>
                    </DragDropContext>

                </table>
                <button className={style.content__addExercise} onClick={openExerciseAdding}>
                    <div className={style.content__addExercise__icon}><GreenPlus /></div>
                    <p>{t('partners.addExercise')}</p>
                </button>
                
            </div>
            <div style={{
                display: 'flex'
            }}>
            {
                !is_training && exercises.length ?
                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    marginRight: '10px'
                }}>
                    <p style={{
                        marginRight: '5px',
                        fontSize: '16px',
                        fontWeight: 'bold'
                    }}>
                        {
                            `${t('exercises.totalExerciseTimeLabel')}: `
                        }
                    </p>
                    <TimePicker
                    timeProperty={'duration'}
                    time={totalExerciseTime}
                    id={'total_exercise_time'}
                    changeTime={() => {}}
                    />
                </div>
                :
                <></>
            }
                <button className={style.saveButton} onClick={handleSubmit}>{t('partners.save')}</button>
            </div>

        </div>
    );
};

export default Exercises;
