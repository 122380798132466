import React from 'react';
import Main from '../components/Main';
import { Redirect, Route, Switch } from 'react-router-dom';
import MyCourses from '../components/Pages/MyCourses';
import Sections from '../components/Pages/Sections';
import Course from '../components/Pages/Course';
import Lesson from '../components/Pages/Lesson';
import PlayWithBotPage from '../components/Pages/PlayWithBotPage';
import VkLogin from '../components/Login/VkLogin';
import About from '../components/Pages/About';
import Profile from '../components/Pages/Profile';
import SettingsPage from '../components/Pages/SettingsPage';
import AppleLogin from '../components/Login/AppleLogin';
import SearchPage from '../components/Pages/SearchPage';
import Subscription from '../components/Pages/Subscription';
import Politics from '../components/Pages/Politics';
import SupportPage from '../components/Pages/SupportPage';
import PrivateRoute from '../components/common/PrivateRoute';
import MistakesTable from '../components/Pages/Profile/MistakesTable';
import BuyCourse from '../components/Pages/BuyCoursePage';
import Webinar from '../components/Pages/Webinar';
import { DurationContextProvider, WebinarContextProvider } from '../constants/contexts';
import ABClass from '../components/Pages/ABClass';
import Achievements from '../components/Pages/Rating/components/Subpages/Achievements';
import ChessKnowledge from '../components/Pages/Rating/components/Subpages/ChessKnowledge';
import CognitiveSkills from '../components/Pages/Rating/components/Subpages/CognitiveSkills';
import MyRating from '../components/Pages/Rating/components/Subpages/MyRating';
import Top from '../components/Pages/Rating/components/Subpages/Top';
import QualitySolvedExercises from '../components/Pages/Rating/components/Subpages/QualitySolvedExercises';

const Router = () => {
  return (
    <Switch>
      <Route path='/' exact component={Main} />
      <Route path='/courses' component={Sections} />
      <Route path='/course/:id' exact component={Course} />
      <Route path='/super-categories/:url' exact component={Sections} />
      <Route path='/super-categories/:url/course/:id/lesson/:id' exact component={Lesson} />
      <Route path='/super-categories/:url/:id' exact component={Sections} />
      <Route path='/super-categories/:url/course/:id' exact component={Course} />
      <Route path='/course/:id/lesson/:id' component={Lesson} />
      <Route path='/play' component={PlayWithBotPage} />
      <Route path='/vk_auth' component={VkLogin} />
      <Route path='/apple_auth' component={AppleLogin} />
      <Route path='/about' component={About} />
      <Route path='/politics' component={Politics} />
      <Route path='/search' component={SearchPage} />
      <Route path='/course-buy' component={BuyCourse} />
      <Route path='/subscription' component={Subscription} />
      <Route path='/profile/mistakes' component={MistakesTable} />
      <PrivateRoute path='/my-courses' component={MyCourses} />
      <PrivateRoute path='/profile' component={Profile} />
      <PrivateRoute path='/settings' component={SettingsPage} />
      <PrivateRoute exact path='/rating' component={MyRating} />
      <PrivateRoute path='/rating/achievements' component={Achievements} />
      <PrivateRoute path='/rating/chess_knowledge' component={ChessKnowledge} />
      <PrivateRoute path='/rating/cognitive_skills' component={CognitiveSkills} />
      <PrivateRoute path='/rating/exercises' component={QualitySolvedExercises} />
      <PrivateRoute path='/rating/top' component={Top} />
      <Route path='/abclass' component={ABClass} />
      <Route path='/support' component={SupportPage} />
      <Route
        path='/webinar/:id'
        render={(props) => (
          <DurationContextProvider>
            <WebinarContextProvider>
              <Webinar {...props} end={false} />
            </WebinarContextProvider>
          </DurationContextProvider>
        )}
      />
      <Route
        path='/webinar-end/:id'
        render={(props) => (
          <DurationContextProvider>
            <WebinarContextProvider>
              <Webinar {...props} end={true} />
            </WebinarContextProvider>
          </DurationContextProvider>
        )}
      />
      <Route render={() => <Redirect to='/' />} />
    </Switch>
  );
};

export default Router;
