import React from "react";

export default function Loader1() {
  return (
    <div className='loader'>
      <span className={'logo loader__item'}/>
      <span className={'logo loader__item'}/>
      <span className={'logo loader__item'}/>
    </div>
  )
}