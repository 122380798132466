import React, {useEffect, useState} from "react";
import style from "./style.module.scss";
import {useTranslation} from "react-i18next";
import {getLevels} from "../../../../../services/api";
import i18next from "i18next";

const Program = () => {
    const [categories, setCategories] = useState([]);
    const {t} = useTranslation();

    useEffect(() => {
        getLevels()
            .then(res => setCategories(res.data))
    }, [])

    //Временное решение убрать секцию с планом до локализации
    if(i18next.language !== 'rus'){
      return null;
    }

    return (
        <div className={style.container}>
            <p className={style.title}>{t('abclass.training')}</p>
            {
                categories.map(category => (
                    <div key={category.id} className={style.category}>
                        <p className={style.category__title}>{category.name}</p>
                        
                        <div className={style['category-levels']}>
                            {
                                category.levels.map(level => (
                                    <div className={style['category-levels__level']} key={level.id}>
                                        <div className={style['category-levels__level__id']} style={{background: `#${level.color}`}}>
                                            <p>{level.name}</p>
                                        </div>
                                        <div className={style['category-levels__level-info']}>
                                            <p className={style['category-levels__level-info__name']}>{level.title}</p>
                                            {
                                                level.subjects.map((lesson, index) => (
                                                    <div key={lesson.id} className={style['category-levels__level-info__lesson']}>{lesson.name}{lesson.title && " - "}{lesson.title}</div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default Program;