import style from "./style.module.scss";
import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { io } from "socket.io-client";

import { TwitchIcon, YoutubeIcon, ZoomIcon } from "assets/img/social_icons";
import { lessonAuth, webinarSocketUrl, trainingWebinarSocketUrl } from "services/api";
import { WebinarContext } from "constants/contexts";
import useResolution from "hooks/useResolution";
import Title from "components/common/Title/Title";
import * as Sentry from '@sentry/react'

const Button = ({ onClick = () => { }, text, disabled = false, className = '' }) => {
  return (
    <button
      className={`${style['default-button']} ${disabled && style['disabled']} ${className}`}
      disabled={disabled}
      onClick={(e) => { onClick(e) }}>
      {text}
    </button>
  )
}

const Line = () => {
  return (
    <div className={style['line']}></div>
  )
}

const SignIn = ({
  is_training,
  hash,
  zoomLink,
  webinarPassword,
  note
}) => {
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { context, setContext } = useContext(WebinarContext);
  const { t } = useTranslation();
  const isMobile = useResolution(541)
  const notDesktop = useResolution(1280);

  useEffect(() => {
    setPassword(webinarPassword);
  }, [webinarPassword])

  const enterClick = (e) => {
    if (e.key === 'Enter') {
      getAccess()
    }
  }

  const getAccess = () => {
    setLoading(true);
    lessonAuth(hash, password)
      .then(res => {

        const socketEndpoint = is_training 
                                ? trainingWebinarSocketUrl 
                                : webinarSocketUrl;

        setContext({
          ...context,
          accessToken: res.data.session_token,
          hash: hash,
          status: res.data.class_status,
          socket: io(socketEndpoint(hash), {
            extraHeaders: {
              "Class-Session": res.data.session_token
            }
          })
        })
      })
      .catch(err => {
        console.error('lessonAuth error', err);
        if (err?.response?.status === 403) setError(t('webinar.notAccess'))
        else setError(t('webinar.incorrectPassword'))
        Sentry.captureException('Login exception')
      })
      .finally(() => {
        setLoading(false);
      })
  }

  const onOpenHrefHandler = (e, link) => {
    e.preventDefault()
    window.open(link, "_blank")
  }

  return (
    <div className={style.wrapper} style={{ marginTop: !context.isHeaderShow ? isMobile ? '0' : '40px' : '0px' }}>
      <div className={style.form}>
        <div className={style.header}>
          <p className='login__title'>
            AB-Class
          </p>
        </div>
        <div className={style.main} style={{ padding: zoomLink ? '20px 25px 20px 20px' : '20px' }}>
          {zoomLink && //zoomlink
            <>
              <Title number={1} text={t('webinar.connect2Stream')} />
              <div className={style['stream-links-row']}>
                <div className={style['icons-row']}>
                  <ZoomIcon />
                  <YoutubeIcon />
                  <TwitchIcon />
                </div>
                <div className={style['button-wrapper']}>
                  <Button className={style['connect-button']} onClick={(e) => { onOpenHrefHandler(e, zoomLink) }} text={t('webinar.connectToZoom')} />
                </div>
              </div>
              <Line />
              <Title number={2} text={t('webinar.enter2Webinar')} />
            </>
          }

          <div className={style['login-wrapper']}>
            <input
              className={style['login-wrapper-input']}
              value={password}
              onKeyDown={enterClick}
              onChange={e => setPassword(e.target.value)}
              placeholder={t('auth.password')}
              style={{ margin: zoomLink ? !isMobile ? '10px 0 10px 15px' : '10px 0 20px' : '10px 0 20px' }}
            />
            {
              !notDesktop &&
              <note.Component show={note.condition} height={'fixed'} />
            }
            <div className={style['button-wrapper']}>
              <Button className={style['full-of-parent']} onClick={getAccess} disabled={!password || loading} text={t('webinar.enter2WebinarBtn')} />
            </div>
            {error && <p className='login__error'>{error}</p>}
          </div>
        </div>
        {
          notDesktop &&
          <note.Component show={note.condition} />
        }
      </div>
    </div>
  )
}

export default SignIn;
