import React, { useEffect, useState } from 'react';
import style from './Icon.module.scss'
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

const Icon = ({name, achievement, userAchieved, image, description}) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false)

  const onInfoClick = () => {
    setVisible(true)
    setTimeout(() => {
      setShowMessage(true);
    }, 2)
  };

  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    let timer;
    if (showMessage) {
      timer = setTimeout(() => {
        setShowMessage(false);

        setTimeout(() => {
          setVisible(false)
        }, 500)
      }, 3000);
    }

    return () => clearTimeout(timer);
  }, [showMessage]);

  return (
    <div className={style.icon} >
      <img
        className={style.achievement__image}
        src={image}
        alt={t('rating.header.achievements')}
        style={{filter: !userAchieved && 'brightness(40%)'}}
        onClick={() => {
          onInfoClick(name)
          console.log(achievement)
        }}
      />
      <div className={classnames(style.message, { [style['message__active']]: showMessage })}
           style={{display: !visible && 'none'}}
      >
        {/*{name}*/}
        <h1 className={style.name}>{name}</h1>
        <p className={style.description}>{description}</p>
      </div>
    </div>
  );
};

export default Icon;