import React, { useMemo } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';

const Answer = (props) => {
  const { answer } = props;

  const answerTextStyles = classNames(styles.answer__text, {
    [styles.answer_correct]: answer?.answer_correct === true,
    [styles.answer_incorrect]: answer?.answer_correct === false,
  });

  return (
    <p className={styles.answer}>
      {answer.icon && <span className={styles.answer__icon}>{answer.icon}</span>}
      <span className={answerTextStyles}>{answer.data}</span>
    </p>
  );
};

export const AnswersLog = (props) => {
  const { answers } = props;

  const groupedByUserAnswers = useMemo(() => {
    const grouped = answers.reduce((acc, answer) => {
      if (acc[answer.user]) {
        acc[answer.user].push(answer.answer);
      } else {
        acc[answer.user] = [answer.answer];
      }
      return acc;
    }, {});

    return Object.entries(grouped);
  }, [answers]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.answersList}>
        {groupedByUserAnswers &&
          groupedByUserAnswers.map(([user, answers]) => (
            <div key={user} className={styles.answersGroup}>
              <p className={styles.answersGroup__title}>
                Ответ от <strong>{user}</strong>:
              </p>
              {answers && answers.map((data, ind) => <Answer answer={data} key={ind} />)}
            </div>
          ))}
      </div>
    </div>
  );
};
