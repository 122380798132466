import React, {useState} from 'react';
import './questionContainer.scss';

export default function QuestionContainer({question, children}) {
  const [open, setOpen] = useState(false);
  return (
    <div className='q-container'>
      <p className='q-container__title' onClick={() => setOpen(!open)}>{question}</p>
      {open &&
        <div className='q-container__answer'>
          {children}
        </div>
      }
    </div>
  )
}