import React from 'react';
import {useTranslation} from "react-i18next";


function StudySliderHead() {
  const {t} = useTranslation();
  return (
      <div className='study-slider__header'>
        <p className='study-slider__title'>{t('main.welcome')}</p>
      </div>
  );
}

export default StudySliderHead;