import React, { useContext } from 'react'
import './ExerciseTitle.scss'
import { Counter } from "../../../../../../assets/img/webinar";
import Fullsize from 'components/common/WindowButtons/Fullsize/Fullsize';
import { WebinarContext } from 'constants/contexts';

/**
 * 
 * @param {string} counter No. of the exercise / amount of all exercises

 * @param {function} setOpen function which toggles the header
 * @param {boolean} open state of header
 * @param {string} title title of the exercise
 * @returns component which shows current exercise and the webinar statement
 */


export default function ExerciseTitle({ counter, setOpen, open, title, isCourse }) {

    const { context, setContext } = useContext(WebinarContext);

    const state = !isCourse ? !context?.isHeaderShow : true

    const toggleHeader = () => {
        let state = !context.isHeaderShow

        if (!localStorage.hasOwnProperty('headerState') || JSON.parse(localStorage.getItem('headerState')) !== state) {
            localStorage.setItem('headerState', `${state}`)
        }

        setContext({ ...context, isHeaderShow: state })
    }


    return (
        <section className={`exercise-title__wrapper ${isCourse && 'hide-title'}`}>
            <div className={`exercise-title__exercise ${isCourse && 'hide-title'}`}>
                {!isCourse &&
                    <>
                        <div className="exercise-title__exercise-icon">
                            <Counter />
                        </div>
                        <div className="exercise-title__exercise-counter">
                            {counter}
                        </div>
                    </>
                }
            </div>
            <div className="exercise-title__exercise-title">{title}</div>
            <div className={`exercise-title__exercise-fullsize ${isCourse && 'hide-title'}`}>{!isCourse && <Fullsize onClick={toggleHeader} open={state} />}</div>

        </section>
    )
}