import React, {useRef, useState} from "react";
import style from "./style.module.scss";
import {useOnClickOutside} from "../../../../hooks/outsideClick.hook";
import {CaretIcon} from "../../../../assets/img/partners";
import classnames from "classnames";

const MobileMenu = ({links, setType, lessonType}) => {
    const ref = useRef(null);
    const [isOpen, toggleMenu] = useState(false);

    useOnClickOutside(ref, () => toggleMenu(false))

    return (
        <div
            ref={ref}
            onClick={() => toggleMenu(!isOpen)}
            className={classnames(style.mobileMenu, {
                [style.mobileMenu_open]: isOpen
            })}
        >
            <span>{links.find(item => item.query === lessonType).text}</span> <CaretIcon />
            <div className={style['mobileMenu-options']}>
                {
                    links.map(item => (
                            <div
                                className={style['mobileMenu-options__option']}
                                onClick={() => {
                                    setType(item.query)
                                }}
                                key={item.query}
                            >
                                {item.text}
                            </div>
                        )
                    )
                }
            </div>
        </div>
    )
}

export default MobileMenu;