import VimeoComponent from "../../../common/CourseInterface/MainDisplay/VideoDisplay/Vimeo";
import React from "react";

export default function PreviewModal({handleClose, previewVideo}) {
  return (
    <div className='buy-course__preview'>
      <button className={'buy-course__preview-close'} type={'button'} onClick={handleClose}/>
      <VimeoComponent data={{}} videoSrc={previewVideo}/>
    </div>
  )
}