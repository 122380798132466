import { USER__RANKING } from '../../../../constants/rankings';

export function calculateRanking(rating) {
  if (rating < 400)
    return USER__RANKING.pawn__rookie
  else if ((rating >= 400) && (rating < 600))
    return USER__RANKING.agile__footman
  else if ((rating >= 600) && (rating < 800))
    return USER__RANKING.horse__schemer
  else if ((rating >= 800) && (rating < 1000))
    return USER__RANKING.elephant__hunter
  else if ((rating >= 1000) && (rating < 1200))
    return USER__RANKING.attack__master
  else if ((rating >= 1200) && (rating < 1400))
    return USER__RANKING.debut__architect
  else if ((rating >= 1400) && (rating < 1700))
    return USER__RANKING.rook__endgamer
  else if ((rating >= 1700) && (rating < 2000))
    return USER__RANKING.master__strategist
  else if ((rating >= 2000) && (rating < 2300))
    return USER__RANKING.queen__commander
  else if ((rating >= 2300) && (rating < 2600))
    return USER__RANKING.king__master
  else if ((rating >= 2600) && (rating < 2900))
    return USER__RANKING.champion
  else if ((rating >= 2900) && (rating < 4000))
    return USER__RANKING.great__grandmaster
}