export const getCurrency = (number, curr) => {
  if(number && curr) {
    return new Intl.NumberFormat('ru-RU', { maximumSignificantDigits: 3, style: 'currency', currency: curr }).format(number);
  } else if (number) {
    return new Intl.NumberFormat('ru-RU', { maximumSignificantDigits: 3 }).format(number);
  } else {
    return null;
  }
};

export const getPriceFromPaddleGross = (str) => {
  if(!str) {
    return ''
  }
  let letters = str.replace(/[0-9.,]/gi, "");
  let digits = str.split(letters)[1];
  return `${digits} ${letters}`
};
