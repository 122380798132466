import React from 'react';
import './or.scss';
import {useTranslation} from "react-i18next";

export default function Or() {
  const {t} = useTranslation();

  return (
    <div className='or'>
      <span className='or__dotes' />
      <span className='or__text'>{t('main.or')}</span>
      <span className='or__dotes' />
    </div>
  )
}