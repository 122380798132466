import React, {useEffect} from 'react';
import Loader1 from "../common/Loader/loader1";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useHistory} from 'react-router-dom';
import {signInAction} from "actions/signIn";

export default function VkLogin() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const auth = useSelector(state => state.auth.authenticated);

  useEffect(() => {
    if(auth) {
      history.push('/');
    }
  }, [auth]);

  useEffect(() => {
    if(location.hash) {
      let token = location.hash.split('&')[0].split('=')[1];
      let email = location.hash.split('&')[3].split('=')[1];
      if(token) {
        dispatch(signInAction({email, token}, {login_type: 'vk'}, history));
      }
    }

  }, []);

  return (
    <Loader1/>
  )
}