import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import useDebounce from 'hooks/debounce.hook';
import {useHttp} from 'hooks/http.hook';
import {useWindowSize} from 'hooks/resize.hook';
import SearchResults from './SearchResults';
import './search.scss';
import './searchMobile.scss';
import {getSearchUrl} from "services/api";

function HeaderSearch({closeMenu}) {
  const [open, setOpen] = useState(false);
  const [openResults, setOpenResults] = useState(false);
  const [search, setSearch] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [searchData, setSearchData] = useState(
    {
      "find_categories": [],
      "find_courses": []
    }
  );
  const {t} = useTranslation();
  const {request} = useHttp();
  const history = useHistory();
  const debouncedSearchTerm = useDebounce(search, 500);
  const windowSize = useWindowSize();

  useEffect(() => {
      if (debouncedSearchTerm && debouncedSearchTerm.length >= 3) {
        setIsSearching(true);
         request(getSearchUrl(debouncedSearchTerm))
           .then(res => {
             setIsSearching(false);
             setSearchData(res.data);
           });
      } else {
        setSearchData({"find_categories": [], "find_courses": []})
      }
    },
    [debouncedSearchTerm]
  );


  const onSearchChange = (e) => {
    if(e.target.value < 3) {
      setSearchData({"find_categories": [], "find_courses": []})
    }
    setSearch(e.target.value);
  };


  const searchListHide = () => {
    setTimeout(() => {
      setOpenResults(false);
    }, 400)
  };

  const handleKeyUp = (e) => {
    if(e.keyCode === 13 && search.length >= 3) {
      setOpenResults(false);
      history.push(`/search?key=${search}`);
    }
  };

  const hideResultsMobile = () => {
    setOpen(false);
    setOpenResults(false);
  };

  if(windowSize.width < 600) {
    return (
      <div className='header__item header__item--search search'>
        <span className='search__logo header__search-logo'  onClick={() => {setOpen(true); closeMenu(false)}}/>
        {open &&
          <div className='search__mobile-popup'>
            <div className='search__mobile-container'>
              <span className='login__close-button login__close-button--black' style={{top: '-50px'}} onClick={hideResultsMobile}>
                <svg className='login__close-svg' width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path className='login__close-svg-path login__close-svg-path--black' d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="#000000"/>
                </svg>
              </span>
              <label className='header__search-label'>
                <input
                  className='header__search-input search__input'
                  value={search}
                  onChange={onSearchChange}
                  placeholder={t('main.search')}
                  onFocus={() => setOpenResults(true)}
                  maxLength={50}
                  onKeyDown={handleKeyUp}
                />
              </label>
              {openResults && <SearchResults data={searchData} search={search} hideResults={hideResultsMobile} loading={isSearching}/>}
            </div>
          </div>
        }
      </div>
     )
  }

  return (
    <div className='header__item header__item--search search'>
      <label className='header__search-label'>
        <span className='search__logo header__search-logo'/>
        <input
          className='header__search-input search__input'
          value={search}
          onChange={onSearchChange}
          placeholder={t('main.search')}
          onFocus={() => setOpenResults(true)}
          onBlur={searchListHide}
          maxLength={50}
          onKeyDown={handleKeyUp}
        />
      </label>
      {openResults && <SearchResults data={searchData} search={search} hideResults={searchListHide} loading={isSearching}/>}
    </div>
  );
}

export default HeaderSearch;