import React from "react";
import style from "./style.module.scss";
import classnames from "classnames";
import Button from "../Main/Button";

const Banner = ({title, image, isReverse = false}) => {
    const onClickButton = () => {
        document.querySelector('#lesson_signUp').scrollIntoView({behavior: 'smooth'})
    }

    return (
        <div className={classnames(style.banner, {
            [style.banner_reverse]: isReverse
        })}>
            <div>
                {title}
                <Button onClick={onClickButton} classname={classnames(style.banner__link, {
                    [style.banner__link_reverse]: isReverse
                })} />
            </div>
            <img src={image} alt="banner" />
        </div>
    )
}

export default Banner;